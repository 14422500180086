import {
    Meeting,
    Pagination,
    MeetingRoster,
    StudentPaging,
    ProficiencyTestResults
  } from 'models'
import { PagingUtil, PerPageSize } from '@806/utils'
import { cloneDeep } from 'lodash'

export interface EOYLasLinksState {
  meeting: Meeting
  students: MeetingRoster[]
  pagination: Pagination
  paging: StudentPaging
  saveProficiencyEditsMap: Map<number, ProficiencyTestResults[]>,
}

export const state: EOYLasLinksState = {
  meeting: {},
  students: [],
  pagination: {},
  paging: PagingUtil.initialPaging(PerPageSize.Small),
  saveProficiencyEditsMap: new Map<number, ProficiencyTestResults[]>()
}

export const clearState: EOYLasLinksState = cloneDeep(state)
