import { Getter } from 'vuex'
import { RootState } from '@/store/root'
import { ReviewStudentState } from './state'
import { ReviewStudentGetters } from './getters'
import {
  OralLanguageProficiencyTest,
  AcademicAchievementProficiencyTest,
  MeetingPlacement
} from 'models'
import defaults from 'lodash/defaults'
import isEmpty from 'lodash/isEmpty'
import { RecommendationEdits } from './types'
/**
 * These are too expensive to be getters since they would updated on every edit.
 * They are used in multiple actions.
 */

export const buildTest = (
  state: ReviewStudentState,
  getters: Getter<ReviewStudentState, RootState>
): OralLanguageProficiencyTest => {
  const meetingRosterId = getters[ReviewStudentGetters.MeetingRosterId]
  const studentSchoolGradeId = getters[ReviewStudentGetters.StudentSchoolGradeId]
  const savedOlpt: OralLanguageProficiencyTest = getters[ReviewStudentGetters.Test]
  const savedAapt: AcademicAchievementProficiencyTest = savedOlpt && savedOlpt.aapt
  const { olptEmpty, studentOlptEmpty, aaptEmpty, olpt, aapt } = state.testEdits
  const allEmpty = olptEmpty && studentOlptEmpty && aaptEmpty

  if (olptEmpty) {
    olpt.language = undefined
  }

  if (studentOlptEmpty) {
    olpt.studentLanguage = undefined
  }

  return allEmpty ? null : defaults({
    studentSchoolGradeId,
    meetingRosterId,
    aapt: aaptEmpty ? null : defaults(aapt, savedAapt)
  }, olpt, savedOlpt)
}

export const buildRecommendations = (
  state: ReviewStudentState,
  getters: Getter<ReviewStudentState, RootState>
): MeetingPlacement => {
  const meetingRosterId = getters[ReviewStudentGetters.MeetingRosterId]
  const studentSchoolGradeId = getters[ReviewStudentGetters.StudentSchoolGradeId]
  const savedRecommendations: MeetingPlacement = getters[ReviewStudentGetters.Recommendations]

  const recommendedEdits: RecommendationEdits = isEmpty(state.monitorRecommendiationEdits.programType) ? state.recommendationEdits :
    isEmpty(state.monitorRecommendiationEdits.lepStatus) ?
    { programType: state.monitorRecommendiationEdits.programType, lepStatus: state.recommendationEdits.lepStatus } :
    state.monitorRecommendiationEdits

  return defaults({
    studentSchoolGradeId,
    meetingRosterId
  }, recommendedEdits, savedRecommendations)
}
