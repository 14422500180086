import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { ViewStudentsState } from './state'
import {
  StudentSchoolGrade,
  Student,
  MeetingType,
  AssessmentScoreAttribute,
  StudentAccommodation,
  MeetingPlacement
} from 'models'

export enum ViewStudentsGetters {
  Type = 'TYPE',
  TypeName = 'TYPE_NAME',
  NoStudents = 'NO_STUDENTS',
  StudentSchoolGrade = 'STUDENT_SCHOOL_GRADE',
  Student = 'STUDENT',
  StaarScores = 'STUDENT_SCORES',
  StudentAccommodations = 'STUDENT_ACCOMMODATIONS',
  MeetingPlacement = 'MEETING_PLACEMENT'
}

export const getters: GetterTree<ViewStudentsState, RootState> = {

  [ViewStudentsGetters.Type] (state): MeetingType | void {
    if (state.types) {
      return state.types.find(type => type.code === state.code)
    }
  },

  [ViewStudentsGetters.TypeName] (state, getters): string | void {
    const type: MeetingType = getters[ViewStudentsGetters.Type]

    if (type) {
      return type.name
    }
  },

  [ViewStudentsGetters.StudentSchoolGrade](state): StudentSchoolGrade {
    return state.studentSchoolGrade
  },

  [ViewStudentsGetters.Student](state, getters): Student {
    const studentSchoolGrade: StudentSchoolGrade = getters[ViewStudentsGetters.StudentSchoolGrade]

    if (studentSchoolGrade) {
      return studentSchoolGrade.student
    }
  },

  [ViewStudentsGetters.NoStudents] (state): boolean {
    return state.loaded && state.pagination.unfilteredTotal === 0
  },

  [ViewStudentsGetters.StaarScores] (state): Array<AssessmentScoreAttribute> {
    return state.staarScores
  },

  [ViewStudentsGetters.StudentAccommodations] (state): StudentAccommodation[] {
    return state.studentAccommodations
  },

  [ViewStudentsGetters.MeetingPlacement] (state): MeetingPlacement {
    return state.meetingPlacement
  }

}
