import { StoreOptions } from 'vuex'
import { RootState, state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export * from './types'

export { RootState } from './state'
export { RootGetters } from './getters'
export { RootMutations } from './mutations'
export { RootActions } from './actions'

export const store: StoreOptions<RootState> = {
  state,
  getters,
  mutations,
  actions
}
