import BaseService, { ServiceConfig } from './base'
import { RootState}  from '@/store/root'
import { ZendeskUserRequest } from 'models'


export default class ZendeskService extends BaseService {

private readonly baseUri: string = '/zendesk'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async updateUser(state: RootState, request: ZendeskUserRequest) {
    return await this.post(`${this.baseUri}/users/create_or_update`, { request, state })
  }

  async getZendeskJWT(state: RootState): Promise<string> {
    const { data } = await this.get<string>(`${this.baseUri}/jwt`, state)
    return data
  }

}
