import { Module } from 'vuex'
import { RootState } from '@/store/root/state'
import { state, ReportsState } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export * from './types'

export { ReportsState } from './state'
export { ReportsGetters } from './getters'
export { ReportsMutations } from './mutations'
export { ReportsActions } from './actions'

export const store: Module<ReportsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
