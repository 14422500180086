import {
  Assessment,
  MeetingRoster,
  StudentPaging,
  PagedSelectedItems,
  Pagination,
  OralLanguageProficiencyTest,
  AcademicAchievementProficiencyTest,
  GetAssessmentsRequest,
  TelpasAssessmentDecisionRequest,
  Subject,
  Staff,
  GetTeachersByStudentRequest,
  SelectedStaarJustification,
  SelectedStaarTeacher,
  GetSelectionByStudentSchoolGradeIdRequest,
  RemoveStaarJustificationRequest,
  ProficiencyTestResults,
  TelpasAssessmentDecisionResponse, RosterOptionEdit, SaveRosterTransferOptionsRequest, SetRosterEnrollmentTypeRequest, ControlOption
} from 'models'
import { MeetingTypeCode, ProficiencyTestTypeCode } from '@/enums'

export type StudentId = number
export type TeacherId = number
export type AssessmentId = number
export type AssessmentSubjectId = number
export type AssessmentJustificationId = number
export type SubjectId = number

export interface MeetingEditInterfaceText {
  title: string
  showAllButtonSave: string
  showAllButtonEdit: string
  saveButton: string
  editButton: string
  removeButton: string
  saveAllButton: string
  addMoreButton: string
}

export interface SetTeachersDatumParam {
  studentId: StudentId
  subjectId: SubjectId
  staffs: Staff[]
}

export interface TeacherDatum {
  teacherId: TeacherId
  name: string
  studentId: StudentId
}

export interface SelectedSubject {
  studentId: StudentId
  assessmentId: AssessmentId
  assessmentSubjectId: AssessmentSubjectId
  subjectId: SubjectId
}

export interface SelectedTeacher {
  studentId: StudentId
  assessmentId: AssessmentId
  subjectId: SubjectId
  teacherId: TeacherId
}

export interface SelectStaarSelection {
  studentId: StudentId
  selectedStaarJustifications: SelectedStaarJustification[]
  selectedStaarTeachers: SelectedStaarTeacher[]
}

export type SelectSubjectParams = SelectedSubject

export type SelectJustificationParams = SelectedStaarJustification

export interface ConfirmSubjectMove {
  params: SelectSubjectParams[]
}

export type StudentAssessments = Map<StudentId, Assessment[]>
export type SelectedTeachers = Map<StudentId, SelectedTeacher[]>

export enum SelectSubjectBehavior {
  TOGGLE = 'T',
  CHECK_ONLY = 'CO'
}

export interface StaarJustificationsState {
  behavior: SelectSubjectBehavior,
  confirmSubjectMoveNeeded: boolean
  confirmSubjectMoveText: string
  confirmSubjectMove?: ConfirmSubjectMove
  studentAssessments: StudentAssessments
  selectedSubjects: SelectedSubject[]
  teachersDatum: TeacherDatum[]
  selectedStaarJustifications: SelectedStaarJustification[]
  selectedTeachers: SelectedTeachers
}

export interface MeetingTypeStudents {
  students: MeetingRoster[]
  pagination: Pagination
  paging: StudentPaging
  selected: PagedSelectedItems
}

export interface TestEdits {
  olpt?: OralLanguageProficiencyTest
  aapt?: AcademicAchievementProficiencyTest
  olptEmpty?: boolean
  studentOlptEmpty?: boolean
  aaptEmpty?: boolean
}

export interface OlptSaveEdits {
  meetingRosterId: number,
  studentGradeId: number,
  savedOlpt: OralLanguageProficiencyTest,
  testEdits: TestEdits
}

export interface TestEdits {
  olpt?: OralLanguageProficiencyTest
  aapt?: AcademicAchievementProficiencyTest
  olptEmpty?: boolean
  studentOlptEmpty?: boolean
  aaptEmpty?: boolean
}

export interface OlptSaveEdits {
  meetingRosterId: number,
  studentGradeId: number,
  savedOlpt: OralLanguageProficiencyTest,
  testEdits: TestEdits
}

export type MeetingStudents = {
  [type in MeetingTypeCode]: MeetingTypeStudents
}

export interface MeetingWithType {
  meetingId: number
  meetingType: MeetingTypeCode
}

export interface StudentIdAndMeetingType {
  studentId?: number,
  meetingType: MeetingTypeCode,
  rosterId?: number
}

export interface TeacherDatum {
  value: number,
  name: string
}

export interface SupportOptionsRequest {
  columns: number,
  rosterId?: number
  studentId?: number
}

export interface InitialCLSOptions {
  rosterId: number,
  options: ControlOption[]
}

export type GetMeetingTypeStudents = (meeting: MeetingWithType) => Promise<void>
export type GetStudentsInMeetingType = (meetingType: MeetingTypeCode) => boolean
export type SetMeetingTypePaging = (meetingTypePaging: { meetingType: MeetingTypeCode, paging: StudentPaging }) => void
export type SetMeetingTypeSelected = (meetingTypeSelected: { meetingType: MeetingTypeCode, selected: PagedSelectedItems }) => void
export type SetDialogFlag = (dialogFlag: boolean) => Promise<void>
export type SetRemoveStudentIdAndMeetingType = (studentIdAndMeetingType: StudentIdAndMeetingType) => Promise<void>
export type ClearMeetingType = (meetingType: MeetingTypeCode) => void
export type ClearMeetingTypeSelected = (meetingType: MeetingTypeCode) => void
export type SetTestEditsForStudent = (studentTestEdits: { id: number, olptSaveEdits: OlptSaveEdits }) => void
export type SetProficiencyTestEditsForStudent = (studentProficiencyTestEdits: { id: number, proficiencyTestResults: ProficiencyTestResults}) => void
export type SaveProficiencyTest = (olptSaveEdits: OlptSaveEdits) => void
export type SaveProficiencyTestResults = (saveProficiencyTestRequest: { meetingRosterId: number, studentSchoolGradeId: number, grade: number, proficiencyTestResults: ProficiencyTestResults[] }) => Promise<void>
export type SaveAllProficiencyTest = (saveAllRequest: { meetingId: number, meetingType: MeetingTypeCode }) => void
export type SaveRosterTransferOptions = (request: SaveRosterTransferOptionsRequest) => Promise<void>
export type SetRosterEnrollmentType = (request: SetRosterEnrollmentTypeRequest) => Promise<void>
export type SetProficiencyTestTypeForRoster = (setProficiencyTestTypeRequest: {meetingRosterId: number, proficiencyTestTypeCode: ProficiencyTestTypeCode }) => void
export type SetEditOptions = (ids: RosterOptionEdit) => void
export type GetSupportOptions = (request: SupportOptionsRequest) => Promise<void>

// Decisions
export type GetAssessments = (request: GetAssessmentsRequest) => Promise<void>
export type GetStudentAssessments = (studentId: StudentId) => Assessment[]
export type GetTelpasAssessmentDecisions = (request: TelpasAssessmentDecisionRequest) => TelpasAssessmentDecisionResponse
export type UnitVoidPromise = () => Promise<void>
export type SelectSubjectOrConfirm = (params: SelectSubjectParams) => Promise<void>
export type SelectAllSubjects = (studentId: StudentId, assessmentId: AssessmentId, checked: boolean) => Promise<void>
export type StudentIdPredicate = (studentId: StudentId) => boolean
export type StudentIdAssessmenIdSubjectIdPredicate = (predicate: { studentId: StudentId, assessmentId: AssessmentId, subjectId: SubjectId }) => boolean
export type StudentIdAssessmenIdPredicate = (predicate: { studentId: StudentId, assessmentId: AssessmentId }) => boolean
export type StudentIdAssessmenIdJustificationIdPredicate = (predicate: { studentId: StudentId, assessmentId: AssessmentId, assessmentJustificationId: AssessmentJustificationId }) => boolean
export type SelectJustification = (params: SelectJustificationParams) => Promise<void>
export type GetStudentSelectedSubjects = (studentId: StudentId) => Subject[]
export type GetSelectedStudentJustifications = (studentId: StudentId) => SelectedStaarJustification[]
export type GetTeachersDatumByStudent = (studentId: StudentId) => TeacherDatum[]
export type GetAllSubjectsTeachersDatum = (studentId: StudentId) => TeacherDatum[]
export type GetSelectedTeachersForStudent = (studentId: StudentId) => SelectedTeacher[]
export type SetTeachersDatum = (request: GetTeachersByStudentRequest) => Promise<void>
export type SelectTeacher = (datum: SelectedTeacher) => Promise<void>
export type StudentIdVoidPromise = (studentId: StudentId) => Promise<void>
export type SaveAssessmentVoidPromise = (save: { studentId: StudentId }) => Promise<void>
export type RemoveStaarJustificationPromise = (req: RemoveStaarJustificationRequest) => Promise<void>
export type GetStaarSelectionParams = ({studentId: StudentId, req: GetSelectionByStudentSchoolGradeIdRequest})
export type RemoveUnsavedJustificationIdPromise = (studentId: number) => Promise<void>
export type EmptyPredicate = () => boolean
export type SaveDnqStatus = (meetingRosterId: number) => Promise<MeetingRoster>
