import { ActionTree } from 'vuex'
import { meetingService } from '@/services'
import { RootState } from '@/store/root'
import { SetupCommitteeState } from './state'
import { SetupCommitteeMutations } from './mutations'

export enum SetupCommitteeActions {
  GetTypes = 'GET_TYPES',
  GetRoles = 'GET_ROLES',
  GetAllMembers = 'GET_ALL_MEMBERS',
  GetDefaultMembers = 'GET_DEFAULT_MEMBERS'
}

export const actions: ActionTree<SetupCommitteeState, RootState> = {
  async [SetupCommitteeActions.GetTypes]({ commit, rootState }) {
    const types = await meetingService.getTypes(rootState)
    commit(SetupCommitteeMutations.SetTypes, types)
  },
  async [SetupCommitteeActions.GetRoles]({ commit, rootState }) {
    const roles = await meetingService.getRoles(rootState)

    commit(SetupCommitteeMutations.SetRoles, roles)
  },

  async [SetupCommitteeActions.GetAllMembers]({ commit, rootState }) {
    const members = await meetingService.getAllUsers(rootState)

    commit(SetupCommitteeMutations.SetAllMembers, members)
  },

  async [SetupCommitteeActions.GetDefaultMembers]({ commit, rootState }) {
    const members = await meetingService.getDefaultMembers(rootState)

    commit(SetupCommitteeMutations.SetDefaultMembers, members)
  }
}
