import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { ReportsState } from './state'

export enum ReportsGetters {

}

export const getters: GetterTree<ReportsState, RootState> = {

}
