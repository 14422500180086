import {MutationTree} from 'vuex'
import {clearState, SSOState} from '@/store/modules/sso/state'
import {ProductCode} from '@/enums'
import {cloneDeep} from 'lodash'
import {AppAccessResponse, DistrictInfo, ProductInfoResponse} from 'models';


export enum SSOMutations {
  Clear = 'CLEAR',
  SetProduct = 'SET_PRODUCT',
  SetAccess = 'SET_ACCESS',
  SetProductInfo = 'SET_PRODUCT_INFO',
  SetDistrictInfo = 'SET_DISTRICT_INFO',
  SetToYear = 'SET_TO_YEAR',
  ShowLogin = 'SHOW_LOGIN',
  ShowAccessDenied = 'SHOW_ACCESS_DENIED',
  ShowDistrictNotFound = 'SHOW_DISTRICT_NOT_FOUND',
  Hide = 'HIDE',
  SetLoading = 'SET_LOADING'
}

export const mutations: MutationTree<SSOState> = {

  [SSOMutations.Clear](state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [SSOMutations.SetProduct] (state, product: ProductCode) {
    state.product = product
  },

  [SSOMutations.SetAccess](state, ssoAccess: AppAccessResponse) {
    state.access = ssoAccess
  },

  [SSOMutations.SetProductInfo](state, productInfo: ProductInfoResponse) {
    state.productInfo = productInfo
  },

  [SSOMutations.SetDistrictInfo](state, districtInfo: DistrictInfo) {
    state.districtInfo = districtInfo
  },

  [SSOMutations.SetToYear](state, toYear: number) {
    state.toYear = toYear
  },

  [SSOMutations.ShowLogin](state) {
    state.show = { login: true }
  },

  [SSOMutations.ShowAccessDenied](state) {
    state.show = { accessDenied: true }
  },

  [SSOMutations.ShowDistrictNotFound](state) {
    state.show = { districtNotFound: true }
  },

  [SSOMutations.Hide](state) {
    const { show } = clearState

    Object.assign(state, { show })
  },

  [SSOMutations.SetLoading](state, loading: boolean) {
    state.loading = loading
  }
}
