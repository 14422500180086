import { Mutation } from '@/store/helpers'
import { KeyedNotification, NotificationType, RemoveNotification, RootMutations } from '@/store/root'
import { CreateElement, VNode, VNodeChildren } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonThemeMainColor } from '@806/web'

@Component({
  render (createElement) {
    return this.renderNotification(createElement)
  }
})
export default class Notification extends Vue {

  @Prop({
    type: Object,
    required: true
  })
  protected notification: KeyedNotification

  @Prop({
    type: Number,
    default: 10000
  })
  protected dismissTimeout: number

  @Mutation(RootMutations.RemoveNotification)
  protected removeNotification: RemoveNotification

  get color(): CommonThemeMainColor {
    switch (this.notification.type) {
      case NotificationType.Success:
        return 'success'
      case NotificationType.Info:
        return 'secondary'
      case NotificationType.Warning:
        return 'warning'
    }
  }

  private dismissTimer

  private dismissed = false

  protected dismiss () {
    this.dismissed = true
    this.removeNotification(this.notification)
  }

  protected setDismiss () {
    if (!this.dismissed) {
      const timeout = this.notification.message === 'Requesting Access' ? 2000 : this.dismissTimeout
      this.dismissTimer = setTimeout(() => this.dismiss(), timeout)
    }
  }

  protected clearDismiss () {
    clearTimeout(this.dismissTimer)
  }

  protected mounted () {
    this.setDismiss()
  }

  protected beforeDestroy () {
    this.dismissed = true
    this.clearDismiss()
  }

  protected renderNotification (createElement: CreateElement): VNode {
    const content: VNodeChildren = []

    if (this.notification.title) {
      content.push(createElement('div', {
        class: this.$style.title,
        slot: 'title'
      }, this.notification.title))
    }

    content.push(createElement('div', {
      class: this.$style.message
    }, this.notification.message))

    return createElement('c-alert', {
      class: this.$style.container,
      props: {
        color: this.color
      },
      on: {
        click: this.dismiss,
        mouseenter: this.clearDismiss,
        mouseleave: this.setDismiss
      }
    }, content)
  }

}
