import { MeetingRoster, DualLanguageSelection, MeetingPlacement } from 'models'
import { RecommendationEdits } from './types'
import {MeetingStatus} from '@/enums'

export interface RecommendationsState {
  roster: MeetingRoster
  recommendationEdits: RecommendationEdits,
  monitorRecommendiationEdits: RecommendationEdits,
  dualLanguage: DualLanguageSelection,
  priorMeetingPlacement: MeetingPlacement,
  meetingStatus: MeetingStatus
}

export const state: () => RecommendationsState = () => ({
  roster: null,
  recommendationEdits: {
    programType: null,
    lepStatus: null
  },
  monitorRecommendiationEdits: {
    programType: null,
    lepStatus: null
  },
  dualLanguage: {
    year: null,
    language: null
  },
  priorMeetingPlacement: {},
  meetingStatus: null
})
