import {Module} from 'vuex'
import {RootState} from '@/store/root'
import {state, LayoutState} from '@/store/modules/layout/state'
import {mutations} from '@/store/modules/layout/mutations'
import {getters} from '@/store/modules/layout/getters'
import {actions} from '@/store/modules/layout/actions'

export { LayoutState } from './state'
export { LayoutMutations } from './mutations'
export { LayoutActions } from './actions'
export { LayoutGetters } from './getters'

export const store: Module<LayoutState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
