import { RootState } from '@/store/root'
import { PagingUtil } from '@806/utils'
import BaseService, {ServiceConfig} from './base'
import { DistrictLanguage, DistrictSchoolsResponse, ELSchoolYear,
  ELUserRole, PagedELSchoolsResponse, PagedStaffResponse, ReviewersRequest, SchoolsPaging, SchoolsRequest, Staff, StaffPaging } from 'models'
import { RestUrls } from './RestUrls'
export default class SchoolService extends BaseService {

  private readonly baseUri: string = '/rest/api/school'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getDistrictSchools(state: RootState): Promise<DistrictSchoolsResponse> {
    const { data } = await this.get<DistrictSchoolsResponse>(`${RestUrls.getDistrictSchools}`, state)
    return data
  }

  async getRoles(state: RootState, roleInd: string): Promise<ELUserRole[]> {
    const { data } = await this.get<ELUserRole[]>(`${RestUrls.getRoles}/${roleInd}`, state)
    return data
  }

  async getSchoolYears(state: RootState): Promise<ELSchoolYear[]> {
    const { data } = await this.get<ELSchoolYear[]>(`${RestUrls.getSchoolYears}`, state)
    return data
  }

  async getDistrictLanguages(state: RootState): Promise<DistrictLanguage[]> {
    const { data } = await this.get<DistrictLanguage[]>(`${RestUrls.getDistrictLanguages}`, state)
    return data
  }

  async getPagedSchools(
    state: RootState,
    paging: SchoolsPaging,
    isAdvancedUser: boolean,
  ): Promise<PagedELSchoolsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: SchoolsRequest = { paging, isAdvancedUser }

      const { data } = await this.post<PagedELSchoolsResponse>(`${this.baseUri}/getPagedSchools`, { state, request })

      return data
    })
  }

  async getPagedReviewers(
    state: RootState,
    schoolIds: number[],
    paging: StaffPaging,
    isAdvancedUser: boolean,
  ): Promise<PagedStaffResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: ReviewersRequest = { schoolIds, paging, isAdvancedUser }

      const { data } = await this.post<PagedStaffResponse>(`${this.baseUri}/getPagedReviewers`, { state, request })

      return data
    })
  }

  async getPagedTeachers(
    state: RootState,
    schoolIds: number[],
    paging: StaffPaging,
    isAdvancedUser: boolean,
  ): Promise<PagedStaffResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: ReviewersRequest = { schoolIds, paging, isAdvancedUser }

      const { data } = await this.post<PagedStaffResponse>(`${this.baseUri}/getPagedTeachers`, { state, request })

      return data
    })
  }

  async getReviewers(state: RootState, schoolIds: number[]): Promise<Staff[]> {
    const request: ReviewersRequest = { schoolIds }
    const { data } = await this.post<Staff[]>(`${this.baseUri}/getReviewers`, { state, request })

    return data
  }

  async getTeachers(state: RootState, schoolIds: number[]): Promise<Staff[]> {
    const request: ReviewersRequest = { schoolIds }
    const { data } = await this.post<Staff[]>(`${this.baseUri}/getTeachers`, { state, request })

    return data
  }

}
