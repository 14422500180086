
import { Module } from 'vuex'
import { RootState } from '@/store/root/state'
import { MeetingSummaryState, state } from './state'
import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'

export * from './types'

export { MeetingSummaryState } from './state'
export { MeetingSummaryActions } from './actions'
export { MeetingSummaryMutations } from './mutations'
export { MeetingSummaryGetters } from './getters'

export const store: Module<MeetingSummaryState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
