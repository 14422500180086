import { ActionTree } from 'vuex'
import { meetingService, studentService, signService, decisionsService } from '@/services'
import { OverviewMeetingState } from './state'
import { RootState } from '@/store/root'
import { MeetingWithType } from './types'
import { OverviewMeetingMutations } from '@/store/modules/overview-meeting/mutations'
import defaults from 'lodash/defaults'
import { SignNowRequest, GetStaarStudentNotesRequest, StudentNote, UpdateMeetingNotesRequest, GetStudentNotesRequest, GetMemeberSigningUrlRequest, SaveAccomCommitteeOverrideRequest } from 'models'
import { MeetingTypeCode } from '@/enums'

export enum OverviewMeetingActions {
  GetTypes = 'GET_TYPES',
  GetMeetingTypeStudents = 'GET_STUDENTS',
  SaveStudentReview = 'SAVE_STUDENT_REVIEW',
  SignNow = 'SIGN_NOW',
  GetMemberSigningUrl = 'GET_MEMBER_SIGNING_URL',
  CompleteStudentReviewStatus = 'COMP_STU_RVW_STA',
  LoadAccommodations = 'LOAD_ACCOMMODATIONS',
  LoadSubjectAccommodations = 'LOAD_SUBJECT_ACCOMMODATIONS',
  SaveStudentMeetingNote = 'SAVE_STUDENT_MEETING_NOTE',
  GetStudentStaarNotes = 'GET_STUDENT_STAAR_NOTES',
  GetStudentNotes = 'GET_STUDENT_NOTES',
  SaveLpacCommitteeOverride = 'SAVE_LPAC_COMMITTEE_OVERRIDE',
  RemoveLpacCommitteeOverride = 'REMOVE_LPAC_COMMITTEE_OVERRIDE'
}

export const actions: ActionTree<OverviewMeetingState, RootState> = {

  async [OverviewMeetingActions.GetTypes]({ commit, rootState }) {
    const types = await meetingService.getTypes(rootState)
    commit(OverviewMeetingMutations.SetTypes,types)
  },
  async [OverviewMeetingActions.GetMeetingTypeStudents]({ commit, state, rootState }, { meetingId, meetingType, includeStaarData, includeTelpasDate }: MeetingWithType) {
    const { paging } = state.meetingStudents[meetingType]
    const decisionStudents = []
    const { students, pagination } = await studentService.getOpenRosterStudents(rootState, paging, meetingId, meetingType, decisionStudents, includeStaarData, includeTelpasDate)
    if (paging.page !== pagination.currentPage) {
      commit(OverviewMeetingMutations.SetMeetingTypePaging,{
        meetingType,
        paging: defaults({
          page: pagination.currentPage
        }, paging)
      })
    }

    commit(OverviewMeetingMutations.SetMeetingTypeStudents, { meetingType, students, pagination })
  },
  /* Deprecated */
  // async [OverviewMeetingActions.SaveStudentReview]({ rootState }, request: SaveStudentReviewRequest) {
  //   await studentService.saveStudentReview(rootState, request.meetingRosterId, request.summary, request.test, request.proficiencyTestResults,request.recommendations, request.proficiencyTestTypeCode as ProficiencyTestTypeCode, request.dualLanguage)
  // },

  async [OverviewMeetingActions.SignNow]({ commit, rootState }, request: SignNowRequest) {
    const signUrl: string = await signService.signNow(rootState, request)
    commit(OverviewMeetingMutations.SetCurrentSigningUrl, signUrl)
  },

  async [OverviewMeetingActions.GetMemberSigningUrl]({ commit, rootState }, request: GetMemeberSigningUrlRequest) {
    const resp = await signService.getMemberSigningUrl(rootState, request)
    commit(OverviewMeetingMutations.SetCurrentSigningUrl, resp.signUrl)
  },

  async [OverviewMeetingActions.LoadAccommodations]({ commit, rootState }) {
    const accommodations = await decisionsService.getAccommodations(rootState)
    commit(OverviewMeetingMutations.SetAccommodations, accommodations)
  },

  async [OverviewMeetingActions.LoadSubjectAccommodations]({ commit, rootState }) {
    const subjectAccommodations = await decisionsService.getSubjectAccommodations(rootState)
    commit(OverviewMeetingMutations.SetSubjectAccommodations, subjectAccommodations)
  },

  async [OverviewMeetingActions.SaveStudentMeetingNote]({ rootState }, req: UpdateMeetingNotesRequest) {
    await studentService.saveStudentNote(rootState, req)
  },

  async [OverviewMeetingActions.GetStudentStaarNotes]({ commit, rootState }, req: GetStaarStudentNotesRequest) {
    const studentNoteLst: StudentNote[] = await studentService.getStaarStudentNotes(rootState, req)
    commit(OverviewMeetingMutations.SetStudentNotes, studentNoteLst)
  },

  async [OverviewMeetingActions.GetStudentNotes]({ commit, rootState }, req: GetStudentNotesRequest) {
    const studentNoteLst: StudentNote[] = await studentService.getStudentNotesByMeetingType(rootState, req)
    commit(OverviewMeetingMutations.SetStudentNotes, studentNoteLst)
  },

  async [OverviewMeetingActions.SaveLpacCommitteeOverride]({ commit, rootState }, req: SaveAccomCommitteeOverrideRequest) {
    const data = await decisionsService.saveLpacCommitteeOverride(rootState, req)
    commit(OverviewMeetingMutations.ResetRosterEntry, { meetingType: MeetingTypeCode.StaarDecisions, roster: data.meetingRoster })
  },
  async [OverviewMeetingActions.RemoveLpacCommitteeOverride]({ commit, rootState }, req: SaveAccomCommitteeOverrideRequest) {
    const data = await decisionsService.removeLpacCommitteeOverride(rootState, req)
    commit(OverviewMeetingMutations.ResetRosterEntry, { meetingType: MeetingTypeCode.StaarDecisions, roster: data.meetingRoster })
  }
}
