import { Component, Vue } from 'vue-property-decorator'
import { RootActions } from '@/store/root';
import { Action, AuthGetter } from '@/store/helpers'
import { AuthGetters } from '@/store/modules/auth';
import { ZendeskChatStatus } from '@/components/app/zendesk-chat/types';

@Component({
  name: 'ZendeskChat',
  render: h => h()
})
export default class ZendeskChat extends Vue {

  @Action(RootActions.GetZendeskJwt)
  protected getZendeskJWT: () => Promise<string>

  @AuthGetter(AuthGetters.UserFullName)
  protected userFullName: string

  @AuthGetter(AuthGetters.UserEmail)
  protected userEmail: string

  protected identify() {
    window.zE('webWidget', 'identify', {
      name: this.userFullName,
      email: this.userEmail
    });
  }

  protected prefill() {
    window.zE('webWidget', 'prefill', {
      name: {
        value: this.userFullName
      },
      email: {
        value: this.userEmail
      }
    });
  }

  protected chatLogout() {
    window?.zE('webWidget', 'logout');
  }

  protected chatEnd() {
    window.zE('webWidget:on', 'chat:end', () => {
      this.chatLogout();
      this.identify();
      this.prefill();
    });
  }

  protected chatStatus() {
    window.zE('webWidget:on', 'chat:status', (status) => {
      if (status === ZendeskChatStatus.Offline || status === ZendeskChatStatus.Away) {
        this.identify();
        this.prefill();
      }
    });
  }

  protected loadEvents() {
    this.identify();
    this.prefill();
    this.chatEnd();
    this.chatStatus();
  }

  protected async authenticateUser() {
    const jwtToken = await this.getZendeskJWT();

    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: function (callback) {
              callback(jwtToken)
            }
          }
        }
      }
    };

    this.loadEvents();
  }
  protected async loadScript() {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=88d0d4e4-4310-4c37-bd71-f06dd179e59e';
    document.body.appendChild(script);
  }

  protected async mounted() {
    await this.loadScript();
    await this.$nextTick();
    await this.authenticateUser();
  }
}
