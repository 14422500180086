import cloneDeep from 'lodash/cloneDeep'

export interface LayoutState {
  switchProductsExpanded: boolean
}
export const state: LayoutState = {
  switchProductsExpanded: false
}

export const clearState: LayoutState = cloneDeep(state)

