import cloneDeep from 'lodash/cloneDeep'
import { Meeting, MeetingRoster } from 'models'
import { ParentDecisionEdits } from './types'

export interface ParentDecisionState {
  meeting: Meeting,
  roster: MeetingRoster
  edits: ParentDecisionEdits,
}

export const state: ParentDecisionState = {
  meeting: null,
  roster: null,
  edits: {},
}

export const clearState: ParentDecisionState = cloneDeep(state)
