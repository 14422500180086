export enum RestUrls {
    studentMarkingPeriodGrades = 'grades/student',
    getDistrictSchools = 'rest/api/school/getDistrictSchools',
    getDistrictLanguages = 'rest/api/school/getDistrictLanguages',
    getSchoolYears = 'rest/api/school/getSchoolYears',
    getRoles = 'rest/api/role/getRoles',
    features = 'rest/api/features',
    getDocumentTypes = 'rest/api/document/getDocumentTypes',
    getZipDocument = 'rest/api/document/getZipDocument',
    getZipDocumentStatus = 'rest/api/document/getZipDocumentStatus',
    getSupportOptions = 'grades/student/support-options',
    getDistrictUsers = 'rest/api/users/getDistrictUsers',
    getReportTypes = 'reports',
    getRegeneratingParentLetters = 'rest/api/document/getRegeneratingParentLetters',
    getPotentialExitRegeneratingParentLetters = 'rest/api/document/getPotentialExitRegeneratingParentLetters',
    getCombinedExitLettersDownloadCount = 'rest/api/document/getCombinedExitLettersDownloadCount',
    getMergedParentLetters = 'rest/api/document/downloadMergedExitingParentLetters',
}
