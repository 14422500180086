import { meetingService } from '@/services'
import { RootState } from '@/store/root'
import { CompletedMeetingsState } from './state'
import { CompletedMeetingsMutations } from './mutations'
import { ActionTree } from 'vuex'
import { PrintReclassificationRequest, PrintMeetingMinutesRequest, PrintMeetingRosterRequest } from 'models'
import defaults from 'lodash/defaults'

export enum CompletedMeetingsActions {
  GetPagedMeetings = 'GET_PAGED_MEETINGS',
  PrintReclassificationForm = 'PRINT_RECLASSIFICATION_FORM',
  PrintMeetingMinutesForm = 'PRINT_MEETING_MINUTES_FORM',
  PrintMeetingRosterForm = 'PRINT_MEETING_ROSTER_FORM'
}

export const actions: ActionTree<CompletedMeetingsState, RootState> = {

  async [CompletedMeetingsActions.GetPagedMeetings] ({ commit, state, rootState }) {
    commit(CompletedMeetingsMutations.SetLoaded, false)

    try {

      const { meetings, pagination } = await meetingService.getPagedMeetings(rootState, state.paging, state.roleId)

      if (state.paging.page !== pagination.currentPage) {
        commit(CompletedMeetingsMutations.SetPaging, defaults({
          page: pagination.currentPage
        }, state.paging))
      }

      commit(CompletedMeetingsMutations.SetMeetings, { meetings, pagination })
    } finally {
      commit(CompletedMeetingsMutations.SetLoaded, true)
    }
  },

  async [CompletedMeetingsActions.PrintReclassificationForm]({ rootState }, request: PrintReclassificationRequest) {
    await meetingService.printReclassificationForm(rootState, request)
  },

  async [CompletedMeetingsActions.PrintMeetingMinutesForm]({ rootState }, request: PrintMeetingMinutesRequest) {
    await meetingService.printMeetingMinutesForm(rootState, request)
  },

  async [CompletedMeetingsActions.PrintMeetingRosterForm]({ rootState }, request: PrintMeetingRosterRequest) {
    await meetingService.printMeetingRosterForm(rootState, request)
  }

}
