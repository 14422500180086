import Vue from 'vue'
import VueRouter, { RouteConfig, Route } from 'vue-router'
import { Pages, paths } from '@/pages'

Vue.use(VueRouter)

const Main = () => import('./components/Main.vue')
const Dashboard = () => import('./components/pages/dashboard-v2/Dashboard.vue')
const CreateMeeting = () => import('./components/pages/create-meeting-v2/CreateMeeting.vue')
const ReviewMeeting = () => import('./components/pages/review-meeting-v2/ReviewMeeting.vue')
const MeetingSummary = () => import('./components/pages/meeting-summary-v2/MeetingSummary.vue')
const OverviewMeeting = () => import('./components/pages/overview-meeting-v3/OverviewMeeting.vue')
const ReviewStudents = () => import('./components/pages/review-students-v2/ReviewStudents.vue')
const ReviewStudent = () => import('./components/pages/review-students-v2/review-student/ReviewStudent.vue')
const ViewStudent = () => import('./components/pages/students/view-student-v2/ViewStudent.vue')
const CompletedMeetings = () => import('./components/pages/completed-meetings-v2/CompletedMeetings.vue')
const CompletedMeeting = () => import('./components/pages/completed-meeting-v2/CompletedMeeting.vue')
const ParentDecisions = () => import('./components/pages/parent-decisions/ParentDecisions.vue')
const ParentDecision = () => import('./components/pages/parent-decisions/parent-decision/ParentDecision.vue')
const Students = () => import('./components/pages/students/all-students-v2/AllStudents.vue')
const SearchStudents = () => import('./components/pages/students/search-students/SearchStudents.vue')
const Reports = () => import('./components/pages/reports/Reports.vue')
const HistoryLog = () => import('./components/pages/history-log/HistoryLog.vue')
const Committee = () => import('./components/pages/committee/Committee.vue')
const UserSetup = () => import('./components/pages/user-setup-v2/UserSetup.vue')
const StaarAccommodations = () => import('./components/pages/staar-accommodations-v2/StaarAccommodations.vue')
const EOYLasLinks = () => import('./components/pages/eoy-las-links/EOYLasLinks.vue')
const Accommodations = () => import('./components/pages/accommodations/Accommodations.vue')

export const createRoutes: () => RouteConfig[] = () => [{
  path: '',
  component: Main,
  meta: {
    authRequired: true
  },

  children: [{
    path: '',
    redirect: {
      name: Pages.Dashboard
    }
    }, {
      name: Pages.Dashboard,
      path: paths.dashboard,
      component: Dashboard
    }, {
      name: Pages.CreateMeeting,
      path: paths.createMeeting,
      component: CreateMeeting
    }, {
      name: Pages.MeetingSummary,
      path: paths.meetingSummary,
      component: MeetingSummary
    }, {
      name: Pages.ReviewMeeting,
      path: paths.reviewMeeting,
      component: ReviewMeeting
    }, {
      name: Pages.OverviewMeeting,
      path: paths.overviewMeeting,
      component: OverviewMeeting,
      meta: {
        scrollToTop: true
      }
    }, {
      name: Pages.ReviewStudents,
      path: paths.reviewStudents,
      component: ReviewStudents,
      children: [{
        name: Pages.ReviewStudent,
        path: paths.reviewStudent,
        component: ReviewStudent,
        meta: {
          scrollToTop: true
        }
      }]
    }, {
      name: Pages.CompletedMeetings,
      path: paths.completedMeetings,
      component: CompletedMeetings
    }, {
      name: Pages.CompletedMeeting,
      path: paths.completedMeeting,
      component: CompletedMeeting
    }, {
      name: Pages.ParentDecisions,
      path: paths.parentDecisions,
      component: ParentDecisions,
      children: [{
        name: Pages.ParentDecision,
        path: paths.parentDecision,
        component: ParentDecision,
        meta: {
          scrollToTop: true
        }
      }]
    }, {
      name: Pages.Students,
      path: paths.students,
      component: Students
    }, {
      name: Pages.SearchStudents,
      path: paths.searchStudents,
      component: SearchStudents
    }, {
      name: Pages.ViewStudent,
      path: paths.viewStudent,
      component: ViewStudent
    }, {
      name: Pages.Reports,
      path: paths.reports,
      component: Reports
    }, {
      name: Pages.HistoryLog,
      path: paths.history,
      component: HistoryLog
    }, {
      name: Pages.Committee,
      path: paths.committee,
      component: Committee
    }, {
      name: Pages.UserSetup,
      path: paths.userSetup,
      component: UserSetup
    }, {
      name: Pages.StaarAccommodations,
      path: paths.staarAccommodations,
      component: StaarAccommodations
    }, {
      name: Pages.EOYLasLinks,
      path: paths.eoyLasLinks,
      component: EOYLasLinks
    }, {
      name: Pages.Accommodations,
      path: paths.accommodations,
      component: Accommodations
    }]
}]

type Position = { x: number, y: number }

const scrollBehavior = (to: Route): Position => {
  if (to.meta.scrollToTop) {
    return { x: 0, y: 0 }
  }
}

const addLogin = (routes: RouteConfig[]): RouteConfig[] => {
  const Login = () => import('./components/login/Login.vue')

  routes.unshift({
    name: Pages.Login,
    path: paths.login,
    component: Login,
    meta: {
      authRequired: false
    }
  })

  const NewTeacher = () => import('./components/new-teacher/NewTeacher.vue')

  routes.unshift({
    name: Pages.NewTeacher,
    path: paths.newTeacher,
    component: NewTeacher,
    meta: {
      authRequired: false
    }
  })

  if (!process.env.EXT_LOGIN || process.env.IS_DEV) {

    const ResetPassword = () => import('./components/password-reset/PasswordReset.vue')

    routes.unshift({
      name: Pages.PasswordReset,
      path: paths.passwordReset,
      component: ResetPassword,
      meta: {
        authRequired: false
      }
    })

  }

  return routes
}

export const createRouter = () => new VueRouter({
  mode: 'history',
  routes: addLogin(createRoutes()),
  scrollBehavior
})
