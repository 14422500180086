import { RootState } from '@/store/root'
import { GetterTree } from 'vuex'
import { ContactUsState } from './state'


export enum ContactUsGetters {
    ItemPrimaryText = 'ITEM_PRIMARY_TEXT',
}

export const getters: GetterTree<ContactUsState, RootState> = {

}
