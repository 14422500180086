import { RootState } from '@/store/root';
import BaseService, {ServiceConfig} from './base';
import { ContactUsRequest } from 'models'

export default class ContactService extends BaseService {

  constructor (config: ServiceConfig) {
    super(config)
  }

    async sendEmail(state: RootState, req: ContactUsRequest) {
        return this.post('contactUs/sendContactEmail', {state, request: req})
    }
}
