import { RootState } from '@/store/root'
import BaseService, {ServiceConfig} from './base'
import { ReportRequest, ReportResponse, ReportType } from 'models'
import HttpStatus from 'http-status-codes'
import { RestUrls } from './RestUrls'
import { ErrorCode, ErrorHolder } from '@/util/errors'
import {AxiosRequestConfig} from 'axios';

export default class ReportsService extends BaseService {

  private readonly baseUri: string = '/reports'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async downloadContentAndLanguageReport(state: RootState, request: ReportRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/content-language-support`, { state, request })
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'ContentAndLanguageReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }

  async downloadCurrentContentAndLanguageReport(state: RootState, meetingId: number): Promise<void> {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: meetingId
      }
    }
    const { data, headers } = await this.get(`${this.baseUri}/meeting/${meetingId}/content-language-support`, state, config)
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'CurrentContentAndLanguageReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }

  async downloadParentPermissions(state: RootState, request: ReportRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/parent-decisions`, { state, request })
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'ParentPermissionsReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }

  async downloadStudentRecommendationsReport(state: RootState, request: ReportRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/student-classifications-recommendations`, { state, request })
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'StudentRecommendationsReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }

  async downloadStudentProficiencyReport(state: RootState, request: ReportRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/student-proficiency-levels`, { state, request })
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'StudentProficiencyLevelsReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }

  async downloadStudentInfoReport(state: RootState, request: ReportRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/student-info`, { state, request })
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'StudentInfoReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }
  async downloadStaarAccommodationsReport(state: RootState, request: ReportRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/current-staar-accommodations`, { state, request })
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'StaarAccommodationsReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }

  async downloadCompletedAnnualReviewsReport(state: RootState, request: ReportRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/completed-annual-review-meetings`, { state, request })
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'CompletedAnnualReviewsReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }

  async downloadUsEntryReport(state: RootState, request: ReportRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/us-school-entry`, { state, request })
    const contentType = this.getContentType(headers?.['content-type'] as string)
    const fileName = 'USSchoolEntryReport.' + contentType
    this.downloadFile(data, fileName, contentType)
  }

  async downloadLinguisticsAccommodationsReport(state: RootState, request: ReportRequest): Promise<string> {
    try {
      const { data } = await this.post(`${this.baseUri}/linguistic-accommodations`, { state, request }, { responseType: 'blob' })
      if ((data as Blob).size == 0) {
        return 'No results for chosen criteria.'
      }
      const fileName = 'linguistics-accommodations-report.pdf'
      this.downloadFile(data, fileName, 'application/pdf')
      return
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.NO_CONTENT)) {
        throw new ErrorHolder(error, ErrorCode.NotFoundError)
      }
      throw error
    }
  }

  async getReportTypes(state: RootState): Promise<ReportType[]> {
    const { data } = await this.get<ReportResponse>(`${RestUrls.getReportTypes}`, state)

    return data.reportTypes
  }

  getContentType(type: string) {
    let contentType = ''
    if (type === 'text/plain') {
      contentType = 'csv'
    } else if (type === 'application/pdf') {
      contentType = 'application/pdf'
    } else {
      contentType = 'csv'
    }
    return contentType
  }

}
