import { State } from '@/store/helpers'
import { Component, Vue } from 'vue-property-decorator'
import Notification from './notification/Notification.vue'

@Component({
  components: {
    Notification
  }
})
export default class Notifications extends Vue {

  @State
  notifications: Notification[]

}
