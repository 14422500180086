import { MutationTree } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { CreateMeetingState, clearState } from './state'
import {
  MeetingType,
  StudentSchoolGrade,
  StudentPaging,
  Pagination,
  PagedSelectedItems
} from 'models'
import { MeetingTypeCode } from '@/enums'

export enum CreateMeetingMutations {
  Clear = 'CLEAR',
  SetLoaded = 'SET_LOADED',
  SetCode = 'SET_CODE',
  SetAll = 'SET_ALL',
  SetTypes = 'SET_TYPES',
  SetStudents = 'SET_STUDENTS',
  SetPaging = 'SET_PAGING',
  SetSelectedStudents = 'SET_SELECTED_STUDENTS',
  ClearSelectedStudents = 'CLEAR_SELECTED_STUDENTS',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<CreateMeetingState> = {

  [CreateMeetingMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [CreateMeetingMutations.SetLoaded] (state, loaded: boolean) {
    state.loaded = loaded
  },

  [CreateMeetingMutations.SetCode] (state, code: MeetingTypeCode) {
    state.code = code
  },

  [CreateMeetingMutations.SetAll](state, all: boolean) {
    state.all = all
  },

  [CreateMeetingMutations.SetTypes] (state, types: MeetingType[]) {
    const filteredTypes: MeetingType[] = types.filter(typ => typ.code !== MeetingTypeCode.Og)
    state.types = filteredTypes
  },

  [CreateMeetingMutations.SetStudents] (state, { students, allStudentIds, pagination }: {
    students: StudentSchoolGrade[]
    allStudentIds: number[]
    pagination: Pagination
  }) {
    state.students = students
    state.allStudentIds = allStudentIds
    state.pagination = pagination
  },

  [CreateMeetingMutations.SetPaging](state, paging: StudentPaging) {
    state.paging = paging
  },

  [CreateMeetingMutations.SetSelectedStudents] (state, selected: PagedSelectedItems) {
    state.selectedStudents = selected
  },

  [CreateMeetingMutations.ClearSelectedStudents] (state) {
    const { selectedStudents } = cloneDeep(clearState)

    Object.assign(state, { selectedStudents })
  },

  // Only use this mutation for setting the state for test cases
  [CreateMeetingMutations.SetTestState] (state, testData: CreateMeetingState) {
    Object.assign(state, cloneDeep(testData))
  }

}
