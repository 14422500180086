import cloneDeep from 'lodash/cloneDeep'
import { MeetingType, Accommodation, StudentNote, SelectedAccommodationCommittee, SubjectAccommodation } from 'models'
import { MeetingStudents, MeetingTypeStudents, SubjectAccommodationEdits } from './types'
import { MeetingTypeCode } from '@/enums'
import { PagingUtil, PerPageSize } from '@806/utils'

export interface OverviewMeetingState {
  types: MeetingType[],
  meetingStudents: MeetingStudents,
  currentSigningUrl: string,
  accommodations: Accommodation[],
  subjectAccommodations: SubjectAccommodation[]
  studentNotesMap: Map<number, StudentNote>,
  subjectAccommodationEdits: SubjectAccommodationEdits,
  lpacCommitteeAccomEdits: SelectedAccommodationCommittee[],
  loading: boolean
}

const meetingStudents = Object.assign({}, Object.values(MeetingTypeCode)
  .reduce<MeetingStudents>((meetingStudents: MeetingStudents, code: MeetingTypeCode) => {
    meetingStudents[code] = {
      students: [],
      pagination: {},
      paging: PagingUtil.initialPaging(PerPageSize.Medium),
      selected: PagingUtil.initialSelectedItems()
    } as MeetingTypeStudents
    return meetingStudents
  }, {} as MeetingStudents)
)

export const state: OverviewMeetingState = {
  types: [],
  meetingStudents,
  currentSigningUrl: null,
  accommodations: [],
  subjectAccommodations: [],
  studentNotesMap: new Map(),
  subjectAccommodationEdits: null,
  lpacCommitteeAccomEdits: [],
  loading: false
}

export const clearState: OverviewMeetingState = cloneDeep(state)
