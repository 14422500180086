/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable no-var */
// @ts-nocheck
export default () => {
  function initFreshChat() {
    window.fcWidget.init({
      token: process.env.FRESHCHAT_API_KEY,
      host: "https://wchat.freshchat.com",
      siteId: "TELP",
      faqTags : {
          tags : ['TELP'],
          filterType:'category'
        },
    });
  }
  function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
  function initiateCall(){initialize(document,"Freshdesk Messaging-js-sdk")}

  if (document.readyState === 'complete') {
    initiateCall()
  } else {
    window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
  }
}
