import { ErrorHolder } from '@/util/errors'
import { LoggerFactory } from '@806/utils'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import { MutationTree } from 'vuex'
import {
  createKeyedNotification,
  successNotification,
  infoNotification,
  warningNotification
} from './notifications'
import { clearState, RootState } from './state'
import {
  isKeyedNotification,
  Notification,
  TypedNotification,
  KeyedNotification,
  Selected, ProductInfo
} from './types'

import { ELDistrict, ELUserRole, Term, Grade, MeetingReviewAddress, Dimensions, HelloSignConfigResponse, FeatureToggle, ELSchoolYear, DistrictLanguage } from 'models'

const logger = LoggerFactory.getLogger('root store mutations')

export enum RootMutations {
  Clear = 'CLEAR',
  SetDimensions = 'SET_DIMENSIONS',
  SetSelected = 'SET_SELECTED',
  SetSelectedChanging = 'SET_SELECTED_CHANGING',
  SetDistrictSchools = 'SET_DISTRICT_SCHOOLS',
  SetSchoolRole = 'SET_SCHOOL_ROLE',
  SetTerms = 'SET_TERMS',
  SetMeetingReviewAddresses = 'SET_MEETING_REVIEW_ADDRESSES',
  SetGrades = 'SET_GRADES',
  AddSuccessNotification = 'ADD_SUCCESS_NOTIFICATION',
  AddInfoNotification = 'ADD_INFO_NOTIFICATION',
  AddWarningNotification = 'ADD_WARNING_NOTIFICATION',
  AddError = 'ADD_ERROR',
  AddUnexpectedError = 'ADD_UNEXPECTED_ERROR',
  AddNotification = 'ADD_NOTIFICATION',
  RemoveNotification = 'REMOVE_NOTIFICATION',
  ToggleMenu = 'TOGGLE_MENU',
  SetHelloSignConfig = 'SET_HELLOSIGN_CONFIG',
  SetAllFeatureToggles = 'SET_ALL_FEATURE_TOGGLES',
  SetFeatureToggle = 'SET_FEATURE_TOGGLE',
  SetCurrentSchoolYear = 'SET_CURRENT_SCHOOL_YEAR',
  SetDistrictLanguages = 'SET_DISTRICT_LANGUAGES',
  SetSchoolYears = 'SET_SCHOOL_YEARS',
  SetOlarkStatus = 'SET_OLARK_STATUS',
  SetFreshchatStatus = 'SET_FRESHCHAT_STATUS',
  SetHellosignStatus = 'SET_HELLO_SIGN_STATUS',
  SetProductInfo = 'SET_PRODUCT_INFO',
  SetZendeskStatus = 'SET_ZENDESK_STATUS',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<RootState> = {

  [RootMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [RootMutations.SetTerms](state, terms: Term[]) {
    state.terms = terms
  },

  [RootMutations.SetDimensions](state, dimensions: Dimensions) {
    state.dimensions = dimensions
  },

  [RootMutations.SetSelected] (state, selected: Selected) {
    state.selected = selected
  },

  [RootMutations.SetSelectedChanging] (state, changing: boolean) {
    state.selectedChanging = changing
  },

  [RootMutations.SetTerms](state, terms: Term[]) {
    state.terms = terms
  },

  [RootMutations.SetMeetingReviewAddresses](state, meetingReviewAddresses: MeetingReviewAddress[]) {
    state.meetingReviewAddresses = meetingReviewAddresses
  },

  [RootMutations.SetGrades](state, grades: Grade[]) {
    state.grades = grades
  },

  [RootMutations.SetDistrictSchools](state, districts: ELDistrict[]) {
    state.districtSchools = districts
  },

  [RootMutations.SetSchoolRole](state, schoolRole: ELUserRole) {
    state.schoolRole = schoolRole
  },

  [RootMutations.AddSuccessNotification](state, notification: Notification) {
    state.notifications.unshift(successNotification(notification))
  },

  [RootMutations.AddInfoNotification](state, notification: Notification) {
    state.notifications.unshift(infoNotification(notification))
  },

  [RootMutations.AddWarningNotification](state, notification: Notification) {
    state.notifications.unshift(warningNotification(notification))
  },

  [RootMutations.AddError] (state, { message }: Error | ErrorHolder) {
    state.notifications.unshift(warningNotification({ message }))
  },

  [RootMutations.AddUnexpectedError] (state, unexpected) {
      const error = typeof unexpected !== 'object' ? new Error(unexpected) : unexpected
      const { message } = error
      if (message !== 'canceled') {
        state.notifications.unshift(warningNotification({ message }))
      }
  },

  [RootMutations.AddNotification] (state, notification: TypedNotification | KeyedNotification) {
    const keyed = isKeyedNotification(notification) ? notification : createKeyedNotification(notification)

    state.notifications.unshift(keyed)
  },

  [RootMutations.RemoveNotification] (state, notification: KeyedNotification) {
    if (!isKeyedNotification(notification)) {
      logger.warn('Cannot remove notification without key.', notification)
      return
    }

    const index = findIndex(state.notifications, ({ id }) => id === notification.id)

    if (index < 0) {
      logger.warn('Attempted to remove a notification that was not added to root state notifications.', notification)
      return
    }

    state.notifications.splice(index, 1)
  },

  [RootMutations.ToggleMenu] (state) {
    state.menuCollapsed = !state.menuCollapsed
  },
  [RootMutations.SetHelloSignConfig](state: RootState, hsConfig: HelloSignConfigResponse) {
    state.helloSignClientId = hsConfig.clientId
    state.helloSignSkipDomainVerification = hsConfig.skipDomainVerification
  },

  [RootMutations.SetAllFeatureToggles](state: RootState, features: FeatureToggle[]) {
    state.features = features
  },

  [RootMutations.SetFeatureToggle](state: RootState, featureToggle: FeatureToggle) {
    featureToggle.enabled = !featureToggle.enabled
    const index = findIndex(state.features, feature => feature.id === featureToggle.id)
    state.features.splice(index, 1, featureToggle)
  },

  [RootMutations.SetCurrentSchoolYear](state, schoolYear: ELSchoolYear) {
    state.currentSchoolYear = schoolYear
  },

  [RootMutations.SetSchoolYears](state, schoolYears: ELSchoolYear[]) {
    state.schoolYears = schoolYears
  },

  [RootMutations.SetDistrictLanguages](state, languages: DistrictLanguage[]) {
    state.districtLanguages = languages
  },

  // Only use this mutation for setting the state for test cases
  [RootMutations.SetTestState] (state, testData: RootState) {
    Object.assign(state, cloneDeep(testData))
  },

  [RootMutations.SetOlarkStatus](state, status: boolean) {
    state.olarkStatus = status
  },

  [RootMutations.SetFreshchatStatus](state, status: boolean) {
    state.freshchatStatus = status
  },

  [RootMutations.SetZendeskStatus](state, status: boolean) {
    state.zendeskStatus = status
  },

  [RootMutations.SetHellosignStatus](state, status: boolean) {
    state.hellosignStatus = status
  },

  [RootMutations.SetProductInfo](state, productInfo: ProductInfo) {
    state.productInfo = productInfo
  }

}
