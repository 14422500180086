import {GetterTree} from 'vuex'
import {RootState} from '@/store/root'
import {ContentLanguageSupportState} from '@/store/modules/content-language-support/state'


export enum ContentLanguageSupportGetters {
  //
}

export const getters: GetterTree<ContentLanguageSupportState, RootState> = {
  //
}
