import { MutationTree } from 'vuex'
import { MeetingState, clearState } from './state'
import { Meeting, MeetingMember, SigningStatusResponse, MeetingFormSignature, MeetingMemberSignature, GetMeetingMinutesInfoResponse, MemberSigningStatusResponse, MeetingAccommodation, Pagination, MeetingsPaging, MeetingRoster, MeetingType, RosterReview, StudentPaging, DashboardRosterRow, DashboardMeeting, DashboardMeetingsResponse, Paging, StudentSchoolGrade } from 'models'
import cloneDeep from 'lodash/cloneDeep'
import { Optional } from 'typescript-optional'
import { isEmpty } from 'lodash'
import { MeetingStatus, MeetingTypeCode, MeetingTypeStatusCode } from '@/enums'
import { RosterReviewStudents } from '.'

export enum MeetingMutations {
  Clear = 'CLEAR',
  SetLoading = 'SET_LOADING',
  SetCurrentMeeting = 'SET_CURRENT_MEETING',
  SaveMemberSignedStatus = 'SAVE_MEMBER_SIGNED_STATUS',
  SaveMemberBatchSignedStatus = 'SAVE_MEMBER_BATCH_SIGNED_STATUS',
  SetSignatureRequestId = 'SET_SIG_REQ_ID',
  AddMeetingFormSignature = 'ADD_MEETING_FORM_SIGNATURE',
  AddMeetingFormSignatures = 'ADD_MEETING_FORM_SIGNATURES',
  AddActiveRosterByType = 'ADD_ACTIVE_ROSTER_BY_TYPE',
  SetLastSignSuccess = 'SET_LAST_SIGN_SUCCESS',
  ConfirmCommittee = 'CONFIRM_COMMITTEE',
  SetMeetingMinutes= 'SET_MEETING_MINUTES',
  SetMeetingMemberScreenShotLoc = 'SET_MEETING_MEMBER_SCREENSHOT_LOC',
  SetCurrentReviewMtgType = 'SET_CURRENT_REVIEW_MTG_TYPE',
  SetMeetingAccommodations = 'SET_MEETING_ACCOMMODATIONS',
  SetMeetingsPaging = 'SET_MEETINGS_PAGING',
  SetStaffId = 'SET_STAFF_ID',
  SetTeacherLinkMeetingId = 'SET_TEACHER_LINK_MEETING_ID',
  SetMeetingTypeStatusCode = 'SET_MEETING_TYPE_STATUS_CODE',
  SetRosterStatus = 'SET_ROSTER_STATUS',
  SetHasCLSOptions = 'SET_HAS_CLS_OPTIONS',
  SetTypes = 'SET_TYPES',
  SetReadyRoster = 'SET_READY_ROSTER',
  SetCompletedRoster = 'SET_COMPLETED_ROSTER',
  SetPotentialExitPaging = 'SET_POTENTIAL_EXIT_PAGING',
  SetPotentialExitPagination = 'SET_POTENTIAL_EXIT_PAGINATION',
  SetPotentialExitStudents = 'SET_POTENTIAL_EXIT_STUDENTS',
  SetPotentialExitMeetingRosterIds = 'SET_POTENTIAL_EXIT_MEETING_ROSTER_IDS',
  SetPotentialExitGeneratingMergedLetters = 'SET_POTENTIAL_EXIT_GENERATING_MERGED_LETTERS',
  AddMeeting = 'ADD_MEETING',
  AddMeetings = 'ADD_MEETINGS',
  RemoveMeeting = 'REMOVE_MEETING',
  SetActiveRoster = 'SET_ACTIVE_ROSTER',
  SetDashboardMeetingPaging = 'SET_DASHBOARD_MEETING_PAGING',
  SetCombinedLetterDownloadCount = 'SET_COMBINED_LETTER_DOWNLOAD_COUNT',
  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<MeetingState> = {

  [MeetingMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [MeetingMutations.SetLoading] (state, loading: boolean) {
    state.loading = loading
  },

  [MeetingMutations.AddMeeting] (state, meeting: Meeting) {
    if (meeting.meetingType) {
      state.openMeetings.meetings = state.openMeetings.meetings.filter(m => m.id !== meeting.id)
      let meetingTypeStatusCode: MeetingTypeStatusCode = MeetingTypeStatusCode.SelectStudents
      if (!isEmpty(meeting.meetingTypeStatuses)) {
        meetingTypeStatusCode = meeting.meetingTypeStatuses.find(status => status.meetingTypeCode === meeting.meetingType.code)?.meetingTypeStatusCode
      }
      const dashboardMeeting: DashboardMeeting = {
        id: meeting.id,
        schoolId: meeting.schoolId,
        date: meeting.date,
        status: meetingTypeStatusCode,
        code: meeting.meetingType.code,
        title: meeting.title,
        meetingStatus: meeting.status,
        totalStudents: meeting.rosterCount
      }
      state.openMeetings.meetings.push(dashboardMeeting)
    }
  },

  [MeetingMutations.AddMeetings] (state, response: DashboardMeetingsResponse) {
    state.openMeetings.meetings = response.meetings
    state.openMeetings.pagination = response.pagination
  },

  [MeetingMutations.AddActiveRosterByType] (state, rosters: StudentSchoolGrade[]) {
    state.activeRosters = rosters
  },

  [MeetingMutations.SetDashboardMeetingPaging](state, paging: Paging) {
    state.openMeetings.paging = paging
  },

  [MeetingMutations.RemoveMeeting] (state, meetingId: number) {
    state.openMeetings.meetings = state.openMeetings.meetings.filter(mtg => mtg.id !== meetingId)
    state.currentMeeting = null
  },

  [MeetingMutations.SetCurrentMeeting] (state, meeting: Meeting) {
    if (meeting && isEmpty(meeting.signatureRequestId)) {
      meeting.signatureRequestId = null // Required null to be reactive
    }
    state.currentMeeting = meeting
  },

  [MeetingMutations.SetMeetingMemberScreenShotLoc] (state, location: boolean) {
    state.meetingMemberScreenshotUploaded = location
  },

  [MeetingMutations.SaveMemberSignedStatus](state, mss: SigningStatusResponse) {
    state.lastSignSuccess = mss.success
    if (mss.success === true) {
      Optional.ofNullable(state.currentMeeting.members.find((mmb: MeetingMember) => mmb.id === mss.memberId)).ifPresent((mmb: MeetingMember) => {
        const mtgMmbSig: MeetingMemberSignature = {
          meetingFormTypeCode: mss.meetingFormTypeCode,
          hasSignedIndicator: mss.success
        }
        mmb.meetingMemberSignatures.push(mtgMmbSig)
      })
      if (mss.allSigned === true) {
        Optional.ofNullable(state.currentMeeting.meetingFormSignatures.find(mtgFormSig => mtgFormSig.meetingFormTypeCode === mss.meetingFormTypeCode)).ifPresent(mtgFormSig => {
          mtgFormSig.allMembersSigned = true
        })
        if (mss.status && mss.status === MeetingStatus.F) {
          state.currentMeeting.status = MeetingStatus.F
        }
      }
    }
  },

  [MeetingMutations.SaveMemberBatchSignedStatus](state, mss: MemberSigningStatusResponse) {
    state.lastSignSuccess = mss.success
    if (mss.success === true) {
      const meetingFormTypeCodes = mss.meetingFormSignatures.map(mtgFormSignature => mtgFormSignature.meetingFormTypeCode)
      meetingFormTypeCodes.forEach(metingFormTypeCd => {
        state.currentMeeting.meetingFormSignatures = state.currentMeeting.meetingFormSignatures.filter(meetingFormSig => meetingFormSig.meetingFormTypeCode !== metingFormTypeCd)
      })
      mss.meetingFormSignatures.forEach(meetingFormSig => state.currentMeeting.meetingFormSignatures.push(meetingFormSig))
      if (mss.allSigned === true) {
        if (mss.status && mss.status === MeetingStatus.F) {
          state.currentMeeting.status = MeetingStatus.F
          state.currentMeeting.meetingFormSignatures.forEach(mtgFormSig => mtgFormSig.allMembersSigned = true)
        }
      }
    }
  },

  [MeetingMutations.SetSignatureRequestId](state, sigReqId: string) {
    state.currentMeeting.signatureRequestId = sigReqId
  },

  [MeetingMutations.SetLastSignSuccess](state: MeetingState, success: boolean) {
    state.lastSignSuccess = success
  },
  [MeetingMutations.AddMeetingFormSignature](state, mtgFormSig: MeetingFormSignature) {
    if (state.currentMeeting.meetingFormSignatures) {
      state.currentMeeting.meetingFormSignatures.push(mtgFormSig)
    }
  },
  [MeetingMutations.AddMeetingFormSignatures](state, mtgFormSigs: MeetingFormSignature[]) {
    if (state.currentMeeting.meetingFormSignatures) {
      // state.currentMeeting.meetingFormSignatures = []
      const meetingFormTypeCodes = mtgFormSigs.map(mtgFormSignature => mtgFormSignature.meetingFormTypeCode)
      meetingFormTypeCodes.forEach(metingFormTypeCd => {
        state.currentMeeting.meetingFormSignatures = state.currentMeeting.meetingFormSignatures.filter(meetingFormSig => meetingFormSig.meetingFormTypeCode !== metingFormTypeCd)
      })
      mtgFormSigs.forEach(meetingFormSig => state.currentMeeting.meetingFormSignatures.push(meetingFormSig))
    }
  },
  [MeetingMutations.ConfirmCommittee](state) {
    const status = state.currentMeeting.status

    /* Resets the meeting statuses if committee is confirmed after meeting starts */
    if (status === MeetingStatus.P) {
      const newStatuses = []
      state.currentMeeting.meetingTypeStatuses.forEach(status => {
        const newStatus = status
        switch (newStatus.meetingTypeCode) {
          case MeetingTypeCode.Identification:
            newStatus.meetingTypeStatusCode = MeetingTypeStatusCode.EnteringTestData
            break
          case MeetingTypeCode.StaarDecisions:
            newStatus.meetingTypeStatusCode = MeetingTypeStatusCode.TeacherHasSigned
            break
          case MeetingTypeCode.TelpasDecisions:
            newStatus.meetingTypeStatusCode = MeetingTypeStatusCode.TelpasJustificationsComplete
            break
          default:
            newStatus.meetingTypeStatusCode = MeetingTypeStatusCode.Justifications
        }
        newStatuses.push(newStatus)
      })
      state.currentMeeting.meetingTypeStatuses = newStatuses
    }
    state.currentMeeting.committeeVerified = true
  },
  [MeetingMutations.SetMeetingMinutes](state: MeetingState, meetingMinuteInfo: GetMeetingMinutesInfoResponse) {
    state.currentMeeting.meetingReviewAddresses = meetingMinuteInfo.meetingReviewAddresses
    state.currentMeeting.meetingGrades = meetingMinuteInfo.grades
  },
  [MeetingMutations.SetCurrentReviewMtgType](state: MeetingState, meetingTypeCode: MeetingTypeCode) {
    state.currentReviewMtgType = meetingTypeCode
  },

  [MeetingMutations.SetMeetingAccommodations] (state, { meetings, pagination }: {
    meetings: MeetingAccommodation[]
    pagination: Pagination
  }) {
    state.meetingAccommodations = meetings
    state.pagination = pagination
  },

  [MeetingMutations.SetMeetingsPaging](state, meetingsPaging: MeetingsPaging) {
    state.meetingsPaging = meetingsPaging
  },

  [MeetingMutations.SetStaffId](state, staffId: number) {
    state.staffId = staffId
  },

  [MeetingMutations.SetTeacherLinkMeetingId](state, teacherLinkMeetingId: number) {
    state.teacherLinkMeetingId = teacherLinkMeetingId
  },

  [MeetingMutations.SetMeetingTypeStatusCode](state, { meetingTypeCode, meetingTypeStatusCode }) {
    const index = state.currentMeeting.meetingTypeStatuses.findIndex(status => status.meetingTypeCode === meetingTypeCode)
    state.currentMeeting.meetingTypeStatuses[index].meetingTypeStatusCode = meetingTypeStatusCode
  },

  [MeetingMutations.SetRosterStatus](state, roster: MeetingRoster) {
    if (!isEmpty(state.currentMeeting.roster)) {
      const index: number = state.currentMeeting.roster.findIndex(student => student.id === roster.id)
      state.currentMeeting.roster[index].status = roster.status
    } else {
      const index: number = state.rosterReviewStudents.students.findIndex(student => student.meetingRosterId === roster.id)
      state.rosterReviewStudents.students[index].status = roster.status
    }
  },

  [MeetingMutations.SetActiveRoster](state, roster: MeetingRoster[]) {
    state.currentMeeting.roster = roster
  },

  [MeetingMutations.SetTypes](state, types: MeetingType[]) {
    state.types = types
  },

  [MeetingMutations.SetHasCLSOptions](state, hasCLSOptions: boolean) {
    state.hasCLSOptions = hasCLSOptions
  },

  [MeetingMutations.SetReadyRoster] (state, rosterReviewStudents: RosterReviewStudents) {
    state.rosterReviewStudents = rosterReviewStudents
  },

  [MeetingMutations.SetCompletedRoster] (state, rosterReviewStudent: RosterReview) {
    const paging: StudentPaging = state.rosterReviewStudents.paging
    const students: RosterReview[] = state.rosterReviewStudents.students
    const index: number = students.findIndex(student => student.meetingRosterId === rosterReviewStudent.meetingRosterId)
    students[index] = rosterReviewStudent
    state.rosterReviewStudents = {
      students: students,
      paging: paging
    }
  },

  [MeetingMutations.SetPotentialExitPaging] (state, potentialExitPaging: StudentPaging) {
    state.potentialExitStuPaging.paging = potentialExitPaging
  },
  [MeetingMutations.SetPotentialExitPagination] (state, pagination: Pagination) {
    state.potentialExitStuPaging.pagination = pagination
  },
  [MeetingMutations.SetPotentialExitStudents] (state, potentialExitStudents: DashboardRosterRow[]) {
    state.potentialExitStudents = potentialExitStudents
  },
  [MeetingMutations.SetPotentialExitMeetingRosterIds] (state, potentialExitMeetingRosterIds: number[]) {
    state.potentialExitMeetingRosterIds = potentialExitMeetingRosterIds
  },

  [MeetingMutations.SetPotentialExitGeneratingMergedLetters](state, isGeneratingPotentialExitMergedLetters: boolean) {
    state.generatingPotentialExitMergedLetters = isGeneratingPotentialExitMergedLetters
  },

  [MeetingMutations.SetCombinedLetterDownloadCount](state, count: number) {
    state.combinedLetterDownloadCount = count
  },

  // Only use this mutation for setting the state for test cases
  [MeetingMutations.SetTestState] (state, testData: MeetingState) {
    Object.assign(state, cloneDeep(testData))
  }

}
