import { meetingService, studentService } from '@/services'
import { RootState } from '@/store/root'
import { ParentDecisionState } from './state'
import { ParentDecisionMutations } from './mutations'
import { ParentDecisionGetters } from './getters'
import { ActionTree } from 'vuex'
import { ParentDecisionRequest } from 'models'

export enum ParentDecisionActions {
  GetMeeting = 'GET_MEETING',
  GetRoster = 'GET_ROSTER',
  SaveParentDecision = 'SAVE_PARENT_DECISION',
  SaveParentDecisionUpdates = 'SAVE_PARENT_DECISION_UPDATES',
  RemoveParentDecisionForm = 'REMOVE_PARENT_DECISION_FORM',
  DownloadParentDecisionForm = 'DOWNLOAD_PARENT_DECISION_FORM',
}

export const actions: ActionTree<ParentDecisionState, RootState> = {

  async [ParentDecisionActions.GetMeeting]({ commit, rootState }, meetingId: number) {
    const meeting = await meetingService.getCompletedMeeting(rootState, meetingId)

    commit(ParentDecisionMutations.SetMeeting, meeting)
  },

  async [ParentDecisionActions.GetRoster]({ commit, rootState }, rosterId: number) {
    const roster = await studentService.getRoster(rootState, rosterId, null)

    commit(ParentDecisionMutations.SetRoster, roster)
  },

  async [ParentDecisionActions.SaveParentDecision]({ commit, state, rootState }) {
    const { date, decisionId } = state.edits
    const meetingId: number = state.meeting.id
    const roster = await studentService.saveParentDecision(rootState, state.roster.id, date, decisionId, meetingId)

    commit(ParentDecisionMutations.SetRoster, roster)
  },

  async [ParentDecisionActions.SaveParentDecisionUpdates]({ commit, rootState }, request: ParentDecisionRequest) {
    const roster = await studentService.saveParentDecisionUpdates(rootState, request)

    commit(ParentDecisionMutations.SetRoster, roster)
  },

  async [ParentDecisionActions.RemoveParentDecisionForm]({ commit, state, rootState }) {
    const roster = await studentService.removeParentDecisionForm(rootState, state.roster.id, state.meeting.id)

    commit(ParentDecisionMutations.SetRoster, roster)
  },

  async [ParentDecisionActions.DownloadParentDecisionForm]({ state, rootState, getters }) {
    const studentNumber = getters[ParentDecisionGetters.RosterStudentNumber]
    const filename = `${studentNumber}-${state.roster.meetingPlacement.formFilename}`

    await studentService.downloadParentDecisionForm(rootState, state.roster.id, filename)
  }

}
