import { MutationTree } from 'vuex'
import { SetupCommitteeState, clearState } from './state'
import { MeetingType, MeetingRole, MeetingMember, MeetingMemberAddRequest, RemoveAdditionalMember } from 'models'
import { cloneDeep } from 'lodash'

export enum SetupCommitteeMutations {
    Clear = 'CLEAR',
    SetTypes = 'SET_TYPES',
    SetRoles = 'SET_ROLES',
    SetPrimaryRoles = 'SET_PRIMARY_ROLES',
    SetBilingualRole = 'SET_BILINGUAL_ROLE',
    ClearPrimaryRole = 'CLEAR_PRIMARY_ROLE',
    SetAllMembers = 'SET_ALL_MEMBERS',
    SetDefaultMembers = 'SET_DEFAULT_MEMBERS',
    SetAdditionalMember = 'SET_ADDITIONAL_MEMBER',
    RemoveAdditionalMember = 'REMOVE_ADDITIONAL_MEMBER',
    ClearAdditionalMembers = 'CLEAR_ADDITIONAL_MEMBERS',
    ClearPrimaryRoles = 'CLEAR_PRIMARY_ROLES',
    RemoveMemberFromDefaultMembers = 'REMOVE_MEMBER_FROM_DEFAULT_MEMBERS',
    SetAllMembersUsed = 'SET_ALL_MEMBERS_USED',
    SetFilteredMembers = 'SET_FILTERED_MEMBERS',
    AddFilteredMember = 'ADD_FILTERED_MEMBER',
    RemoveFilteredMember = 'REMOVE_FILTERED_MEMBER',

    // Test Mutations
    SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<SetupCommitteeState> = {
  [SetupCommitteeMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [SetupCommitteeMutations.SetTypes](state, types: MeetingType[]) {
    state.types = types
  },

  [SetupCommitteeMutations.SetRoles](state, roles: MeetingRole[]) {
    state.roles = roles
  },

  [SetupCommitteeMutations.SetPrimaryRoles](state, primaryRole: MeetingMemberAddRequest) {
    state.primaryRoles.push(primaryRole)
  },

  [SetupCommitteeMutations.ClearPrimaryRole](state, primaryRole: MeetingMemberAddRequest) {
    state.primaryRoles = state.primaryRoles.filter(role => role.index !== primaryRole.index)
  },

  [SetupCommitteeMutations.SetBilingualRole](state, bilingualRole: MeetingRole) {
    state.bilingualRole = bilingualRole
  },

  [SetupCommitteeMutations.SetAllMembers](state, members: MeetingMember[]) {
    state.allMembers = members
  },

  [SetupCommitteeMutations.SetDefaultMembers](state, members: MeetingMember[]) {
    state.defaultMembers = members
  },

  [SetupCommitteeMutations.SetAdditionalMember](state, member: number) {
    state.additionalMembers.push(member)
  },

  [SetupCommitteeMutations.RemoveAdditionalMember](state, member: RemoveAdditionalMember) {
    state.additionalMembers = state.additionalMembers.filter(user => user !== member.memberPosition)
  },

  [SetupCommitteeMutations.ClearAdditionalMembers](state) {
    state.additionalMembers = []
  },

  [SetupCommitteeMutations.ClearPrimaryRoles](state) {
    state.primaryRoles = []
  },

  [SetupCommitteeMutations.RemoveMemberFromDefaultMembers](state, memberEmail: string) {
    state.defaultMembers = state.defaultMembers.filter(member => member.email !== memberEmail)
  },

  [SetupCommitteeMutations.SetAllMembersUsed](state, membersEmpty: boolean) {
    state.allMembersUsed = membersEmpty
  },

  [SetupCommitteeMutations.SetFilteredMembers](state, members: MeetingMember[]) {
    state.filteredMembers = members
  },

  [SetupCommitteeMutations.AddFilteredMember](state, member: MeetingMember) {
    state.filteredMembers.push(member)
  },

  [SetupCommitteeMutations.RemoveFilteredMember](state, member: number) {
    state.filteredMembers = state.filteredMembers.filter(mmb => mmb.user?.id !== member)
  },

  // Only use this mutation for setting the state for test cases
  [SetupCommitteeMutations.SetTestState] (state, testData: SetupCommitteeState) {
    Object.assign(state, cloneDeep(testData))
  }
}
