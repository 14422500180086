import { MeetingRole, MeetingMember, MeetingType, MeetingMemberAddRequest } from 'models'
import cloneDeep from 'lodash/cloneDeep'

export enum MemberChoice {
  Existing, New
}

export interface SetupCommitteeState {
  types: MeetingType[],
  roles: MeetingRole[],
  allMembers: MeetingMember[],
  primaryRoles: MeetingMemberAddRequest[],
  bilingualRole: MeetingRole,
  defaultMembers: MeetingMember[],
  additionalMembers: Array<number>,
  allMembersUsed: boolean,
  filteredMembers: MeetingMember[]
}

export const state: SetupCommitteeState = {
  types: [],
  roles: [],
  allMembers: [],
  primaryRoles: [],
  bilingualRole: null,
  defaultMembers: [],
  additionalMembers: [],
  allMembersUsed: false,
  filteredMembers: []
}

export const clearState: SetupCommitteeState = cloneDeep(state)
