import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { MeetingState } from './state'
import { GetMeetingTypeInRoster, SigningStatus } from './types'
import { MeetingMember, Grade, MeetingReviewAddress, MeetingRoster, MeetingFormSignature, MeetingMemberFormSignature, RosterReview } from 'models'
import { flatMap, head, isEmpty, isNil, sortBy } from 'lodash'
import { MeetingStatus, MeetingRosterStatus, MeetingTypeCode } from '@/enums'

export enum MeetingGetters {
  Status = 'STATUS',
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  Roster = 'ROSTER',
  IdentificationRoster = 'IdentificationRoster',
  RosterByCurrentReviewType = 'RosterByCurrentReviewType',
  EmptyRoster = 'EMPTY_ROSTER',
  EmptyRosterMultipleMeeting = 'EMPTY_ROSTER_MULTIPLE_MEETING',
  RosterSize = 'ROSTER_SIZE',
  RosterIds = 'ROSTER_IDS',
  ReadyRoster = 'READY_ROSTER',
  ReadyReviewRoster = 'READY_REVIEW_ROSTER',
  ReadyCount = 'READY_COUNT',
  RosterComplete = 'ROSTER_COMPLETE',
  MeetingTypeInRoster = 'MEETING_TYPE_IN_ROSTER',
  Members = 'MEMBERS',
  MemberRoleNames = 'MEMBER_ROLE_NAMES',
  MeetingGrades = 'MEETING_GRADES',
  MeetingReviewAddresses = 'MEETING_REVIEW_ADDRESSES',
  SummaryText = 'SUMMARY_TEXT',
  ReadyStudentCount = 'READY_STUDENT_COUNT',
  SigningStatus = 'SIGNING_STATUS',
  Cancelable = 'CANCELABLE',
  MeetingStatus = 'MEETING_STATUS',
  MeetingDate = 'MEETING_DATE',
  MeetingFormSignatures = 'MEETING_FORM_SIGNATURES',
  HasAMemberSigned = 'HAS_A_MEMBER_SIGNED'
}

export const getters: GetterTree<MeetingState, RootState> = {

  [MeetingGetters.Status](state): MeetingStatus | void {
    if (state.currentMeeting) {
      return state.currentMeeting.status as MeetingStatus
    }
  },

  [MeetingGetters.Open](state, getters): boolean {
    return getters[MeetingGetters.Status] === MeetingStatus.O
  },

  [MeetingGetters.InProgress](state, getters): boolean {
    return getters[MeetingGetters.Status] === MeetingStatus.P
  },

  [MeetingGetters.Roster](state): MeetingRoster[] {
    return state.currentMeeting ? state.currentMeeting.roster : []
  },

  [MeetingGetters.IdentificationRoster](state): MeetingRoster[] {
    return state.currentMeeting ? state.currentMeeting.roster.filter(rst => rst.meetingType.code === MeetingTypeCode.Identification) : []
  },

  [MeetingGetters.RosterByCurrentReviewType](state): MeetingRoster[] {
    return state.currentMeeting ? state.currentMeeting.roster.filter(rst => rst.meetingType.code === state.currentReviewMtgType) : []
  },

  [MeetingGetters.EmptyRoster](state, getters): boolean | void {
    return isEmpty(getters[MeetingGetters.Roster])
  },

  [MeetingGetters.EmptyRosterMultipleMeeting](state): boolean | void {
    return state.currentMeeting && state.currentMeeting.rosterCount && state.currentMeeting.rosterCount === 0
  },

  [MeetingGetters.RosterSize](state, getters): number | void {
    return getters[MeetingGetters.Roster].length
  },

  [MeetingGetters.RosterIds](state, getters): number[] {
    const roster: MeetingRoster[] = getters[MeetingGetters.Roster]

    return roster.map(roster => roster.id)
  },

  [MeetingGetters.ReadyRoster](state, getters): MeetingRoster[] {
    const roster: MeetingRoster[] = getters[MeetingGetters.Roster]
    const readyRoster: MeetingRoster[] = roster.filter(roster => roster.status === MeetingRosterStatus.Ready && roster.meetingType.code === state.currentReviewMtgType)
    return sortBy(readyRoster, 'studentSchoolGrade.student.lastName')
  },

  [MeetingGetters.ReadyReviewRoster](state): RosterReview[] {
    if (state.rosterReviewStudents) {
      const roster: RosterReview[] = state.rosterReviewStudents?.students
      if (roster && !isNil(head(roster))) {
        return roster.filter(roster => roster.status === MeetingRosterStatus.Ready)
      }
    }
    return []
  },

  [MeetingGetters.ReadyCount](state, getters): number {
    const ready: MeetingRoster[] = getters[MeetingGetters.ReadyRoster]

    return ready.length
  },

  [MeetingGetters.RosterComplete](state, getters): boolean {
    const roster: MeetingRoster[] = getters[MeetingGetters.Roster]

    return roster.every(roster => roster.status === MeetingRosterStatus.Complete)
  },

  [MeetingGetters.MeetingTypeInRoster](state): GetMeetingTypeInRoster {
    return code => {
      if (state.currentMeeting) {
        return state.currentMeeting.roster.some(roster => roster.meetingType.code === code)
      }

      return false
    }
  },

  [MeetingGetters.Members](state): MeetingMember[] {
    return state.currentMeeting && Array.isArray(state.currentMeeting.members) ?
      state.currentMeeting.members : []
  },

  [MeetingGetters.MemberRoleNames](state, getters): string[] {
    return getters[MeetingGetters.Members].map(member => member.roleName)
  },

  [MeetingGetters.MeetingGrades](state): Grade[] {
    return state.currentMeeting && Array.isArray(state.currentMeeting.meetingGrades) ?
      state.currentMeeting.meetingGrades : []
  },

  [MeetingGetters.MeetingReviewAddresses](state): MeetingReviewAddress[] {
    return state.currentMeeting && Array.isArray(state.currentMeeting.meetingReviewAddresses) ?
      state.currentMeeting.meetingReviewAddresses : []
  },

  [MeetingGetters.SummaryText](state): string {
    return state.currentMeeting && state.currentMeeting.summary ? state.currentMeeting.summary : ''
  },

  [MeetingGetters.ReadyStudentCount](state: MeetingState): number {
    return state.currentMeeting && state.currentMeeting.roster ?
      state.currentMeeting.roster.filter(r => r.status === 'READY' && r.meetingType.code === state.currentReviewMtgType).length : 0
  },

  [MeetingGetters.SigningStatus](state: MeetingState): SigningStatus {
    if (!state.currentMeeting) return SigningStatus.NotStarted
    if (state.currentMeeting.signatureRequestId == null) {
      return SigningStatus.NotStarted
    } else if (!state.currentMeeting.allMembersSigned) {
      return SigningStatus.Started
    } else {
      return SigningStatus.Complete
    }
  },

  [MeetingGetters.Cancelable](state: MeetingState, getters): boolean {
    return getters[MeetingGetters.Open] || getters[MeetingGetters.InProgress]
  },

  [MeetingGetters.MeetingStatus](state: MeetingState): MeetingStatus {
    if (state.currentMeeting) {
      return MeetingStatus[state.currentMeeting.status]
    }
  },
  [MeetingGetters.MeetingDate](state: MeetingState, getters): string {
    if (state.currentMeeting) {
      if(getters[MeetingGetters.Open]){
        return state.currentMeeting.date
      }
      return state.currentMeeting.startDate
    }
  },
  [MeetingGetters.MeetingFormSignatures](state: MeetingState): MeetingFormSignature[] {
    if (state.currentMeeting && !isEmpty(state.currentMeeting.meetingFormSignatures)) {
      return state.currentMeeting.meetingFormSignatures
    }
  },
  [MeetingGetters.HasAMemberSigned](state: MeetingState) {
    if (state.currentMeeting && Array.isArray(state.currentMeeting.members)) {
      const mtgMmbSigs: MeetingMemberFormSignature[] = flatMap(state.currentMeeting.members, member => member.meetingMemberSignatures)
      if (Array.isArray(mtgMmbSigs)) {
        const mtgMmbHasSigned = mtgMmbSigs.find(mtgMmbSig => mtgMmbSig.hasSignedIndicator === true)
        if (mtgMmbHasSigned) {
          return true
        }
      }
    }
    return false
  }
}
