import { MutationTree } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { ViewStudentsState, clearState } from './state'
import {
  Meeting,
  MeetingType,
  StudentSchoolGrade,
  StudentPaging,
  Pagination,
  PagedSelectedItems,
  MeetingPlacement,
  MeetingsPaging,
  AssessmentStudentScore,
  StudentGradeAccommodation,
  StudentMarkingPeriodGrades,
  AssessmentAttribute,
  StudentDocument
} from 'models'
import { MeetingTypeCode, ZipFileStatus } from '@/enums'
import { isEmpty } from 'lodash'

export enum ViewStudentsMutations {
  Clear = 'CLEAR',
  SetLoaded = 'SET_LOADED',
  SetCode = 'SET_CODE',
  SetShowInactive = 'SET_SHOW_INACTIVE',
  SetDPU = 'SET_DPU',
  SetTypes = 'SET_TYPES',
  SetStudents = 'SET_STUDENTS',
  SetPaging = 'SET_PAGING',
  SetMeetingsPaging = 'SET_MEETINGS_PAGING',
  SetSelectedStudents = 'SET_SELECTED_STUDENTS',
  ClearSelectedStudents = 'CLEAR_SELECTED_STUDENTS',
  SetStudentSchoolGrade = 'SET_STUDENT_SCHOOL_GRADE',
  SetMeetingPlacement = 'SET_MEETING_PLACEMENT',
  SetStudent = 'SET_STUDENT',
  SetStudentId = 'SET_STUDENT_ID',
  SetMeetings = 'SET_MEETINGS',
  SetStaarScores = 'SET_STUDENT_SCORES',
  SetTelpasScores = 'SET_TELPAS_SCORES',
  SetTestTypes = 'SET_TEST_TYPES',
  SetStudentAccommodations = 'SET_STUDENT_ACCOMMODATIONS',
  SetMarkingPeriodGrades = 'SET_MARKING_PERIOD_GRADES',
  ClearStudentSchoolGrade = 'CLEAR_STUDENT_SCHOOL_GRADE',
  SaveParams = 'SAVE_PARAMS',
  LoadParams = 'LOAD_PARAMS',
  SetSchoolYear = 'SET_SCHOOL_YEAR',
  SetZipDocument = 'SET_ZIP_DOCUMENT',
  SetZipFileStatus = 'SET_ZIP_FILE_STATUS',
  SetHasDocs = 'SET_HAS_DOCS',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<ViewStudentsState> = {

  [ViewStudentsMutations.Clear] (state) {
    const params = state.params
    Object.assign(state, cloneDeep(clearState))
    state.params = params
  },

  [ViewStudentsMutations.SetLoaded] (state, loaded: boolean) {
    state.loaded = loaded
  },

  [ViewStudentsMutations.SetCode] (state, code: MeetingTypeCode) {
    state.code = code
  },

  [ViewStudentsMutations.SetShowInactive] (state, showInactive: boolean) {
    state.showInactive = showInactive
  },

  [ViewStudentsMutations.SetDPU](state, isDPU: boolean) {
    state.isDPU = isDPU
  },

  [ViewStudentsMutations.SetTypes] (state, types: MeetingType[]) {
    state.types = types
  },

  [ViewStudentsMutations.SaveParams] (state, params: StudentPaging) {
    state.params = params
  },

  [ViewStudentsMutations.LoadParams] (state) {
    if (state.params) {
      state.paging = state.params
      state.params = null
    }
  },

  [ViewStudentsMutations.SetStudents] (state, { students, allStudentIds, pagination }: {
    students: StudentSchoolGrade[]
    allStudentIds: number[]
    pagination: Pagination
  }) {
    state.students = students
    state.allStudentIds = allStudentIds
    state.pagination = pagination
  },

  [ViewStudentsMutations.SetStudentSchoolGrade] (state, { studentSchoolGrade }: {
    studentSchoolGrade: StudentSchoolGrade
  }) {
    state.studentSchoolGrade = studentSchoolGrade
  },

  [ViewStudentsMutations.SetMeetingPlacement] (state, { meetingPlacement }: {
    meetingPlacement: MeetingPlacement
  }) {
    state.meetingPlacement = meetingPlacement
  },

  [ViewStudentsMutations.ClearStudentSchoolGrade] (state) {
    state.studentSchoolGrade = null
  },

  [ViewStudentsMutations.SetStudent] (state) {
    state.student = state.studentSchoolGrade ? state.studentSchoolGrade.student : null
  },

  [ViewStudentsMutations.SetStudentId](state, studentId: number) {
    state.studentId = studentId
  },

  [ViewStudentsMutations.SetMeetings] (state, { meetings, pagination }: {
    meetings: Meeting[]
    pagination: Pagination
  }) {
    state.meetings = meetings
    state.pagination = pagination
  },

  [ViewStudentsMutations.SetPaging](state, paging: StudentPaging) {
    state.paging = paging
  },

  [ViewStudentsMutations.SetMeetingsPaging](state, paging: MeetingsPaging) {
    if (!isEmpty(paging)) {
      state.paging = paging
    } else {
      state.paging = state.meetingsPaging
    }
  },

  [ViewStudentsMutations.SetPaging](state, paging: MeetingsPaging) {
    state.paging = paging
  },

  [ViewStudentsMutations.SetSelectedStudents] (state, selected: PagedSelectedItems) {
    state.selectedStudents = selected
  },

  [ViewStudentsMutations.SetStaarScores] (state, staarScores: AssessmentStudentScore[]) {
    state.staarScores = staarScores
  },

  [ViewStudentsMutations.SetTelpasScores] (state, telpasScores: AssessmentStudentScore[]) {
    state.telpasScores = telpasScores
  },

  [ViewStudentsMutations.SetTestTypes] (state, testTypes: AssessmentAttribute[]) {
    state.testTypes = testTypes
  },

  [ViewStudentsMutations.SetStudentAccommodations] (state, studentAccommodations: StudentGradeAccommodation[]) {
    state.studentAccommodations = studentAccommodations
  },

  [ViewStudentsMutations.SetMarkingPeriodGrades] (state, studentMarkingPeriodGrades: StudentMarkingPeriodGrades[]) {
    const grades = studentMarkingPeriodGrades.filter(results => {
      return !(results.gradeValue === '')
    })
    state.studentMarkingPeriodGrades = grades
  },

  [ViewStudentsMutations.ClearSelectedStudents] (state) {
    const { selectedStudents } = cloneDeep(clearState)

    Object.assign(state, { selectedStudents })
  },

  [ViewStudentsMutations.SetSchoolYear] (state, schoolYear: string) {
    state.schoolYear = schoolYear
  },

  [ViewStudentsMutations.SetZipDocument] (state, stuDoc: StudentDocument) {
    state.zipDocument = stuDoc
  },

  [ViewStudentsMutations.SetZipFileStatus] (state, stuZipFileStatus: ZipFileStatus) {
    state.zipFileStatus = stuZipFileStatus
  },

  [ViewStudentsMutations.SetHasDocs] (state, hasDocs: boolean) {
    state.hasDocs = hasDocs
  },

  // Only use this mutation for setting the state for test cases
  [ViewStudentsMutations.SetTestState] (state, testData: ViewStudentsState) {
    Object.assign(state, cloneDeep(testData))
  }

}
