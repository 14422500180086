import { Module } from 'vuex'
import { RootState } from '@/store/root/state'
import { CompletedMeetingState, state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export * from './types'

export { CompletedMeetingState } from './state'
export { CompletedMeetingGetters } from './getters'
export { CompletedMeetingMutations } from './mutations'
export { CompletedMeetingActions } from './actions'

export const store: Module<CompletedMeetingState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
