import { MutationTree } from 'vuex'
import { CompletedMeetingsState, clearState } from './state'
import { Meeting, Pagination, MeetingsPaging } from 'models'
import cloneDeep from 'lodash/cloneDeep'

export enum CompletedMeetingsMutations {
  Clear = 'CLEAR',
  SetMeetings = 'SET_MEETINGS',
  SetPaging = 'SET_PAGING',
  SetLoaded = 'SET_LOADED',
  SetSelectedMeetings = 'SET_SELECTED_MEETINGS',
  SetRoleId = 'SET_ROLEID',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<CompletedMeetingsState> = {

  [CompletedMeetingsMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [CompletedMeetingsMutations.SetMeetings] (state, meetings: Meeting[]) {
    state.meetings = meetings
  },

  [CompletedMeetingsMutations.SetLoaded] (state, loaded: boolean) {
    state.loaded = loaded
  },

  [CompletedMeetingsMutations.SetRoleId] (state, roleId: string) {
    state.roleId = roleId
  },

  [CompletedMeetingsMutations.SetMeetings] (state, { meetings, pagination }: {
    meetings: Meeting[]
    pagination: Pagination
  }) {
    state.meetings = meetings
    state.pagination = pagination
  },

  [CompletedMeetingsMutations.SetPaging](state, paging: MeetingsPaging) {
    state.paging = paging
  },

  // Only use this mutation for setting the state for test cases
  [CompletedMeetingsMutations.SetTestState] (state, testData: CompletedMeetingsState) {
    Object.assign(state, cloneDeep(testData))
  }

}
