import { ActionTree } from 'vuex'
import { EOYLasLinksState } from './state'
import { RootState } from '@/store/root'
import { EOYLasLinksMutations } from './mutations'
import { meetingService, studentService } from '@/services'
import defaults from 'lodash/defaults'

export enum EOYLasLinksActions {
  GetMeeting = 'GET_MEETING',
  GetStudents = 'GET_STUDENTS',
  SetProficiencyEditsForStudent = 'SET_PROFICIENCY_EDITS_FOR_STUDENT',
  SaveProficiencyTestResults = 'SAVE_PROFICIENCY_TEST_RESULTS'
}

export const actions: ActionTree<EOYLasLinksState, RootState> = {
  async [EOYLasLinksActions.GetMeeting] ({ commit, rootState }, meetingId: number) {
    const meeting = await meetingService.getCompletedMeeting(rootState, meetingId)

    commit(EOYLasLinksMutations.SetMeeting, meeting)
  },
  async [EOYLasLinksActions.GetStudents]({ commit, state, rootState }, meetingId: number) {
    const { students, pagination } = await studentService.getRosterStudents(rootState, state.paging, meetingId)

    if (state.paging.page !== pagination.currentPage) {
      commit(EOYLasLinksMutations.SetPaging, defaults({
        page: pagination.currentPage
      }, state.paging))
    }

    commit(EOYLasLinksMutations.SetStudents, { students, pagination })
  },
  async [EOYLasLinksActions.SetProficiencyEditsForStudent]({ commit }, { id, proficiencyTestResults }) {
    commit(EOYLasLinksMutations.SetProficiencyEditsForStudent, { id, proficiencyTestResults })
  },
  async [EOYLasLinksActions.SaveProficiencyTestResults]({ commit, rootState }, { meetingRosterId, studentSchoolGradeId, grade, proficiencyTestResults }) {
    commit(EOYLasLinksMutations.SetIdentificationAssessmentDecisions, { proficiencyTestResults, studentSchoolGradeId, grade })
    const roster = await studentService.saveProficiencyTestResults(rootState, meetingRosterId, studentSchoolGradeId, proficiencyTestResults, true)
    commit(EOYLasLinksMutations.ResetMeetingStudentInRoster, { roster })
    commit(EOYLasLinksMutations.RemoveTestResultsEntryFromMap, roster.id)
  }
}
