import { RootState } from '@/store/root'
import { PagingUtil } from '@806/utils'
import BaseService, {ServiceConfig} from './base'
import {
  Assessment,
  AssessmentResponse,
  GetAssessmentsRequest,
  GetTeachersByStudentRequest,
  Staff,
  StaffResponse,
  StaarDecisionFormResponse,
  StaarDecisionFormRequest,
  SaveStaarJustificationRequest,
  RemoveStaarJustificationRequest,
  StaarJustificationResponse,
  GetSelectionByStudentSchoolGradeIdRequest,
  SelectedStaarJustificationResponse,
  SelectedStaarTeacherResponse,
  SelectedStaarTeacher,
  SelectedStaarJustification,
  AccommodationResponse,
  Accommodation,
  GetMeetingTeachersRequest,
  MeetingStaarTeacherResponse,
  GetStudentAccommodationsRequest,
  StudentAccommodation,
  StudentAccommodationsResponse,
  SaveAccommodationCommitteeRequest,
  SaveAccommodationCommitteeResponse,
  SaveAccomCommitteeOverrideResponse,
  SaveAccomCommitteeOverrideRequest,
  SubjectAccommodation,
  SubjectAccommodationsResponse,
  StudentSubjectAccommodationsResponse
} from 'models'

export default class DecisionsService extends BaseService {
  private readonly baseUri: string = '/decisions'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getAssessments(state: RootState, req: GetAssessmentsRequest): Promise<Assessment[]> {
    return this
      .post<AssessmentResponse>(`${this.baseUri}/getAssessments`, { state, request: req })
      .then(resp => {
        return resp.data.assessments
      })
  }

  async getStaarDecisionFormData(state: RootState): Promise<StaarDecisionFormResponse> {
    const req: StaarDecisionFormRequest = {
      studentId: 7,
      meetingRosterId: 33
    }
    return this.post<StaarDecisionFormResponse>(`${this.baseUri}/getStaarDecisionFormData`, { state, request: req })
    .then(resp => {
      return resp.data
    })
  }

  async getStaarDecisionFormDataTeacher(state): Promise<StaarDecisionFormResponse> {
    const req: StaarDecisionFormRequest = {
      studentId: 7,
      meetingRosterId: 33,
      teacherId: 11
    }
    return this.post<StaarDecisionFormResponse>(`${this.baseUri}/getStaarDecisionFormDataTeacher`, { state, request: req })
    .then(resp => {
      return resp.data
    })
  }

  async getTeachers(
    state: RootState,
    req: GetTeachersByStudentRequest
  ): Promise<Staff[]> {
    return this
    .post<StaffResponse>(`${this.baseUri}/getTeachers`, { state, request: req })
    .then(resp => {
      return resp.data.staff
    })
  }

  async getLpacArdCommitteeStfEntryByDstId(
    state: RootState
  ): Promise<Staff[]> {
    return this
    .post<StaffResponse>(`${this.baseUri}/getLpacArdCommitteeStfEntryByDstId`, { state })
    .then(resp => {
      return resp.data.staff
    })
  }

  async getSelectedStaarTeachers(
    state: RootState,
    req: GetSelectionByStudentSchoolGradeIdRequest
  ): Promise<SelectedStaarTeacher[]> {
    return this
    .post<SelectedStaarTeacherResponse>(`${this.baseUri}/getSelectedStaarTeachers`, { state, request: req })
    .then(resp => {
      return resp.data.selectedStaarTeachers
    })
  }

  async getMeetingStaarTeachers(
    state: RootState,
    req: GetMeetingTeachersRequest
  ): Promise<MeetingStaarTeacherResponse> {
    return PagingUtil.getPagedInBounds(req.paging, async () => {
      return this
      .post<MeetingStaarTeacherResponse>(`${this.baseUri}/getMeetingStaarTeachers`, { state, request: req })
      .then(resp => {
        return resp.data
      })
    })
  }

  async getSelectedStaarJustifications(
    state: RootState,
    req: GetSelectionByStudentSchoolGradeIdRequest
  ): Promise<SelectedStaarJustification[]> {
    return this
    .post<SelectedStaarJustificationResponse>(`${this.baseUri}/getSelectedStaarJustifications`, { state, request: req })
    .then(resp => {
      return resp.data.selectedStaarJustifications
    })
  }

  async getStudentAccommodations(
    state: RootState,
    req: GetStudentAccommodationsRequest
  ): Promise<StudentAccommodation[]> {
    return this
    .post<StudentAccommodationsResponse>(`${this.baseUri}/getStudentAccommodations`, { state, request: req })
    .then(resp => {
      return resp.data.studentAccommodations
    })
  }

  async getStudentSubjectAccommodations(
    state: RootState,
    req: GetStudentAccommodationsRequest
  ): Promise<StudentSubjectAccommodationsResponse> {
    return PagingUtil.getPagedInBounds(req.paging, async () => {
      return this
      .post<StudentSubjectAccommodationsResponse>(`${this.baseUri}/getStudentSubjectAccommodations`, { state, request: req })
      .then(resp => {
        return resp.data
      })
    })
  }

  async saveStaarJustification(
    state: RootState,
    req: SaveStaarJustificationRequest
  ): Promise<StaarJustificationResponse> {
    return this.post<StaarJustificationResponse>(`${this.baseUri}/saveStaarJustification`, { state, request: req })
  }

  async saveTelpasJustifications(
    state: RootState,
    req: SaveStaarJustificationRequest
  ): Promise<StaarJustificationResponse> {
    return this.post<StaarJustificationResponse>(`${this.baseUri}/saveTelpasJustification`, { state, request: req })
  }

  async removeStaarJustification(
    state: RootState,
    req: RemoveStaarJustificationRequest
  ): Promise<StaarJustificationResponse> {
    return this.post<StaarJustificationResponse>(`${this.baseUri}/removeStaarJustificationStudent`, { state, request: req })
  }

  async getAccommodations(
    state: RootState
  ): Promise<Accommodation[]> {
    const { data: { accommodations } } = await this.post<AccommodationResponse>(`${this.baseUri}/getAccommodations`, { state })
    return accommodations
  }

  async getSubjectAccommodations(
    state: RootState
  ): Promise<SubjectAccommodation[]> {
    const { data: { subjectAccommodations } } = await this.post<SubjectAccommodationsResponse>(`${this.baseUri}/getSubjectAccommodations`, { state })
    return subjectAccommodations
  }

  async saveAccommodationCommittees(state: RootState, req: SaveAccommodationCommitteeRequest): Promise<SaveAccommodationCommitteeResponse> {
    return this.post<SaveAccommodationCommitteeResponse>(`${this.baseUri}/saveAccommodationCommittees`, { state, request: req })
  }

  async saveLpacCommitteeOverride(state: RootState, req: SaveAccomCommitteeOverrideRequest): Promise<SaveAccomCommitteeOverrideResponse> {
    const { data } = await this.post<SaveAccomCommitteeOverrideResponse>(`${this.baseUri}/saveAccommodationCommitteesOveride`, { state, request: req })
    return data
  }

  async removeLpacCommitteeOverride(state: RootState, req: SaveAccomCommitteeOverrideRequest): Promise<SaveAccomCommitteeOverrideResponse> {
    const { data } = await this.post<SaveAccomCommitteeOverrideResponse>(`${this.baseUri}/removeAccommodationCommitteesOveride`, { state, request: req })
    return data
  }
}
