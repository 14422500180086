import BaseService, {ServiceConfig} from './base'
import { RootState } from '@/store/root'
import { RestUrls } from './RestUrls'
import { FeatureToggle, SaveFeatureTogglesRequest } from 'models'

export default class FeatureService extends BaseService {

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getFeatures(state: RootState): Promise<FeatureToggle> {

    const { data } = await this.get<FeatureToggle>(`${RestUrls.features}`, state)

    return data
  }

  async setFeatures(state: RootState, request: SaveFeatureTogglesRequest): Promise<FeatureToggle[]> {

    const { data } = await this.post<FeatureToggle[]>(`${RestUrls.features}/save`, { state, request })

    return data

  }

}
