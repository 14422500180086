import { GetterTree } from 'vuex'
import { AuthState } from './state'
import { RootState } from '@/store/root'
import { ELUserRole, ELPermission } from 'models'
import { Dictionary } from 'vue-router/types/router'
import { groupBy } from 'lodash'
import { HasPermission } from './types'
import { AccommodationsSigningStatus } from '@/enums'

export enum AuthGetters {
  Authorized = 'AUTHORIZED',
  UserID = 'USER_ID',
  UserSchoolID = 'USER_SCHOOL_ID',
  UserSchoolName = 'USER_SCHOOL_NAME',
  UserDistrictName = 'USER_DISTRICT_NAME',
  UserFullName = 'USER_FULL_NAME',
  UserTitle = 'USER_TITLE',
  UserEmail = 'USER_EMAIL',
  UserRole = 'USER_ROLE',
  UserRoleName = 'USER_ROLE_NAME',
  UserReadOnly = 'USER_READ_ONLY',
  UserPermissions = 'USER_PERMISSIONS',
  UserStaffId = 'USER_STAFF_ID',
  HasPermission = 'HAS_PERMISSION',
  TeacherUsername = 'TEACHER_USERNAME',
  StaffId = 'STAFF_ID',
  StaffSigningStatus = 'STAFF_SIGNING_STATUS',
  ValidLink = 'VALID_LINK',
  NewTeacherComplete = 'NEW_TEACHER_COMPLETE'
}

export const getters: GetterTree<AuthState, RootState> = {

  [AuthGetters.Authorized] (state): boolean {
    return state.authorized
  },

  [AuthGetters.UserID] (state): number | void {
    if (state.user) {
      return state.user.id
    }
  },

  [AuthGetters.UserSchoolID](state): number | void {
    if (state.user) {
      return state.user.school.id
    }
  },

  [AuthGetters.UserSchoolName] (state): string | void {
    if (state.user) {
      return state.user.school.name
    }
  },

  [AuthGetters.UserDistrictName] (state): string | void {
    if (state.user) {
      return state.user.district.name
    }
  },

  [AuthGetters.UserFullName] (state): string | void {
    if (state.user) {
      return `${state.user.firstName} ${state.user.lastName}`
    }
  },

  [AuthGetters.UserTitle] (state): string | void {
    if (state.user) {
      return state.user.title.name
    }
  },

  [AuthGetters.UserEmail] (state): string | void {
    if (state.user) {
      return state.user.email
    }
  },

  [AuthGetters.UserRole] (state): ELUserRole | void {
    if (state.user) {
      return state.user.role
    }
  },

  [AuthGetters.UserRoleName] (state): string | void {
    if (state.user) {
      return state.user.role.name
    }
  },

  [AuthGetters.UserReadOnly] (state): boolean | void {
    if (state.user) {
      return state.user.role.readOnly
    }
  },

  [AuthGetters.UserPermissions] (state): Dictionary<ELPermission[]> | void {
    if (state.user && state.user.role && state.user.role.permissions) {
      return groupBy(state.user.role.permissions,
        perm => perm.permissionCategory)
    }
  },

  [AuthGetters.UserStaffId] (state): number | void {
    if (state.user && state.user.staffId) {
      return state.user.staffId
    }
  },

  [AuthGetters.HasPermission] (state, getters): HasPermission {
    return request => {
      const permissions: Dictionary<ELPermission[]> = getters[AuthGetters.UserPermissions]

      if (permissions && permissions[request.category]) {
        return permissions[request.category].some(perm => perm.permissionType === request.type)
      }
      return false
    }
  },

  [AuthGetters.TeacherUsername] (state): string | void {
    if (state.teacher) {
      return state.teacher.teacherEmail
    }
  },

  [AuthGetters.StaffId] (state): number | void {
    if (state.teacher) {
      return state.teacher.teacherId
    }
  },

  [AuthGetters.StaffSigningStatus] (state): AccommodationsSigningStatus | void {
    if (state.teacher) {
      return state.teacher.accommodationsStatus
    }
  },

  [AuthGetters.ValidLink] (state): boolean {
    return state.validLink
  },

  [AuthGetters.NewTeacherComplete] (state): boolean {
    return state.newTeacherComplete
  }

}
