import BaseService, {ServiceConfig} from './base'
import {
  StudentInformationRequest,
  StudentInformationResponse,
  GetAccomomdationsRequest,
  StudentGradeAccommodationsResponse,
  TelpasTypesResponse,
  SchoolYearResponse
} from 'models'
import { RootState } from '@/store/root'
import {AxiosRequestConfig} from 'axios';

export default class StandardizedTestService extends BaseService {

  private readonly baseUri: string = '/standardizedTest'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getStudentStaarResults(
    state: RootState,
    studentId: number,
    hideCurrentMeeting?: boolean
  ): Promise<StudentInformationResponse> {
    const request: StudentInformationRequest = { studentId, hideCurrentMeeting }
    const { data } = await this.post<StudentInformationResponse>(`${this.baseUri}/getStudentStaarResults`, { state, request })
    return data
  }

  async getStudentTelpasResults(
    state: RootState,
    studentId: number
  ): Promise<StudentInformationResponse> {
    const config: AxiosRequestConfig = {
      params: {
        studentId: studentId
      }
    }
    const { data } = await this.get<StudentInformationResponse>(`${this.baseUri}/getStudentTelpasResults/` + studentId, state, config)
    return data
  }

  async getTestTypes(
    state: RootState,
    testType: string
  ): Promise<TelpasTypesResponse> {
    const config: AxiosRequestConfig = {
      params: {
        testType: testType
      }
    }
    const { data } = await this.get<TelpasTypesResponse>(`${this.baseUri}/getTestResultTypes/` + testType, state, config)
    return data
  }

  async getStudentAccommodations(
    state: RootState,
    studentId: number,
    nonExit?: boolean
  ): Promise<StudentGradeAccommodationsResponse> {
    const request: GetAccomomdationsRequest = { studentId, nonExit }
    const { data } = await this.post<StudentGradeAccommodationsResponse>(`${this.baseUri}/getStudentAccommodations`, { state, request })
    return data
  }

  async getSchoolYearByStudentSchoolGradeId(
    state: RootState,
    studentSchoolGradeId: number
  ): Promise<SchoolYearResponse> {
    const config: AxiosRequestConfig = {
      params: {
        studentSchoolGradeId: studentSchoolGradeId
      }
    }
    const { data } = await this.get<SchoolYearResponse>(`${this.baseUri}/getSchoolYearByStuSchGrdId/` + studentSchoolGradeId, state, config)
    return data
  }

}
