import { schoolService, authService } from '@/services'
import { RootState } from '@/store/root'
import { UserSetupState } from './state'
import { UserSetupMutations } from './mutations'
import { ActionTree } from 'vuex'
import { ELUser, ELUserEdit, InsertUserRequest, UserEditRequest, UsersRequest } from 'models'
import defaults from 'lodash/defaults'

export enum UserSetupActions {
  GetDistrictSchools = 'GET_DISTRICT_SCHOOLS',
  GetRoles = 'GET_ROLES',
  InsertUser = 'INSERT_USER',
  RemoveUser = 'REMOVE_USER',
  GetDistrictUsers = 'GET_DISTRICT_USERS',
  GetUserEdit = 'GET_USER_EDIT',
  SaveUserEdit = 'SAVE_USER_EDIT',
  GetCommitteeAssignments = 'GET_COMMITTEE_ASSIGNMENTS'
}

export const actions: ActionTree<UserSetupState, RootState> = {

  async [UserSetupActions.GetDistrictSchools]({ commit, rootState }) {
    const schools = await schoolService.getDistrictSchools(rootState)

    commit(UserSetupMutations.SetDistrictSchools, schools)
  },

  async [UserSetupActions.GetCommitteeAssignments]({ commit, rootState }, userId: number) {
    const { assignments } = await authService.getCommitteeAssignments(rootState, userId);

    commit(UserSetupMutations.SetAssignedCommittees, assignments)
  },

  async [UserSetupActions.GetDistrictUsers]({ commit, state, rootState }) {
    const request: UsersRequest = { paging: state.paging }
    const { users, pagination } = await authService.getDistrictUsers(rootState, request)

    if (state.paging.page !== pagination.currentPage) {
      commit(UserSetupMutations.SetPaging, defaults({
        page: pagination.currentPage
      }, state.paging))
    }

    commit(UserSetupMutations.SetDistrictUsers, { users, pagination })
  },

  async [UserSetupActions.GetRoles]({ commit, rootState }, roleInd: string) {
    const roles = await schoolService.getRoles(rootState, roleInd)

    commit(UserSetupMutations.SetRoles, roles)
  },

  async [UserSetupActions.InsertUser](context, req: InsertUserRequest) {
    const user = await authService.insertUser(req)
    return user
  },

  async [UserSetupActions.RemoveUser]({ rootState }, user: ELUser) {
    const data = await authService.removeUser(rootState, user)
    return data
  },

  async [UserSetupActions.GetUserEdit]({ commit, rootState }, userId: number) {
    const request: UsersRequest = { userId }
    const user = await authService.getUserEdit(rootState, request)

    commit(UserSetupMutations.SetEditUser, user)
  },

  async [UserSetupActions.SaveUserEdit]({ rootState }, userEdit: ELUserEdit) {
    const request: UserEditRequest = { userEdit }
    const user = await authService.saveUserEdit(rootState, request)
    return user
  }

}
