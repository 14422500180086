import {CustomFilterMenu, CustomFilterOption} from 'models';
import {CustomFilterComponent} from '@/enums';

export enum ComponentType {
  DROPDOWN = 'dropdown',
  TOGGLE = 'toggle'
}

export enum FieldType {
  View = 'view',
  Filter = 'filter'
}

export type SetSelected = (option: CustomFilterOption) => void
export type GetFilterMenuByType = (type: CustomFilterComponent) => CustomFilterMenu
export type GetFilterMenus = (type: CustomFilterComponent[]) => Promise<void>
export type SetType = (type: CustomFilterComponent) => void
export type SetFiltered = (filtered: boolean) => void
export type ClearSelected = () => void

