import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { StaarAccommodationsState } from './state'
import {
  GetStudentAccommodations,
  TeacherId
} from './types'
import { isEmpty } from 'lodash'

export enum StaarAccommodationsGetters {
  GetStudentsAccommodations = 'GET_STUDENTS_ACCOMMODATIONS'
}

export const getters: GetterTree<StaarAccommodationsState, RootState> = {
  [StaarAccommodationsGetters.GetStudentsAccommodations](state): GetStudentAccommodations {
    return (teacherId: TeacherId) => {
      if (!isEmpty(state.staarAccommodationsStudents) && teacherId) {
        return state.staarAccommodationsStudents.filter(student => student.staffId === teacherId)
      }
      return state.staarAccommodationsStudents
    }
  }
}
