import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { MeetingSummaryState } from './state'
import { MeetingStatus } from './types'

export enum MeetingSummaryGetters {
  NonEmptyStatuses = 'NON_EMPTY_STATUSES'
}

export const getters: GetterTree<MeetingSummaryState, RootState> = {
  [MeetingSummaryGetters.NonEmptyStatuses](state: MeetingSummaryState): MeetingStatus[] {
    return Object
      .keys(state.statuses)
      .map<MeetingStatus>(s => state.statuses[s])
      .filter(status => status.numberOfStudents > 0)
  }
}
