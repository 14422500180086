import BaseService, {ServiceConfig} from './base'
import { RootState } from '@/store/root'
import { Grade, GradeListResponse, MeetingReviewAddress, MeetingReviewAddressesResponse } from 'models'

export default class DimensionalLookupService extends BaseService {
  private readonly baseUri: string = '/dimensions'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getMeetingReviewAddresses(state: RootState): Promise<MeetingReviewAddress[]> {
    const { data: { meetingReviewAddresses } } = await this.post<MeetingReviewAddressesResponse>(`${this.baseUri}/getMeetingReviewAddresses`, { state })
    return meetingReviewAddresses
  }

  async getAllGrades(state: RootState): Promise<Grade[]> {
    const { data: { grades } } = await this.post<GradeListResponse>(`${this.baseUri}/getAllGrades`, { state })
    const sortedGrades = grades.sort((a, b) => a.order - b.order)
    return sortedGrades
  }
}
