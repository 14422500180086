import { MutationTree } from 'vuex'
import {
  ELSchool,
  ELUserRole,
  ELUser,
  Pagination,
  ELUsersPaging,
  ELUserEdit,
  ELUserCampus,
  MeetingMemberAssignment
} from 'models'
import { UserSetupState } from './state'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import { cloneDeep } from 'lodash'

export enum UserSetupMutations {
  Clear = 'CLEAR',
  SetDistrictSchools = 'SET_DISTRICT_SCHOOLS',
  SetRoles = 'SET_ROLES',
  SetDistrictUsers = 'SET_DISTRICT_USERS',
  SetPaging = 'SET_PAGING',
  SetEditUser = 'SET_EDIT_USER',
  InitializeSchoolTypes = 'INITIALIZE_SCHOOL_TYPES',
  SetSchoolTypeOpen = 'SET_SCHOOL_TYPE_OPEN',
  UpdateAdvancedCampuses = 'UPDATE_ADVANCED_CAMPUSES',
  UpdateAdvancedCampusesByType = 'UPDATE_ADVANCED_CAMPUSES_BY_TYPE',
  SetAssignedCommittees = 'SET_ASSIGNED_COMMITTEES',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<UserSetupState> = {

  [UserSetupMutations.Clear](state) {
    state.advancedCampuses = []
    state.schoolTypes = []
    state.editUser = null
  },

  [UserSetupMutations.SetDistrictSchools](state, schools: ELSchool[]) {
    state.districtSchools = schools
  },

  [UserSetupMutations.SetAssignedCommittees](state, assignedCommittees: MeetingMemberAssignment[]) {
    state.assignedCommittees = assignedCommittees
  },

  [UserSetupMutations.SetDistrictUsers](state, { users, pagination }: {
    users: ELUser[],
    pagination: Pagination
  }) {
    state.users = users
    state.pagination = pagination
  },

  [UserSetupMutations.SetPaging](state, paging: ELUsersPaging) {
    state.paging = paging
  },

  [UserSetupMutations.SetEditUser](state, userEdit: ELUserEdit) {
    state.editUser = userEdit

    state.schoolTypes.forEach(schoolType => {
      if (schoolType?.schools?.some(school => !isEmpty(state.editUser.advancedCampuses) && state.editUser?.advancedCampuses?.some(campus => campus.campusId === school.id))) {
        schoolType.open = true
      } else {
        schoolType.open = false
      }
    })

    state.advancedCampuses = userEdit.advancedCampuses
  },

  [UserSetupMutations.SetRoles](state, roles: ELUserRole[]) {
    state.roles = roles
    const roleOpts = roles.map(opt => ({ name: opt.name, value: opt.id }))
    roleOpts.unshift({ name: 'No Access', value: 0 })
    state.roleOptions = roleOpts
  },

  [UserSetupMutations.InitializeSchoolTypes](state) {
    const schoolTypeKeys = Object.keys(groupBy(state.districtSchools, school => school.schoolTypeCode))
    state.schoolTypes = schoolTypeKeys.map(type => ({
      open: false,
      code: type,
      schools: state.districtSchools.filter(school => school.schoolTypeCode === type).sort((a,b) => {
        if (a.name > b.name) { return 1 }
        if (a.name < b.name) { return -1 }
        return 0
      }),
      permission: 0
    }))
  },

  async [UserSetupMutations.SetSchoolTypeOpen](state, { typeCode, permission }: {
    typeCode: string,
    permission?: number
  }) {
    state.schoolTypes.forEach(type => {
      if (type.code === typeCode) {
        type.open = !type.open
        type.permission = permission ? permission : type.permission
      }
    })
  },

  async [UserSetupMutations.UpdateAdvancedCampuses](state, edit: ELUserCampus) {
    const campus = state.advancedCampuses.find(adv => adv.campusId === edit.campusId)
    if (campus) {
      state.advancedCampuses = state.advancedCampuses.filter(adv => adv.campusId !== campus.campusId)
    }
    state.advancedCampuses.push(edit)
  },

  async [UserSetupMutations.UpdateAdvancedCampusesByType](state, { typeCode, permission }: {
    typeCode: string,
    permission: number
  }) {
    const schools = state.districtSchools.filter(school => school.schoolTypeCode === typeCode)
    const campuses = state.advancedCampuses.filter(adv =>
      schools.some(school => school.id === adv.campusId))
    if (campuses) {
      state.advancedCampuses = state.advancedCampuses.filter(adv => !campuses.some(camp => camp.campusId === adv.campusId))
    }
    state.advancedCampuses.push(...schools.map(school => ({ campusId: school.id, roleId: permission } as ELUserCampus)))
  },

  // Only use this mutation for setting the state for test cases
  [UserSetupMutations.SetTestState] (state, testData: UserSetupState) {
    Object.assign(state, cloneDeep(testData))
  }

}
