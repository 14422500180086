import BaseService, {ServiceConfig} from './base'
import {
  Dimensions,
  DimensionsResponse
} from 'models'
import { RootState } from '@/store/root'

export default class DimensionService extends BaseService {
  private readonly baseUri: string = '/dimensions'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getDimensions(state: RootState): Promise<Dimensions> {
    const { data: { dimensions } } = await this.get<DimensionsResponse>(`${this.baseUri}`,  state)

    return dimensions
  }

}
