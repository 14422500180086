import { studentService, meetingService } from '@/services'
import { RootGetters, RootState } from '@/store/root'
import { ReviewStudentState } from './state'
import { ReviewStudentMutations } from './mutations'
import { ReviewStudentGetters } from './getters'
import { ActionTree } from 'vuex'
import { buildTest, buildRecommendations } from './builders'
import { buildRecommendations as buildNewRecommendations } from '@/store/modules/recommendations/builders'
import { EnrollmentTypeCode, ProficiencyTestTypeCode } from '@/enums'
import { DualLanguageSelection, EnrollmentType, SetRosterEnrollmentTypeRequest, SaveRosterTransferOptionsRequest, MeetingTypeCode, SaveDnqRequest } from 'models'
import isEmpty from 'lodash/isEmpty'

export enum ReviewStudentActions {
  GetRoster = 'GET_ROSTER',
  SetProficiencyTestTypeForRoster = 'SET_PROFICIENCY_TEST_TYPE',
  SaveProficiencyTest = 'SAVE_PROFICIENCY_TEST',
  SaveRecommendations = 'SAVE_RECOMMENDATIONS',
  CompleteReview = 'COMPLETE_REVIEW',
  CompleteAnnualLepReview = 'COMPLETE_ANNUAL_LEP_REVIEW',
  CompletePotentialExitReview = 'COMPLETE_POTENTIAL_EXIT_REVIEW',
  CompleteAnnualMonitorReview = 'COMPLETE_ANNUAL_MONITOR_REVIEW',
  GetTeacherDocument = 'GET_TEACHER_DOCUMENT',
  RemoveTeacherDocument = 'REMOVE_TEACHER_DOCUMENT',
  SetRosterEnrollmentType = 'SET_ROSTER_ENROLLMENT_TYPE',
  SaveDnqStatus = 'SAVE_DNQ_STATUS',
}

export const actions: ActionTree<ReviewStudentState, RootState> = {

  async [ReviewStudentActions.GetRoster]({ commit, rootState, state },  { rosterId, meetingStatus }) {
    commit(ReviewStudentMutations.SetLoading, true)

    try {
      const roster = await studentService.getRoster(rootState, rosterId, meetingStatus)
      if(!isEmpty(roster.studentPlacement)){
        commit(ReviewStudentMutations.SetPriorMeetingPlacement, roster.studentPlacement)
      } else {
        const { priorMeetingPlacement } = await studentService.getPriorMeetingPlacement(rootState, String(roster.studentSchoolGrade.id))
        commit(ReviewStudentMutations.SetPriorMeetingPlacement, priorMeetingPlacement)
      }
      
      const notes = roster.summary ?? state.notes
      if (roster?.enrollmentType?.enrollmentTypeCode === EnrollmentTypeCode.Review && (!roster?.meetingPlacement?.programType?.lepStatusId || !roster?.meetingPlacement?.programType?.programTypeCD)) {
        roster.meetingPlacement = roster.carryOverMeetingPlacement
      }
      commit(ReviewStudentMutations.SetRoster, roster)
      commit(ReviewStudentMutations.SetNotes, notes)
      commit(ReviewStudentMutations.SetRosterTransferEdits, roster.transferOptionIds)
    } finally {
      commit(ReviewStudentMutations.SetLoading, false)
    }
  },

  async [ReviewStudentActions.SaveDnqStatus]({ rootState }, { meetingRosterId, isDnq }) {
    const request: SaveDnqRequest = { isDnq, meetingRosterId }
    await studentService.saveDnqStatus(rootState, request)
  },

  async [ReviewStudentActions.SaveProficiencyTest]({ commit, state, getters, rootState }) {
    const meetingRosterId = getters[ReviewStudentGetters.MeetingRosterId]
    commit(ReviewStudentMutations.SetLoading, true)

    try {
      if (state.roster.proficiencyTestType.proficiencyTestTypeCode === ProficiencyTestTypeCode.LasLink) {
        const roster = await studentService.saveStudentProficiencyTest(rootState, meetingRosterId, state.proficiencyTestResultEdits)
        commit(ReviewStudentMutations.SetRoster, roster)
      } else {
        const test = buildTest(state, getters)
        const meetingTypeCode = getters[ReviewStudentGetters.MeetingTypeCode]
        const roster = await studentService.saveProficiencyTest(rootState, meetingRosterId, test, meetingTypeCode)
        commit(ReviewStudentMutations.SetRoster, roster)
      }
      const enrollmentType: EnrollmentType = getters[ReviewStudentGetters.EnrollmentType]
      if (enrollmentType?.enrollmentTypeCode === EnrollmentTypeCode.TransferEnrollment || enrollmentType?.enrollmentTypeCode === EnrollmentTypeCode.Review) {
        const transferOptionIds = Array.from(state.transferOptIds)
        const saveRosterTransferOptionsRequest: SaveRosterTransferOptionsRequest = {
          meetingRosterId,
          transferOptionIds
        }
        await studentService.saveRosterTransferOptions(rootState, saveRosterTransferOptionsRequest)
      }
    } finally {
      commit(ReviewStudentMutations.SetLoading, false)
    }
  },

  async [ReviewStudentActions.SaveRecommendations]({ commit, state, getters, rootState }) {
    const meetingRosterId = getters[ReviewStudentGetters.MeetingRosterId]
    const recommendations = buildRecommendations(state, getters)

    commit(ReviewStudentMutations.SetLoading, true)

    try {
      const roster = await studentService.saveRecommendations(rootState, meetingRosterId, recommendations)

      commit(ReviewStudentMutations.SetRoster, roster)
    } finally {
      commit(ReviewStudentMutations.SetLoading, false)
    }
  },

  async [ReviewStudentActions.CompleteReview]({ commit, state, getters, rootState, rootGetters }, { summary, inProgress, studentRecommendations }) {
    const meetingRosterId = getters[ReviewStudentGetters.MeetingRosterId]
    const test = buildTest(state, getters)
    const recommendations = buildNewRecommendations(studentRecommendations, getters)
    const proficiencyTestTypeCode: ProficiencyTestTypeCode = state.roster.proficiencyTestType.proficiencyTestTypeCode as ProficiencyTestTypeCode
    const dualLanguage: DualLanguageSelection = studentRecommendations.dualLanguage
    const enrollmentType: EnrollmentType = getters[ReviewStudentGetters.EnrollmentType]

    commit(ReviewStudentMutations.SetLoading, true)

    try {
      if (enrollmentType?.enrollmentTypeCode === EnrollmentTypeCode.TransferEnrollment || enrollmentType?.enrollmentTypeCode === EnrollmentTypeCode.Review) {
        const transferOptionIds = Array.from(state.transferOptIds)
        const saveRosterTransferOptionsRequest: SaveRosterTransferOptionsRequest = {
          meetingRosterId,
          transferOptionIds
        }
        if (!isEmpty(saveRosterTransferOptionsRequest.transferOptionIds)) {
          await studentService.saveRosterTransferOptions(rootState, saveRosterTransferOptionsRequest)
        }
      }
      const meetingTypeCode: MeetingTypeCode = state.roster.meetingType.code
      const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
      const meetingId: number = multiMeetingsActive ? state.roster.meetingId : null
      await studentService.saveStudentReview(rootState, meetingRosterId, summary, test, state.proficiencyTestResultEdits, recommendations, proficiencyTestTypeCode, inProgress, dualLanguage, meetingTypeCode, meetingId)
    } finally {
      commit(ReviewStudentMutations.SetLoading, false)
    }
  },

  async [ReviewStudentActions.CompleteAnnualLepReview]({ commit, state, getters, rootState, rootGetters }, { summary, inProgress, meetingTypeCode, studentRecommendations }) {
    const meetingRosterId = getters[ReviewStudentGetters.MeetingRosterId]
    const test = buildTest(state, getters)
    const recommendations = buildNewRecommendations(studentRecommendations, getters)
    const dualLanguage: DualLanguageSelection = studentRecommendations.dualLanguage
    const proficiencyTestTypeCode: ProficiencyTestTypeCode = state.roster.proficiencyTestType.proficiencyTestTypeCode as ProficiencyTestTypeCode
    commit(ReviewStudentMutations.SetLoading, true)
    try {
      const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
      const meetingId: number = multiMeetingsActive ? state.roster.meetingId : null
      await studentService.saveStudentAnnualLepReview(rootState, meetingRosterId, summary, test, state.proficiencyTestResultEdits, recommendations, proficiencyTestTypeCode, inProgress, state.readiness, dualLanguage, meetingId, meetingTypeCode)
    } finally {
      commit(ReviewStudentMutations.SetLoading, false)
    }
  },

  async [ReviewStudentActions.CompletePotentialExitReview]({ commit, state, getters, rootState, rootGetters }, { summary, studentRecommendations } ) {
    const meetingRosterId = getters[ReviewStudentGetters.MeetingRosterId]
    const test = buildTest(state, getters)
    const recommendations = buildNewRecommendations(studentRecommendations, getters)
    const dualLanguage: DualLanguageSelection = studentRecommendations?.dualLanguage
    const proficiencyTestTypeCode: ProficiencyTestTypeCode = state.roster.proficiencyTestType.proficiencyTestTypeCode as ProficiencyTestTypeCode
    commit(ReviewStudentMutations.SetLoading, true)
    try {
      const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
      const meetingId: number = multiMeetingsActive ? state.roster.meetingId : null
      await studentService.saveStudentPotentialExit(rootState, meetingRosterId, summary, test, state.proficiencyTestResultEdits, recommendations, proficiencyTestTypeCode, null, state.readiness, dualLanguage, meetingId)
    } finally {
      commit(ReviewStudentMutations.SetLoading, false)
    }
  },

  async [ReviewStudentActions.CompleteAnnualMonitorReview]({ commit, state, getters, rootState, rootGetters }, { summary, inProgress, studentRecommendations }) {
    const meetingRosterId = getters[ReviewStudentGetters.MeetingRosterId]
    const test = buildTest(state, getters)
    const recommendations = buildNewRecommendations(studentRecommendations, getters)
    const proficiencyTestTypeCode: ProficiencyTestTypeCode = state.roster.proficiencyTestType.proficiencyTestTypeCode as ProficiencyTestTypeCode
    const dualLanguage: DualLanguageSelection = studentRecommendations.dualLanguage

    commit(ReviewStudentMutations.SetLoading, true)

    try {
      const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
      const meetingId: number = multiMeetingsActive ? state.roster.meetingId : null
      await studentService.saveStudentAnnualMonitorReview(rootState, meetingRosterId, summary, test, state.proficiencyTestResultEdits, recommendations, proficiencyTestTypeCode, inProgress, dualLanguage, meetingId)
    } finally {
      commit(ReviewStudentMutations.SetLoading, false)
    }
  },

  async [ReviewStudentActions.GetTeacherDocument]({ rootState }, teacherDocumentLocation: string) {
    await meetingService.downloadTeacherDocument(rootState, teacherDocumentLocation)
  },

  async [ReviewStudentActions.RemoveTeacherDocument]({ commit, rootState }, rosterId: number) {
    const roster = await studentService.removeTeacherDocument(rootState, rosterId)

    commit(ReviewStudentMutations.SetRoster, roster)
  },

  async [ReviewStudentActions.SetProficiencyTestTypeForRoster]({ commit, rootState }, { meetingRosterId, proficiencyTestTypeCode }) {
    const roster = await studentService.saveProficiencyTestType(rootState, meetingRosterId, proficiencyTestTypeCode)
    commit(ReviewStudentMutations.SetRoster, roster)
  },
  async [ReviewStudentActions.SetRosterEnrollmentType]({ commit, rootState }, setRosterEnrollmentTypeRequest: SetRosterEnrollmentTypeRequest) {
    await studentService.setRosterEnrollmentType(rootState, setRosterEnrollmentTypeRequest)
    commit(ReviewStudentMutations.SetRosterEnrollmentType, setRosterEnrollmentTypeRequest.enrollmentType)
  }

}
