import Vue from 'vue'
import { MutationTree } from 'vuex'
import { MeetingType, Accommodation, StudentNote, SelectedAccommodationCommittee, SubjectAccommodation } from 'models'
import { OverviewMeetingState, clearState } from './state'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import { MeetingTypeStudents, SubjectAccommodationEdits } from './types'

export enum OverviewMeetingMutations {
    Clear = 'CLEAR',
    SetTypes = 'SET_TYPES',
    ClearMeetingType = 'CLEAR_MEETING_TYPE',
    SetMeetingTypeStudents = 'SET_MEETING_TYPE_STUDENTS',
    SetMeetingTypePaging = 'SET_MEETING_TYPE_PAGING',
    SetMeetingTypeSelected = 'SET_MEETING_TYPE_SELECTED',
    ClearMeetingTypeSelected = 'CLEAR_MEETING_TYPE_SELECTED',
    SetCurrentSigningUrl = 'SET_CURRENT_SIGNING_URL',
    CompleteStudentReviewStatus = 'COMP_STU_RVW_STA',
    SetAccommodations = 'SET_ACCOMMODATIONS',
    SetSubjectAccommodations = 'SET_SUBJECT_ACCOMMODATIONS',
    SetStudentNotes = 'SET_STUDENT_STAAR_NOTES',
    UpdateStudentNote = 'UPDATE_STUDENT_STAAR_NOTE',
    SetSubjectAccommodationEdits = 'SET_SUBJECT_ACCOMMODATIONS_EDITS',
    UpdateAssessmentFormatOption = 'UPDATE_ASSESSMENT_FORMAT_OPTION',
    AddCommitteeAccom = 'ADD_COMMITTEE_ACCOM',
    RemoveCommitteeAccom = 'REMOVE_COMMITTEE_ACCOM',
    ResetRosterEntry = 'RESET_ROSTER_ENTRY',
    SetLoading = 'SET_LOADING',

    // Test Mutations
    SetTestState = 'SET_TEST_STATE'
  }

export const mutations: MutationTree<OverviewMeetingState> = {
  [OverviewMeetingMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [OverviewMeetingMutations.SetTypes](state, types: MeetingType[]) {
    state.types = types
  },

  [OverviewMeetingMutations.SetMeetingTypeStudents](state, { meetingType, students, pagination }) {
    Vue.set(state.meetingStudents[meetingType], 'students', students)
    Vue.set(state.meetingStudents[meetingType], 'pagination', pagination)
  },

  [OverviewMeetingMutations.SetMeetingTypePaging](state, { meetingType, paging }) {
    Vue.set(state.meetingStudents[meetingType], 'paging', paging)
  },

  [OverviewMeetingMutations.SetMeetingTypeSelected](state, { meetingType, selected }) {
    Vue.set(state.meetingStudents[meetingType], 'selected', selected)
  },

  [OverviewMeetingMutations.SetCurrentSigningUrl](state, signUrl: string) {
    state.currentSigningUrl = signUrl
  },
  [OverviewMeetingMutations.SetAccommodations](state, accommodations: Accommodation[]) {
    state.accommodations = accommodations
  },
  [OverviewMeetingMutations.SetSubjectAccommodations](state, subjectAccommodations: SubjectAccommodation[]) {
    state.subjectAccommodations = subjectAccommodations
  },
  [OverviewMeetingMutations.SetStudentNotes](state, studentNoteLst: StudentNote[]) {
    const studentNoteMap: Map<number, StudentNote> = new Map(studentNoteLst.map(studentNote => [studentNote.studentSchoolGrdId, studentNote]))
    state.studentNotesMap = studentNoteMap
  },
  [OverviewMeetingMutations.UpdateStudentNote](state, studentNote: StudentNote) {
    state.studentNotesMap.set(studentNote.studentSchoolGrdId, studentNote)
  },
  [OverviewMeetingMutations.SetSubjectAccommodationEdits](state, subjectAccommodationEdits: SubjectAccommodationEdits) {
    state.subjectAccommodationEdits = subjectAccommodationEdits
  },
  [OverviewMeetingMutations.UpdateAssessmentFormatOption](state, formatOpt: string) {
    state.subjectAccommodationEdits.subject.formatOpt = formatOpt
  },
  [OverviewMeetingMutations.AddCommitteeAccom](state, selectedAccommodationCommittee: SelectedAccommodationCommittee) {
    const filteredEntries = state.lpacCommitteeAccomEdits.filter(edit => !(edit.accommodationId === selectedAccommodationCommittee.accommodationId && edit.subjectId === selectedAccommodationCommittee.subjectId))
    filteredEntries.push(selectedAccommodationCommittee)
    state.lpacCommitteeAccomEdits = filteredEntries
  },
  [OverviewMeetingMutations.RemoveCommitteeAccom](state, selectedAccommodationCommittee: SelectedAccommodationCommittee) {
    const filteredEntries = state.lpacCommitteeAccomEdits.filter(edit => !(edit.studentSchoolGradeId === selectedAccommodationCommittee.studentSchoolGradeId && edit.accommodationId === selectedAccommodationCommittee.accommodationId && edit.subjectId === selectedAccommodationCommittee.subjectId))
    state.lpacCommitteeAccomEdits = filteredEntries
  },
  [OverviewMeetingMutations.ResetRosterEntry](state, { meetingType, roster }) {
    const currentMeetingStudents: MeetingTypeStudents = state.meetingStudents[meetingType]
    const rstIndex = currentMeetingStudents.students.findIndex(rst => rst.id === roster.id)
    if (!isNil(rstIndex)) {
      currentMeetingStudents.students[rstIndex] = roster
      const updatedStudents = cloneDeep(currentMeetingStudents.students)
      Vue.set(state.meetingStudents[meetingType], 'students', updatedStudents)
    }
  },
  [OverviewMeetingMutations.SetLoading](state, loading: boolean) {
    state.loading = loading
  },
  // Only use this mutation for setting the state for test cases
  [OverviewMeetingMutations.SetTestState] (state, testData: OverviewMeetingState) {
    Object.assign(state, cloneDeep(testData))
  }
}
