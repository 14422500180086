import { documentsService, meetingService, signService, studentService } from '@/services'
import { RootGetters, RootState } from '@/store/root'
import { MeetingState, state } from './state'
import { MeetingMutations } from './mutations'
import { ActionTree } from 'vuex'
import {
  MeetingSelectedStudentsRequest,
  MeetingMemberUpdateRequest,
  InitiateMeetingSigningRequest,
  InitiateMeetingSigningResponse,
  SaveMeetingMinutesRequest,
  RetrieveAllSignedFilesRequest,
  RemoveStudentRequest,
  SigningStatusRequest,
  RetrieveTeacherSignedFilesRequest,
  InitiateStaarDecisionSigningRequest,
  InitiateStaarDecisionSigningResponse,
  SigningStatusResponse,
  PrintTeacherFormRequest,
  GetCurrentSigIdRequest,
  ConfirmCommitteeRequest,
  ResetMeetingSignaturesRequest,
  PrintReclassificationStaarRequest,
  GetMeetingMinutesInfoRequest,
  InitiateTelpasDecisionSigningRequest,
  InitiateTelpasDecisionSigningResponse,
  PrintReclassificationTelpasRequest,
  GetCurrentMtgSignaturesRequest,
  MemberSigningStatusRequest,
  MemberSigningStatusResponse,
  MeetingMemberScreenshotRequest,
  InitiateMeetingMinuteSigningRequest,
  InitiateMeetingMinuteSigningResponse,
  StudentPaging,
  PrintReclassificationRequest,
  CreateMeetingRequest,
  DashboardMeetingsResponse,
  DeleteMeetingRequest,
  MeetingRequest,
  GetRosterRequest,
  Meeting,
  UpdateMeetingRequest,
  StudentSchoolGrade,
  MeetingMemberAddRequest, ProcessStudentPlacementRequest
} from 'models'
import { LimitedEnglishProficiencyCode, MeetingFormTypeCode, MeetingTypeCode } from '@/enums'
import defaults from 'lodash/defaults'
import { MeetingWithType } from '../review-meeting'
import {CurrentMeeting} from './types'
import {ErrorCode} from '@/util/errors';
import {isEqual} from 'lodash';

export enum MeetingActions {
  GetRosterReviewStudents = 'GET_ROSTER_REVIEW_STUDENTS',
  GetAllRostersCompleted = 'GET_ALL_ROSTERS_COMPLETED',
  GetCurrentMeeting = 'GET_CURRENT_MEETING',
  GetMeetingByMeetingId = 'GET_MEETING_BY_MEETING_ID',
  GetDashboardMeetings = 'GET_DASHBOARD_MEETINGS',
  CreateMeeting = 'CREATE_MEETING',
  SaveMeeting = 'SAVE_MEETING',
  CancelMeeting = 'CANCEL_MEETING',
  StartMeeting = 'START_MEETING',
  AddStudents = 'ADD_STUDENTS',
  RemoveStudents = 'REMOVE_STUDENTS',
  RemoveStudent = 'REMOVE_STUDENT',
  AddMember = 'ADD_MEMBER',
  AddDefaultMember = 'ADD_DEFAULT_MEMBER',
  RemoveMember = 'REMOVE_MEMBER',
  UpdateMember = 'UPDATE_MEMBER',
  SetHasSigned = 'SET_HAS_SIGNED',
  SetMemberHasSigned = 'SET_MEMBER_HAS_SIGNED',
  InitiateMeetingMinutesSignature = 'INIT_MTG_MIN_SIG',
  InitiateSignatures = 'INIT_SIGS',
  InitiateStaarSignatures = 'INIT_STAAR_SIGS',
  InitiateTelpasSignatures = 'INIT_TELPAS_SIGS',
  SaveMeetingMinutes = 'SAVE_MEETING_MINUTES',
  RetrieveSignedFiles = 'RETRIEVE_SIGNED_FILES',
  RetrieveSignedStaarFiles = 'RETRIEVE_SIGNED_STAAR_FILES',
  RetrieveSignedTeacherStaarFiles = 'RETRIEVE_SIGNED_TEACHER_STAAR_FILES',
  RetrieveSignedTelpasFiles = 'RETRIEVE_SIGNED_TELPAS_FILES',
  ClearCurrentMeeting = 'CLEAR_CURRENT_MEETING',
  SetLastSignSuccess = 'SET_LAST_SIGN_SUCCESS',
  PrintTeacherForm = 'PRINT_TEACHER_FORM',
  GetCurrentSigId = 'GET_CURRENT_SIG_ID',
  GetCurrentMtgSignatures = 'GET_CURRENT_MTG_SIGNATURES',
  ConfirmCommittee = 'CONFIRM_COMMITTEE',
  ResetMeetingSignatures = 'RESET_MEETING_SIGNATURES',
  ResetMeetingSignaturesById = 'RESET_MEETING_SIGNATURES_BY_ID',
  PrintReclassStaar = 'PRINT_RECLASS_STAAR',
  PrintReclassTelpas = 'PRINT_RECLASS_TELPAS',
  GetMeetingMinutes = 'GET_MEETING_MINUTES',
  GetMeetingMemberScreenShotLoc = 'GET_MEETING_MEMBER_LOCATION',
  DownloadMeetingAttendeeUpload = 'DOWNLOAD_MEETING_ATTENDEE_UPLOAD',
  SkipMeetingAttendeeUpload = 'SKIP_MEETING_ATTENDEE_UPLOAD',
  GetPagedMeetingsByStaffId = 'GET_PAGED_MEETINGS_BY_STAFF_ID',
  ResetMeeting = 'RESET_MEETING',
  GetMeetingByTeacherLink = 'GET_MEETING_BY_TEACHER_LINK',
  SetMeetingTypeStatusCode = 'SET_MEETING_TYPE_STATUS_CODE',
  GetPotentialExitStudents = 'GET_POTENTIAL_EXIT_STUDENTS',
  PrintPotentialExitReclassificationForm = 'PRINT_POTENTIAL_EXIT_RECLASSIFICATION_FORM',
  PrintMergedParentLetters = 'PRINT_MERGED_PARENT_LETTERS',
  GetActiveRostersByType = 'GET_ACTIVE_ROSTERS_BY_TYPE',
  ClearPendingStudentsList = 'CLEAR_PENDING_STUDENTS_LIST',
  ProcessStudentPlacements = 'PROCESS_STUDENT_PLACEMENTS',
  IsGeneratingPotentialExitMergedLetters = 'IS_GENERATING_POTENTIAL_EXIT_MERGED_LETTERS',
  GetCombinedLetterDownloadCount = 'GET_COMBINED_LETTER_DOWNLOAD_COUNT',
  PrintCurrentStaarAccommodations = 'PRINT_CURRENT_STAAR_ACCOMMODATIONS',
  RemoveSingleStudent = 'REMOVE_SINGLE_STUDENT'
}

export const actions: ActionTree<MeetingState, RootState> = {

  async [MeetingActions.GetRosterReviewStudents]({ commit, state, rootState }, { meetingId, meetingType }: MeetingWithType) {
    const { paging } = state.rosterReviewStudents
    const { students, hasCLSOptions } = await studentService.getRosterReviewStudents(rootState, paging, meetingId, meetingType)

    commit(MeetingMutations.SetHasCLSOptions, hasCLSOptions )
    commit(MeetingMutations.SetReadyRoster, { students })
  },

  async [MeetingActions.GetAllRostersCompleted]({ rootState }, meetingId: number) {
    return await studentService.getAllRostersCompleted(rootState, meetingId)
  },

  async [MeetingActions.GetMeetingByMeetingId]( { commit, rootState }, request: MeetingRequest) {
    try {
      const meeting = await meetingService.getMeetingByMeetingId(rootState, request)
      const currentMeeting: CurrentMeeting = {
        districtId: rootState.selected.districtId,
        schoolId: rootState.selected.schoolId,
        meetingId: meeting.id,
      }
      window.localStorage.setItem('current-meeting-id', JSON.stringify(currentMeeting))
      commit(MeetingMutations.SetCurrentMeeting, meeting)
    } catch (error) {
      if (isEqual(ErrorCode.NotFoundError, error.code)) {
        window.localStorage.removeItem('current-meeting-id')
      }
    }

  },

  async [MeetingActions.GetDashboardMeetings]( { commit, rootState }) {
    const paging  = state.openMeetings.paging
    const response: DashboardMeetingsResponse = await meetingService.getDashboardMeetings(rootState, paging)
    commit(MeetingMutations.AddMeetings, response)
  },

  async [MeetingActions.GetCurrentMeeting]({ commit, rootState }) {
    const meeting = await meetingService.getCurrentMeeting(rootState)

    commit(MeetingMutations.SetCurrentMeeting, meeting)
  },

  async [MeetingActions.CreateMeeting]({ commit, rootState }, request: CreateMeetingRequest) {
    const meeting = await meetingService.createMeeting(rootState, request)
    if (meeting) {
      const currentMeeting: CurrentMeeting = {
        districtId: rootState.selected.districtId,
        schoolId: rootState.selected.schoolId,
        meetingId: meeting.id
      }
      window.localStorage.setItem('current-meeting-id', JSON.stringify(currentMeeting))
      commit(MeetingMutations.SetCurrentMeeting, meeting)
      commit(MeetingMutations.AddMeeting, meeting)
    }
  },

  async [MeetingActions.SaveMeeting]({ commit, rootState }, request: UpdateMeetingRequest) {
    const meeting = await meetingService.updateMeeting(rootState, request)
    const existingMeetingId = Number(window.localStorage.getItem('current-meeting-id'))
    if (meeting.id === existingMeetingId) {
      commit(MeetingMutations.SetCurrentMeeting, meeting)
    }
    const paging  = state.openMeetings.paging
    const response: DashboardMeetingsResponse = await meetingService.getDashboardMeetings(rootState, paging)
    commit(MeetingMutations.AddMeetings, response)
  },

  async [MeetingActions.CancelMeeting]({ commit, rootState, rootGetters }, meetingId: number) {
    const request: DeleteMeetingRequest = { meetingId }
    await meetingService.deleteMeetingByMeetingId(rootState, request)
    window.localStorage.removeItem('current-meeting-id')
    commit(MeetingMutations.RemoveMeeting, meetingId)
    const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
    if (!multiMeetingsActive) {
      commit(MeetingMutations.Clear)
    }
  },

  [MeetingActions.ClearCurrentMeeting]({ commit }) {
    commit(MeetingMutations.Clear)
  },

  async [MeetingActions.StartMeeting]({ commit, rootState }, request: MeetingRequest) {
    const meeting = await meetingService.startMeeting(rootState, request)

    commit(MeetingMutations.SetCurrentMeeting, meeting)
  },

  async [MeetingActions.GetMeetingMemberScreenShotLoc]({ commit, rootState }, request: MeetingMemberScreenshotRequest) {
    const location = await meetingService.getMeetingMemberScreenshotS3Loc(rootState, request)
    const locationExists = location !== undefined ? true : false

    commit(MeetingMutations.SetMeetingMemberScreenShotLoc, locationExists)
  },

  async [MeetingActions.AddStudents]({ commit, rootState }, meetingStudents: MeetingSelectedStudentsRequest) {
    commit(MeetingMutations.SetLoading, true)

    try {
      const meeting = await meetingService.addStudents(rootState, meetingStudents)

      commit(MeetingMutations.SetCurrentMeeting, meeting)
      commit(MeetingMutations.AddMeeting, meeting)
    } finally {
      commit(MeetingMutations.SetLoading, false)
    }
  },

  async [MeetingActions.RemoveStudents]({ commit, rootState }, meetingStudents: MeetingSelectedStudentsRequest) {
    commit(MeetingMutations.SetLoading, true)

    try {
      const meeting = await meetingService.removeStudents(rootState, meetingStudents)

      commit(MeetingMutations.SetCurrentMeeting, meeting)
    } finally {
      commit(MeetingMutations.SetLoading, false)
    }
  },
  async [MeetingActions.RemoveStudent]({ commit, rootState, rootGetters, state }, { studentId, meetingType }) {
    const request: RemoveStudentRequest = {
      meetingType: meetingType,
      studentId: studentId
    }
    const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
    const meetingId: number = multiMeetingsActive && state.currentMeeting ? state.currentMeeting.id : null
    request.meetingId = meetingId
    const meeting = await meetingService.removeStudent(rootState, request)

    commit(MeetingMutations.SetCurrentMeeting, meeting)
  },
  async [MeetingActions.RemoveSingleStudent]({ commit, rootState, rootGetters, state }, rosterId: number) {
    const request: RemoveStudentRequest = {
      rosterId: rosterId
    }
    const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
    const meetingId: number = multiMeetingsActive && state.currentMeeting ? state.currentMeeting.id : null
    request.meetingId = meetingId
    const meeting = await meetingService.removeStudent(rootState, request)

    commit(MeetingMutations.SetCurrentMeeting, meeting)
  },
  async [MeetingActions.AddMember]({ commit, rootState }, addMember: MeetingMemberAddRequest) {
    const meeting: Meeting = await meetingService.addMeetingMember(rootState, addMember)
    commit(MeetingMutations.SetCurrentMeeting, meeting)
  },

  async [MeetingActions.AddDefaultMember]({ commit, rootState }, addMember: MeetingMemberAddRequest) {
    const meeting: Meeting = await meetingService.addDefaultMember(rootState, addMember)
    commit(MeetingMutations.SetCurrentMeeting, meeting)
  },

  async [MeetingActions.RemoveMember]({ commit, rootState }, { member, meetingId, isDefault }) {
    const meeting = await meetingService.removeMember(rootState, member, meetingId, isDefault)
    if (meeting) {
      commit(MeetingMutations.SetCurrentMeeting, meeting)
    }
  },

  async [MeetingActions.UpdateMember]({ commit, rootState, rootGetters, state }, updateMember: MeetingMemberUpdateRequest) {
    commit(MeetingMutations.SetLoading, true)

    try {
      const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
      const meetingId: number = multiMeetingsActive && state.currentMeeting ? state.currentMeeting.id : null
      updateMember.meetingId = meetingId
      const meeting = await meetingService.updateMember(rootState, updateMember)
      commit(MeetingMutations.SetCurrentMeeting, meeting)
    } finally {
      commit(MeetingMutations.SetLoading, false)
    }
  },

  async [MeetingActions.SetHasSigned]({ commit, rootState }, request: SigningStatusRequest) {
    const rsp: SigningStatusResponse = await signService.saveSigningStatus(rootState, request)
    commit(MeetingMutations.SaveMemberSignedStatus, rsp)
  },

  async [MeetingActions.SetMemberHasSigned]({ commit, rootState }, request: MemberSigningStatusRequest) {
    const rsp: MemberSigningStatusResponse = request.meetingFormTypeCode === MeetingFormTypeCode.MeetingMinutesForm ? await signService.saveMeetingMinutesSigningStatus(rootState, request) : await signService.saveMemberSigningStatus(rootState, request)
    commit(MeetingMutations.SaveMemberBatchSignedStatus, rsp)
  },

  async [MeetingActions.InitiateSignatures]({ commit, rootState }, request: InitiateMeetingSigningRequest) {
    const rsp: InitiateMeetingSigningResponse = await signService.initiateMeetingSigning(rootState, request)
    commit(MeetingMutations.AddMeetingFormSignatures, rsp.meetingFormSignatures)
  },

  async [MeetingActions.InitiateMeetingMinutesSignature]({ commit, rootState }, request: InitiateMeetingMinuteSigningRequest) {
    const rsp: InitiateMeetingMinuteSigningResponse = await signService.InitiateMeetingMinutesSignature(rootState, request)
    commit(MeetingMutations.AddMeetingFormSignatures, rsp.meetingFormSignatures)
  },

  async [MeetingActions.InitiateStaarSignatures]({ commit, rootState }, request: InitiateStaarDecisionSigningRequest) {
    const rsp: InitiateStaarDecisionSigningResponse = await signService.initiateStaarMeetingSigning(rootState, request)
    commit(MeetingMutations.AddMeetingFormSignatures, rsp.meetingFormSignatures)
  },

  async [MeetingActions.GetActiveRostersByType]({ commit, rootState }, request: GetRosterRequest) {
    const rosters: StudentSchoolGrade[] = await meetingService.getActiveRostersByMeetingType(rootState, request)
    commit(MeetingMutations.AddActiveRosterByType, rosters)
  },

  async [MeetingActions.InitiateTelpasSignatures]({ commit, rootState }, request: InitiateTelpasDecisionSigningRequest) {
    const rsp: InitiateTelpasDecisionSigningResponse = await signService.initiateTelpasMeetingSigning(rootState, request)
    commit(MeetingMutations.AddMeetingFormSignatures, rsp.meetingFormSignatures)
  },
  async [MeetingActions.SaveMeetingMinutes]({ commit, rootState }, request: SaveMeetingMinutesRequest) {
    commit(MeetingMutations.SetLoading, true)
    try {
      await meetingService.saveMeetingMinutes(rootState, request)
      const meetingMinutesRequest = { meetingId: state.currentMeeting.id, meetingFormTypeCode: MeetingFormTypeCode.MeetingMinutesForm }
      const resp = await signService.getCurrentMtgSignatures(rootState, meetingMinutesRequest)
      if (resp.meetingFormSignatures) {
        commit(MeetingMutations.AddMeetingFormSignatures, resp.meetingFormSignatures)
      }
    } finally {
      commit(MeetingMutations.SetLoading, false)
    }
  },

  async[MeetingActions.ProcessStudentPlacements]({ rootState, state }) {
    if (state.currentMeeting.meetingType.code !== MeetingTypeCode.StaarDecisions && state.currentMeeting.meetingType.code !== MeetingTypeCode.TelpasDecisions) {
      const request: ProcessStudentPlacementRequest = {
        meetingId: state.currentMeeting.id
      }
      await studentService.processStudentPlacements(rootState, request)
    }
  },

  async [MeetingActions.RetrieveSignedFiles]({ rootState }, request: RetrieveAllSignedFilesRequest) {
    await signService.retrieveSignedFiles(rootState, request)
  },
  async [MeetingActions.RetrieveSignedTeacherStaarFiles]({ rootState }, request: RetrieveTeacherSignedFilesRequest) {
    await signService.retrieveSignedTeacherStaarFiles(rootState, request)
  },

  [MeetingActions.SetLastSignSuccess]({ commit }, success: boolean) {
    commit(MeetingMutations.SetLastSignSuccess, success)
  },

  async [MeetingActions.PrintTeacherForm]({ rootState }, request: PrintTeacherFormRequest) {
    await meetingService.printTeacherForm(rootState, request)
  },

  async [MeetingActions.GetCurrentSigId]({ commit, rootState }, request: GetCurrentSigIdRequest) {
    const resp = await signService.getCurrentSigId(rootState, request)
    if (resp.signatureRequestId) {
      commit(MeetingMutations.SetSignatureRequestId, resp.signatureRequestId)
    } else {
      commit(MeetingMutations.SetSignatureRequestId, null)
    }
    if (resp.meetingFormSignatures) {
      resp.meetingFormSignatures.forEach(mtgFormSig => {
        commit(MeetingMutations.AddMeetingFormSignature, mtgFormSig)
      })
    }

  },
  async [MeetingActions.GetCurrentMtgSignatures]({ commit, rootState }, request: GetCurrentMtgSignaturesRequest) {
    const resp = await signService.getCurrentMtgSignatures(rootState, request)
    if (resp.meetingFormSignatures) {
      commit(MeetingMutations.AddMeetingFormSignatures, resp.meetingFormSignatures)
    }

  },

  async [MeetingActions.PrintTeacherForm]({ rootState }, request: PrintTeacherFormRequest) {
    await meetingService.printTeacherForm(rootState, request)
  },

  async [MeetingActions.ConfirmCommittee]({ commit,rootState }, request: ConfirmCommitteeRequest) {
    await meetingService.confirmCommittee(rootState, request)
    commit(MeetingMutations.ConfirmCommittee)
  },

  async [MeetingActions.ResetMeetingSignaturesById]({ commit, rootState }, request: ResetMeetingSignaturesRequest) {
    const currentMeeting = await meetingService.resetMeetingSignaturesById(rootState, request)
    commit(MeetingMutations.SetCurrentMeeting, currentMeeting)
  },

  async [MeetingActions.ResetMeetingSignatures]({ commit, rootState }) {
    const currentMeeting = await meetingService.resetMeetingSignatures(rootState)
    commit(MeetingMutations.SetCurrentMeeting, currentMeeting)
  },

  async [MeetingActions.PrintReclassStaar]({ rootState }, request: PrintReclassificationStaarRequest) {
    await meetingService.printReclassificationStaarForm(rootState, request)
  },

  async [MeetingActions.PrintReclassTelpas]({ rootState }, request: PrintReclassificationTelpasRequest) {
    await meetingService.printReclassificationTelpasForm(rootState, request)
  },

  async [MeetingActions.DownloadMeetingAttendeeUpload]({ rootState }, request: MeetingMemberScreenshotRequest) {
    await meetingService.downloadMemberScreenshotUpload(rootState, request)
  },

  async [MeetingActions.GetMeetingMinutes]({ commit,rootState }, request: GetMeetingMinutesInfoRequest) {
    const resp = await meetingService.getMeetingMinutesInfo(rootState, request)
    commit(MeetingMutations.SetMeetingMinutes,resp)
  },
  async [MeetingActions.SkipMeetingAttendeeUpload]({ rootState }, meetingId: number) {
    await meetingService.skipMeetingAttendeeUpload(rootState, meetingId)
  },

  async [MeetingActions.ResetMeeting]({ commit, rootState, rootGetters, state }) {
    const multiMeetingsActive: boolean = rootGetters[RootGetters.MultiMeetingsActive]
    const meetingId: number = multiMeetingsActive && state.currentMeeting ? state.currentMeeting.id : null
    const request: MeetingRequest = { meetingId }
    const currentMeeting = await meetingService.resetMeeting(rootState, request)
    commit(MeetingMutations.SetCurrentMeeting, currentMeeting)
  },

  async [MeetingActions.GetPagedMeetingsByStaffId] ({ commit, state, rootState }) {
    commit(MeetingMutations.SetLoading, true)
    try {
      const { meetings, pagination } = await meetingService.getPagedMeetingsByStaffId(rootState, state.meetingsPaging, state.staffId)

      if (state.meetingsPaging.page !== pagination.currentPage) {
        commit(MeetingMutations.SetMeetingAccommodations, defaults({
          page: pagination.currentPage
        }, state.meetingsPaging))
      }

      commit(MeetingMutations.SetMeetingAccommodations, { meetings, pagination })
    } finally {
      commit(MeetingMutations.SetLoading, false)
    }
  },

  async [MeetingActions.GetMeetingByTeacherLink]({ commit, rootState }, teacherLink: string) {
    const meetingId = await meetingService.getMeetingByTeacherLink(rootState, teacherLink)

    commit(MeetingMutations.SetTeacherLinkMeetingId, meetingId)
  },

  async [MeetingActions.SetMeetingTypeStatusCode]({ commit, rootState }, { meetingId, meetingTypeCode, meetingTypeStatusCode }) {
    const { hasCLSOptions } = await meetingService.setMeetingTypeStatusCode(rootState, meetingId, meetingTypeCode, meetingTypeStatusCode)

    commit(MeetingMutations.SetHasCLSOptions, hasCLSOptions )
    commit(MeetingMutations.SetMeetingTypeStatusCode, { meetingTypeCode, meetingTypeStatusCode })
  },

  async [MeetingActions.GetPotentialExitStudents]({ commit, rootState }, studentPaging: StudentPaging) {
    const resp = await studentService.getPotentialExitStudents(rootState, studentPaging, LimitedEnglishProficiencyCode.M1Pending, MeetingTypeCode.AnnualReviewLep)
    commit(MeetingMutations.SetPotentialExitStudents,resp.dashboardRosterRows)
    commit(MeetingMutations.SetPotentialExitPagination,resp.pagination)
    commit(MeetingMutations.SetPotentialExitMeetingRosterIds, resp.meetingRosterIds)
  },

  async [MeetingActions.PrintPotentialExitReclassificationForm]({ rootState }, request: PrintReclassificationRequest) {
    await meetingService.printPotentialExitReclassifcationForm(rootState, request, rootState.selected.schoolId)
  },

  async [MeetingActions.IsGeneratingPotentialExitMergedLetters]({ commit, rootState }, schoolId: number) {
    const resp = await documentsService.getPotentialExitRegeneratingParentLetters(rootState, schoolId)
    commit(MeetingMutations.SetPotentialExitGeneratingMergedLetters, resp)
  },

  async [MeetingActions.PrintMergedParentLetters]({ rootState }) {
    return await documentsService.getMergedParentLetters(rootState)
  },

  async [MeetingActions.ClearPendingStudentsList]({rootState}, schoolId: number){
    return await meetingService.clearPendingStudentsList(rootState, schoolId)
  },

  async [MeetingActions.GetCombinedLetterDownloadCount]({ commit, rootState}, schoolId: number){
    const count = await documentsService.getCombinedExitLettersDownloadCount(rootState, schoolId)
    commit(MeetingMutations.SetCombinedLetterDownloadCount, count)
  },

  async [MeetingActions.PrintCurrentStaarAccommodations]({ rootState }, meetingId: number) {
    return await meetingService.printCurrentStaarAccommodations(rootState, meetingId)
  }
}
