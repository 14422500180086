import { RootMutations, RootState } from '@/store/root'
import { ErrorHolder } from '@/util/errors'
import { LoggerFactory } from '@806/utils'
import { Plugin } from 'vuex'

const logger = LoggerFactory.getLogger('store error logger')

export const errorLogger: Plugin<RootState> = store => {

  store.subscribe(mutation => {
    switch (mutation.type) {

      case RootMutations.AddError:
      case RootMutations.AddUnexpectedError:
        logger.error(ErrorHolder.unwrap(mutation.payload))
        break
    }
  })

}
