import { MutationTree } from 'vuex'
import { CompletedMeetingState, clearState } from './state'
import {
  Meeting,
  Pagination,
  StudentSchoolGrade,
  StudentPaging,
  MeetingRoster,
  StudentDocument,
  MeetingFormSignature
} from 'models'
import cloneDeep from 'lodash/cloneDeep'

export enum CompletedMeetingMutations {
  Clear = 'CLEAR',
  SetMeeting = 'SET_MEETING',
  SetMeetingFormSignatures = 'SET_MEETING_FORM_SIGNATURES',
  SetStudents = 'SET_STUDENTS',
  SetStudent = 'SET_STUDENT',
  SetRosterMeetingFormTypes = 'SET_ROSTER_MEETING_FORM_TYPES',
  SetPaging = 'SET_PAGING',
  SetMeetingMemberScreenshotUploaded = 'SET_MEETING_MEMBER_SCREENSHOT_UPLOADED',
  SetParentLettersUploaded = 'SET_PARENT_LETTERS_UPLOADED',
  SetRegeneratingParentLetters = 'SET_REGENERATING_PARENT_LETTERS',
  SetDocuments = 'SET_DOCUMENTS',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<CompletedMeetingState> = {

  [CompletedMeetingMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [CompletedMeetingMutations.SetMeeting] (state, meeting: Meeting) {
    state.meeting = meeting
  },

  [CompletedMeetingMutations.SetMeetingFormSignatures] (state, meetingFormSignatures: MeetingFormSignature[]) {
    state.meeting.meetingFormSignatures = meetingFormSignatures
  },

  [CompletedMeetingMutations.SetDocuments] (state, documents: StudentDocument[]) {
    state.documents = documents
  },

  [CompletedMeetingMutations.SetStudent] (state, student: MeetingRoster) {
    const index = state.students.findIndex(stu => stu.id === student.id)
    state.students[index] = student
  },

  [CompletedMeetingMutations.SetRosterMeetingFormTypes] (state, { roster, meetingFormTypes }) {
    const index = state.students.findIndex(stu => stu.id === roster.id)
    state.students[index].meetingFormTypes = meetingFormTypes
  },

  [CompletedMeetingMutations.SetStudents] (state, { students, pagination }: {
    students: StudentSchoolGrade[]
    pagination: Pagination
  }) {
    state.students = students
    state.pagination = pagination
  },

  [CompletedMeetingMutations.SetPaging](state, paging: StudentPaging) {
    state.paging = paging
  },

  [CompletedMeetingMutations.SetMeetingMemberScreenshotUploaded](state, locationExists: boolean) {
    state.meetingMemberScreenshotUploaded = locationExists
  },

  [CompletedMeetingMutations.SetParentLettersUploaded](state, locationExists: boolean) {
    state.parentLettersUploaded = locationExists
  },

  [CompletedMeetingMutations.SetRegeneratingParentLetters](state, generatingParentLetters: boolean) {
    state.generatingParentLetters = generatingParentLetters
  },

  // Only use this mutation for setting the state for test cases
  [CompletedMeetingMutations.SetTestState] (state, testData: CompletedMeetingState) {
    Object.assign(state,cloneDeep(testData))
  }

}
