import cloneDeep from 'lodash/cloneDeep'
import { ELSchool, PagedSelectedItems, Pagination, ReportType, SchoolsPaging, Staff, StaffPaging, StudentPaging, StudentSchoolGrade } from 'models'
import { MutationTree } from 'vuex'
import { clearState, ReportsState } from './state'
import { PagingUtil, PerPageSize } from '@806/utils'

export enum ReportsMutations {
  Clear = 'CLEAR',
  SetLoaded = 'SET_LOADED',
  SetReports = 'SET_REPORTS',
  SetSelectedReport = 'SET_SELECTED_REPORT',
  SetSchools = 'SET_SCHOOLS',
  SetReviewers = 'SET_REVIEWERS',
  SetTeachers = 'SET_TEACHERS',
  SetStudents = 'SET_STUDENTS',
  GetSortedSchools = 'GET_SORTED_SCHOOLS',
  GetSortedReviewers = 'GET_SORTED_REVIEWERS',
  GetSortedTeachers = 'GET_SORTED_TEACHERS',
  SetSelectedSchools = 'SET_SELECTED_SCHOOLS',
  SetSelectedPagedSchools = 'SET_SELECTED_PAGED_SCHOOLS',
  SetPagedSchools = 'SET_PAGED_SCHOOLS',
  RemovePagedSchool = 'REMOVE_PAGED_SCHOOL',
  InitializePagedSchools = 'INITIALIZE_PAGED_SCHOOLS',
  ClearSelectedPagedSchools = 'CLEAR_SELECTED_PAGED_SCHOOLS',
  SetSelectedTeachers = 'SET_SELECTED_TEACHERS',
  SetSelectedPagedTeachers = 'SET_SELECTED_PAGED_TEACHERS',
  SetPagedTeachers = 'SET_PAGED_TEACHERS',
  SetTeachersPaging = 'SET_TEACHERS_PAGING',
  RemovePagedTeacher = 'REMOVE_PAGED_TEACHER',
  ClearTeachersPaging = 'CLEAR_TEACHERS_PAGING',
  InitializePagedTeachers = 'INITIALIZE_PAGED_TEACHERS',
  ClearSelectedPagedTeachers = 'CLEAR_SELECTED_PAGED_TEACHERS',
  SavePagedSchoolItems = 'SAVE_PAGED_SCHOOL_ITEMS',
  SavePagedReviewerItems = 'SAVE_PAGED_REVIEWER_ITEMS',
  SavePagedTeacherItems = 'SAVE_PAGED_TEACHER_ITEMS',
  SetSelectedReviewers = 'SET_SELECTED_REVIEWERS',
  SetSelectedPagedReviewers = 'SET_SELECTED_PAGED_REVIEWERS',
  SetFileFormat = 'SET_FILE_FORMAT',
  SetSchoolsPaging = 'SET_SCHOOLS_PAGING',
  ClearAllOptions = 'CLEAR_ALL_OPTIONS',
  SetDropdownType = 'SET_DROPDOWN_TYPE',
  SetDropdownSchool = 'SET_DROPDOWN_SCHOOL',
  SetDropdownReviewer = 'SET_DROPDOWN_REVIEWER',
  SetDropdownTeacher = 'SET_DROPDOWN_TEACHER',
  SetPagedReviewers = 'SET_PAGED_REVIEWERS',
  SetReviewersPaging = 'SET_REVIEWERS_PAGING',
  RemovePagedReviewer = 'REMOVE_PAGED_REVIEWER',
  InitializePagedReviewers = 'INITIALIZE_PAGED_REVIEWERS',
  ClearReviewersPaging = 'CLEAR_REVIEWERS_PAGING',
  ClearSelectedPagedReviewers = 'CLEAR_SELECTED_PAGED_REVIEWERS',
  SetPagedStudents = 'SET_PAGED_STUDENTS',
  SetSelectedStudents = 'SET_SELECTED_STUDENTS',
  SetSelectedPagedStudents = 'SET_SELECTED_PAGED_STUDENTS',
  SetStudentsPaging = 'SET_STUDENTS_PAGING',
  SavePagedStudentItems = 'SAVE_PAGED_STUDENT_ITEMS',
  RemovePagedStudent = 'REMOVE_PAGED_STUDENT',
  InitializePagedStudents = 'INITIALIZE_PAGED_STUDENTS',
  ClearSelectedPagedStudents = 'CLEAR_SELECTED_PAGED_STUDENTS',
  ClearStudentsPaging = 'CLEAR_STUDENTS_PAGING',
  ClearSchoolsPaging = 'CLEAR_SCHOOLS_PAGING',
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<ReportsState> = {

  [ReportsMutations.Clear](state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [ReportsMutations.SetLoaded](state, loaded: boolean) {
    state.loaded = loaded
  },

  [ReportsMutations.SetReports](state, reports: ReportType[]) {
    state.reports = reports
  },

  [ReportsMutations.SetSelectedReport](state, selectedReport: ReportType) {
    state.selectedReport = selectedReport
  },

  [ReportsMutations.SetSchools](state, schools: ELSchool[]) {
    const sortedSchools = schools.sort((a, b) => a.name.toLowerCase().localeCompare(b.name))
    state.schools = sortedSchools
  },

  [ReportsMutations.GetSortedSchools](state) {
    const sorted = state.schools
    return sorted.sort((a, b) => a.schoolTypeCode.toLowerCase().localeCompare(b.schoolTypeCode.toLowerCase()))
  },

  [ReportsMutations.SetSelectedSchools](state, selectedSchools: number[]) {
    state.selectedSchools = selectedSchools
  },

  [ReportsMutations.SetSchoolsPaging](state, paging: SchoolsPaging) {
    state.schoolsPaging = paging
  },

  [ReportsMutations.SetSelectedPagedSchools](state, selected: PagedSelectedItems) {
    state.schoolsEdited = true
    state.selectedPagedSchools = selected
  },

  [ReportsMutations.SetPagedSchools](state, { schools, allSchoolIds, pagination }: {
    schools: ELSchool[]
    allSchoolIds: number[]
    pagination: Pagination,
  }) {
    state.pagedSchools = schools
    state.allSchoolIds = allSchoolIds
    state.schoolsPagination = pagination
  },

  [ReportsMutations.RemovePagedSchool](state, school: ELSchool) {
    state.schoolsEdited = true
    const itemIds = state.selectedPagedSchools?.itemIds.filter(id => school.id !== id)
    state.selectedPagedSchools.itemIds = itemIds ? itemIds : []
    state.selectedPagedSchools.total--
  },

  [ReportsMutations.InitializePagedSchools](state) {
    if (state.selectedSchools.length > 0) {
      state.selectedPagedSchools = {
        all: false,
        itemIds: state.selectedSchools,
        total: state.selectedSchools.length,
      } as PagedSelectedItems
    }
  },

  [ReportsMutations.ClearSelectedPagedSchools](state) {
    const { selectedPagedSchools } = cloneDeep(clearState)

    Object.assign(state, { selectedPagedSchools })
  },

  [ReportsMutations.SavePagedSchoolItems](state) {
    state.selectedSchools = state.selectedPagedSchools.itemIds
  },

  [ReportsMutations.ClearSchoolsPaging](state) {
    const { schoolsPaging } = cloneDeep(clearState)
    Object.assign(state, { schoolsPaging })
  },

  [ReportsMutations.SetReviewers](state, reviewers: Staff[]) {
    state.reviewers = reviewers
  },

  [ReportsMutations.SetSelectedReviewers](state, selectedReviewers: number[]) {
    state.selectedReviewers = selectedReviewers
  },

  [ReportsMutations.SetReviewersPaging](state, paging: StaffPaging) {
    let newPaging = paging
    if (paging?.perPage != PagingUtil.initialPaging(PerPageSize.Small).perPage) {
      newPaging = PagingUtil.initialPaging(PerPageSize.Small)
    }
    state.reviewersPaging = newPaging
  },

  [ReportsMutations.SetSelectedPagedReviewers](state, selected: PagedSelectedItems) {
    state.selectedPagedReviewers = selected
  },

  [ReportsMutations.SetPagedReviewers](state, { staff, allStaffIds, pagination }: {
    staff: Staff[]
    allStaffIds: number[]
    pagination: Pagination,
  }) {
    state.pagedReviewers = staff
    state.allReviewerIds = allStaffIds
    state.reviewersPagination = pagination
  },

  [ReportsMutations.RemovePagedReviewer](state, reviewer: Staff) {
    const itemIds = state.selectedPagedReviewers?.itemIds.filter(id => reviewer.id !== id)
    state.selectedPagedReviewers.itemIds = itemIds ? itemIds : []
    state.selectedPagedReviewers.total--
  },

  [ReportsMutations.InitializePagedReviewers](state) {
    if (state.selectedReviewers.length > 0) {
      state.selectedPagedReviewers = {
        all: false,
        itemIds: state.selectedReviewers,
        total: state.selectedReviewers.length,
      } as PagedSelectedItems
    }
  },

  [ReportsMutations.ClearSelectedPagedReviewers](state) {
    const { selectedPagedReviewers, schoolsEdited } = cloneDeep(clearState)

    Object.assign(state, { selectedPagedReviewers, schoolsEdited })
  },

  [ReportsMutations.SavePagedReviewerItems](state) {
    state.selectedReviewers = state.selectedPagedReviewers.itemIds
  },

  [ReportsMutations.ClearReviewersPaging](state) {
    const { reviewersPaging } = cloneDeep(clearState)
    Object.assign(state, { reviewersPaging })
  },

  [ReportsMutations.SetTeachers](state, teachers: Staff[]) {
    state.teachers = teachers
  },

  [ReportsMutations.SetSelectedTeachers](state, selectedTeachers: number[]) {
    state.selectedTeachers = selectedTeachers
  },

  [ReportsMutations.SetTeachersPaging](state, paging: StaffPaging) {
    let newPaging = paging
    if (paging?.perPage != PagingUtil.initialPaging(PerPageSize.Small).perPage) {
      newPaging = PagingUtil.initialPaging(PerPageSize.Small)
    }
    state.teachersPaging = newPaging
  },

  [ReportsMutations.SetSelectedPagedTeachers](state, selected: PagedSelectedItems) {
    state.teachersEdited = true
    state.selectedPagedTeachers = selected
  },

  [ReportsMutations.SetPagedTeachers](state, { staff, allStaffIds, pagination }: {
    staff: Staff[]
    allStaffIds: number[]
    pagination: Pagination,
  }) {
    state.pagedTeachers = staff
    state.allTeacherIds = allStaffIds
    state.teachersPagination = pagination
  },

  [ReportsMutations.RemovePagedTeacher](state, teacher: Staff) {
    state.teachersEdited = true
    const itemIds = state.selectedPagedTeachers?.itemIds.filter(id => teacher.id !== id)
    state.selectedPagedTeachers.itemIds = itemIds ? itemIds : []
    state.selectedPagedTeachers.total--
  },

  [ReportsMutations.InitializePagedTeachers](state) {
    if (state.selectedTeachers.length > 0) {
      state.selectedPagedTeachers = {
        all: false,
        itemIds: state.selectedTeachers,
        total: state.selectedTeachers.length,
      } as PagedSelectedItems
    }
  },

  [ReportsMutations.ClearSelectedPagedTeachers](state) {
    const { selectedPagedTeachers, schoolsEdited } = cloneDeep(clearState)

    Object.assign(state, { selectedPagedTeachers, schoolsEdited })
  },

  [ReportsMutations.SavePagedTeacherItems](state) {
    state.selectedTeachers = state.selectedPagedTeachers.itemIds
  },

  [ReportsMutations.ClearTeachersPaging](state) {
    const { teachersPaging } = cloneDeep(clearState)
    Object.assign(state, { teachersPaging })
  },

  [ReportsMutations.SetStudents](state, students: StudentSchoolGrade[]) {
    state.students = students
  },

  [ReportsMutations.SetSelectedStudents](state, selectedStudents: number[]) {
    state.selectedStudents = selectedStudents
  },

  [ReportsMutations.SetStudentsPaging](state, paging: StudentPaging) {
    let newPaging = paging
    if (paging?.perPage != PagingUtil.initialPaging(PerPageSize.Small).perPage) {
      newPaging = PagingUtil.initialPaging(PerPageSize.Small)
    }
    state.studentsPaging = newPaging
  },

  [ReportsMutations.SetSelectedPagedStudents](state, selected: PagedSelectedItems) {
    state.selectedPagedStudents = selected
  },

  [ReportsMutations.SetPagedStudents](state, { students, allStudentIds, pagination }: {
    students: StudentSchoolGrade[]
    allStudentIds: number[]
    pagination: Pagination,
  }) {
    state.pagedStudents = students
    state.allStudentIds = allStudentIds
    state.studentsPagination = pagination
  },

  [ReportsMutations.RemovePagedStudent](state, teacher: Staff) {
    const itemIds = state.selectedPagedStudents?.itemIds.filter(id => teacher.id !== id)
    state.selectedPagedStudents.itemIds = itemIds ? itemIds : []
    state.selectedPagedStudents.total--
  },

  [ReportsMutations.InitializePagedStudents](state) {
    if (state.selectedStudents.length > 0) {
      state.selectedPagedStudents = {
        all: false,
        itemIds: state.selectedStudents,
        total: state.selectedStudents.length,
      } as PagedSelectedItems
    }
  },

  [ReportsMutations.ClearSelectedPagedStudents](state) {
    const { selectedPagedStudents, teachersEdited } = cloneDeep(clearState)

    Object.assign(state, { selectedPagedStudents, teachersEdited })
  },

  [ReportsMutations.SavePagedStudentItems](state) {
    state.selectedStudents = state.selectedPagedStudents.itemIds
  },

  [ReportsMutations.ClearStudentsPaging](state) {
    const { studentsPaging } = cloneDeep(clearState)
    Object.assign(state, { studentsPaging })
  },

  [ReportsMutations.SetFileFormat](state, fileFormat: string) {
    state.fileFormat = fileFormat
  },

  [ReportsMutations.ClearAllOptions](state) {
    const { selectedPagedSchools,
      selectedSchools,
      selectedPagedReviewers,
      selectedReviewers,
      selectedPagedTeachers,
      selectedTeachers,
      selectedPagedStudents,
      selectedStudents,
      fileFormat,
      schoolsPaging,
      schoolsPagination,
      reviewersPaging,
      reviewersPagination,
      teachersPaging,
      teachersPagination,
      studentsPaging,
      studentsPagination,
      dropdownSchool,
      dropdownReviewer,
      dropdownTeacher,
      dropdownType } = cloneDeep(clearState)

    Object.assign(state, {
      selectedPagedSchools,
      selectedSchools,
      selectedPagedReviewers,
      selectedReviewers,
      selectedPagedTeachers,
      selectedTeachers,
      selectedPagedStudents,
      selectedStudents,
      fileFormat,
      schoolsPaging,
      schoolsPagination,
      reviewersPaging,
      reviewersPagination,
      teachersPaging,
      teachersPagination,
      studentsPaging,
      studentsPagination,
      dropdownSchool,
      dropdownReviewer,
      dropdownTeacher,
      dropdownType })
  },

  [ReportsMutations.SetDropdownSchool](state, selected: string) {
    state.dropdownSchool = selected
  },

  [ReportsMutations.SetDropdownTeacher](state, selected: string) {
    state.dropdownTeacher = selected
  },

  [ReportsMutations.SetDropdownReviewer](state, selected: string) {
    state.dropdownReviewer = selected
  },

  [ReportsMutations.SetDropdownType](state, selected: number) {
    state.dropdownType = selected
  },
  // Only use this mutation for setting the state for test cases
  [ReportsMutations.SetTestState] (state, testData: ReportsState) {
    Object.assign(state, cloneDeep(testData))
  }
}
