import cloneDeep from 'lodash/cloneDeep'
import { Pagination, StudentDocument, DocumentsPaging, StudentDocumentType } from 'models'
import { PagingUtil, PerPageSize } from '@806/utils'
import { ZipFileStatus } from '@/enums'

export interface StudentDocumentsState {
  loaded: boolean
  documents: StudentDocument[]
  pagination: Pagination
  paging: DocumentsPaging
  studentId: number
  stuSchGrdId: number
  meetingRosterId: number
  documentTypes: StudentDocumentType[]
  zipDocument: StudentDocument,
  zipFileStatus: ZipFileStatus,
  hasDocs: boolean,
  zipFileExist: boolean
}

export const state: StudentDocumentsState = {
  loaded: false,
  documents: [],
  pagination: {},
  paging: PagingUtil.initialPaging(PerPageSize.Small),
  studentId: null,
  stuSchGrdId: null,
  meetingRosterId: null,
  documentTypes: [],
  zipDocument: null,
  zipFileStatus: ZipFileStatus.None,
  hasDocs: false,
  zipFileExist: false
}

export const clearState: StudentDocumentsState = cloneDeep(state)
