export enum TestElement {
  HomeInfo,
  HomeLabel,
  Login,
  LoginUsername,
  LoginPassword,
  Logout,
  ForgotPasswordLink,
  ReturnToLoginLink,
  RequestReset,
  ResetPasswordField,
  RepeatPasswordField,
  ResetPassword,
  CollapseMenu,
  CancelMeeting,
  ViewLpacMeeting,
  StartSignatures,
  ReviewStudents,
  CreateLpacMeeting,
  Navigation,
  AddStudentsButton,
  SelectMeetingTypeDropDown,
  ShowAllStudentsToggle,
  ShowAllStudentsViewToggle,
  ViewMeeting,
  ViewStudentMeeting,
  ViewStudentDocument,
  HideStudentsDecisions,
  ShowMeetingAttendeeUpload,
  DownloadMeetingScreenshot,
  StaarDataYearToggle,
  TelpasDataYearToggle,
  StaarResultRow,
  ViewStaarStudentDocument,
  StudentGradePanel,
  StudentGradesPresentPanel,
  StudentGradesNotPresentPanel,
  StudentGradeIconExpand,
  StudentGradeIconCollapse,
  StudentGradesNotPresentMessage,
  CreateMeetingPrepForMeetingButton,
  ExpandOverviewStudentTableIcon,
  CollapseOverviewStudentTableIcon,
  StudentProgressButton,
  RemoveStudentReviewButton,
  AddStudentsModalButton,
  PopupAddStudentsModalButton,
  CancelAddStudentsModalButton,
  MeetingStatusBtn,
  CommitteeMembersBtn,
  CommitteeMembersBtnConfirmed,
  StartMeetingBtnDisabled,
  StartMeetingModal,
  StartMeetingModalStartBtn,
  StartMeetingModalCancelBtn,
  ResetMeetingSignaturesModal,
  ResetMeetingSignaturesModalStartBtn,
  ResetMeetingSignaturesModalCancelBtn,
  CompleteMeetingBtnSmall,
  PrepareMeetingBtnSmall,
  CreateMeetingBtnSmall,
  ViewAllMeetings,
  StudentDocumentBtn,
  TeacherDocumentCompMeetBtn,
  DownloadAllBtn,
  AddMoreStudentsMeeting,
  PrepareMeeting,
  EditAllBtnSmall,
  EditSingleBtnSmall,
  RemoveSingleBtnSmall,
  SaveAllBtnLg,
  AddMoreStudentsLg,
  ChooseAdditionalCommitteeMember,
  ConfirmMembers,
  EditMember,
  RemoveMember,
  MeetingMinutes,
  ViewStudent,
  BackToStudents,
  ParticipationDecisions,
  ParentDecision,
  ReclassificationForm,
  ViewCompletedMeeting,
  ReturnToMeeting,
  SaveParentDecision,
  ParentDecisionDropzone,
  Start,
  EditAllAccommodations,
  SaveAllAccommodations,
  ReturnStaarJustifications,
  NextAccommodation,
  EditAccommodation,
  SaveAccommodation,
  ViewEditNotes,
  SignAccommodation,
  AddEditNotesModal,
  SaveAccommodationNotes,
  CancelAccommodationNotes,
  DownloadAccommodation,
  SaveMeetingMinutes,
  StartSignaturesModal,
  StartSignaturesModalBtn,
  CancelSignaturesModalBtn,
  MeetingUploadDropzone,
  StartSignaturesPopupBtnDisabled,
  StartSignaturesPopup,
  CancelMeetingUpload,
  SignNow,
  SigningComplete,
  SaveSingleBtnSmall,
  SaveTelpasNote,
  SaveStaarNote,
  ParentDecisionForm,
  ViewParentDecisionForm,
  RemoveParentDecisionForm,
  ConfirmParentDecisionFormRemoval,
  ReviewStudent,
  SaveTests,
  SaveTestsAndContinue,
  ReturnToOverview,
  CompleteStudent,
  CancelMeetingModal,
  SetupFirstNameField,
  SetupLastNameField,
  SetupEmailField,
  SetupUsernameField,
  SetupPasswordField,
  SetupJobTitleField,
  SetupPhoNumField,
  SetupPhoExtField,
  SetupCellPhoneField,
  SendUserEmailToggle,
  SetupPrimaryCampusDropdown,
  SetupRequireMessage,
  SetupModalHeading,
  SetupModalBtn,
  SetupSaveBtn,
  SetupCancelBtn,
  OptionsMenu,
  IdentificationMeetingBtn,
  StaarMeetingBtn,
  TelpasMeetingBtn,
  AnnualReviewLepMeetingBtn,
  AnnualReviewMonitorMeetingBtn,
  CreateMeetingIcon,
  CreateMeetingIconText,
  PrepareMeetingIcon,
  PrepareMeetingIconText,
  StartMeetingIcon,
  StartMeetingIconText,
  CompleteMeetingIcon,
  CompleteMeetingIconText,
  ProgressBarPrepareMeeting,
  ProgressBarStartMeeting,
  ProgressBarCompleteMeeting,
  ManageAccountHeader,
  UserSetupLink,
  UserSetupTitle,
  AddNewUserPanelHeader,
  SetupReqFieldHelpText,
  SetupModalHeaderText,
  SetupModalText,
  CellPhoneInfoIcon,
  HomeTitle,
  DashboardMainText,
  DashboardInstructionText,
  EoyLasLinksButton,
  EoyLasLinksButtonText,
  EoyLasLinksBackButton,
  EoyLasLinksBackButtonText,
  EoyLasLinksHeader,
  SendUserNotificationEmailToggle,
  TelpasAssessmentTableHeader,
  TelpasParticipationTableHeader,
  TelpasJustification,
  TelpasAssessmentName,
  ReviewerHeader,
  StaarSubjectReviewerLabel,
  StaarSubject,
  ReviewerLabel,
  ReviewerStaff,
  MeetingTypeVerificationModal,
  MeetingTypeVerificationModalHeading,
  MeetingTypeVerificationModalVirtuallyBtn,
  MeetingTypeVerificationModalInPersonBtn,
  ReportsPageHeader,
  SelectReportPanelHeaderText,
  ReportLink,
  ReportIcon,
  ReportNameText,
  ReportSchoolsDropdownLabelText,
  ReportTeacherDropdownLabelText,
  ReportReviewerDropdownLabelText,
  ReportFileFormatDropdownLabelText
}
