import {studentService, contentLanguageSupportService} from '@/services'
import { RootState } from '@/store/root'
import { ContentLanguageSupportState } from './state'
import { ContentLanguageSupportMutations } from './mutations'
import { ActionTree } from 'vuex'
import { RosterStudentOptionsRequest, RosterOptionEdit, ContentLanguageControlOptions, SupportOptionsRequest } from 'models'
import { flatMap } from 'lodash'

export enum ContentLanguageSupportActions {
  SaveAllCLSOptions = 'SAVE_ALL_CLS_OPTIONS',
  GetSupportOptions = 'GET_SUPPORT_OPTIONS',
  SaveStudentSupportOptions = 'SAVE_STUDENT_SUPPORT_OPTIONS',
}

export const actions: ActionTree<ContentLanguageSupportState, RootState> = {

  async [ContentLanguageSupportActions.GetSupportOptions]({ commit, rootState }, request: SupportOptionsRequest) {
    if (request.studentId) {
      const inMeeting = await contentLanguageSupportService.getStudentInOpenMeeting(rootState, request.studentId)
      commit(ContentLanguageSupportMutations.SetMeetingStatus, inMeeting)
    }

    const categories = request.studentId
      ? await contentLanguageSupportService.getSupportOptions(rootState, request)
      : await contentLanguageSupportService.getRosterSupportOptions(rootState, request)

    // Change the order of CLS Options to read vertically instead of horizontally
    const sortedCategories = []

    categories.options.forEach(category => {
      const result = []
      let mod = category.options.length % request.columns
      let mod2 = mod === 0 ? (category.options.length / request.columns) : Math.ceil((category.options.length) / request.columns)
      let smallCom = false

      category.options.forEach(function(element, index) {

        const group = smallCom ? ((index - (category.options.length % request.columns)) % mod2) : (index) % mod2
        let temp = result[group]

        if (!Array.isArray(temp)) {
          temp = []
        }
        temp.push(element)
        result[group] = temp
        if (mod > 0 && (group + 1) === mod2) {
          mod--
          if (mod === 0) {
            mod2--
            smallCom = true
          }
        }
      })
      sortedCategories.push({ supportId: category.supportId, order: category.order, text: category.text, options: flatMap(result) } as ContentLanguageControlOptions)
    })
    commit(ContentLanguageSupportMutations.SetSupportOptions, sortedCategories)
    commit(ContentLanguageSupportMutations.SetInitialOptions, { rosterId: request.rosterId, studentId: request.studentId, options: categories.carryOverOptions })
  },

  async [ContentLanguageSupportActions.SaveStudentSupportOptions]({ commit, rootState }, edits: RosterOptionEdit) {
    const request: RosterStudentOptionsRequest = { option: { meetingRosterId: edits.meetingRosterId, studentId: edits.studentId, studentOptions: edits.studentOptions } }
    const options = await contentLanguageSupportService.saveStudentSupportOption(rootState, request)
    if (edits.meetingRosterId) {
      commit(ContentLanguageSupportMutations.RemoveRosterEditOptions, edits.meetingRosterId)
    }

    return options
  },

  async [ContentLanguageSupportActions.SaveAllCLSOptions]({ commit, state, rootState }) {
    const options: RosterOptionEdit[] = state.supportOptionEdits
    await studentService.saveAllCLSOptions(rootState, options)

    commit(ContentLanguageSupportMutations.ClearEditOptions)
  }
}
