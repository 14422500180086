import { MutationTree } from 'vuex'
import { AuthState, clearState } from './state'
import { cloneDeep } from 'lodash'
import { ELUser, MeetingStaarTeacher, TeacherLinkResponse } from 'models'
import { AccommodationsSigningStatus } from '@/enums'

export enum AuthMutations {
  Clear = 'CLEAR',
  SetAuthorized = 'SET_AUTHORIZED',
  SetAuthUser = 'SET_AUTH_USER',
  SetPasswordReset = 'SET_PASSWORD_RESET',
  SetRetrievedUsername = 'SET_RETRIEVED_USERNAME',
  SetTeacher = 'SET_TEACHER',
  SetValid = 'SET_VALID',
  SetNewTeacherComplete = 'SET_NEW_TEACHER_COMPLETE',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<AuthState> = {

  [AuthMutations.Clear] (state) {
    Object.assign(state,cloneDeep(clearState))
  },

  [AuthMutations.SetAuthorized] (state, authorized: boolean) {
    state.authorized = authorized
  },

  [AuthMutations.SetPasswordReset] (state, passwordReset: boolean) {
    state.passwordReset = passwordReset
  },

  [AuthMutations.SetRetrievedUsername] (state, username: string) {
    state.retrievedUsername = username
    state.user.username = username
  },

  [AuthMutations.SetAuthUser] (state, user: ELUser) {
    state.user = user
    state.authorized = true
  },

  [AuthMutations.SetTeacher] (state, teacher: MeetingStaarTeacher) {
    state.teacher = teacher
  },

  [AuthMutations.SetValid] (state, response: TeacherLinkResponse) {
    if (!response.success && response.teacher && response.teacher.accommodationsStatus !== AccommodationsSigningStatus.Requested) {
      state.teacher = response.teacher
    }
    state.validLink = response.success
  },

  [AuthMutations.SetNewTeacherComplete] (state, success: boolean) {
    state.newTeacherComplete = success
  },

  // Only use this mutation for setting the state for test cases
  [AuthMutations.SetTestState] (state, testData: AuthState) {
    Object.assign(state,cloneDeep(testData))
  }

}
