import { MutationTree } from 'vuex'
import { ContactUsState } from './state'

export enum ContactUsMutations {
  EditText = 'EDIT_TEXT',
}

export const mutations: MutationTree<ContactUsState> = {

  [ContactUsMutations.EditText](state, edits) {
    if(edits != undefined) {
      state.edits = edits
    }
  },
}
