import { Optional } from 'typescript-optional'
import { ErrorCode } from './ErrorCode'
import { errorMessages } from './messages'
import { FormError } from 'models'

export class ErrorHolder {

  private error: Error
  private code: ErrorCode

  constructor (error: Error | ErrorHolder, code: ErrorCode) {
    this.error = ErrorHolder.unwrap(error)
    this.code = code
  }

  static isCode = (error: Error | ErrorHolder, ...codes: ErrorCode[]): boolean => {
    return error instanceof ErrorHolder && error.isCode(...codes)
  }

  static getCode = (error: Error | ErrorHolder): Optional<ErrorCode> => {
    return error instanceof ErrorHolder ?
      Optional.ofNonNull(error.code) :
      Optional.empty()
  }

  static unwrap = (error: Error | ErrorHolder): Error => {
    return error instanceof ErrorHolder ? error.unwrapped : error
  }

  static emptyFields = (errors: Record<string, string>): FormError[] => {
    const formErrors: FormError[] = []
    for (const [key, value] of Object.entries(errors)) {
      formErrors.push({ ref: key, message: value })
    }
    return formErrors
  }

  get message(): string {
    return errorMessages[this.code]
  }

  get unwrapped(): Error {
    return this.error
  }

  isCode(...codes: ErrorCode[]): boolean {
    return Array.isArray(codes) && codes.includes(this.code)
  }

}
