import { PluginFunction } from 'vue'
import { Store } from 'vuex'
import { RootState, RootGetters, Terms } from '@/store/root'
import format from 'string-template'

export interface TermFilterOptions {
  store: Store<RootState>
}

export const termFilter: PluginFunction<TermFilterOptions> = (Vue, options) => {
  Vue.filter('terms', (value: string) => {
    const terms: Terms = options.store.getters[RootGetters.Terms]

    return format(value, terms)
  })
}
