import cloneDeep from 'lodash/cloneDeep'
import {
  ELSchool,
  ELUserRole,
  ELUser,
  Pagination,
  ELUsersPaging,
  ELUserEdit,
  ELUserCampus,
  MeetingMemberAssignment
} from 'models'
import { PagingUtil } from '@806/utils'
import { SchoolType } from './types'

export interface UserSetupState {
  districtSchools: ELSchool[]
  roles: ELUserRole[]
  advancedCampuses: ELUserCampus[]
  schoolTypes: SchoolType[]
  pagination: Pagination
  paging: ELUsersPaging
  users: ELUser[]
  editUser: ELUserEdit
  roleOptions: Array<{name: string, value: number}>,
  assignedCommittees: MeetingMemberAssignment[]
}

export const state: UserSetupState = {
  districtSchools: [],
  roles: [],
  advancedCampuses: [],
  schoolTypes: [],
  pagination: {},
  paging: PagingUtil.initialPaging(),
  users: [],
  editUser: null,
  roleOptions: [],
  assignedCommittees: []
}

export const clearState: UserSetupState = cloneDeep(state)
