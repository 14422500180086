import BaseService, {ServiceConfig} from './base'
import { RootState } from '@/store/root'
import {
  InitiateMeetingSigningResponse,
  SignNowRequest,
  SignNowResponse,
  InitiateMeetingSigningRequest,
  HelloSignConfigResponse,
  RetrieveAllSignedFilesResponse, RetrieveAllSignedFilesRequest, SigningStatusRequest, SigningStatusResponse, RetrieveTeacherSignedFilesRequest, InitiateStaarDecisionSigningRequest, InitiateStaarDecisionSigningResponse,
  GetCurrentSigIdRequest,
  GetCurrengSigIdResponse,
  InitiateTelpasDecisionSigningRequest,
  InitiateTelpasDecisionSigningResponse,
  GetCurrengMtgSignaturesResponse,
  GetCurrentMtgSignaturesRequest,
  GetMemeberSigningUrlRequest,
  GetMemberSigningUrlResponse,
  MemberSigningStatusRequest,
  MemberSigningStatusResponse,
  InitiateMeetingMinuteSigningRequest,
  InitiateMeetingMinuteSigningResponse
} from 'models'

export default class SignService extends BaseService {
  private readonly baseUri: string = '/sign'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async InitiateMeetingMinutesSignature(state: RootState, request: InitiateMeetingMinuteSigningRequest): Promise<InitiateMeetingMinuteSigningResponse> {
    const { data } = await this.post<InitiateMeetingSigningResponse>(`${this.baseUri}/initiateMeetingMinutesSignatures`, { state, request })
    return data
  }

  async initiateMeetingSigning(state: RootState, request: InitiateMeetingSigningRequest): Promise<InitiateMeetingSigningResponse> {
    const { data } = await this.post<InitiateMeetingSigningResponse>(`${this.baseUri}/initiateMeetingSigning`, { state, request })
    return data
  }

  async initiateTeacherStaarDecisionSigning(state: RootState, request: InitiateStaarDecisionSigningRequest): Promise<InitiateStaarDecisionSigningResponse> {
    const { data } = await this.post<InitiateStaarDecisionSigningResponse>(`${this.baseUri}/initiateTeacherStaarDecisionSigning`, { state, request })
    return data
  }

  async initiateStaarMeetingSigning(state: RootState, request: InitiateStaarDecisionSigningRequest): Promise<InitiateStaarDecisionSigningResponse> {
    const { data } = await this.post<InitiateStaarDecisionSigningResponse>(`${this.baseUri}/initiateStaarDecisionSigning`, { state, request })
    return data
  }

  async initiateTelpasMeetingSigning(state: RootState, request: InitiateTelpasDecisionSigningRequest): Promise<InitiateTelpasDecisionSigningResponse> {
    const { data } = await this.post<InitiateTelpasDecisionSigningResponse>(`${this.baseUri}/initiateTelpasDecisionSigning`, { state, request })
    return data
  }

  async getHelloSignConfig(state: RootState): Promise<HelloSignConfigResponse> {
    const { data } = await this.post<HelloSignConfigResponse>(`${this.baseUri}/getHelloSignConfig`, { state })
    return data
  }

  async retrieveSignedFiles(state: RootState, request: RetrieveAllSignedFilesRequest): Promise<RetrieveAllSignedFilesResponse> {
    const { data } = await this.post<RetrieveAllSignedFilesResponse>(`${this.baseUri}/retrieveAllSignedFiles`, { state, request })
    return data
  }

  async retrieveSignedTeacherStaarFiles(state: RootState, request: RetrieveTeacherSignedFilesRequest): Promise<RetrieveAllSignedFilesResponse> {
    const { data } = await this.post<RetrieveAllSignedFilesResponse>(`${this.baseUri}/retrieveSignedTeacherStaarFiles`, { state, request })
    return data
  }

  async signNow(state: RootState, request: SignNowRequest): Promise<string> {
    const { data } = await this.post<SignNowResponse>(`${this.baseUri}/signNow`, { state, request })
    return data.signUrl
  }

  async saveSigningStatus(state: RootState, request: SigningStatusRequest): Promise<SigningStatusResponse> {
    const { data } = await this.post<SigningStatusResponse>(`${this.baseUri}/saveSigningStatus`, { state, request })
    return data
  }

  async getTeacherCompletionStatus(
    state: RootState,
    meetingId: number
  ): Promise<boolean> {
    return this
      .get<boolean>(`${this.baseUri}/getTeacherCompletionStatus/` + meetingId, state)
      .then(resp => {
        return resp.data
      })
  }

  async saveMemberSigningStatus(state: RootState, request: MemberSigningStatusRequest): Promise<MemberSigningStatusResponse> {
    const { data } = await this.post<MemberSigningStatusResponse>(`${this.baseUri}/saveMemberSigningStatus`, { state, request })
    return data
  }

  async saveMeetingMinutesSigningStatus(state: RootState, request: MemberSigningStatusRequest): Promise<MemberSigningStatusResponse> {
    const { data } = await this.post<MemberSigningStatusResponse>(`${this.baseUri}/saveMeetingMinutesSigningStatus`, { state, request })
    return data
  }

  async getCurrentSigId(state: RootState, request: GetCurrentSigIdRequest): Promise<GetCurrengSigIdResponse> {
    const { data } = await this.post<GetCurrengSigIdResponse>(`${this.baseUri}/getCurrentSigIdByMtgFormType`, { state, request })
    return data
  }

  async getCurrentMtgSignatures(state: RootState, request: GetCurrentMtgSignaturesRequest): Promise<GetCurrengMtgSignaturesResponse> {
    const { data } = await this.post<GetCurrengMtgSignaturesResponse>(`${this.baseUri}/getCurrentMtgSignatures`, { state, request })
    return data
  }

  async getMemberSigningUrl(state: RootState, request: GetMemeberSigningUrlRequest): Promise<GetMemberSigningUrlResponse> {
    const { data } = await this.post<GetMemberSigningUrlResponse >(`${this.baseUri}/getMemberSigningUrl`, { state, request })
    return data
  }
}
