import { Module } from 'vuex'
import { RootState } from '@/store/root/state'
import { ParentDecisionState, state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export * from './types'

export { ParentDecisionState } from './state'
export { ParentDecisionGetters } from './getters'
export { ParentDecisionMutations } from './mutations'
export { ParentDecisionActions } from './actions'

export const store: Module<ParentDecisionState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
