import cloneDeep from 'lodash/cloneDeep'
import { ELSchool, PagedSelectedItems, Pagination, ReportType, SchoolsPaging, Staff, StaffPaging, StudentPaging, StudentSchoolGrade } from 'models'
import { PagingUtil, PerPageSize } from '@806/utils'

export interface ReportsState {
  loaded: boolean
  schoolsEdited: boolean
  teachersEdited: boolean
  reports: ReportType[]
  selectedReport: ReportType
  schools: ELSchool[]
  reviewers: Staff[]
  teachers: Staff[]
  students: StudentSchoolGrade[]
  selectedSchools: number[]
  pagedSchools: ELSchool[]
  allSchoolIds: number[]
  selectedReviewers: number[]
  pagedReviewers: Staff[]
  allReviewerIds: number[]
  selectedTeachers: number[]
  pagedTeachers: Staff[]
  allTeacherIds: number[]
  selectedStudents: number[]
  pagedStudents: Staff[]
  allStudentIds: number[]
  fileFormat: string
  schoolsPagination: Pagination
  schoolsPaging: SchoolsPaging
  selectedPagedSchools: PagedSelectedItems
  reviewersPagination: Pagination
  reviewersPaging: StaffPaging
  selectedPagedReviewers: PagedSelectedItems
  teachersPagination: Pagination
  teachersPaging: StaffPaging
  selectedPagedTeachers: PagedSelectedItems
  studentsPagination: Pagination
  studentsPaging: StudentPaging
  selectedPagedStudents: PagedSelectedItems
  dropdownType: number
  dropdownSchool: string
  dropdownReviewer: string
  dropdownTeacher: string
}

export const state: ReportsState = {
  loaded: false,
  schoolsEdited: false,
  teachersEdited: false,
  reports: [],
  selectedReport: null,
  schools: [],
  reviewers: [],
  teachers: [],
  students: [],
  selectedSchools: [],
  pagedSchools: [],
  allSchoolIds: [],
  selectedReviewers: [],
  pagedReviewers: [],
  allReviewerIds: [],
  selectedTeachers: [],
  pagedTeachers: [],
  allTeacherIds: [],
  selectedStudents: [],
  pagedStudents: [],
  allStudentIds: [],
  fileFormat: '',
  schoolsPagination: {},
  schoolsPaging: PagingUtil.initialPaging(PerPageSize.Small),
  selectedPagedSchools: PagingUtil.initialSelectedItems(),
  reviewersPagination: {},
  reviewersPaging: PagingUtil.initialPaging(PerPageSize.Small),
  selectedPagedReviewers: PagingUtil.initialSelectedItems(),
  teachersPagination: {},
  teachersPaging: PagingUtil.initialPaging(PerPageSize.Small),
  selectedPagedTeachers: PagingUtil.initialSelectedItems(),
  studentsPagination: {},
  studentsPaging: PagingUtil.initialPaging(PerPageSize.Small),
  selectedPagedStudents: PagingUtil.initialSelectedItems(),
  dropdownType: -1,
  dropdownSchool: '',
  dropdownReviewer: '',
  dropdownTeacher: ''
}

export const clearState: ReportsState = cloneDeep(state)
