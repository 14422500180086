import { RootState } from '@/store/root'
import { EOYLasLinksState } from './state'
import { GetterTree } from 'vuex'
import { Meeting } from 'models'

export enum EOYLasLinksGetters {
  Meeting = 'MEETING'
}

export const getters: GetterTree<EOYLasLinksState, RootState> = {
  [EOYLasLinksGetters.Meeting](state): Meeting {
    return state.meeting
  }
}
