import {MutationTree} from 'vuex';
import {CustomFilterState} from '@/store/modules/custom-filter'
import cloneDeep from 'lodash/cloneDeep';
import {clearState} from '@/store/modules/custom-filter/state';
import {CustomFilterComponent, CustomFilterMenu, CustomFilterOption} from 'models';

export enum CustomFilterMutations {
  Clear = 'CLEAR',
  ClearSelected = 'CLEAR_SELECTED',
  SetSelected = 'SET_SELECTED',
  SetType = 'SET_TYPE',
  SetFilterMenus = 'SET_FILTER_MENUS',
  SetFiltered = 'SET_FILTERED'
}

export const mutations: MutationTree<CustomFilterState> = {
  [CustomFilterMutations.Clear](state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [CustomFilterMutations.ClearSelected](state) {
    state.selected = []
  },

  [CustomFilterMutations.SetSelected](state, option: CustomFilterOption) {
    const previousFilter: CustomFilterOption = state.selected.find(opt => opt.id === option.id)
    const selected: CustomFilterOption[] = state.selected.filter(opt => opt.id !== option.id)
    if (!previousFilter || option.selected) {
      selected.push(option)
    }
    state.selected = selected
  },

  [CustomFilterMutations.SetType](state, type: CustomFilterComponent) {
    state.type = type
  },

  [CustomFilterMutations.SetFilterMenus](state, filterMenus: CustomFilterMenu[]) {
    state.filterMenus = filterMenus
  },

  [CustomFilterMutations.SetFiltered](state, filtered: boolean) {
    state.filtered = filtered
  }

}
