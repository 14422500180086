import cloneDeep from 'lodash/cloneDeep'
import {ProductCode} from '@/enums';
import {AppAccessResponse, DistrictInfo, ProductInfoResponse} from 'models';
import {SSOModals} from '@/store/modules/sso/types';

export interface SSOState {
  product: ProductCode,
  access: AppAccessResponse,
  productInfo: ProductInfoResponse,
  districtInfo: DistrictInfo
  toYear: number,
  show: SSOModals,
  loading: boolean
}
export const state: SSOState = {
  product: null,
  access: null,
  productInfo: null,
  districtInfo: null,
  toYear: null,
  show: {},
  loading: false
}

export const clearState: SSOState = cloneDeep(state)

