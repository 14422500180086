import {
  Term,
  TermsResponse
} from 'models'
import { RootState } from '@/store/root'
import BaseService, {ServiceConfig} from './base'

export default class TermService extends BaseService {
  private readonly baseUri: string = '/terms'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getTerms(state: RootState): Promise<Term[]> {
    const { data: { terms } } = await this.post<TermsResponse>(`${this.baseUri}/getTerms`, { state })

    return terms
  }

}
