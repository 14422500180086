import { MutationTree } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { MeetingSummaryState, clearState } from './state'
import { Meeting, MeetingType, MeetingRoster } from 'models'
import { MeetingTypeCode } from '@/enums'
import { isEmpty } from 'lodash'

export enum MeetingSummaryMutations {
  Clear = 'CLEAR',
  SetTypes = 'SET_TYPES',
  UpdateMeetingStatuses = 'UPDATE_MEETING_STATUSES',
  UpdateMeetingTypeStatuses = 'UPDATE_MEETING_TYPE_STATUSES',
  SetCurrentSigningUrl = 'SET_CURRENT_SIGNING_URL',
  SetIsSigningMeetingMinutes = 'SET_IS_SIGNING_MEETING_MINUTES',
  SetIsSigningCommittee = 'SET_IS_SIGNING_COMMITTEE',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

const getButtonText: (code: string) => string = code => {
  if (code === MeetingTypeCode.Identification) {
    return 'Enter Test Data'
  } else if (code === MeetingTypeCode.StaarDecisions) {
    return 'STAAR Justifications'
  } else if (code === MeetingTypeCode.TelpasDecisions) {
    return 'TELPAS Decisions'
  }
}

export const mutations: MutationTree<MeetingSummaryState> = {
  [MeetingSummaryMutations.Clear](state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [MeetingSummaryMutations.SetTypes](state, types: MeetingType[]) {
    state.types = types
  },

  [MeetingSummaryMutations.UpdateMeetingStatuses](state, currentMeeting: Meeting) {
    if (currentMeeting !== undefined && !isEmpty(currentMeeting.roster)) {
      state = currentMeeting
      .roster
      .reduce<MeetingSummaryState>((summary: MeetingSummaryState, roster: MeetingRoster) => {
        const code = roster.meetingType.code

        summary.statuses[code] = Object.assign(
          summary.statuses[code], {
            numberOfStudents: summary.statuses[code].numberOfStudents + 1,
            meetingTypeName: roster.meetingType.name,
            linkTo: `/review/${code}`,
            buttonText: getButtonText(code),
            code: code
          }
        )

        return summary
      }, state)
      state.meetingTypeStatuses = currentMeeting.meetingTypeStatuses
    } else {
      const code = currentMeeting.meetingType.code
      state.statuses[code] = {
        numberOfStudents: currentMeeting.rosterCount,
        meetingTypeName: currentMeeting.meetingType.name,
        buttonText: getButtonText(code),
        code: code
      }
    }
  },
  [MeetingSummaryMutations.UpdateMeetingTypeStatuses](state, currentMeeting: Meeting) {
    /**
     * Updates the meeting status of the current meeting if one is in progress
     */
    if (currentMeeting && currentMeeting.meetingTypeStatuses) {
      state.meetingTypeStatuses = currentMeeting.meetingTypeStatuses
    }
  },
  [MeetingSummaryMutations.SetCurrentSigningUrl](state, signUrl: string) {
    state.currentSigningUrl = signUrl
  },
  [MeetingSummaryMutations.SetIsSigningMeetingMinutes](state, isSigningMeetingMinutes: boolean) {
    state.isSigningMeetingMinutes = isSigningMeetingMinutes
  },
  [MeetingSummaryMutations.SetIsSigningCommittee](state, isSigningCommittee: boolean) {
    state.isSigningCommittee = isSigningCommittee
  },
  // Only use this mutation for setting the state for test cases
  [MeetingSummaryMutations.SetTestState] (state, testData: MeetingSummaryState) {
    Object.assign(state, cloneDeep(testData))
  }
}
