import { Module } from 'vuex'
import { RootState } from '@/store/root/state'
import { UserSetupState, state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export { UserSetupState } from './state'
export { UserSetupGetters } from './getters'
export { UserSetupMutations } from './mutations'
export { UserSetupActions } from './actions'

export const store: Module<UserSetupState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
