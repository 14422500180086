import BaseService, {ServiceConfig} from './base'
import { RootState } from '@/store/root'
import { ToggleStatusResponse } from 'models'
import {AxiosRequestConfig} from 'axios';

export default class ExternalTogglesService extends BaseService {

  constructor (config: ServiceConfig) {
    super(config)
  }

  private readonly baseUri: string = '/rest/api/external-toggles'

    async getStatus(rootState: RootState, toggle: string): Promise<ToggleStatusResponse> {
        const config: AxiosRequestConfig = {
          params: {
            toggle: toggle
          }
        }
        const { data } = await this.get<ToggleStatusResponse>(`${this.baseUri}/getStatus/${toggle}`, rootState, config)
        return data
      }
}
