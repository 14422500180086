
import { meetingService, studentService, gradeService, standardizedTestService, documentsService } from '@/services'
import { RootState } from '@/store/root'
import { ViewStudentsState } from './state'
import { ViewStudentsMutations } from './mutations'
import { ActionTree } from 'vuex'
import { StudentMeetingFileRequest, GetAccomomdationsRequest, MeetingRoster, StudentPaging, MeetingPlacement, StudentDocFileRequest } from 'models'
import first from 'lodash/first'
import defaults from 'lodash/defaults'

export enum ViewStudentsActions {
  GetTypes = 'GET_TYPES',
  GetStudents = 'GET_STUDENTS',
  GetStudentById = 'GET_STUDENT_BY_ID',
  GetStudentByProvidedId = 'GET_STUDENT_BY_PROVIDED_ID',
  GetStudentStaarResults = 'GET_STUDENT_STAAR_RESULTS',
  GetStudentTelpasResults = 'GET_STUDENT_TELPAS_RESULTS',
  GetStudentAccommodations = 'GET_STUDENT_ACCOMMODATIONS',
  GetStudentMarkingPeriodGrades = 'GET_STUDENT_MARKING_PERIOD_GRADES',
  GetStudentMarkingPeriodGradesById = 'GET_STUDENT_MARKING_PERIOD_GRADES_BY_ID',
  GetTestTypes = 'GET_TEST_TYPES',
  GetStudentDocument = 'GET_STUDENT_DOCUMENT',
  GetPagedMeetings = 'GET_PAGED_MEETINGS',
  GetTeacherDocument = 'GET_TEACHER_DOCUMENT',
  GetSchoolYear = 'GET_SCHOOL_YEAR',
  GetParentDecisionForm = 'GET_PARENT_DECISION_FORM',
  GetStudentZipFile = 'GET_STUDENT_ZIP_FILE',
  GetAllStudentDocuments = 'GET_ALL_STUDENT_DOCUMENTS',
  GetZipDocument = 'GET_ZIP_DOCUMENT',
  GetZipFileStatus = 'GET_ZIP_FILE_STATUS',
}

export const actions: ActionTree<ViewStudentsState, RootState> = {

  async [ViewStudentsActions.GetStudentMarkingPeriodGrades] ({ commit, state, rootState }) {
    const studentId = state.studentSchoolGrade.student.id
    const data = await gradeService.getStudentMarkingPeriodGrades(rootState, studentId)
    commit(ViewStudentsMutations.SetMarkingPeriodGrades, data)
  },

  async [ViewStudentsActions.GetStudentMarkingPeriodGradesById] ({ commit, rootState }, studentId) {
    const data = await gradeService.getStudentMarkingPeriodGrades(rootState, studentId)
    commit(ViewStudentsMutations.SetMarkingPeriodGrades, data)
  },

  async [ViewStudentsActions.GetTypes] ({ commit, state, rootState }) {
    const types = await meetingService.getTypes(rootState)

    if (!state.code) {
      commit(ViewStudentsMutations.SetCode, first(types).code)
    }

    commit(ViewStudentsMutations.SetTypes, types)
  },

  async [ViewStudentsActions.GetStudents] ({ commit, state, rootState }) {
    commit(ViewStudentsMutations.SetLoaded, false)

    try {
      const code = state.code
      const showInactive = state.showInactive
      const hideCurrentMeeting = false
      const isDPU = state.isDPU
      const paging: StudentPaging = {
        page: state.paging.page,
        perPage: state.paging.perPage,
        search: state.paging.search,
        sort: state.paging.sort,
        sortAscending: state.paging.sortAscending,
        sortType: state.paging.sortType
      }
      const { students, allStudentIds, pagination } = await studentService.getAnyTypeStudents(rootState, paging, code, showInactive, isDPU, hideCurrentMeeting)

      if (state.paging.page !== pagination.currentPage) {
        commit(ViewStudentsMutations.SetPaging, defaults({
          page: pagination.currentPage
        }, state.paging))
      }

      commit(ViewStudentsMutations.SetStudents, { students, allStudentIds, pagination })
    } finally {
      commit(ViewStudentsMutations.SetLoaded, true)
    }
  },

  async [ViewStudentsActions.GetStudentById] ({ commit, state, rootState }) {
    commit(ViewStudentsMutations.SetLoaded, false)

    try {
      const hideCurrentMeeting = false
      const showInactive = true
      const { studentSchoolGrade } = await studentService.getStudentById(rootState, state.studentId, hideCurrentMeeting, showInactive)
      const { studentPlacement }  = await studentService.getRecentStudentPlacement(rootState, state.studentId)
      const meetingPlacement: MeetingPlacement = studentPlacement

      commit(ViewStudentsMutations.SetStudentSchoolGrade, { studentSchoolGrade })
      commit(ViewStudentsMutations.SetMeetingPlacement, { meetingPlacement })
    } finally {
      commit(ViewStudentsMutations.SetLoaded, true)
    }
  },

  async [ViewStudentsActions.GetStudentByProvidedId] ({ commit, rootState }, studentId) {
    commit(ViewStudentsMutations.SetLoaded, false)

    try {
      const hideCurrentMeeting = false

      const { studentSchoolGrade } = await studentService.getStudentById(rootState, studentId, hideCurrentMeeting)
      const { studentPlacement } = await studentService.getRecentStudentPlacement(rootState, studentId)
      const meetingPlacement: MeetingPlacement = studentPlacement

      commit(ViewStudentsMutations.SetStudentSchoolGrade, { studentSchoolGrade })
      commit(ViewStudentsMutations.SetMeetingPlacement, { meetingPlacement })
    } finally {
      commit(ViewStudentsMutations.SetLoaded, true)
    }
  },

  async [ViewStudentsActions.GetStudentStaarResults] ({ commit, rootState }, studentId) {
    commit(ViewStudentsMutations.SetLoaded, false)

    try {
      const hideCurrentMeeting = false

      const { studentScores } = await standardizedTestService.getStudentStaarResults(rootState, studentId, hideCurrentMeeting)
      commit(ViewStudentsMutations.SetStaarScores, studentScores)
    } finally {
      commit(ViewStudentsMutations.SetLoaded, true)
    }
  },

  async [ViewStudentsActions.GetStudentTelpasResults] ({ commit, rootState }, studentId) {
    commit(ViewStudentsMutations.SetLoaded, false)

    try {

      const { studentScores } = await standardizedTestService.getStudentTelpasResults(rootState, studentId)
      commit(ViewStudentsMutations.SetTelpasScores, studentScores)
    } finally {
      commit(ViewStudentsMutations.SetLoaded, true)
    }
  },

  async [ViewStudentsActions.GetStudentAccommodations] ({ commit, rootState }, { studentId, nonExit }: GetAccomomdationsRequest) {
    commit(ViewStudentsMutations.SetLoaded, false)

    try {
      const { studentGradeAccommodations } = await standardizedTestService.getStudentAccommodations(rootState, studentId, nonExit)
      commit(ViewStudentsMutations.SetStudentAccommodations, studentGradeAccommodations)
    } finally {
      commit(ViewStudentsMutations.SetLoaded, true)
    }
  },

  async [ViewStudentsActions.GetTestTypes] ({ commit, rootState }, testType) {
    commit(ViewStudentsMutations.SetLoaded, false)

    try {
      const { attributes } = await standardizedTestService.getTestTypes(rootState, testType)
      commit(ViewStudentsMutations.SetTestTypes, attributes)
    } finally {
      commit(ViewStudentsMutations.SetLoaded, true)
    }
  },

  async [ViewStudentsActions.GetPagedMeetings] ({ commit, state, rootState }) {
    commit(ViewStudentsMutations.SetLoaded, false)

    try {

      const { meetings, pagination } = await meetingService.getPagedMeetingsByStudentId(rootState, state.paging, state.studentId)

      if (state.paging.page !== pagination.currentPage) {
        commit(ViewStudentsMutations.SetPaging, defaults({
          page: pagination.currentPage
        }, state.paging))
      }

      commit(ViewStudentsMutations.SetMeetings, { meetings, pagination })
    } finally {
      commit(ViewStudentsMutations.SetLoaded, true)
    }
  },

  async [ViewStudentsActions.GetStudentDocument]({ rootState }, getStudentDocument: StudentMeetingFileRequest) {
    await studentService.getStudentMeetingFile(rootState, getStudentDocument)
  },

  async [ViewStudentsActions.GetParentDecisionForm]({ rootState }, roster: MeetingRoster) {
    const studentNumber = roster.studentSchoolGrade.student.studentNumber
    const filename = `${studentNumber}-${roster.meetingPlacement.formFilename}`

    await studentService.downloadParentDecisionForm(rootState, roster.id, filename)
  },

  async [ViewStudentsActions.GetTeacherDocument]({ rootState }, getStudentDocument: StudentMeetingFileRequest) {
    await studentService.getStudentMeetingFile(rootState, getStudentDocument)
  },

  async [ViewStudentsActions.GetSchoolYear]({ commit, rootState }, studentSchoolGradeId: number) {
    const { schoolYear } = await standardizedTestService.getSchoolYearByStudentSchoolGradeId(rootState, studentSchoolGradeId)
    commit(ViewStudentsMutations.SetSchoolYear, schoolYear)
  },

  async [ViewStudentsActions.GetStudentZipFile]({ rootState }, request: StudentDocFileRequest) {
    await documentsService.downloadStudentDocument(rootState, request)
  },

  async [ViewStudentsActions.GetAllStudentDocuments]({ rootState }, studentId: number) {
    await documentsService.downloadAllStudentDocuments(rootState, studentId)
  },

  async [ViewStudentsActions.GetZipDocument]({ commit, rootState }, studentId: number) {
    const zipDoc = await documentsService.getZipDocument(rootState, studentId)

    commit(ViewStudentsMutations.SetZipDocument, zipDoc)
  },

  async [ViewStudentsActions.GetZipFileStatus]({ commit, rootState }, studentId: number) {
    const zipFileStatusResponse = await documentsService.getZipFileStatus(rootState, studentId)
  
    commit(ViewStudentsMutations.SetZipFileStatus, zipFileStatusResponse.zipFileStatus)
    commit(ViewStudentsMutations.SetHasDocs, zipFileStatusResponse.hasDocs)
  },

}
