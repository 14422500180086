import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { CompletedMeetingState } from './state'
import {Meeting, MeetingFormSignature} from 'models'
import { GetIsFileDownloaded } from './types'
import { MeetingTypeCode, MeetingFormTypeCode } from '@/enums'

export enum CompletedMeetingGetters {
  MeetingDate = 'MEETING_DATE',
  MeetingFormSignatures = 'MEETING_FORM_SIGNATURES',
  FilesDownloadByType = 'FILES_DOWNLOADED_BY_TYPE',
  AllFilesDownloaded = 'FILES_DOWNLOADED',
  ProgramTypesInMeeting = 'PROGRAM_TYPES_IN_MEETING',
  GetCompletedMeeting = 'GET_COMPLETED_MEETING'
}

export const getters: GetterTree<CompletedMeetingState, RootState> = {

  [CompletedMeetingGetters.MeetingDate](state): string {
    if (state.meeting) {
      return state.meeting.mtgDate
    }
  },
  [CompletedMeetingGetters.MeetingFormSignatures](state): MeetingFormSignature[] {
    if (state.meeting) {
      return state.meeting.meetingFormSignatures
    }
  },
  [CompletedMeetingGetters.FilesDownloadByType](state): GetIsFileDownloaded {
    return (meetingTypeCode: MeetingTypeCode) => {
      const mtgFormSig = state.meeting.meetingFormSignatures.find(mtgFormSig => mtgFormSig.meetingFormTypeCode === meetingTypeCodeToMeetingFormTypeCodeConverter(meetingTypeCode))
      if (mtgFormSig) {
        return mtgFormSig.isSignedFileDownloaded
      }
      return false
    }
  },
  [CompletedMeetingGetters.AllFilesDownloaded](state): boolean {
    if (state.meeting) {
      const mtgFormSigWithoutFleDle = state.meeting.meetingFormSignatures.find(mtgFormSig => !mtgFormSig.isSignedFileDownloaded && mtgFormSig.meetingFormTypeCode !== MeetingFormTypeCode.MeetingMinutesForm)
      if (mtgFormSigWithoutFleDle) {
        return false
      }
      return true
    }
  },
  [CompletedMeetingGetters.ProgramTypesInMeeting](state): Set<string> {
    return new Set(state.students.map(student => student.meetingPlacement.programType.programDesc))
  },
  [CompletedMeetingGetters.GetCompletedMeeting](state): Meeting {
    if (state.meeting) {
      return state.meeting
    }
  }
}

export const meetingTypeCodeToMeetingFormTypeCodeConverter = (meetingTypeCode: MeetingTypeCode): MeetingFormTypeCode => {
  switch (meetingTypeCode) {
    case MeetingTypeCode.Identification:
      return MeetingFormTypeCode.StudentIdentificationForm
    case MeetingTypeCode.StaarDecisions:
      return MeetingFormTypeCode.StaarParticipationAndAccommodationForm
    case MeetingTypeCode.TelpasDecisions:
      return MeetingFormTypeCode.TelpasDecisionsForm
    case MeetingTypeCode.AnnualReviewLep:
      return MeetingFormTypeCode.AnnualReviewLepForm
    case MeetingTypeCode.AnnualReviewMonitor:
      return MeetingFormTypeCode.AnnualReviewMonitorForm
    case MeetingTypeCode.ReviewLpac:
      return MeetingFormTypeCode.ReviewLpacForm
  }
}
