import {MutationTree} from 'vuex'
import {state as clearState, ReviewStudentState} from './state'
import {RecommendationEdits, TestEdits} from './types'
import {
  ContentLanguageControlOptions,
  DistrictLanguage,
  EnrollmentType, IndicatorType,
  MeetingPlacement,
  MeetingRoster,
  PriorMeetingPlacement,
  ProficiencyTestResults,
  ProgramType
} from 'models'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import {EnrollmentTypeCode, MeetingTypeCode} from '@/enums'
import {isNumber} from 'lodash'

export enum ReviewStudentMutations {
  Clear = 'CLEAR',
  SetLoading = 'SET_LOADING',
  SetRoster = 'SET_ROSTER',
  SetTestEdits = 'SET_TEST_EDITS',
  SetProficiencyEdits = 'SET_PROFICIENCY_EDITS',
  SetProficiencyEditsForStudent = 'SET_PROFICIENCY_TEST_EDITS',
  SetRecommendationEdits = 'SET_RECOMMENDATION_EDITS',
  SetMonitorRecommendationEdits = 'SET_MONTIOR_RECOMMENDATION_EDITS',
  ResetProficiencyEditsForStudent = 'RESET_PROFICIENCY_EDITS_FOR_STUDENT',
  SetTeacherDenial = 'SET_TEACHER_DENIAL',
  SetReadiness = 'SET_READINESS',
  SetSupportOptions = 'SET_SUPPORT_OPTIONS',
  SetDualLanguageYear = 'SET_DUAL_LANGUAGE_YEAR',
  SetDualLanguageLang = 'SET_DUAL_LANGUAGE_LANGUAGE',
  AddOrRemoveTransferOptId = 'ADD_OR_REMOVE_TRANSFER_OPT_ID',
  SetRosterEnrollmentType = 'SET_ROSTER_ENROLLMENT_TYPE',
  RemoveRosterTransferEdits = 'REMOVE_ROSTER_TRANSFER_EDITS',
  SetRosterTransferEdits = 'SET_ROSTER_TRANSFER_EDITS',
  SetRosterOptions = 'SET_ROSTER_OPTIONS',
  SetClsEdits = 'SET_CLS_EDITS',
  SetPriorMeetingPlacement = 'SET_PRIOR_MEETING_PLACEMEHT',
  SetDefaultMeetingPlacement = 'SET_DEFAULT_MEETING_PLACEMENT',
  SetNotes = 'SET_NOTES',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<ReviewStudentState> = {

  [ReviewStudentMutations.Clear](state) {
    Object.assign(state, clearState())
  },

  [ReviewStudentMutations.SetLoading](state, loading: boolean) {
    state.loading = loading
  },

  [ReviewStudentMutations.SetNotes](state, notes: string) {
    state.notes = notes
  },

  [ReviewStudentMutations.SetRoster](state, roster: MeetingRoster) {
    state.roster = roster
  },

  [ReviewStudentMutations.SetTestEdits](state, edits: TestEdits) {
    state.testEdits = edits
  },

  [ReviewStudentMutations.SetProficiencyEdits](state, proficiencyTestResultEdits: ProficiencyTestResults[]) {
    state.proficiencyTestResultEdits = proficiencyTestResultEdits
  },

  [ReviewStudentMutations.SetPriorMeetingPlacement](state, priorMeetingPlacement: PriorMeetingPlacement) {
    state.priorMeetingPlacement = {} as MeetingPlacement
    if (!isEmpty(priorMeetingPlacement)) {
      state.priorMeetingPlacement = priorMeetingPlacement
    }
  },

  [ReviewStudentMutations.SetDefaultMeetingPlacement](state) {
    const isReviewLpac: boolean = state.roster.meetingType.code === MeetingTypeCode.ReviewLpac
    const meetingPlacement: MeetingPlacement = state.roster.meetingPlacement
    if (!isEmpty(state.priorMeetingPlacement)) {
      if (isReviewLpac && !meetingPlacement) {
        state.recommendationEdits.lepStatus = state.priorMeetingPlacement.lepStatus
        state.recommendationEdits.programType = state.priorMeetingPlacement.programType
        if (state.priorMeetingPlacement.dualLanguage) {
          state.dualLanguage = state.priorMeetingPlacement.dualLanguage
        }
      }
    }
  },

  [ReviewStudentMutations.SetProficiencyEditsForStudent](state, proficiencyTestResultEdits: ProficiencyTestResults) {
    if (!isEmpty(state.proficiencyTestResultEdits)) {
      const currentEditIndex = state.proficiencyTestResultEdits.findIndex(profTstRsltEdit => profTstRsltEdit.languageId === proficiencyTestResultEdits.languageId)
      if (currentEditIndex > -1) {
        state.proficiencyTestResultEdits[currentEditIndex] = proficiencyTestResultEdits
      } else {
        state.proficiencyTestResultEdits.push(proficiencyTestResultEdits)
      }
    } else {
      state.proficiencyTestResultEdits.push(proficiencyTestResultEdits)
    }
  },

  [ReviewStudentMutations.SetRecommendationEdits](state, edits: RecommendationEdits) {
    state.recommendationEdits = edits
  },

  [ReviewStudentMutations.SetMonitorRecommendationEdits](state, edits: RecommendationEdits) {
    state.monitorRecommendiationEdits = edits
  },

  [ReviewStudentMutations.ResetProficiencyEditsForStudent](state) {
    state.proficiencyTestResultEdits = []
  },

  [ReviewStudentMutations.SetTeacherDenial](state, teacherDenial: boolean) {
    state.teacherDenial = teacherDenial
  },

  [ReviewStudentMutations.SetReadiness](state, readiness: IndicatorType) {
    state.readiness = readiness
  },

  [ReviewStudentMutations.SetSupportOptions](state, options: ContentLanguageControlOptions[]) {
    state.supportOptions = options
  },

  [ReviewStudentMutations.SetDualLanguageYear](state, year: ProgramType) {
    state.dualLanguage.year = year
  },

  [ReviewStudentMutations.SetDualLanguageLang](state, language: DistrictLanguage) {
    state.dualLanguage.language = language
  },
  [ReviewStudentMutations.AddOrRemoveTransferOptId](state, transferOptId: number) {
    if (!state.transferOptIds.has(transferOptId)) {
      state.transferOptIds.add(transferOptId)
    } else {
      state.transferOptIds.delete(transferOptId)
    }
  },
  [ReviewStudentMutations.SetRosterEnrollmentType](state, enrollmentType: EnrollmentType) {
    state.roster.enrollmentType = enrollmentType
    if (enrollmentType.enrollmentTypeCode === EnrollmentTypeCode.InitialEnrollment && !isNumber(state.recommendationEdits.lepStatus?.order)) {
      state.recommendationEdits.lepStatus = null
    }
    if (state.roster?.enrollmentType?.enrollmentTypeCode === EnrollmentTypeCode.Review && state.roster?.meetingPlacement?.programType && (!state.roster.meetingPlacement.programType.lepStatusId || !state.roster.meetingPlacement.programType.programTypeCD)) {
      state.roster.meetingPlacement = state.roster.carryOverMeetingPlacement
      state.recommendationEdits.lepStatus = state.roster.carryOverMeetingPlacement?.lepStatus
      state.recommendationEdits.programType = state.roster.carryOverMeetingPlacement?.programType
    }
    state.roster = cloneDeep(state.roster)
  },
  [ReviewStudentMutations.RemoveRosterTransferEdits](state) {
    state.transferOptIds.clear()
  },
  [ReviewStudentMutations.SetRosterTransferEdits](state, transferOptIds: number[]) {
    state.transferOptIds.clear()
    const optIds = new Set<number>()
    transferOptIds.forEach(optId => {
      optIds.add(optId)
    })
    state.transferOptIds = optIds
  },

  [ReviewStudentMutations.SetRosterOptions](state, options: ContentLanguageControlOptions[]) {
    state.roster.options = options
  },

  [ReviewStudentMutations.SetClsEdits](state, userHasChangedCLS: boolean) {
    state.userHasChangedCLS = userHasChangedCLS
  },

  // Only use this mutation for setting the state for test cases
  [ReviewStudentMutations.SetTestState] (state, testData: ReviewStudentState) {
    Object.assign(state, cloneDeep(testData))
  }

}
