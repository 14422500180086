import cloneDeep from 'lodash/cloneDeep'
import { DashboardRosterRow, Meeting, MeetingAccommodation, MeetingsPaging, MeetingType, Pagination, StudentSchoolGrade } from 'models'
import { MeetingTypeCode } from '@/enums'
import { PagingUtil, PerPageSize } from '@806/utils'
import { DashboardPaging, PagedDashboardMeetings, RosterReviewStudents } from './types'

export interface MeetingState {
  loading: boolean
  currentMeeting: Meeting
  lastSignSuccess: boolean
  meetingMemberScreenshotUploaded: boolean
  currentReviewMtgType: MeetingTypeCode
  meetingAccommodations: MeetingAccommodation[]
  pagination: Pagination
  meetingsPaging: MeetingsPaging,
  potentialExitStuPaging: DashboardPaging,
  potentialExitStudents: DashboardRosterRow[],
  potentialExitMeetingRosterIds: number[],
  staffId: number
  teacherLinkMeetingId: number,
  types: MeetingType[],
  rosterReviewStudents: RosterReviewStudents,
  hasCLSOptions: boolean,
  openMeetings: PagedDashboardMeetings,
  activeRosters: StudentSchoolGrade[],
  generatingPotentialExitMergedLetters: boolean,
  combinedLetterDownloadCount: number
}

export const state: MeetingState = {
  loading: false,
  currentMeeting: null,
  lastSignSuccess: true,
  meetingMemberScreenshotUploaded: false,
  currentReviewMtgType: MeetingTypeCode.Identification,
  meetingAccommodations:  [],
  pagination: {},
  meetingsPaging: PagingUtil.initialPaging(PerPageSize.Small),
  potentialExitStuPaging: {
    paging: PagingUtil.initialPaging(PerPageSize.Small),
    pagination: {}
  },
  potentialExitStudents: [],
  potentialExitMeetingRosterIds: [],
  staffId: null,
  teacherLinkMeetingId: null,
  types: [],
  rosterReviewStudents: null,
  hasCLSOptions: false,
  openMeetings: {
    meetings: [],
    paging: PagingUtil.initialPaging(PerPageSize.Small),
    pagination: {}
  },
  activeRosters: [],
  generatingPotentialExitMergedLetters: false,
  combinedLetterDownloadCount: 0
}

export const clearState: MeetingState = cloneDeep(state)
