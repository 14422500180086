import { AuthState } from '@/store/helpers'
import { ELUser } from 'models'
import { ProductCode } from '@/enums'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { tracker } from '@/util/open-replay'

@Component({
  render: h => h()
})
export default class OpenReplay extends Vue {

  @AuthState
  protected authorized: boolean

  @AuthState
  protected user: ELUser

  get userVars() {
    if (this.user) {
      return {
        name: this.user.fullName,
        email: this.user.email,
        role: this.user.role.name,
        product: ProductCode.El,
        title: this.user.title.name,
        districtId: this.user.district.id,
        districtName: this.user.district.name,
        campusId: this.user.school.id,
        campusName: this.user.school.name,
      }
    }
  }

  @Watch('authorized')
  protected setTracker(authorized: boolean) {
    if (authorized) {
      this.identifyUser()
    }
  }

  protected identifyUser() {
    if (process.env.OPEN_REPLAY_ENABLED && this.user) {
      tracker.setUserID(this.user.username)
      for (const [key, value] of Object.entries(this.userVars)) {
        tracker.setMetadata(key, String(value))
      }
    }
  }

  protected mounted() {
    this.identifyUser()
  }

}
