import {CustomFilterComponent, CustomFilterMenu, CustomFilterOption} from 'models'
import {cloneDeep} from 'lodash'

export interface CustomFilterState {
  selected: CustomFilterOption[],
  type: CustomFilterComponent,
  filterMenus: CustomFilterMenu[],
  filtered: boolean
}

export const state: CustomFilterState = {
  selected: [],
  type: null,
  filterMenus: [],
  filtered: false,
}

export const clearState: CustomFilterState = cloneDeep(state)
