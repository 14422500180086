import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { StudentDocumentsState } from './state'

export enum StudentDocumentsGetters {
  DocumentDate = 'DOCUMENT_DATE'
}

export const getters: GetterTree<StudentDocumentsState, RootState> = {

}
