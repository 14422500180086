import { Store } from 'vuex'
import { RootState } from '../store/root'
import { NavigationGuard } from 'vue-router'
import { get } from 'lodash'
import { AuthGetters, AuthActions } from '../store/modules/auth'
import { ErrorHolder, ErrorCode } from './errors'
import { Pages } from '@/pages'
import {Optional} from 'typescript-optional';
import {SessionVariables} from '@/util/SessionVariables';

export const createLoginGuard = (store: Store<RootState>): NavigationGuard => {
  return async (to, from, next) => {
    const authRequired = to.matched.some(route => get(route.meta, 'authRequired', true))
    const goingToLogin = to.name === Pages.Login

    if (goingToLogin) {
      return goToLogin()
    }

    if (!authRequired) {
      return next()
    }

    if (store.getters[`auth/${AuthGetters.Authorized}`]) {
      return goingToLogin ? next({ name: Pages.Dashboard }) : next()
    }

    try {
      await store.dispatch(`auth/${AuthActions.GetAuthUser}`)
    } catch (error) {
      if (ErrorHolder.isCode(error, ErrorCode.NotAuthError)) {
        return goToLogin()
      }
      return next(ErrorHolder.unwrap(error))
    }

    next()

    function goToLogin() {
      if (process.env.EXT_LOGIN) {
        // redirect to external site for all other environments
        location.href = process.env.LOGIN_URL

        next(false)
      } else {
        goingToLogin ? next() : next({ name: Pages.Login })
      }
    }

  }
}

export const getSessionItem = (key: SessionVariables) => {
  return Optional.ofNullable(sessionStorage.getItem(key))
}
