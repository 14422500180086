import {ActionTree} from 'vuex';
import {RootState} from '@/store/root'
import {CustomFilterState} from '@/store/modules/custom-filter/state'
import {CustomFilterMutations} from '@/store/modules/custom-filter/mutations'
import { customFilterService } from '@/services'
import {CustomFilterMenu} from 'models';
import {CustomFilterComponent} from '@/enums';

export enum CustomFilterActions {
  GetFilterMenus = 'GET_FILTER_MENUS'
}


export const actions: ActionTree<CustomFilterState, RootState> = {
  async [CustomFilterActions.GetFilterMenus]({ commit, rootState }, types: CustomFilterComponent[]) {
    const menus: CustomFilterMenu[] = []
    for (const type of types) {
      const menu: CustomFilterMenu = await customFilterService.getFilterMenu(rootState, type)
      menus.push(menu)
    }
    commit(CustomFilterMutations.SetFilterMenus, menus)
  }
}
