import { RootState } from '@/store/root'
import { RecommendationsState } from './state'
import { ActionTree } from 'vuex'
import {MeetingRoster} from 'models';
import { RecommendationsMutations} from '@/store/modules/recommendations/mutations'

export enum RecommendationsActions {
  SetRoster = 'SET_ROSTER'
}

export const actions: ActionTree<RecommendationsState, RootState> = {

  async [RecommendationsActions.SetRoster]({ commit, state }, roster: MeetingRoster) {
    const dualLangYear = roster.meetingPlacement?.dualLanguage ? roster.meetingPlacement.dualLanguage.year : state.dualLanguage?.year
    const dualLangLang = roster.meetingPlacement?.dualLanguage ? roster.meetingPlacement.dualLanguage.language : state.dualLanguage?.language
    commit(RecommendationsMutations.SetRoster, roster)
    commit(RecommendationsMutations.SetDualLanguageYear, dualLangYear)
    commit(RecommendationsMutations.SetDualLanguageLang, dualLangLang)
  }
}
