import { Module } from 'vuex'
import { RootState } from '@/store/root/state'
import { AuthState, state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export * from './types'

export { AuthState } from './state'
export { AuthGetters } from './getters'
export { AuthMutations } from './mutations'
export { AuthActions } from './actions'

export const store: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
