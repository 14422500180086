import cloneDeep from 'lodash/cloneDeep'
import { Meeting, Pagination, MeetingsPaging } from 'models'
import { PagingUtil } from '@806/utils'

export interface CompletedMeetingsState {
  loaded: boolean
  meetings: Meeting[]
  pagination: Pagination
  paging: MeetingsPaging
  roleId: string
}

export const state: CompletedMeetingsState = {
  loaded: false,
  meetings: [],
  pagination: {},
  paging: PagingUtil.initialPaging(),
  roleId: ''
}

export const clearState: CompletedMeetingsState = cloneDeep(state)
