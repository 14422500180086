import Notifications from '@/components/notifications/Notifications.vue'
import SupportChat from '@/components/support-chat/SupportChat.vue'
import OpenReplay from './components/app/open-replay/OpenReplay.vue'
import { Component, Vue } from 'vue-property-decorator'
import ZendeskChat from '@/components/app/zendesk-chat/ZendeskChat';
import { RootGetters } from '@/store/root';
import { Getter } from '@/store/helpers';

@Component({
  components: {
    Notifications,
    SupportChat,
    OpenReplay,
    ZendeskChat
  }
})
export default class App extends Vue {

  @Getter(RootGetters.ZendeskEnabled)
  protected zendeskEnabled: boolean

  @Getter(RootGetters.FreshchatEnabled)
  protected freshchatEnabled: boolean

}
