import { Getter } from 'vuex'
import { RootState } from '@/store/root'
import { RecommendationsState } from './state'
import { RecommendationsGetters } from './getters'
import {
  MeetingPlacement, StudentPlacement
} from 'models'
import defaults from 'lodash/defaults'
import isEmpty from 'lodash/isEmpty'
import { RecommendationEdits } from './types'
/**
 * These are too expensive to be getters since they would updated on every edit.
 * They are used in multiple actions.
 */


export const buildRecommendations = (
  state: RecommendationsState,
  getters: Getter<RecommendationsState, RootState>
): MeetingPlacement => {
  const meetingRosterId = getters[RecommendationsGetters.MeetingRosterId]
  const studentSchoolGradeId = getters[RecommendationsGetters.StudentSchoolGradeId]
  const savedRecommendations: MeetingPlacement = getters[RecommendationsGetters.Recommendations]
  
  const recommendedEdits: RecommendationEdits = isEmpty(state?.monitorRecommendiationEdits?.programType) ? state?.recommendationEdits :
    isEmpty(state.monitorRecommendiationEdits.lepStatus) ?
    { programType: state.monitorRecommendiationEdits.programType, lepStatus: state.recommendationEdits.lepStatus } :
    state.monitorRecommendiationEdits

  return defaults({
    studentSchoolGradeId,
    meetingRosterId
  }, recommendedEdits, savedRecommendations)
}

export const buildStudentPlacementRecommendations = (state: RecommendationsState): StudentPlacement => {

  const recommendedEdits: RecommendationEdits = isEmpty(state.monitorRecommendiationEdits.programType) ? state.recommendationEdits :
    isEmpty(state.monitorRecommendiationEdits.lepStatus) ?
      { programType: state.monitorRecommendiationEdits.programType, lepStatus: state.recommendationEdits.lepStatus } :
      state.monitorRecommendiationEdits

  return defaults(recommendedEdits)
}
