import {RootState} from '@/store/root';
import {
  CustomFilterMenu,
  CustomFilterOption,
  StudentPaging,
  StudentSchoolGradesRequest,
  StudentSchoolGradesResponse
} from 'models';
import {CustomFilterComponent, MeetingTypeCode} from '@/enums';
import { PagingUtil } from '@806/utils'
import BaseService, {ServiceConfig} from './base'
import {AxiosRequestConfig} from 'axios';


export default class CustomFilterService extends BaseService {

  private readonly baseUri: string = '/rest/api/filters'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getFilterMenu(state: RootState, type: CustomFilterComponent): Promise<CustomFilterMenu> {
    const config: AxiosRequestConfig = {
      params: {
        type: type
      }
    }
    const { data } = await this.get<CustomFilterMenu>(`${this.baseUri}/getCustomFilters/${type}`, state, config)
    return data
  }

  async getFilteredStudents(
    state: RootState,
    paging: StudentPaging,
    meetingType?: MeetingTypeCode,
    hideCurrentMeeting?: boolean,
    filters?: CustomFilterOption[]
  ): Promise<StudentSchoolGradesResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: StudentSchoolGradesRequest = { paging, meetingType, hideCurrentMeeting, filters }
      const { data } = await this.post<StudentSchoolGradesResponse>(`${this.baseUri}/getFilteredStudents`, { state, request })

      return data
    })
  }
}
