import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { SetupCommitteeState } from './state'

export enum SetupCommitteeGetters {
  RoleNames = 'ROLE_NAMES'
}

export const getters: GetterTree<SetupCommitteeState, RootState> = {
  [SetupCommitteeGetters.RoleNames](state): string[] {
    return state.roles ? state.roles.map(role => role.name) : []
  }
}
