import { OralLanguageProficiencyTest, AcademicAchievementProficiencyTest } from 'models'
import { OlptSaveEdits, TestEdits } from './types'
import defaults from 'lodash/defaults'
import isNil from 'lodash/isNil'

export const buildTest = (olptSaveEdits: OlptSaveEdits): OralLanguageProficiencyTest => {
  const meetingRosterId = olptSaveEdits.meetingRosterId
  const studentSchoolGradeId = olptSaveEdits.studentGradeId
  const savedOlpt: OralLanguageProficiencyTest = olptSaveEdits.savedOlpt
  const savedAapt: AcademicAchievementProficiencyTest = savedOlpt && savedOlpt.aapt
  const { olptEmpty, studentOlptEmpty, aaptEmpty, olpt, aapt } = olptSaveEdits.testEdits
  const allEmpty = olptEmpty && studentOlptEmpty && aaptEmpty

  const test = defaults({
    studentSchoolGradeId,
    meetingRosterId,
    aapt: aaptEmpty ? null : defaults(aapt, savedAapt)
  }, olpt, savedOlpt)

  if (olptEmpty) {
    test.language = undefined
  }

  if (studentOlptEmpty) {
    test.studentLanguage = undefined
  }

  return allEmpty ? null : test
}

export const buildInitialTestEdits = (currentOlpt: OralLanguageProficiencyTest, currentTestEdits: TestEdits): TestEdits => {
  if (!isNil(currentOlpt.aapt)) {
    currentTestEdits.aapt = currentOlpt.aapt
    currentTestEdits.aaptEmpty = false
  }
  currentTestEdits.olpt = currentOlpt
  return currentTestEdits
}
