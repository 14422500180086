import BaseService, {ServiceConfig} from './base'
import {
  DocumentsPaging,
  PagedDocumentsResponse,
  DocumentsRequest,
  StudentDocFileRequest,
  RemoveStudentDocRequest,
  StudentDocument,
  ParentLetterMergeResponse,
  StudentDocumentResponse,
  StudentDocumentRequest,
  ZipFileStatusResponse
} from 'models'
import { RootState } from '@/store/root'
import { PagingUtil } from '@806/utils'
import { RestUrls } from './RestUrls'
import {AxiosRequestConfig} from 'axios';
import { isEmpty } from 'lodash'

export default class DocumentsService extends BaseService {

  private readonly baseUri: string = '/rest/api/document'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getPagedDocuments(
    state: RootState,
    paging: DocumentsPaging,
    stuId: number,
    meetingRosterId: number
  ): Promise<PagedDocumentsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: DocumentsRequest = { paging, studentId: stuId, meetingRosterId }

      const { data } = await this.post<PagedDocumentsResponse>(`${this.baseUri}/getPagedDocuments`, { state, request })

      return data
    })
  }

  async getDocumentByType(
    state: RootState,
    request: StudentDocumentRequest
  ): Promise<StudentDocumentResponse> {

    const { data } = await this.post<StudentDocumentResponse>(`${this.baseUri}/getDocumentByType`, { state, request })

    return data
  }

  async getDocumentTypes(state: RootState): Promise<string[]> {
    const { data } = await this.get<string[]>(`${RestUrls.getDocumentTypes}`, state)

    return data
  }

  async getRegeneratingParentLetters(state: RootState, meetingId: number): Promise<boolean> {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: meetingId
      }
    }
    const { data } = await this.get<boolean>(`${RestUrls.getRegeneratingParentLetters}/${meetingId}`, state, config)

    return data
  }

  async getPotentialExitRegeneratingParentLetters(state: RootState, schoolId: number): Promise<boolean> {
    const config: AxiosRequestConfig = {
      params: {
        schoolId: schoolId
      }
    }
    const { data } = await this.get<boolean>(`${RestUrls.getPotentialExitRegeneratingParentLetters}/${schoolId}`, state, config)

    return data
  }

  async getCombinedExitLettersDownloadCount(state: RootState, schoolId: number): Promise<number> {
    const config: AxiosRequestConfig = {
      params: {
        schoolId: schoolId
      }
    }
    const { data } = await this.get<number>(`${RestUrls.getCombinedExitLettersDownloadCount}/${schoolId}`, state, config)

    return data
  }

  async removeStudentDocument(state: RootState, request: RemoveStudentDocRequest): Promise<void> {
    await this.post(`${this.baseUri}/removeStudentDocument`, { state, request })
  }

  async downloadAllStudentDocuments(state: RootState, studentId: number) {
    const request: DocumentsRequest = { studentId: studentId }
    await this.post(`${this.baseUri}/getAllStudentDocuments`, { state, request })
  }

  async getZipDocument(state: RootState, studentId: number) {
    const config: AxiosRequestConfig = {
      params: {
        studentId: studentId
      }
    }
    const { data } = await this.get<StudentDocument>(`${RestUrls.getZipDocument}/${studentId}`, state, config)

    return data
  }

  async getZipFileStatus(state: RootState, studentId: number){
    const config: AxiosRequestConfig = {
      params: {
        studentId: studentId
      }
    }
    const { data } = await this.get<ZipFileStatusResponse>(`${RestUrls.getZipDocumentStatus}/${studentId}`, state, config)

    return data
  }

  async downloadStudentDocument(state: RootState, request: StudentDocFileRequest): Promise<void> {
    const { data, headers } = await this.post(`${this.baseUri}/getStudentDocument`, { state, request }, { responseType: 'blob' })
    const type = request.fileLoc?.split('.').pop()
    let contentType = ''
    if (type === 'pdf') {
      contentType = 'application/pdf'
    } else if (type === 'csv') {
      contentType = 'application/csv'
    } else {
      contentType = headers['content-type']
    }

    const stuNumStrNm = isEmpty(request?.studentNumber) ? '' : request?.studentNumber + '_'
    const fileName =  stuNumStrNm +request.fileLoc?.split('/').pop() 
    this.downloadFile(data, fileName, contentType)
  }

  async getMergedParentLetters(state: RootState): Promise<ParentLetterMergeResponse> {
    const { data } = await this.post<ParentLetterMergeResponse>(`${this.baseUri}/downloadMergedExitingParentLetters`, { state }, { responseType: 'blob' })
    if (typeof data === 'object' && 'success' in data && 'message' in data) {
      return data
    } else {
      this.downloadFile(data, 'PotentialExitingParentLetters.pdf', 'application/pdf')
    }
  }

}
