import {MutationTree} from 'vuex';
import {LayoutState} from '@/store/modules/layout/state';

export enum LayoutMutations {
  ToggleSwitchProducts = 'TOGGLE_SWITCH_PRODUCTS',
  CloseSwitchProducts = 'CLOSE_SWITCH_PRODUCTS'
}

export const mutations: MutationTree<LayoutState> = {

  [LayoutMutations.ToggleSwitchProducts](state) {
    state.switchProductsExpanded = !state.switchProductsExpanded
  },

  [LayoutMutations.CloseSwitchProducts](state) {
    state.switchProductsExpanded = false
  },

}
