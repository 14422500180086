import { uniqueId } from '@806/utils'
import { KeyedNotification, TypedNotification, Notification, NotificationType } from './types'

export const createKeyedNotification = ({ type, message, title }: TypedNotification): KeyedNotification => {
  const id = uniqueId('notification')

  return { id, type, message, title }
}

export const successNotification = ({ message, title }: Notification): KeyedNotification => {
  const type = NotificationType.Success

  return createKeyedNotification({ type, message, title })
}

export const infoNotification = ({ message, title }: Notification): KeyedNotification => {
  const type = NotificationType.Info

  return createKeyedNotification({ type, message, title })
}

export const warningNotification = ({ message, title }: Notification): KeyedNotification => {
  const type = NotificationType.Warning

  return createKeyedNotification({ type, message, title })
}
