import cloneDeep from 'lodash/cloneDeep'
import { ELDistrict, ELUserRole, Term, FeatureToggle, Grade, MeetingReviewAddress, Dimensions, ELSchoolYear, DistrictLanguage } from 'models'
import {Selected, KeyedNotification, SupportInfo, ProductInfo} from './types'

export interface RootState {
  notifications: KeyedNotification[]
  selected: Selected
  selectedChanging: boolean
  districtSchools: ELDistrict[]
  schoolRole: ELUserRole
  terms: Term[]
  dimensions: Dimensions
  features: FeatureToggle[]
  menuCollapsed: boolean
  meetingReviewAddresses: MeetingReviewAddress[]
  grades: Grade[]
  helloSignClientId: string
  helloSignSkipDomainVerification: boolean,
  currentSchoolYear: ELSchoolYear,
  schoolYears: ELSchoolYear[],
  districtLanguages: DistrictLanguage[],
  olarkStatus: boolean,
  freshchatStatus: boolean,
  zendeskStatus: boolean,
  hellosignStatus: boolean,
  support: SupportInfo,
  productInfo: ProductInfo
}

export const state: RootState = {
  notifications: [],
  selected: null,
  selectedChanging: false,
  districtSchools: [],
  schoolRole: null,
  terms: [],
  dimensions: {},
  features: [],
  menuCollapsed: false,
  meetingReviewAddresses: [],
  grades: [],
  helloSignClientId: null,
  helloSignSkipDomainVerification: false,
  currentSchoolYear: {},
  schoolYears: [],
  districtLanguages: [],
  olarkStatus: true,
  freshchatStatus: false,
  zendeskStatus: false,
  hellosignStatus: true,
  support: {
    email: process.env.SUPPORT_EMAIL,
    phone: process.env.SUPPORT_PHONE,
  },
  productInfo: {}
}

export const clearState: RootState = cloneDeep(state)
