import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { UserSetupState } from './state'
import { ELSchool, ELUserRole, ELUser } from 'models'

export enum UserSetupGetters {
  DistrictSchools = 'DISTRICT_SCHOOLS',
  Roles = 'ROLES',
  DistrictUsers = 'DISTRICT_USERS',
  RoleOptions = 'ROLE_OPTIONS'
}

export const getters: GetterTree<UserSetupState, RootState> = {

  [UserSetupGetters.DistrictSchools](state): ELSchool[] {
    return state.districtSchools
  },

  [UserSetupGetters.DistrictUsers](state): ELUser[] {
    return state.users
  },

  [UserSetupGetters.Roles](state): ELUserRole[] {
    return state.roles
  },

  [UserSetupGetters.RoleOptions](state): { name: string, value: number }[] {
    return state.roleOptions
  }

}
