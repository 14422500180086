export enum AccommodationsSigningStatus {
	Requested = 'REQUESTED',
	NotStarted = 'NOT_STARTED',
	InProgress = 'IN_PROGRESS',
	Signed = 'SIGNED',
	Complete = 'COMPLETE',
}
export enum CustomFilterComponent {
	AddStudentsToMeeting = 'ADD_STUDENTS_TO_MEETING',
}
export enum FeatureCode {
	AdditionalRoles = 'ADDITIONAL_ROLES',
}
export enum IndicatorType {
	TeacherDenial = 'TEACHER_DENIAL',
	TeacherApproval = 'TEACHER_APPROVAL',
	RstStaIn = 'RST_STA_IN',
}
export enum LimitedEnglishProficiencyCode {
	Lep = 'LEP',
	M1 = 'M1',
	M2 = 'M2',
	M3 = 'M3',
	M4 = 'M4',
	M5 = 'M5',
	NotLep = 'NOT_LEP',
	NpButRecBilEsl = 'NP_BUT_REC_BIL_ESL',
	ContinueEl = 'CONTINUE_EL',
	M1Pending = 'M1_PENDING',
}
export enum MeetingFormTypeCode {
	StudentIdentificationForm = 'STUDENT_IDENTIFICATION_FORM',
	StaarParticipationAndAccommodationForm = 'STAAR_PARTICIPATION_AND_ACCOMMODATION_FORM',
	TelpasDecisionsForm = 'TELPAS_DECISIONS_FORM',
	AnnualReviewMonitorForm = 'ANNUAL_REVIEW_MONITOR_FORM',
	AnnualReviewLepForm = 'ANNUAL_REVIEW_LEP_FORM',
	AnnualReviewLepLasLinkForm = 'ANNUAL_REVIEW_LEP_LAS_LINK_FORM',
	MeetingMinutesForm = 'MEETING_MINUTES_FORM',
	MeetingRosterForm = 'MEETING_ROSTER_FORM',
	ParentPermissionAnnualEl = 'PARENT_PERMISSION_ANNUAL_EL',
	ParentPermissionAnnualMonitor = 'PARENT_PERMISSION_ANNUAL_MONITOR',
	ParentIdentificationBil = 'PARENT_IDENTIFICATION_BIL',
	ParentIdentificationEsl = 'PARENT_IDENTIFICATION_ESL',
	ReviewLpacForm = 'REVIEW_LPAC_FORM',
	ParentPermissionPotentialExitAnnualEl = 'PARENT_PERMISSION_POTENTIAL_EXIT_ANNUAL_EL',
}
export enum MeetingMemberType {
	Df = 'DF',
	Ro = 'RO',
}
export enum MeetingRosterStatus {
	Ready = 'READY',
	Complete = 'COMPLETE',
}
export enum MeetingStatus {
	O = 'O',
	P = 'P',
	F = 'F',
}
export enum MeetingTypeCode {
	Identification = 'IDENTIFICATION',
	StaarDecisions = 'STAAR_DECISIONS',
	TelpasDecisions = 'TELPAS_DECISIONS',
	AnnualReviewMonitor = 'ANNUAL_REVIEW_MONITOR',
	AnnualReviewLep = 'ANNUAL_REVIEW_LEP',
	ReviewLpac = 'REVIEW_LPAC',
	Og = 'OG',
	AnyType = 'ANY_TYPE',
	AnyTypeAll = 'ANY_TYPE_ALL',
}
export enum MeetingTypeStatusCode {
	Justifications = 'JUSTIFICATIONS',
	Accommodations = 'ACCOMMODATIONS',
	TeacherHasSigned = 'TEACHER_HAS_SIGNED',
	HasSigned = 'HAS_SIGNED',
	EnteringTestData = 'ENTERING_TEST_DATA',
	TelpasJustificationsComplete = 'TELPAS_JUSTIFICATIONS_COMPLETE',
	TestDataComplete = 'TEST_DATA_COMPLETE',
	RecommendationsComplete = 'RECOMMENDATIONS_COMPLETE',
	ReviewComplete = 'REVIEW_COMPLETE',
	ReadyForSigning = 'READY_FOR_SIGNING',
	SelectStudents = 'SELECT_STUDENTS',
}
export enum PreferenceType {
	DisableParentDecisions = 'DISABLE_PARENT_DECISIONS',
	DashboardType = 'DASHBOARD_TYPE',
	MeetingAttendeeUpload = 'MEETING_ATTENDEE_UPLOAD',
	SigningBatchSize = 'SIGNING_BATCH_SIZE',
	CovidActive = 'COVID_ACTIVE',
	PrepareForMeetingV2 = 'PREPARE_FOR_MEETING_V2',
	IdentificationReviewV2 = 'IDENTIFICATION_REVIEW_V2',
	IdReviewStudentsV2 = 'ID_REVIEW_STUDENTS_V2',
	ShowCls = 'SHOW_CLS',
	UseMultiMeetings = 'USE_MULTI_MEETINGS',
	UsePeims = 'USE_PEIMS',
	UseReportsService = 'USE_REPORTS_SERVICE',
	DualLanguageImmersion = 'DUAL_LANGUAGE_IMMERSION',
	ShowStudentSearchToggle = 'SHOW_STUDENT_SEARCH_TOGGLE',
	MeetingTypeLimit = 'MEETING_TYPE_LIMIT',
}
export enum ProficiencyCategoryType {
	Writing = 'WRITING',
	Listening = 'LISTENING',
	Speaking = 'SPEAKING',
	Reading = 'READING',
	OralLanguage = 'ORAL_LANGUAGE',
}
export enum ProficiencyTestTypeCode {
	NonLasLink = 'NON_LAS_LINK',
	LasLink = 'LAS_LINK',
}
export enum ProgramTypeAttributeCode {
	DualLanguage = 'DUAL_LANGUAGE',
}
export enum ProgramTypeCode {
	A = 'A',
	B = 'B',
	E = 'E',
	M = 'M',
	L = 'L',
	D = 'D',
}
export enum RoleType {
	PowerUser = 'POWER_USER',
	DistrictPowerUser = 'DISTRICT_POWER_USER',
	AccommodationsUser = 'ACCOMMODATIONS_USER',
	SuperUser = 'SUPER_USER',
}
export enum SchoolTypeCode {
	C = 'C',
	D = 'D',
	E = 'E',
	H = 'H',
	M = 'M',
	O = 'O',
}
export enum SortType {
	StudentSchoolGrade = 'STUDENT_SCHOOL_GRADE',
	Student = 'STUDENT',
	Grade = 'GRADE',
	HomeLanguage = 'HOME_LANGUAGE',
	StudentLanguage = 'STUDENT_LANGUAGE',
	MeetingType = 'MEETING_TYPE',
	ProgramType = 'PROGRAM_TYPE',
	LepStatus = 'LEP_STATUS',
	ParentDecision = 'PARENT_DECISION',
	Meeting = 'MEETING',
	SchoolName = 'SCHOOL_NAME',
	Campus = 'CAMPUS',
	TeacherNumber = 'TEACHER_NUMBER',
	TeacherName = 'TEACHER_NAME',
	StudentDocument = 'STUDENT_DOCUMENT',
	DocumentType = 'DOCUMENT_TYPE',
	User = 'USER',
	SystemRole = 'SYSTEM_ROLE',
	UserTitle = 'USER_TITLE',
	SignatureLink = 'SIGNATURE_LINK',
	StaffMeeting = 'STAFF_MEETING',
	Assessment = 'ASSESSMENT',
	Subject = 'SUBJECT',
	SchoolType = 'SCHOOL_TYPE',
	Staff = 'STAFF',
	OptionsCount = 'OPTIONS_COUNT',
}
export enum TermCode {
	Committee = 'COMMITTEE',
	Committees = 'COMMITTEES',
}
export enum ZipFileStatus {
	None = 'NONE',
	Pending = 'PENDING',
	Completed = 'COMPLETED',
}
export enum ELPermissionCategory {
	CompletedMeetingCampus = 'COMPLETED_MEETING_CAMPUS',
	AllStudentsCampus = 'ALL_STUDENTS_CAMPUS',
	ViewStudentMeetingCampus = 'VIEW_STUDENT_MEETING_CAMPUS',
	RetrieveDistrictSchools = 'RETRIEVE_DISTRICT_SCHOOLS',
	ViewStandardMenus = 'VIEW_STANDARD_MENUS',
	ViewAccommodationsMenu = 'VIEW_ACCOMMODATIONS_MENU',
	ViewAllAccommodations = 'VIEW_ALL_ACCOMMODATIONS',
	ViewOwnAccommodationsOnly = 'VIEW_OWN_ACCOMMODATIONS_ONLY',
	UpdateReportsSchoolsDropdown = 'UPDATE_REPORTS_SCHOOLS_DROPDOWN',
	ViewReportsSchoolsDropdown = 'VIEW_REPORTS_SCHOOLS_DROPDOWN',
	UpdateReportsStaffDropdown = 'UPDATE_REPORTS_STAFF_DROPDOWN',
	ViewReportsStaffDropdown = 'VIEW_REPORTS_STAFF_DROPDOWN',
	ViewSwaggerUi = 'VIEW_SWAGGER_UI',
}
export enum ELPermissionType {
	Create = 'CREATE',
	Read = 'READ',
	Update = 'UPDATE',
	Delete = 'DELETE',
}
export enum StaarAttributeLevel {
	ApproachesGradeLevel = 'Approaches_Grade_Level',
	MeetsGradeLevel = 'Meets_Grade_Level',
	MastersGradeLevel = 'Masters_Grade_Level',
	ReadApproachesLevel = 'Read_Approaches_Level',
	ReadMeetsLevel = 'Read_Meets_Level',
	ReadMastersLevel = 'Read_Masters_Level',
}
export enum TELPASRating {
	NoRating = 'No_Rating',
	Beginner = 'Beginner',
	Intermediate = 'Intermediate',
	Advanced = 'Advanced',
	AdvancedHigh = 'Advanced_High',
}
export enum EnrollmentTypeCode {
	InitialEnrollment = 'INITIAL_ENROLLMENT',
	TransferEnrollment = 'TRANSFER_ENROLLMENT',
	Review = 'REVIEW',
}
export enum PreferenceType {
	Userlistview = 'USERLISTVIEW',
	Dashboardlistview = 'DASHBOARDLISTVIEW',
	Reviewlistview = 'REVIEWLISTVIEW',
	Districtreviewstatus = 'DISTRICTREVIEWSTATUS',
	AutoAcceptCmprsPdf = 'AUTO_ACCEPT_CMPRS_PDF',
	WopiEditEnabled = 'WOPI_EDIT_ENABLED',
	Districtweeklysnapshot = 'DISTRICTWEEKLYSNAPSHOT',
	Histloglistview = 'HISTLOGLISTVIEW',
	Hideitemsassignedtodistrict = 'HIDEITEMSASSIGNEDTODISTRICT',
	MiniCrate = 'MINI_CRATE',
	Lockpreviousschoolyear = 'LOCKPREVIOUSSCHOOLYEAR',
	HideTitleOneComponents = 'HIDE_TITLE_ONE_COMPONENTS',
	CustomNumberingLevel1 = 'CUSTOM_NUMBERING_LEVEL1',
	CustomNumberingLevel2 = 'CUSTOM_NUMBERING_LEVEL2',
	CustomNumberingLevel3 = 'CUSTOM_NUMBERING_LEVEL3',
	CustomNumberingLevel4 = 'CUSTOM_NUMBERING_LEVEL4',
	CustomNumberingLevel5 = 'CUSTOM_NUMBERING_LEVEL5',
	CustomNumberingLevel1Prefix = 'CUSTOM_NUMBERING_LEVEL1_PREFIX',
	CustomNumberingLevel2Prefix = 'CUSTOM_NUMBERING_LEVEL2_PREFIX',
	CustomNumberingLevel3Prefix = 'CUSTOM_NUMBERING_LEVEL3_PREFIX',
	CustomNumberingLevel4Prefix = 'CUSTOM_NUMBERING_LEVEL4_PREFIX',
	CustomNumberingLevel5Prefix = 'CUSTOM_NUMBERING_LEVEL5_PREFIX',
	CustomNumberingLevel1Suffix = 'CUSTOM_NUMBERING_LEVEL1_SUFFIX',
	CustomNumberingLevel2Suffix = 'CUSTOM_NUMBERING_LEVEL2_SUFFIX',
	CustomNumberingLevel3Suffix = 'CUSTOM_NUMBERING_LEVEL3_SUFFIX',
	CustomNumberingLevel4Suffix = 'CUSTOM_NUMBERING_LEVEL4_SUFFIX',
	CustomNumberingLevel5Suffix = 'CUSTOM_NUMBERING_LEVEL5_SUFFIX',
	DistrictReviewBy = 'DISTRICT_REVIEW_BY',
	WeeklySnapshotEmailPeriod = 'WEEKLY_SNAPSHOT_EMAIL_PERIOD',
	RejectRewordingPresent = 'REJECT_REWORDING_PRESENT',
	RejectRewordingPast = 'REJECT_REWORDING_PAST',
	MasterCrate = 'MASTER_CRATE',
	MasterCrateState = 'MASTER_CRATE_STATE',
	CpuNoUsrSetup = 'CPU_NO_USR_SETUP',
	ReviewAddFile = 'REVIEW_ADD_FILE',
	LayoutCollapsed = 'LAYOUT_COLLAPSED',
	CrateitNotifSeen = 'CRATEIT_NOTIF_SEEN',
	ShowCreateHiddenFolders = 'SHOW_CREATE_HIDDEN_FOLDERS',
	WeeklySnapshotAlert = 'WEEKLY_SNAPSHOT_ALERT',
	WeeklyAwaitingReviewAlert = 'WEEKLY_AWAITING_REVIEW_ALERT',
	WeeklyOverdueFilesAlert = 'WEEKLY_OVERDUE_FILES_ALERT',
	LeaMessagesAlert = 'LEA_MESSAGES_ALERT',
	OverdueFilesThreshold = 'OVERDUE_FILES_THRESHOLD',
	AcceptedFileAlert = 'ACCEPTED_FILE_ALERT',
	RejectedFileAlert = 'REJECTED_FILE_ALERT',
	AcceptedFilesSummaryAlert = 'ACCEPTED_FILES_SUMMARY_ALERT',
	SignatureRequiredAlert = 'SIGNATURE_REQUIRED_ALERT',
	MessagesRecipient = 'MESSAGES_RECIPIENT',
	MarkAsReadDelayMessages = 'MARK_AS_READ_DELAY_MESSAGES',
	ProdFeatureFlagAccess = 'PROD_FEATURE_FLAG_ACCESS',
	UploadFilter = 'UPLOAD_FILTER',
	ProfDevEmailIds = 'PROF_DEV_EMAIL_IDS',
	MessagesSummaryAlert = 'MESSAGES_SUMMARY_ALERT',
	AllowCustMinDocs = 'ALLOW_CUST_MIN_DOCS',
	EoySrvyStartDate = 'EOY_SRVY_START_DATE',
	EoySrvyEndDate = 'EOY_SRVY_END_DATE',
	FolderMessages = 'FOLDER_MESSAGES',
	IntroWalkthrough = 'INTRO_WALKTHROUGH',
	CustomVideos = 'CUSTOM_VIDEOS',
	CustomLinks = 'CUSTOM_LINKS',
	HasLateAndReplaced = 'HAS_LATE_AND_REPLACED',
	HasSchoolReps = 'HAS_SCHOOL_REPS',
	HasPendingAdditionalDetails = 'HAS_PENDING_ADDITIONAL_DETAILS',
	HasNotSubmitted = 'HAS_NOT_SUBMITTED',
	HasSummaryColumn = 'HAS_SUMMARY_COLUMN',
	DisableSaveUserButton = 'DISABLE_SAVE_USER_BUTTON',
	PauseNotifications = 'PAUSE_NOTIFICATIONS',
	FileUploadLimit = 'FILE_UPLOAD_LIMIT',
	DisableAcceptedFileReplace = 'DISABLE_ACCEPTED_FILE_REPLACE',
	HideFileRemoval = 'HIDE_FILE_REMOVAL',
	WklyCmpSnpshtEmlFmt = 'WKLY_CMP_SNPSHT_EML_FMT',
	OntimeAcceptedRejected = 'ONTIME_ACCEPTED_REJECTED',
	StCrt = 'ST_CRT',
	FerpaAgreement = 'FERPA_AGREEMENT',
	SchoolTerm = 'SCHOOL_TERM',
	StLeaMsgTab = 'ST_LEA_MSG_TAB',
	StLeaMsgLink = 'ST_LEA_MSG_LINK',
}
export enum ProductCode {
	P4L = 'P4L',
	Ir = 'IR',
	T1C = 'T1C',
	Isb = 'ISB',
	El = 'EL',
	JobDaemon = 'JOB_DAEMON',
	Svc = 'SVC',
	Cl = 'CL',
	Bgtsvc = 'BGTSVC',
	Html = 'HTML',
	Rpt = 'RPT',
	Analytics = 'ANALYTICS',
}
export enum ProductLogoType {
	Icon = 'ICON',
	Title = 'TITLE',
	Splash = 'SPLASH',
}
export enum ProductPageType {
	Main = 'MAIN',
	Logout = 'LOGOUT',
}
export enum TEALUserRole {
	Admin = 'Admin',
	Escuser = 'EscUser',
	Campususer = 'CampusUser',
	Districtuser = 'DistrictUser',
	Externaluser = 'ExternalUser',
	Simanager = 'SIManager',
	Sireviewer = 'SIReviewer',
	Ssauser = 'SSAUser',
	Nonpublicuser = 'NonpublicUser',
}
export enum TEALUserRoleGroup {
	Tea = 'TEA',
	Esc = 'ESC',
}
export enum ExternalToggle {
	Olark = 'OLARK',
	Freshdesk = 'FRESHDESK',
	Tinymce = 'TINYMCE',
	Hellosign = 'HELLOSIGN',
	Zendesk = 'ZENDESK',
}
export enum PushNotificationAction {
	Show = 'SHOW',
}
