import { MutationTree } from 'vuex'
import { Meeting, MeetingRoster } from 'models'
import { ParentDecisionState, clearState } from './state'
import { ParentDecisionEdits } from './types'
import cloneDeep from 'lodash/cloneDeep'

export enum ParentDecisionMutations {
  Clear = 'CLEAR',
  SetEdits = 'SET_EDITS',
  SetMeeting = 'SET_MEETING',
  SetRoster = 'SET_ROSTER',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<ParentDecisionState> = {

  [ParentDecisionMutations.Clear](state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [ParentDecisionMutations.SetEdits](state, edits: ParentDecisionEdits) {
    state.edits = edits
  },

  [ParentDecisionMutations.SetMeeting](state, meeting: Meeting) {
    state.meeting = meeting
  },

  [ParentDecisionMutations.SetRoster](state, roster: MeetingRoster) {
    state.roster = roster
  },

  // Only use this mutation for setting the state for test cases
  [ParentDecisionMutations.SetTestState] (state, testData: ParentDecisionState) {
    Object.assign(state, cloneDeep(testData))
  }

}
