import {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestTransformer,
  AxiosRequestConfig
} from 'axios'
import { RootState } from '@/store/root'
import { ErrorCode, ErrorHolder } from '@/util/errors'
import { Optional } from 'typescript-optional'
import HttpStatus from 'http-status-codes'
import compact from 'lodash/compact'
import concat from 'lodash/concat'
import defaults from 'lodash/defaults'
import { SelectedHeaders } from '@/util/SelectedHeaders'
import {isNil} from 'lodash';

interface Request { state?: RootState, request? }

export interface CSRF {
  token: string
  clear(): void
}

export interface ServiceConfig {
  http: AxiosInstance
  csrf?: CSRF
}

const responseErrorCode = <R>(error: AxiosError<R>): ErrorCode => {
  switch (error?.response?.status) {
    case HttpStatus.UNAUTHORIZED:
      return ErrorCode.NotAuthError

    case HttpStatus.NOT_FOUND:
      return ErrorCode.NotFoundError

    default:
      return ErrorCode.ServiceError
  }
}

export default class BaseService {
  private api: AxiosInstance
  private csrf: Optional<CSRF>
  private csrfHeader = 'X-XSRF-TOKEN'

  constructor({ http, csrf }: ServiceConfig) {
    this.api = http
    this.csrf = Optional.ofNullable(csrf)
  }

  public downloadFile(data, filename: string, contentType: string) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const ms = (window.navigator as any)?.msSaveOrOpenBlob

    if (ms) {
      ms(new Blob([data]), filename)
    } else if (navigator.userAgent.indexOf('CriOS') > -1) {
      const reader = new FileReader()
      const out = new Blob([data], { type: contentType })
      reader.onload = function() {
        window.open(String(reader.result))
      }
      reader.readAsDataURL(out)
    } else {
      const url = window.URL.createObjectURL(new Blob([data], { type: contentType }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      link.parentElement.removeChild(link)
    }
  }

  /**
   * POST http event used for creating new entities.
   *
   * @param url URL to post to
   * @param data POST data - Rootstate & JSON Body
   * @param config Additional Axios Request Configuration
   */
  protected async post<R>(url: string, data: Request = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<R>> {
    return this.handleError(async () => {
      const transformRequest: AxiosRequestTransformer[] = this.getAxiosTransformer(data.state, config)
      return this.processResponse(await this.api.post<R>(url, data.request, defaults({ transformRequest }, config)))
    })
  }

  /**
   * GET http event used for retrieving data.
   *
   * @param url URL of resource to get
   * @param state Request data - Rootstate used for access to root state for user information. No JSON Body for GET Request.
   * @param config Additional Axios Request Configuration
   */
  protected async get<R>(url: string, state: RootState, config: AxiosRequestConfig = {}): Promise<AxiosResponse<R>> {
    /* Use the params object on AxiosRequestConfig to determine if any required url path variables are null.
     * If any path variables are null, GET request is canceled, since most likely scenario is user
     * abandoning page
     */
    if (config.params && Object.values(config.params).some(param => isNil(param))) {
      const controller: AbortController = new AbortController()
      config.signal = controller.signal
      controller.abort()
    }
    /* Clears the params so we don't send as query parameters to server. Path variables are used on GET requests */
    config.params = null
    return this.handleError(async () => {
      const transformRequest: AxiosRequestTransformer[] = this.getAxiosTransformer(state, config)
      return this.processResponse(await this.api.get<R>(url, defaults({ transformRequest }, config)))
    })
  }

  /**
   * PUT http request used for updating an ENTIRE entity
   *
   * @param url URL to issue put request to
   * @param data PUT data - Rootstate & JSON Body
   * @param config Additional Axios Request Configuration
   */
  protected async put<R>(url: string, data: Request = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<R>> {
    return this.handleError(async () => {
      const transformRequest: AxiosRequestTransformer[] = this.getAxiosTransformer(data.state, config)
      return this.processResponse(await this.api.put<R>(url, data.request, defaults({ transformRequest }, config)))
    })
  }

  /**
   * PATCH http request used for updating part of an entity
   *
   * @param url URL to issue put request to
   * @param data PUT data - Rootstate & JSON Body
   * @param config Additional Axios Request Configuration
   */
  protected async patch<R>(url: string, data: Request = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<R>> {
    return this.handleError(async () => {
      const transformRequest: AxiosRequestTransformer[] = this.getAxiosTransformer(data.state, config)
      return this.processResponse(await this.api.patch<R>(url, data.request, defaults({ transformRequest }, config)))
    })
  }

  /**
   * DELETE http request used for deleting an entity
   *
   * @param url URL to issue delete request to
   * @param state Request object - Rootstate used for access to root state for user information. No JSON request object for deletes.
   * @param config Additional Axios Request Configuration
   */
  protected async delete<R>(url: string, state: RootState, config: AxiosRequestConfig = {}): Promise<AxiosResponse<R>> {
    return this.handleError(async () => {
      const transformRequest: AxiosRequestTransformer[] = this.getAxiosTransformer(state, config)
      return this.processResponse(await this.api.delete<R>(url, defaults({ transformRequest }, config)))
    })
  }

  /**
   * Helper method to process the servers response after a http call.
   *
   * @param response Response from server
   */
  private processResponse<R>(response: AxiosResponse<R>) {
    Optional
      .ofNullable(response.headers[this.csrfHeader.toLowerCase()])
      .ifPresent(token => {
        this.csrf.ifPresent(csrf => csrf.token = token)
      })

    return response
  }

  protected clearCsrf() {
    this.csrf.ifPresent(csrf => csrf.clear())
  }

  protected errorResponse(error: Error): Optional<AxiosResponse> {
    return Optional.ofNullable(ErrorHolder.unwrap(error) as AxiosError).map(e => e.response)
  }

  protected errorStatus(error: Error): Optional<number> {
    return this.errorResponse(error).map(e => e.status)
  }

  protected isErrorStatus(error: Error, ...statuses: number[]): boolean {
    return this.errorStatus(error).map(status => statuses.includes(status)).orElse(false)
  }

  protected errorMessage(error: Error): Optional<string> {
    return this.errorResponse(error).flatMap(e => Optional.ofNullable(e.data.message))
  }

  /**
   * Wrapper function for handling any server errors.
   *
   * @param f Async function call to the server.
   */
  private async handleError<R>(f: () => Promise<AxiosResponse<R>>): Promise<AxiosResponse<R>> {
    try {
      return await f()
    } catch (error) {
      if (ErrorHolder.isCode(error, ErrorCode.RuntimeError)) {
        throw error
      }

      const httpError = error as AxiosError

      if (httpError.response) {
        throw new ErrorHolder(error, responseErrorCode(error))
      } else if (httpError.request) {
        // no request made but no response received
        throw new ErrorHolder(error, ErrorCode.NetworkError)
      }

      throw error
    }
  }

  /**
   * Returns the AxiosRequestTransformer needed for each request.
   * @param data Request object
   * @param config Additional Axios Config that might have been passed into the service method.
   */
  private getAxiosTransformer(state: RootState, config: AxiosRequestConfig): AxiosRequestTransformer[] {
    return compact(concat(this.buildTransformer(state), config.transformRequest))
  }

  /**
   * Helper method to configure request headers.
   * @param state RootState
   */
  private buildTransformer(state?: RootState): AxiosRequestTransformer {
    return (request, headers) => {
      if (state) {
        if (state.selected) {
          Object.assign(headers, {
            [SelectedHeaders.District]: state.selected.districtId,
            [SelectedHeaders.School]: state.selected.schoolId,
            [SelectedHeaders.LookupCode]: state.selected.districtLookupCode
          })
        } else {
          const error = new Error('Provided state is missing required fields.')

          throw new ErrorHolder(error, ErrorCode.RuntimeError)
        }
      }

      this.csrf
        .map(({ token }) => token)
        .ifPresent(token => {
          headers[this.csrfHeader] = token
        })

      headers['Content-Type'] = 'application/json'

      return request ? JSON.stringify(request) : request
    }
  }

}
