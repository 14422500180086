import { MutationTree } from 'vuex'
import Vue from 'vue'
import { EOYLasLinksState, clearState } from './state'
import {
    Meeting,
    Pagination,
    StudentSchoolGrade,
    StudentPaging,
    ProficiencyTestResults
  } from 'models'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'

export enum EOYLasLinksMutations {
  Clear = 'CLEAR',
  SetMeeting = 'SET_MEETING',
  SetStudents = 'SET_STUDENTS',
  SetPaging = 'SET_PAGING',
  SetProficiencyEditsForStudent = 'SET_PROFICIENCY_EDITS_FOR_STUDENT',
  SetIdentificationAssessmentDecisions = 'SET_IDENTIFICATION_ASSESSMENT_DECISIONS',
  RemoveTestResultsEntryFromMap = 'REMOVE_TEST_RESULTS_ENTRY_FROM_MAP',
  ResetMeetingStudentInRoster = 'RESET_MEETING_STUDENT_IN_ROSTER',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<EOYLasLinksState> = {
  [EOYLasLinksMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [EOYLasLinksMutations.SetMeeting] (state, meeting: Meeting) {
    state.meeting = meeting
  },

  [EOYLasLinksMutations.SetStudents] (state, { students, pagination }: {
    students: StudentSchoolGrade[]
    pagination: Pagination
  }) {
    state.students = students
    state.pagination = pagination
  },

  [EOYLasLinksMutations.SetPaging](state, paging: StudentPaging) {
    state.paging = paging
  },
  [EOYLasLinksMutations.SetProficiencyEditsForStudent](state, { id, proficiencyTestResults }) {
    if (state.saveProficiencyEditsMap.has(id)) {
      const currentEntryIndex = state.saveProficiencyEditsMap.get(id).findIndex(currentProfEntry => currentProfEntry.languageId === proficiencyTestResults.languageId)
      if (currentEntryIndex > -1) {
        const updatedProfArr = state.saveProficiencyEditsMap.get(id)
        updatedProfArr[currentEntryIndex] = proficiencyTestResults
        state.saveProficiencyEditsMap.set(id, updatedProfArr)
      } else {
        state.saveProficiencyEditsMap.get(id).push(proficiencyTestResults)
      }
    } else {
      const newProfArr: ProficiencyTestResults[] = [proficiencyTestResults]
      state.saveProficiencyEditsMap.set(id, newProfArr)
    }
  },
  [EOYLasLinksMutations.RemoveTestResultsEntryFromMap](state, id: number) {
    state.saveProficiencyEditsMap.delete(id)
  },
  [EOYLasLinksMutations.ResetMeetingStudentInRoster](state, { roster }) {
    const currentMeetingStudents = state.students
    const rstIndex = state.students.findIndex(rst => rst.id === roster.id)
    if (!isNil(rstIndex)) {
      state.students[rstIndex] = roster
      Vue.set(state.students, 'students', currentMeetingStudents)
    }
  },
  // Only use this mutation for setting the state for test cases
  [EOYLasLinksMutations.SetTestState] (state, testData: EOYLasLinksState) {
    Object.assign(state, cloneDeep(testData))
  }
}
