import Tracker from '@openreplay/tracker'

export const tracker = new Tracker({
    projectKey: process.env.OPEN_REPLAY_KEY,
    ingestPoint: process.env.OPEN_REPLAY_URL,
    obscureInputEmails: false,
    obscureTextEmails: false,
    obscureTextNumbers: false,
    obscureInputNumbers: false,
    defaultInputMode: 0
    // network: {
    //   ignoreHeaders: ['Authorization', 'X-XSRF-TOKEN', 'X-Request-ID', 'x-session-token', 'csrfId'],
    //   sessionTokenHeader: 'x-session-token',
    //   failuresOnly: false,
    //   capturePayload: true,
    //   captureInIframes: true,
    // }
})
