import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { OverviewMeetingState } from './state'

export enum OverviewMeetingGetters {

}

export const getters: GetterTree<OverviewMeetingState, RootState> = {

}
