import * as Bowser from 'bowser'
import { BrowserInfo } from 'models'

const bowser = Bowser.getParser(window.navigator.userAgent)

export const browser: BrowserInfo = {
  name: bowser.getBrowserName(),
  version: bowser.getBrowserVersion(),
  os: bowser.getOSName()
}
