import { ContentLanguageControlOptions, MeetingRoster, ProficiencyTestResults, DualLanguageSelection, MeetingPlacement } from 'models'
import { TestEdits, RecommendationEdits } from './types'
import {IndicatorType} from '@/enums'

export interface ReviewStudentState {
  loading: boolean
  roster: MeetingRoster
  testEdits: TestEdits,
  proficiencyTestResultEdits: ProficiencyTestResults[],
  recommendationEdits: RecommendationEdits,
  monitorRecommendiationEdits: RecommendationEdits,
  teacherDenial: boolean,
  readiness: IndicatorType,
  supportOptions: ContentLanguageControlOptions[],
  dualLanguage: DualLanguageSelection,
  transferOptIds: Set<number>,
  userHasChangedCLS: boolean,
  priorMeetingPlacement: MeetingPlacement,
  notes: string
}

export const state: () => ReviewStudentState = () => ({
  loading: false,
  roster: null,
  testEdits: {
    olpt: {},
    aapt: {},
    olptEmpty: false,
    studentOlptEmpty: false,
    aaptEmpty: false
  },
  proficiencyTestResultEdits: [],
  recommendationEdits: {
    programType: null,
    lepStatus: null
  },
  monitorRecommendiationEdits: {
    programType: null,
    lepStatus: null
  },
  teacherDenial: null,
  readiness: null,
  supportOptions: [],
  dualLanguage: {
    year: null,
    language: null
  },
  transferOptIds: new Set<number>(),
  userHasChangedCLS: false,
  priorMeetingPlacement: {},
  notes: null
})
