import cloneDeep from 'lodash/cloneDeep'
import {
  Meeting,
  Pagination,
  MeetingRoster,
  StudentPaging,
  StudentDocument
} from 'models'
import { PagingUtil, PerPageSize } from '@806/utils'

export interface CompletedMeetingState {
  meeting: Meeting
  students: MeetingRoster[]
  pagination: Pagination
  paging: StudentPaging
  meetingMemberScreenshotUploaded: boolean,
  parentLettersUploaded: boolean,
  generatingParentLetters: boolean,
  documents: StudentDocument[]
}

export const state: CompletedMeetingState = {
  meeting: {},
  students: [],
  pagination: {},
  paging: PagingUtil.initialPaging(PerPageSize.Small),
  meetingMemberScreenshotUploaded: false,
  parentLettersUploaded: false,
  generatingParentLetters: false,
  documents: []
}

export const clearState: CompletedMeetingState = cloneDeep(state)
