import { RootState } from '@/store/root'
import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { ContactUsState, state } from './state'

export { ContactUsState } from './state'
export { ContactUsGetters } from './getters'
export { ContactUsMutations } from './mutations'
export { ContactUsActions } from './actions'

export const store: Module<ContactUsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
