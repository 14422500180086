import { ErrorCode, ErrorHolder } from '@/util/errors'
import HttpStatus from 'http-status-codes'
import {
  ELUser,
  User,
  UserAuthenticationRequest,
  UserAuthenticationResponse,
  PasswordResetRequest,
  PasswordResetResponse,
  UserResponse,
  ELDistrict,
  DistrictsResponse,
  ELUserRole,
  RoleResponse,
  InsertUserRequest,
  InsertUserResponse,
  UsersRequest,
  PagedUsersResponse,
  ELUserEdit,
  UserEditRequest,
  TeacherLinkRequest,
  TeacherLinkResponse,
  NewTeacherRequest,
  NewTeacherResponse,
  MeetingMemberAssignmentResponse
} from 'models'
import { RootState } from '@/store/root'
import BaseService, {ServiceConfig} from './base'

export default class AuthService extends BaseService {

  constructor (config: ServiceConfig) {
    super(config)
  }

  async login (user: User): Promise<User> {
    try {
      const request: UserAuthenticationRequest = { user }
      const { data: encrypted } = await this.post<UserAuthenticationRequest>('/users/encryptAuth', { request })
      const { data: result } = await this.post<UserAuthenticationResponse>('/login', { request: encrypted })

      if (!result.success) {
        throw new Error(result.message)
      }

      return result.user
    } catch (error) {

      const reason = this.errorResponse(error).get().data.reason
      if (reason == 'USER_NOT_FOUND') {
        throw new ErrorHolder(error, ErrorCode.NoUserFound)

      } else if (reason == 'BAD_CREDENTIALS') {
        throw new ErrorHolder(error, ErrorCode.BadCredentials)
      }
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }

      throw error
    }
  }

  async getAuthUser (): Promise<ELUser> {
    // get new CSRF token
    this.clearCsrf()

    try {
      const { data } = await this.get<UserResponse>('/users/getAuthUser', null)

      return data.user
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }

      throw error
    }
  }

  async getDistrictUsers (state: RootState, request: UsersRequest): Promise<PagedUsersResponse> {
    const { data } = await this.post<PagedUsersResponse>('/users/getPagedUsers', { state, request })

    return data
  }

  async getUserEdit (state: RootState, request: UsersRequest): Promise<ELUserEdit> {
    const { data } = await this.post<ELUserEdit>('/users/getUserEdit', { state, request })

    return data
  }

  async saveUserEdit (state: RootState, request: UserEditRequest) {
    await this.post('/users/saveUserEdit', { state, request })

  }

  async getCommitteeAssignments(state: RootState, userId: number): Promise<MeetingMemberAssignmentResponse> {
    const { data } = await this.get(`/users/committee-assignments/${userId}`, state)
    return data
  }

  async removeUser (state: RootState, user: ELUser) {
    const request = { userEdit: user } as UserEditRequest
    await this.post('/users/removeUser', { state, request })

  }

  async getUserDistrictSchools(state: RootState): Promise<ELDistrict[]> {
    const { data: { districts } } = await this.post<DistrictsResponse>('/users/getAuthUserDistrictSchools', { state })

    return districts
  }

  async getUserSchoolRole(state: RootState): Promise<ELUserRole> {
    const { data: { role } } = await this.post<RoleResponse>('/users/getAuthUserSchoolRole', { state })

    return role
  }

  async insertResetLink(email: string): Promise<PasswordResetResponse> {
    try {
      const request: PasswordResetRequest = { email }
      const { data } = await this.post<PasswordResetResponse>('/users/insertResetLink', { request })

      return data
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }

      throw error
    }
  }

  async sendPasswordReset(request: PasswordResetRequest): Promise<PasswordResetResponse> {
    try {
      const { data } = await this.post<PasswordResetResponse>('/users/sendPasswordReset', { request })

      return data
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }

      throw error
    }
  }

  async sendPasswordResetByUsername(request: PasswordResetRequest): Promise<PasswordResetResponse> {
    try {
      const { data } = await this.post<PasswordResetResponse>('/users/sendPasswordReset', { request })

      return data
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }

      throw error
    }
  }

  async insertUser(request: InsertUserRequest): Promise<InsertUserResponse> {
    try {
      const { data } = await this.post<InsertUserResponse>('/users/insertUser', { request })

      return data
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }
      throw error
    }
  }

  async sendTeacherStaarLinks(state: RootState, req: TeacherLinkRequest): Promise<TeacherLinkResponse> {
    try {
      const { data } = await this.post<TeacherLinkResponse>('/users/sendTeacherStaarLinks', { state, request: req })
      return data
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }
      throw error
    }
  }

  async getTeacherByLink(req: TeacherLinkRequest): Promise<TeacherLinkResponse> {
    try {
      const { data } = await this.get<TeacherLinkResponse>(`/users/getTeacherByLink/${req.teacherLink}`, null)
      return data
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }
      throw error
    }
  }

  async insertNewTeacher(request: NewTeacherRequest): Promise<NewTeacherResponse> {
    try {
      const { data } = await this.post<NewTeacherResponse>('/users/insertNewTeacher', { request })

      return data
    } catch (error) {
      if (this.isErrorStatus(error, HttpStatus.UNAUTHORIZED)) {
        throw new ErrorHolder(error, ErrorCode.NotAuthError)
      }
      throw error
    }
  }

  async logout(): Promise<string> {
    const { data } = await this.post<{ redirect: string }>('/logout?redirect=false')
    this.clearCsrf()

    return data.redirect
  }

}
