import { reportsService, schoolService, studentService } from '@/services'
import { RootState } from '@/store/root'
import { defaults } from 'lodash'
import { ActionTree } from 'vuex'
import { ReportsMutations } from './mutations'
import { ReportsState } from './state'
import { DownloadReportRequest } from './types'

export enum ReportsActions {
  GetReportTypes = 'GET_REPORT_TYPES',
  GetDistrictSchools = 'GET_DISTRICT_SCHOOLS',
  DownloadReport = 'DOWNLOAD_REPORT',
  GetPagedSchools = 'GET_PAGED_SCHOOLS',
  GetPagedReviewers = 'GET_PAGED_REVIEWERS',
  GetReviewers = 'GET_REVIEWERS',
  GetPagedTeachers = 'GET_PAGED_TEACHERS',
  GetTeachers = 'GET_TEACHERS',
  GetStudents = 'GET_STUDENTS',
  GetPagedStudents = 'GET_PAGED_STUDENTS',
  DownloadCurrentCLS = 'DOWNLOAD_CURRENT_CLS'
}

export const actions: ActionTree<ReportsState, RootState> = {

  async [ReportsActions.GetReportTypes]({ commit, rootState }) {
    const reports = await reportsService.getReportTypes(rootState)

    commit(ReportsMutations.SetReports, reports)
  },

  async [ReportsActions.GetDistrictSchools]({ commit, rootState }) {
    const schools = await schoolService.getDistrictSchools(rootState)

    commit(ReportsMutations.SetSchools, schools)
  },

  async [ReportsActions.GetReviewers]({ commit, rootState }, schoolIds: number[]) {
    const reviewers = await schoolService.getReviewers(rootState, schoolIds)
    commit(ReportsMutations.SetReviewers, reviewers)
  },

  async [ReportsActions.GetTeachers]({ commit, rootState }, schoolIds: number[]) {
    const teachers = await schoolService.getTeachers(rootState, schoolIds)
    commit(ReportsMutations.SetTeachers, teachers)
  },

  async [ReportsActions.GetStudents]({ commit, state, rootState }, teacherIds: number[]) {
    let students = []
    const schoolIds = state.selectedPagedSchools?.total > 0 ? state.selectedPagedSchools.itemIds : state.selectedSchools
    if (teacherIds.length > 0) {
      students = await studentService.getStudentsByTeacherIds(rootState, teacherIds, schoolIds)
    }
    commit(ReportsMutations.SetStudents, students)
  },

  async [ReportsActions.DownloadReport]({ rootState }, download: DownloadReportRequest) {
    switch (download.reportType) {
      case 'Content and Language Support':
        return await reportsService.downloadContentAndLanguageReport(rootState, download.request)
      case 'Current STAAR Accommodations':
        return await reportsService.downloadStaarAccommodationsReport(rootState, download.request)
      case 'Parent Decisions':
        return await reportsService.downloadParentPermissions(rootState, download.request)
      case 'Student Classifications & Recommendations':
        return await reportsService.downloadStudentRecommendationsReport(rootState, download.request)
      case 'Linguistics Accommodations':
        return await reportsService.downloadLinguisticsAccommodationsReport(rootState, download.request)
      case 'Completed Annual Review Meetings':
        return await reportsService.downloadCompletedAnnualReviewsReport(rootState, download.request)
      case 'US School Entry Date':
        return await reportsService.downloadUsEntryReport(rootState, download.request)
      case 'Student Proficiency Levels':
        return await reportsService.downloadStudentProficiencyReport(rootState, download.request)
      case 'Student Info':
        return await reportsService.downloadStudentInfoReport(rootState, download.request)
      default:
        break
    }
  },

async [ReportsActions.DownloadCurrentCLS]({ rootState }, meetingId: number) {
  await reportsService.downloadCurrentContentAndLanguageReport(rootState, meetingId)
},

  async [ReportsActions.GetPagedSchools]({ commit, state, rootState }, isAdvancedUser: boolean) {
    commit(ReportsMutations.SetLoaded, false)
    try {
      const { schools, allSchoolIds, pagination } =
        await schoolService.getPagedSchools(rootState, state.schoolsPaging, isAdvancedUser)
      if (state.schoolsPaging.page !== pagination.currentPage) {
        commit(ReportsMutations.SetSchoolsPaging, defaults({
          page: pagination.currentPage,
        }, state.schoolsPaging))
      }
      commit(ReportsMutations.SetPagedSchools, { schools, allSchoolIds, pagination })
    } finally {
      commit(ReportsMutations.SetLoaded, true)
    }
  },

  async [ReportsActions.GetPagedReviewers]({ commit, state, rootState }, isAdvancedUser: boolean) {
    if (!state.loaded) {
      return
    }
    commit(ReportsMutations.SetLoaded, false)
    try {
      const selected = state.schoolsEdited ?
        state.selectedPagedSchools.itemIds : state.selectedSchools
      const { staff, allStaffIds, pagination } =
        await schoolService.getPagedReviewers(rootState, selected, state.reviewersPaging, isAdvancedUser)
      if (state.reviewersPaging.page !== pagination.currentPage) {
        commit(ReportsMutations.SetReviewersPaging, defaults({
          page: pagination.currentPage,
        }, state.reviewersPaging))
      }
      commit(ReportsMutations.SetPagedReviewers, { staff, allStaffIds, pagination })
    } finally {
      commit(ReportsMutations.SetLoaded, true)
    }
  },

  async [ReportsActions.GetPagedTeachers]({ commit, state, rootState }, isAdvancedUser: boolean) {
    const selected = state.schoolsEdited ?
    state.selectedPagedSchools.itemIds : state.selectedSchools
    if (selected.length == 0) {
      return
    }
    try {
      commit(ReportsMutations.SetLoaded, false)
      const { staff, allStaffIds, pagination } =
        await schoolService.getPagedTeachers(rootState, selected, state.teachersPaging, isAdvancedUser)
      if (state.teachersPaging.page !== pagination.currentPage) {
        commit(ReportsMutations.SetTeachersPaging, defaults({
          page: pagination.currentPage,
        }, state.teachersPaging))
      }
      commit(ReportsMutations.SetPagedTeachers, { staff, allStaffIds, pagination })
    } finally {
      commit(ReportsMutations.SetLoaded, true)
    }
  },

  async [ReportsActions.GetPagedStudents]({ commit, state, rootState }, isAdvancedUser: boolean) {
    const selected = state.teachersEdited ?
    state.selectedPagedTeachers.itemIds : state.selectedTeachers
    if (selected.length == 0) {
      return
    }
    commit(ReportsMutations.SetLoaded, false)
    try {
      const schoolIds = state.selectedPagedSchools?.total > 0 ? state.selectedPagedSchools.itemIds : state.selectedSchools
      const { students, allStudentIds, pagination } =
        await studentService.getPagedStudentsByTeacherIds(rootState, selected, schoolIds, state.studentsPaging, isAdvancedUser)
      if (state.studentsPaging.page !== pagination.currentPage) {
        commit(ReportsMutations.SetStudentsPaging, defaults({
          page: pagination.currentPage,
        }, state.studentsPaging))
      }
      commit(ReportsMutations.SetPagedStudents, { students, allStudentIds, pagination })
    } finally {
      commit(ReportsMutations.SetLoaded, true)
    }
  }

}
