import { MutationTree } from 'vuex'
import { StudentDocumentsState, clearState } from './state'
import { Pagination, MeetingsPaging, StudentDocument, StudentDocumentType } from 'models'
import cloneDeep from 'lodash/cloneDeep'
import { ZipFileStatus } from '@/enums'

export enum StudentDocumentsMutations {
  Clear = 'CLEAR',
  SetDocuments = 'SET_DOCUMENTS',
  SetPaging = 'SET_PAGING',
  SetLoaded = 'SET_LOADED',
  SetStudentId = 'SET_STUDENT_ID',
  SetStuSchGrdId = 'SET_STU_SCH_GRD_ID',
  SetDocumentTypes = 'SET_DOCUMENT_TYPES',
  SetZipDocument = 'SET_ZIP_DOCUMENT',
  SetZipFileStatus = 'SET_ZIP_FILE_STATUS',
  SetMeetingRosterId = 'SET_MEETING_ROSTER_ID',
  SetHasDocs = 'SET_HAS_DOCS',
  SetZipFileExist = 'SET_ZIP_FILE_EXIST',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<StudentDocumentsState> = {

  [StudentDocumentsMutations.Clear] (state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [StudentDocumentsMutations.SetDocuments] (state, documents: StudentDocument[]) {
    state.documents = documents
  },

  [StudentDocumentsMutations.SetLoaded] (state, loaded: boolean) {
    state.loaded = loaded
  },

  [StudentDocumentsMutations.SetStudentId] (state, studentId: number) {
    state.studentId = studentId
  },

  [StudentDocumentsMutations.SetStuSchGrdId] (state, stuSchGrdId: number) {
    state.stuSchGrdId = stuSchGrdId
  },

  [StudentDocumentsMutations.SetMeetingRosterId] (state, meetingRosterId: number) {
    state.meetingRosterId = meetingRosterId
  },

  [StudentDocumentsMutations.SetDocuments] (state, { documents, pagination }: {
    documents: StudentDocument[]
    pagination: Pagination
  }) {
    state.documents = documents
    state.pagination = pagination
  },

  [StudentDocumentsMutations.SetPaging](state, paging: MeetingsPaging) {
    state.paging = paging
  },

  [StudentDocumentsMutations.SetDocumentTypes](state, types: StudentDocumentType[]) {
    state.documentTypes = types
  },

  [StudentDocumentsMutations.SetZipDocument](state, stuDoc: StudentDocument) {
    state.zipDocument = stuDoc
  },

  [StudentDocumentsMutations.SetZipFileStatus](state, stuZipFileStatus: ZipFileStatus) {
    state.zipFileStatus = stuZipFileStatus
    if(stuZipFileStatus == ZipFileStatus.Completed || stuZipFileStatus == ZipFileStatus.Pending){
      state.zipFileExist = true
    } else {
      state.zipFileExist = false
    }
  
  },
  [StudentDocumentsMutations.SetHasDocs](state, hasDocs: boolean) {
    state.hasDocs = hasDocs
  },

  [StudentDocumentsMutations.SetZipFileExist](state, zipFileExist: boolean) {
    state.zipFileExist = zipFileExist
  },

  // Only use this mutation for setting the state for test cases
  [StudentDocumentsMutations.SetTestState] (state, testData: StudentDocumentsState) {
    Object.assign(state, cloneDeep(testData))
  }
}
