import { meetingService, studentService, decisionsService, gradeService } from '@/services'
import { RootState } from '@/store/root'
import { ReviewMeetingState } from './state'
import { ReviewMeetingMutations } from './mutations'
import { ActionTree } from 'vuex'
import {
  MeetingWithType,
  OlptSaveEdits,
  StudentIdAndMeetingType,
  SelectedTeacher,
  SelectStaarSelection,
  SelectedSubject,
  GetStaarSelectionParams,
  SupportOptionsRequest
} from './types'
import defaults from 'lodash/defaults'
import flatten from 'lodash/flatten'
import { buildTest } from './builders'
import {
  OralLanguageProficiencyTest,
  GetAssessmentsRequest,
  GetTeachersByStudentRequest,
  MeetingRoster,
  SaveStaarJustificationRequest,
  SaveStaarJustificationPayload,
  SaveStaarSubjectPayload,
  SelectedStaarJustification,
  RemoveStaarJustificationRequest,
  TelpasAssessmentDecisionRequest, RosterStudentOptionsRequest, RosterOptionEdit, SaveRosterTransferOptionsRequest, SetRosterEnrollmentTypeRequest, ContentLanguageControlOptions, UpdateMeetingNotesRequest, GetStudentNotesRequest, StudentNote, SaveDnqRequest
} from 'models'

import { EnrollmentTypeCode, MeetingTypeCode } from '@/enums'
import isEmpty from 'lodash/isEmpty'
import { flatMap } from 'lodash'

export enum ReviewMeetingActions {
  GetTypes = 'GET_TYPES',
  GetMeetingTypeStudents = 'GET_STUDENTS',
  GetMeetingTypeStudentsNoPaging = 'GET_STUDENTS_NO_PAGING',
  GetTelpasAssessmentDecisions = 'GET_TELPAS_ASSESSMENT_DECISIONS',
  GetRoles = 'GET_ROLES',
  GetAllMembers = 'GET_ALL_MEMBERS',
  SetTestEditsForStudent = 'SET_TEST_EDITS_FOR_STUDENT',
  SetProficiencyEditsForStudent = 'SET_PROFICIENCY_EDITS_FOR_STUDENT',
  SaveProficiencyTest = 'SAVE_PROFICIENCY_TEST',
  SaveProficiencyTestResults = 'SAVE_PROFICIENCY_TEST_RESULTS',
  SaveAllProficiencyTests = 'SAVE_ALL_PROFICIENCY_TESTS',
  SaveAllCLSOptions = 'SAVE_ALL_CLS_OPTIONS',
  SetRemoveStudentIdAndMeetingType = 'SET_REMOVE_STUDENT_ID_AND_MEETING_TYPE',
  SetProficiencyTestTypeForRoster = 'SET_PROFICIENCY_TEST_TYPE',
  GetSupportOptions = 'GET_SUPPORT_OPTIONS',
  SaveStudentSupportOptions = 'SAVE_STUDENT_SUPPORT_OPTIONS',
  SaveRosterTransferOptions = 'SAVE_ROSTER_TRANSFER_OPIONS',
  SetRosterEnrollmentType = 'SET_ROSTER_ENROLLMENT_TYPE',
  DeleteRosterEnrollmentType = 'DELETE_ROSTER_ENROLLMENT_TYPE',
  SaveStudentMeetingNote = 'SAVE_STUDENT_MEETING_NOTE',
  SaveRosterSummary = 'SAVE_ROSTER_SUMMARY',
  SaveDnqStatus = 'SAVE_DNQ_STATUS',

  // Decisions
  GetAssessments = 'GET_ASSESSMENTS',
  ConfirmSubjectMove = 'CONFIRM_SUBJECT_MOVE',
  SelectAllSubjects = 'SELECT_ALL_SUBJECTS',
  SelectSubjectOrConfirm = 'SELECT_SUBJECT_OR_CONFIRM',
  CloseConfirmSubjectMove = 'CLOSE_CONFIRM_SUBJECT_MOVE',
  SelectJustification = 'SELECT_JUSTIFICATION',
  SetTeachersDatum = 'SET_TEACHERS_DATUM',
  SetLpacArdStaff = 'SET_LPAC_ARD_STAFF',
  SelectTeacher = 'SELECT_TEACHER',
  SaveStaarJustification = 'SAVE_STAAR_JUSTIFICATION',
  SaveTelpasJustification = 'SAVE_TELPAS_JUSTIFICATION',
  SaveAllStaarJustifications = 'SAVE_ALL_STAAR_JUSTIFICATIONS',
  SaveAllTelpasJustifications = 'SAVE_ALL_TELPAS_JUSTIFICATIONS',
  GetStaarSelection = 'GET_STAAR_SELECTION',
  RemoveStaarJustificationStudent = 'REMOVE_STAAR_JUSTIFICATION_STUDENT',
  RemoveFromUnsavedJustificationsSet = 'REMOVE_FROM_UNSAVED_JUSITIFCATIONS_SET',
  ClearJustificationsStudent = 'CLEAR_JUSTIFICATIONS_STUDENT',
  GetStudentNotes = 'GET_STUDENT_NOTES'
}

export const actions: ActionTree<ReviewMeetingState, RootState> = {

  async [ReviewMeetingActions.SaveStudentMeetingNote]({ rootState }, req: UpdateMeetingNotesRequest) {
    await studentService.saveStudentNote(rootState, req)
  },

  async [ReviewMeetingActions.GetStudentNotes]({ commit, rootState }, req: GetStudentNotesRequest) {
    const studentNoteLst: StudentNote[] = await studentService.getStudentNotesByMeetingType(rootState, req)
    commit(ReviewMeetingMutations.SetStudentNotes, studentNoteLst)
  },

  async [ReviewMeetingActions.GetTelpasAssessmentDecisions]({ commit, rootState }, request: TelpasAssessmentDecisionRequest) {
    const telpasAssessmentDecisions = await studentService.getTelpasAssessmentDecisions(rootState, request)

    commit(ReviewMeetingMutations.SetTelpasAssessmentDecisions, telpasAssessmentDecisions)
  },

  async [ReviewMeetingActions.GetTypes]({ commit, rootState }) {
    const types = await meetingService.getTypes(rootState)

    commit(ReviewMeetingMutations.SetTypes, types)
  },

  async [ReviewMeetingActions.GetMeetingTypeStudentsNoPaging]({ commit, state, rootState }, { meetingId, meetingType }: MeetingWithType) {
    const hideDecisions = state.hideDecisions
    const decisionStudents = hideDecisions === true ? Array.from(state.decisionStudents) : []
    const { paging } = state.meetingStudents[meetingType]
    const { students, pagination } = await studentService.getOpenIdRosterStudents(rootState, paging, meetingId, meetingType, decisionStudents)

    if (paging.page !== pagination.currentPage) {
      commit(ReviewMeetingMutations.SetMeetingTypePaging, {
        meetingType,
        paging: defaults({
          page: pagination.currentPage
        }, paging)
      })
    }

    commit(ReviewMeetingMutations.SetMeetingTypeStudents, { meetingType, students, pagination })
  },

  async [ReviewMeetingActions.GetRoles]({ commit, rootState }) {
    const roles = await meetingService.getRoles(rootState)

    commit(ReviewMeetingMutations.SetRoles, roles)
  },

  async [ReviewMeetingActions.GetAllMembers]({ commit, rootState }) {
    const members = await meetingService.getAllMembers(rootState)

    commit(ReviewMeetingMutations.SetAllMembers, members)
  },

  async [ReviewMeetingActions.SetTestEditsForStudent]({ commit }, { id, olptSaveEdits }) {
    commit(ReviewMeetingMutations.SetTestEditsForStudent, { id, olptSaveEdits })
  },

  async [ReviewMeetingActions.SetProficiencyEditsForStudent]({ commit }, { id, proficiencyTestResults }) {
    commit(ReviewMeetingMutations.SetProficiencyEditsForStudent, { id, proficiencyTestResults })
  },

  async[ReviewMeetingActions.SetProficiencyTestTypeForRoster]({ commit, rootState }, { meetingRosterId, proficiencyTestTypeCode }) {
    const roster = await studentService.saveProficiencyTestType(rootState, meetingRosterId, proficiencyTestTypeCode)
    const meetingType = roster.meetingType.code
    commit(ReviewMeetingMutations.ResetMeetingStudentInRoster, { roster, meetingType })
  },

  async [ReviewMeetingActions.GetSupportOptions]({ commit, rootState }, { columns, rosterId }: SupportOptionsRequest) {
    const categories = await gradeService.getSupportOptions(rootState, rosterId)

    // Change the order of CLS Options to read vertically instead of horizontally
    const sortedCategories = []

    categories.options.forEach(category => {
      const result = []
      let mod = category.options.length % columns
      let mod2 = mod === 0 ? (category.options.length / columns) : Math.ceil((category.options.length) / columns)
      let smallCom = false

      category.options.forEach(function(element, index) {

        const group = smallCom ? ((index - (category.options.length % columns)) % mod2) : (index) % mod2
        let temp = result[group]

        if (!Array.isArray(temp)) {
          temp = []
        }
        temp.push(element)
        result[group] = temp
        if (mod > 0 && (group + 1) === mod2) {
          mod--
          if (mod === 0) {
            mod2--
            smallCom = true
          }
        }
      })
      sortedCategories.push({ supportId: category.supportId, order: category.order, text: category.text, options: flatMap(result) } as ContentLanguageControlOptions)
    })
    commit(ReviewMeetingMutations.SetSupportOptions, sortedCategories)
    commit(ReviewMeetingMutations.SetInitialOptions, { rosterId: rosterId, options: categories.carryOverOptions })
  },

  async [ReviewMeetingActions.SaveStudentSupportOptions]({ commit, rootState }, edits: RosterOptionEdit) {
    const request: RosterStudentOptionsRequest = { option: { meetingRosterId: edits.meetingRosterId, studentOptions: edits.studentOptions } }
    const options = await studentService.saveStudentSupportOption(rootState, request)
    commit(ReviewMeetingMutations.RemoveEditOptions, edits.meetingRosterId)

    return options
  },

  async [ReviewMeetingActions.SaveRosterSummary]({ state, commit, rootState }, meetingRosterId: number) {
    const roster = await studentService.saveRosterSummary(rootState, meetingRosterId, state.summaryNotes)
    commit(ReviewMeetingMutations.UpdateStudentSummaryNotes, roster)
  },

  async [ReviewMeetingActions.SaveDnqStatus]({ state, commit, rootState }, meetingRosterId: number) {
    const request: SaveDnqRequest = { isDnq: state.isDnq, meetingRosterId }
    const roster: MeetingRoster = await studentService.saveDnqStatus(rootState, request)
    commit(ReviewMeetingMutations.UpdateDnqStatus, roster)
  },

  async[ReviewMeetingActions.SaveRosterTransferOptions]({ commit, rootState }, saveRosterTransferRequest: SaveRosterTransferOptionsRequest) {
    const roster = await studentService.saveRosterTransferOptions(rootState, saveRosterTransferRequest)
    const meetingType = roster.meetingType.code
    commit(ReviewMeetingMutations.SetTransferEnrollmentDecisionStudent, roster.studentSchoolGrade.id)
    commit(ReviewMeetingMutations.ResetMeetingStudentInRoster, { roster, meetingType })
    commit(ReviewMeetingMutations.RemoveRosterTransferEdits, roster.id)
  },

  async[ReviewMeetingActions.SetRosterEnrollmentType]({ commit, rootState }, setRosterEnrollmentTypeRequest: SetRosterEnrollmentTypeRequest) {
    await studentService.setRosterEnrollmentType(rootState, setRosterEnrollmentTypeRequest)
    const meetingRosterId = setRosterEnrollmentTypeRequest.meetingRosterId
    const enrollmentType = setRosterEnrollmentTypeRequest.enrollmentType
    const meetingType = MeetingTypeCode.Identification
    commit(ReviewMeetingMutations.ResetRosterEnrollmentType, { meetingRosterId, enrollmentType, meetingType })
    commit(ReviewMeetingMutations.SetTransferOptEdits, { meetingRosterId })
  },

  async[ReviewMeetingActions.DeleteRosterEnrollmentType]({ commit, rootState }, setRosterEnrollmentTypeRequest: SetRosterEnrollmentTypeRequest) {
    await studentService.deleteRosterEnrollmentType(rootState, setRosterEnrollmentTypeRequest)
    const meetingRosterId = setRosterEnrollmentTypeRequest.meetingRosterId
    const enrollmentType = setRosterEnrollmentTypeRequest.enrollmentType
    const meetingType = MeetingTypeCode.Identification
    commit(ReviewMeetingMutations.ResetRosterEnrollmentType, { meetingRosterId, enrollmentType, meetingType })
    commit(ReviewMeetingMutations.SetTransferOptEdits, { meetingRosterId })
  },

  async [ReviewMeetingActions.SaveProficiencyTest]({ commit, rootState }, olptSaveEdits: OlptSaveEdits) {
    const test = buildTest(olptSaveEdits)
    const roster = await studentService.saveProficiencyTest(rootState, olptSaveEdits.meetingRosterId, test, MeetingTypeCode.AnyType)
    const meetingType = roster.meetingType.code
    commit(ReviewMeetingMutations.ResetMeetingStudentInRoster, { roster, meetingType })
    commit(ReviewMeetingMutations.RemoveEditEntryFromMap, roster.id)
  },
  async [ReviewMeetingActions.SaveProficiencyTestResults]({ commit, rootState }, { meetingRosterId, studentSchoolGradeId, grade, proficiencyTestResults }) {
    commit(ReviewMeetingMutations.SetIdentificationAssessmentDecisions, { proficiencyTestResults, studentSchoolGradeId, grade })
    const roster = await studentService.saveProficiencyTestResults(rootState, meetingRosterId, studentSchoolGradeId, proficiencyTestResults)
    const meetingType = roster.meetingType.code
    if (roster.enrollmentType && roster.enrollmentType.enrollmentTypeCode === EnrollmentTypeCode.TransferEnrollment) {
      commit(ReviewMeetingMutations.SetTransferEnrollmentDecisionStudent, roster.studentSchoolGrade.id)
    }
    commit(ReviewMeetingMutations.ResetMeetingStudentInRoster, { roster, meetingType })
    commit(ReviewMeetingMutations.RemoveTestResultsEntryFromMap, roster.id)
  },

  async [ReviewMeetingActions.SaveAllProficiencyTests]({ commit, state, rootState }, { meetingId, meetingType }) {
    const { paging } = state.meetingStudents[meetingType]
    const values: OlptSaveEdits[] = Array.from(state.saveEditsMap.values())
    const testLst: OralLanguageProficiencyTest[] = values.map(oltptSaveEdit => buildTest(oltptSaveEdit))
    const proficiencyTestResults = flatten(Array.from(state.saveProficiencyEditsMap.values()))
    const saveRosterTransferOptionsRequests: SaveRosterTransferOptionsRequest[] = []
    if (state.saveTransferEditsMap.size > 0) {
      const saveTransferEditsMap = state.saveTransferEditsMap
      saveTransferEditsMap.forEach((optIds,rosterId) => {
        const saveTransferRequest: SaveRosterTransferOptionsRequest = {
          meetingRosterId: rosterId,
          transferOptionIds: Array.from(optIds)
        }
        saveRosterTransferOptionsRequests.push(saveTransferRequest)
      })

    }
    const { students, pagination } = await studentService.saveProficiencyTests(rootState, meetingId, testLst, proficiencyTestResults, paging, meetingType, saveRosterTransferOptionsRequests)

    commit(ReviewMeetingMutations.ClearEditsMap)
    commit(ReviewMeetingMutations.SetMeetingTypeStudents, { meetingType, students, pagination })
  },

  async [ReviewMeetingActions.SaveAllCLSOptions]({ commit, state, rootState }) {
    const options: RosterOptionEdit[] = state.supportOptionEdits
    await studentService.saveAllCLSOptions(rootState, options)

    commit(ReviewMeetingMutations.ClearEditOptions)
  },

  async [ReviewMeetingActions.SetRemoveStudentIdAndMeetingType]({ commit }, studentIdAndMeetingType: StudentIdAndMeetingType) {
    commit(ReviewMeetingMutations.SetRemoveStudentIdAndMeetingType, studentIdAndMeetingType)
  },

  // Decisions
  async [ReviewMeetingActions.GetAssessments]({ commit, rootState }, req: GetAssessmentsRequest) {
    decisionsService
      .getAssessments(rootState, req)
      .then(asmts => {
        commit(ReviewMeetingMutations.SetAssessments, { studentId: req.studentId, asmts })
      })
  },

  async [ReviewMeetingActions.GetStaarSelection]({ commit, rootState }, params: GetStaarSelectionParams) {
    const req = params.req
    const studentId = params.studentId

    await Promise.all([
      decisionsService.getSelectedStaarJustifications(rootState, req),
      decisionsService.getSelectedStaarTeachers(rootState, req)
    ]).then(value => {
      commit(ReviewMeetingMutations.SetStaarSelection, {
        studentId: studentId,
        selectedStaarJustifications: value[0],
        selectedStaarTeachers: value[1]
      } as SelectStaarSelection)
    })
  },

  async [ReviewMeetingActions.ConfirmSubjectMove]({ commit }) {
    commit(ReviewMeetingMutations.ConfirmSubjectMove)
  },

  async [ReviewMeetingActions.SelectSubjectOrConfirm]({ commit }, params) {
    commit(ReviewMeetingMutations.SelectSubjectOrConfirm, params)
  },

  async [ReviewMeetingActions.SelectAllSubjects]({ commit }, params) {
    commit(ReviewMeetingMutations.SelectAllSubjects, params)
  },

  async [ReviewMeetingActions.CloseConfirmSubjectMove]({ commit }) {
    commit(ReviewMeetingMutations.CloseConfirmSubjectMove)
  },

  async [ReviewMeetingActions.SelectJustification]({ commit }, params) {
    commit(ReviewMeetingMutations.SelectJustification, params)
  },

  async [ReviewMeetingActions.SetTeachersDatum]({ commit, rootState }, req: GetTeachersByStudentRequest) {
    decisionsService
      .getTeachers(rootState, req)
      .then(staff => {
        commit(ReviewMeetingMutations.SetTeachersDatum, {
          studentId: req.studentId,
          staff
        })
      })
  },

  async [ReviewMeetingActions.SetLpacArdStaff]({ commit, rootState }, req: GetTeachersByStudentRequest) {
    decisionsService
      .getLpacArdCommitteeStfEntryByDstId(rootState)
      .then(staff => {
        commit(ReviewMeetingMutations.SetTeachersDatum, {
          studentId: req.studentId,
          staff
        })
      })
  },

  async [ReviewMeetingActions.SelectTeacher]({ commit }, selectedTeacher: SelectedTeacher) {
    commit(ReviewMeetingMutations.SelectTeacher, selectedTeacher)
  },

  async [ReviewMeetingActions.SaveStaarJustification]({ commit, state, rootState }, { studentId }) {
    saveStaarJustifications(commit, state, rootState, studentId)
  },

  async [ReviewMeetingActions.SaveTelpasJustification]({ commit, state, rootState }, { studentId }) {
    saveTelpasJustifications(commit, state, rootState, studentId, false)
  },

  async [ReviewMeetingActions.SaveAllStaarJustifications]({ commit, state, rootState }) {
    state.meetingStudents.STAAR_DECISIONS.students.forEach(s => {
      saveStaarJustifications(commit, state, rootState, s.studentSchoolGrade.student.id)
    })
  },
  async [ReviewMeetingActions.SaveAllTelpasJustifications]({ commit, state, rootState }) {
    state.meetingStudents.TELPAS_DECISIONS.students.forEach(s => {
      saveTelpasJustifications(commit, state, rootState, s.studentSchoolGrade.student.id, true)
    })
  },

  async [ReviewMeetingActions.RemoveStaarJustificationStudent]({ commit, rootState }, req: RemoveStaarJustificationRequest) {
    decisionsService
      .removeStaarJustification(rootState, req)
      .then(() => {
        commit(ReviewMeetingMutations.RemoveStaarJustificationStudent, req.studentSchoolGradeId)
      })
  },
  async [ReviewMeetingActions.RemoveFromUnsavedJustificationsSet]({ commit }, studentId: number) {
    commit(ReviewMeetingMutations.RemoveFromUnsavedJustificationsSet, studentId)
  },
  async [ReviewMeetingActions.ClearJustificationsStudent]({ commit }, studentId: number) {
    commit(ReviewMeetingMutations.RemoveStaarJustificationStudent, studentId)
  }
}

const saveTelpasJustifications = (commit, state, rootState, studentId, saveAll) => {
  const roster = state.meetingStudents.TELPAS_DECISIONS.students.find((mr: MeetingRoster): mr is MeetingRoster => {
    return mr.studentSchoolGrade.student.id === studentId
  })
  if (roster !== undefined) {
    const students = state.decisionStudents
    const asmtJustPayload = (
      state.staarJustifications.selectedStaarJustifications || []
    ).filter((sj: SelectedStaarJustification): sj is SelectedStaarJustification => {
      return sj.studentId === studentId
    })
    .map(sj => {
      return {
        assessmentJustificationId: sj.assessmentJustificationId,
        studentSchoolGradeId: roster.studentSchoolGrade.id,
        meetingId: rootState.meeting.currentMeeting.id
      } as SaveStaarSubjectPayload
    })

    if (!(isEmpty(asmtJustPayload) && saveAll)) {
      if (isEmpty(asmtJustPayload)) {
        students.delete(roster.studentSchoolGrade.id)
        asmtJustPayload.push({
          assessmentJustificationId: null,
          studentSchoolGradeId: roster.studentSchoolGrade.id,
          meetingId: rootState.meeting.currentMeeting.id
        })
      } else {
        students.add(roster.studentSchoolGrade.id)
      }

      const request = {
        meetingId: rootState.meeting.currentMeeting.id,
        justifications: asmtJustPayload
      } as SaveStaarJustificationRequest

      decisionsService
        .saveTelpasJustifications(rootState, request)
        .then(() => commit(ReviewMeetingMutations.SetStaarJustificationUnsavedStatus, false))

    }
  }
}

const saveStaarJustifications = (commit, state, rootState, studentId) => {
  const roster = state.meetingStudents.STAAR_DECISIONS.students.find((mr: MeetingRoster): mr is MeetingRoster => {
    return mr.studentSchoolGrade.student.id === studentId
  })
  const getSelectedTeacher = (selectedSubject, selectedTeachers) => {
    return selectedTeachers.find((st: SelectedTeacher): st is SelectedTeacher => {
      return st.studentId === selectedSubject.studentId &&
        st.subjectId === selectedSubject.subjectId
    })
  }

  if (roster !== undefined) {
    const filteredSubjects = state.staarJustifications.selectedSubjects.filter(
      (ss: SelectedSubject): ss is SelectedSubject => {
        return ss.studentId === studentId
      }
    )

    const asmtSubPayload = filteredSubjects.map(ss => {
      return {
        assessmentSubjectId: ss.assessmentSubjectId,
        studentSchoolGradeId: roster.studentSchoolGrade.id,
        meetingId: rootState.meeting.currentMeeting.id,
        teacherId: getSelectedTeacher(
          ss,
          state.staarJustifications.selectedTeachers.get(studentId)
        ).teacherId
      } as SaveStaarJustificationPayload
    })

    const asmtJustPayload = (
      state.staarJustifications.selectedStaarJustifications || []
    ).filter((sj: SelectedStaarJustification): sj is SelectedStaarJustification => {
      return sj.studentId === studentId
    })
    .map(sj => {
      return {
        assessmentJustificationId: sj.assessmentJustificationId,
        studentSchoolGradeId: roster.studentSchoolGrade.id,
        meetingId: rootState.meeting.currentMeeting.id
      } as SaveStaarSubjectPayload
    })

    if (asmtJustPayload.length !== 0) {
      const students = state.decisionStudents
      students.add(roster.studentSchoolGrade.id)
    }

    const request = {
      meetingId: rootState.meeting.currentMeeting.id,
      subjects: asmtSubPayload,
      justifications: asmtJustPayload,
      meetingTypeCode: MeetingTypeCode.StaarDecisions,
      meetingRosterId: roster.id
    } as SaveStaarJustificationRequest

    decisionsService
      .saveStaarJustification(rootState, request)
      .then(() => commit(ReviewMeetingMutations.SetStaarJustificationUnsavedStatus, false))
  }
}
