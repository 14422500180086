import {Module} from 'vuex'
import {RootState} from '@/store/root'
import {state, SSOState} from '@/store/modules/sso/state'
import {mutations} from '@/store/modules/sso/mutations'
import {getters} from '@/store/modules/sso/getters';
import {actions} from '@/store/modules/sso/actions';

export * from './types'

export { SSOState } from './state'
export { SSOMutations } from './mutations'
export { SSOActions } from './actions'
export { SSOGetters } from './getters'

export const store: Module<SSOState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
