import { Module } from 'vuex'
import { RootState } from '@/store/root/state'
import { StudentDocumentsState, state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export * from './types'

export { StudentDocumentsState } from './state'
export { StudentDocumentsGetters } from './getters'
export { StudentDocumentsMutations } from './mutations'
export { StudentDocumentsActions } from './actions'

export const store: Module<StudentDocumentsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
