import { GetterTree } from 'vuex'
import { isEqual, flatMap, sortBy, every, uniq } from 'lodash'
import { RootState } from '@/store/root'
import { ReviewMeetingState } from './state'
import {
  SelectedSubject,
  StudentId,
  StudentIdAssessmenIdJustificationIdPredicate,
  StudentIdAssessmenIdSubjectIdPredicate,
  StudentIdAssessmenIdPredicate,
  GetStudentSelectedSubjects,
  GetTeachersDatumByStudent,
  GetSelectedTeachersForStudent,
  StudentIdPredicate,
  TeacherDatum,
  EmptyPredicate,
  GetSelectedStudentJustifications
} from './types'
import {
  Assessment,
  Subject,
  SelectedStaarJustification
} from 'models'

export enum ReviewMeetingGetters {
  RoleNames = 'ROLE_NAMES',
  GetStudentAssessments = 'GET_STUDENT_ASSESSMENTS',
  GetDecisionStudents = 'GET_DECISION_STUDENTS',
  GetTransferEdits = 'GET_TRANSFER_EDITS',

  // Decisions
  IsSubjectSelected = 'IS_SUBJECT_SELECTED',
  CanSelectJustification = 'CAN_SELECT_JUSTIFICATION',
  AllSubjectsChecked = 'ALL_SUBJECTS_CHECKED',
  ConfirmSubjectMoveNeeded = 'CONFIRM_SUBJECT_MOVE_NEEDED',
  ConfirmSubjectMoveText = 'CONFIRM_SUBJECT_MOVE_TEXT',
  IsJustificationSelected = 'IS_JUSTIFICATION_SELECTED',
  StudentHasEntry = 'STUDENT_HAS_ENTRY',
  ShowTeacherSelection = 'SHOW_TEACHER_SELECTION',
  GetStudentSelectedSubjects = 'GET_STUDENT_SELECTED_SUBJECTS',
  GetTeachersByStudent = 'GET_TEACHERS_BY_STUDENT',
  GetSelectedTeachersForStudent = 'GET_SELECTED_TEACHERS_FOR_STUDENT',
  GetStudentSelectedJustifications = 'GET_STUDENT_SELECTED_JUSTIFICATIONS',
  StaarRosterHasNoInformationMissing = 'STAAR_ROSTER_HAS_NO_INFORMATION_MISSING',
  StudentHasNoInformationMissing = 'STUDENT_HAS_NO_INFORMATION_MISSING',
  StudentHasNoTelpasInformationMissing = 'STUDENT_HAS_TELPAS_INFORMATION_MISSING'
}

const checkNoInformationMissing = (state, studentId) => {
  const {
    selectedSubjects,
    selectedStaarJustifications,
    selectedTeachers
  } = state.staarJustifications

  const studentSelectedSubjects = selectedSubjects.filter((ss: SelectedSubject): ss is SelectedSubject => {
    return ss.studentId === studentId
  })

  const studentSelectedJustifications = selectedStaarJustifications.filter(
    (sj: SelectedStaarJustification): sj is SelectedStaarJustification => {
      return sj.studentId === studentId
    }
  )
  const studentSelectedTeachers = selectedTeachers.get(studentId) || []

  return every(
    studentSelectedSubjects,
    ss => {
      const hasJust = studentSelectedJustifications.findIndex(sj => sj.assessmentId === ss.assessmentId) >= 0
      const hasTeacher = studentSelectedTeachers.findIndex(st => {
        return st.subjectId === ss.subjectId && st.assessmentId === ss.assessmentId
      }) >= 0
      return hasJust && hasTeacher
    }
  ) && studentSelectedSubjects.length > 0
}

const checkTelpasNoInformationMissing = (state, studentId) => {
  const {
    selectedSubjects,
    selectedTeachers
  } = state.staarJustifications

  const studentSelectedSubjects = selectedSubjects.filter((ss: SelectedSubject): ss is SelectedSubject => {
    return ss.studentId === studentId
  })

  const studentSelectedTeachers = selectedTeachers.get(studentId) || []

  return every(
    studentSelectedSubjects,
    ss => {
      const hasTeacher = studentSelectedTeachers.findIndex(st => {
        return st.subjectId === ss.subjectId && st.assessmentId === ss.assessmentId
      }) >= 0
      return hasTeacher
    }
  ) && studentSelectedSubjects.length > 0
}

export const getters: GetterTree<ReviewMeetingState, RootState> = {

  [ReviewMeetingGetters.RoleNames](state): string[] {
    return state.roles ? state.roles.map(role => role.name) : []
  },

  [ReviewMeetingGetters.GetStudentAssessments](state): (studentId: StudentId) => Assessment[] {
    return studentId => {
      return state.staarJustifications.studentAssessments.get(studentId) || []
    }
  },

  [ReviewMeetingGetters.GetDecisionStudents](state): Set<number> {
    return state.decisionStudents
  },

  [ReviewMeetingGetters.IsSubjectSelected](state): StudentIdAssessmenIdSubjectIdPredicate {
    return ({ studentId, assessmentId, subjectId }) => {
      const isSelectedSubject = (sa: SelectedSubject): sa is SelectedSubject => {
        return sa.subjectId === subjectId &&
          sa.assessmentId === assessmentId &&
          sa.studentId === studentId
      }

      return state
        .staarJustifications
        .selectedSubjects
        .find<SelectedSubject>(isSelectedSubject) !== undefined
    }
  },

  [ReviewMeetingGetters.CanSelectJustification](state): StudentIdAssessmenIdPredicate {
    return ({ studentId, assessmentId }) => {
      const { selectedSubjects } = state.staarJustifications

      return selectedSubjects.find<SelectedSubject>((sa: SelectedSubject): sa is SelectedSubject => {
        return sa.assessmentId === assessmentId &&
          sa.studentId === studentId
      }) !== undefined
    }
  },

  [ReviewMeetingGetters.AllSubjectsChecked](state): StudentIdAssessmenIdPredicate {
    return ({ studentId, assessmentId }) => {
      const selectedSubjects = state.staarJustifications.selectedSubjects
        .filter<SelectedSubject>((sa: SelectedSubject): sa is SelectedSubject => {
          return sa.assessmentId === assessmentId && sa.studentId === studentId
        }).map<number>(sa => sa.subjectId)
        .sort()
      const availableAssessments = (
          state.staarJustifications.studentAssessments.get(studentId) || []
        ).filter((a: Assessment): a is Assessment => {
          return a.id === assessmentId
        })
      const availableSubjects = flatMap(
        availableAssessments,
        asmt => asmt.subjects
      ).map(s => s.id)
      .sort()

      return isEqual(uniq(selectedSubjects), uniq(availableSubjects))
    }
  },

  [ReviewMeetingGetters.ConfirmSubjectMoveNeeded](state): boolean {
    return state.staarJustifications.confirmSubjectMoveNeeded
  },

  [ReviewMeetingGetters.ConfirmSubjectMoveText](state): string {
    return state.staarJustifications.confirmSubjectMoveText
  },

  [ReviewMeetingGetters.IsJustificationSelected](state): StudentIdAssessmenIdJustificationIdPredicate {
    return ({ studentId, assessmentId, assessmentJustificationId }) => {
      return (
        state.staarJustifications.selectedStaarJustifications || []
      ).find<SelectedStaarJustification>(
          (sa: SelectedStaarJustification): sa is SelectedStaarJustification => {
            return sa.assessmentJustificationId === assessmentJustificationId &&
              sa.assessmentId === assessmentId &&
              sa.studentId === studentId
          }
        ) !== undefined
    }
  },
  [ReviewMeetingGetters.StudentHasEntry](state): StudentIdAssessmenIdJustificationIdPredicate {
    return ({ studentId, assessmentId }) => {
      return (
        state.staarJustifications.selectedStaarJustifications || []
      ).find<SelectedStaarJustification>(
          (sa: SelectedStaarJustification): sa is SelectedStaarJustification => {
            return sa.studentId === studentId && sa.assessmentId === assessmentId
          }
        ) !== undefined
    }
  },

  [ReviewMeetingGetters.ShowTeacherSelection](state): StudentIdPredicate {
    return studentId => {
      return state
        .staarJustifications
        .selectedSubjects
        .find<SelectedSubject>(
          (sa: SelectedSubject): sa is SelectedSubject => {
            return sa.studentId === studentId
          }
        ) !== undefined
    }
  },

  [ReviewMeetingGetters.GetStudentSelectedSubjects](state): GetStudentSelectedSubjects {
    return studentId => {
      const { selectedSubjects, studentAssessments } = state.staarJustifications
      const availableSubjects = flatMap(
        studentAssessments.get(studentId) || [],
        asmt => asmt.subjects
      )
      const isSelected = (subjectId, assessmentId) => {
        return selectedSubjects.find((ss: SelectedSubject): ss is SelectedSubject => {
          return ss.studentId === studentId &&
                 ss.assessmentId === assessmentId &&
                 ss.subjectId === subjectId
        })
      }

      return sortBy(availableSubjects.filter((s: Subject): s is Subject => {
        return isSelected(s.id, s.assessmentId) !== undefined
      }), 'order')
    }
  },

  [ReviewMeetingGetters.GetStudentSelectedJustifications](state): GetSelectedStudentJustifications {
    return studentId => {
      return state.staarJustifications.selectedStaarJustifications.filter(selectedJustifications => selectedJustifications.studentId === studentId)
    }
  },

  [ReviewMeetingGetters.GetTeachersByStudent](state): GetTeachersDatumByStudent {
    return studentId => state.staarJustifications.teachersDatum
      .filter((td: TeacherDatum): td is TeacherDatum => {
        return td.studentId === studentId
      })
  },

  [ReviewMeetingGetters.GetSelectedTeachersForStudent](state): GetSelectedTeachersForStudent {
    return studentId => state.staarJustifications.selectedTeachers.get(studentId) || []
  },

  [ReviewMeetingGetters.StudentHasNoInformationMissing](state): StudentIdPredicate {
    return studentId => checkNoInformationMissing(state, studentId)
  },

  [ReviewMeetingGetters.StudentHasNoTelpasInformationMissing](state): StudentIdPredicate {
    return studentId => checkTelpasNoInformationMissing(state, studentId)
  },

  [ReviewMeetingGetters.StaarRosterHasNoInformationMissing](state): EmptyPredicate {
    return () => every(
      state.meetingStudents.STAAR_DECISIONS.students.filter(student => state.unsavedJustificationsIds.has(student.studentSchoolGrade.student.id)),
      s => checkNoInformationMissing(state, s.studentSchoolGrade.student.id)
    )
  },

  [ReviewMeetingGetters.GetTransferEdits](state): Map<number, Set<number>> {
    return state.saveTransferEditsMap
  }

}
