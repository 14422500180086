import { ActionTree } from 'vuex'
import { authService, decisionsService, signService, studentService } from '@/services'
import { RootState } from '@/store/root'
import { StaarAccommodationsState } from './state'
import {
  GetMeetingTeachersRequest,
  GetStudentAccommodationsRequest,
  SelectedAssessmentFormatOption,
  SelectedAccommodationCommittee,
  SaveAccommodationCommitteeRequest,
  InitiateStaarDecisionSigningRequest,
  SignNowRequest,
  SigningStatusRequest,
  InitiateStaarDecisionSigningResponse,
  RetrieveTeacherSignedFilesRequest,
  UpdateMeetingNotesRequest,
  GetStaarStudentNotesRequest,
  StudentNote
} from 'models'
import { StaarAccommodationMutations } from './mutations'

export enum StaarAccommodationsActions {
  SelectCommittee = 'SELECT_COMMITTEE',
  SetShowAll = 'SET_SHOW_ALL',
  SetStudentsAccommodations = 'SET_STUDENTS_ACCOMMODATIONS',
  SetStudentSubjectAccommodations = 'SET_STUDENT_SUBJECT_ACCOMMODATIONS',
  SetTeachers = 'SET_TEACHERS',
  RemoveSelectedCommittee = 'REMOVE_SELECTED_COMMITTEE',
  SelectAssessmentFormatOpt = 'SELECT_ASSESSMENT_FORMAT_OPT',
  RemoveAssessmentFormatOpt = 'REMOVE_ASSESSMENT_FORMAT_OPT',
  SaveAccommodationCommittees = 'SAVE_ACCOMMODATION_COMMITTEES',
  InitateStaarTeacherSigning = 'INITATE_STARR_TEACHER_SIGNING',
  TeacherSignNow = 'TEACHER_SIGN_NOW',
  SaveSigningStatus = 'SAVE_SIGNING_STATUS',
  SetLastSign = 'SET_LAST_SIGN',
  RetrieveTeacherSignedFiles = 'RETRIEVE_TEACHER_SIGNED_FILES',
  ClearSelectedAccomForSubject = 'CLEAR_SELECTED_ACCOM_FOR_SUBJECT',
  RemoveTeacherIdFromMapAndSet = 'REMOVE_TEACHER_ID_FROM_MAP_AND_SET',
  AddTeacherToSet = 'ADD_TEACHER_TO_SET',
  AddTeacherToExpanded = 'ADD_TEACHER_TO_EXPANDED',
  RemoveTeacherFromExpanded = 'REMOVE_TEACHER_FROM_EXPANDED',
  SaveStudentMeetingNote = 'SAVE_STUDENT_MEETING_NOTE',
  GetStudentStaarNotes = 'GET_STUDENT_STAAR_NOTES',
  SendAccommodationsRequest = 'SEND_ACCOMMODATIONS_REQUEST',
  SetTeacherSignedStatus = 'SET_TEACHER_SIGNED_STATUS'
}

export const actions: ActionTree<StaarAccommodationsState, RootState> = {
  async [StaarAccommodationsActions.SelectCommittee]({ commit }, selectedCommittee: SelectedAccommodationCommittee) {
    commit(StaarAccommodationMutations.SelectCommittee, selectedCommittee)
  },

  async [StaarAccommodationsActions.RemoveSelectedCommittee]({ commit }, selectedCommittee: SelectedAccommodationCommittee) {
    commit(StaarAccommodationMutations.RemoveSelectedCommittee, selectedCommittee)
  },

  async [StaarAccommodationsActions.SetShowAll]({ commit }, showAll: boolean) {
    commit(StaarAccommodationMutations.SetShowAll, { showAll })
  },

  /** Deprecated */
  // async [StaarAccommodationsActions.SetStudentsAccommodations](
  //   { commit, state, rootState }, req: GetStudentAccommodationsRequest
  // ) {
  //   await decisionsService
  //   .getStudentAccommodations(rootState, req)
  //   .then(resp => {
  //     const newMap: StaarAccommodationsStudents = cloneDeep(state.staarAccommodationsStudents)
  //     newMap.delete(req.teacherId)
  //     newMap.set(req.teacherId, resp)

  //     commit(StaarAccommodationMutations.SetStudentsAccommodations, {
  //       studentsMap: newMap
  //     })
  //     const teacher = newMap.get(req.teacherId)
  //     teacher.forEach((accom: StudentAccommodation) => {
  //       accom.subjects.forEach(subject => {
  //         if (subject.assessmentFormatOptionId) {
  //           commit(StaarAccommodationMutations.SelectAssessmentFormatOpt, {
  //             studentSchoolGradeId: accom.studentSchoolGradeId,
  //             subjectId: subject.subjectId,
  //             nonExit: subject.nonExit,
  //             teacherId: req.teacherId,
  //             assessmentId: subject.assessmentId,
  //             assessmentFormatOptionId: subject.assessmentFormatOptionId
  //           } as SelectedAssessmentFormatOption)
  //         }
  //       })
  //     })
  //   })
  // },

  async [StaarAccommodationsActions.SetStudentSubjectAccommodations](
    { commit, state, rootState }, req: GetStudentAccommodationsRequest
  ) {
    const { teacherId, meetingId, paging } = req
    const payload = {
      meetingId,
      teacherId,
      paging: paging || state.staarAccommodationStudentPaging.paging
    }
    await decisionsService
    .getStudentSubjectAccommodations(rootState, payload)
    .then(resp => {
      const { studentAccommodations, pagination } = resp

      commit(StaarAccommodationMutations.SetStudentAccommodationPagination, pagination)
      commit(StaarAccommodationMutations.SetStudentSubjectAccommodations, studentAccommodations)
    })
  },

  async [StaarAccommodationsActions.SetTeacherSignedStatus]( { commit, rootState }, meetingId: number) {
    const allTechersSigned: boolean = await signService.getTeacherCompletionStatus(rootState, meetingId)
    commit(StaarAccommodationMutations.SetAllTeachersSigned, allTechersSigned)
  },

  async [StaarAccommodationsActions.SetTeachers](
    { commit, state, rootState }, req: GetMeetingTeachersRequest
  ) {
    const { meetingId, staffId, paging } = req
    const payload = {
      meetingId,
      staffId,
      paging: paging || state.staarAccommodationsTeachers.paging
    }
    await decisionsService
      .getMeetingStaarTeachers(rootState, payload)
      .then(resp => {
        const { meetingStaarTeacher, pagination } = resp

        commit(StaarAccommodationMutations.SetTeachers, {
          teachers: meetingStaarTeacher,
          pagination
        })
      })
  },
  [StaarAccommodationsActions.SelectAssessmentFormatOpt]({ commit }, selectedAssessmentFormatOpt: SelectedAssessmentFormatOption) {
    commit(StaarAccommodationMutations.SelectAssessmentFormatOpt, selectedAssessmentFormatOpt)
  },
  [StaarAccommodationsActions.RemoveAssessmentFormatOpt]({ commit }, selectedAssessmentFormatOpt: SelectedAssessmentFormatOption) {
    commit(StaarAccommodationMutations.RemoveSelectedAssessmentFormatOpt, selectedAssessmentFormatOpt)
  },
  async [StaarAccommodationsActions.SaveAccommodationCommittees]({ commit, rootState }, req: SaveAccommodationCommitteeRequest) {
    await decisionsService.saveAccommodationCommittees(rootState, req)
    commit(StaarAccommodationMutations.ResetSigningStatusForTeachers, req.teacherIds)
  },
  async [StaarAccommodationsActions.InitateStaarTeacherSigning]({ commit, rootState }, req: InitiateStaarDecisionSigningRequest) {
    const resp: InitiateStaarDecisionSigningResponse = await signService.initiateTeacherStaarDecisionSigning(rootState, req)
    commit(StaarAccommodationMutations.SetCurrentSigningReqId, resp.signatureRequestId)

  },
  async [StaarAccommodationsActions.TeacherSignNow]({ commit, rootState }, req: SignNowRequest) {
    const signUrl = await signService.signNow(rootState, req)
    commit(StaarAccommodationMutations.SetCurrentSigningUrl, signUrl)
  },

  async [StaarAccommodationsActions.SaveSigningStatus]({ commit, rootState }, req: SigningStatusRequest) {
    const resp = await signService.saveSigningStatus(rootState, req)
    commit(StaarAccommodationMutations.SaveSigningStatus, resp)
  },

  async [StaarAccommodationsActions.SetLastSign]({ commit }, lastSign: boolean) {
    commit(StaarAccommodationMutations.SetLastSign, lastSign)
  },
  async [StaarAccommodationsActions.RetrieveTeacherSignedFiles]({ rootState }, req: RetrieveTeacherSignedFilesRequest) {
    await signService.retrieveSignedTeacherStaarFiles(rootState,req)
  },

  [StaarAccommodationsActions.ClearSelectedAccomForSubject]({ commit }, formatOptSelected: SelectedAssessmentFormatOption) {
    commit(StaarAccommodationMutations.ClearSelectedAccomForSubject, formatOptSelected)
  },
  [StaarAccommodationsActions.RemoveTeacherIdFromMapAndSet]({ commit }, teacherId: number) {
    commit(StaarAccommodationMutations.RemoveTeacherIdFromMapAndSet, teacherId)
  },
  [StaarAccommodationsActions.AddTeacherToSet]({ commit }, teacherId: number) {
    commit(StaarAccommodationMutations.AddTeacherToSet, teacherId)
  },
  [StaarAccommodationsActions.AddTeacherToExpanded]({ commit }, teacherId: number) {
    commit(StaarAccommodationMutations.AddTeacherToExpanded, teacherId)
  },
  [StaarAccommodationsActions.RemoveTeacherFromExpanded]({ commit }, teacherId: number) {
    commit(StaarAccommodationMutations.RemoveTeacherFromExpanded, teacherId)
  },
  async [StaarAccommodationsActions.SaveStudentMeetingNote]({ rootState }, req: UpdateMeetingNotesRequest) {
    await studentService.saveStudentNote(rootState, req)
  },
  async [StaarAccommodationsActions.GetStudentStaarNotes]({ commit, rootState }, req: GetStaarStudentNotesRequest) {
    const studentNoteLst: StudentNote[] = await studentService.getStaarStudentNotes(rootState, req)
    commit(StaarAccommodationMutations.SetStudentStaarNotes, studentNoteLst)
  },
  async [StaarAccommodationsActions.SendAccommodationsRequest]({ rootState }, req: GetStaarStudentNotesRequest) {
    await authService.sendTeacherStaarLinks(rootState, req)
  }

}
