import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { CompletedMeetingsState } from './state'

export enum CompletedMeetingsGetters {
  MeetingDate = 'MEETING_DATE'
}

export const getters: GetterTree<CompletedMeetingsState, RootState> = {

}
