import {MutationTree} from 'vuex'
import {state as clearState, RecommendationsState} from './state'
import {RecommendationEdits} from './types'
import {
  DistrictLanguage,
  MeetingPlacement, MeetingRoster,
  PriorMeetingPlacement,
  ProgramType
} from 'models'
import isEmpty from 'lodash/isEmpty'
import {MeetingStatus, MeetingTypeCode} from '@/enums'
import cloneDeep from 'lodash/cloneDeep';

export enum RecommendationsMutations {
  Clear = 'CLEAR',
  SetRoster = 'SET_ROSTER',
  SetRecommendationEdits = 'SET_RECOMMENDATION_EDITS',
  SetMonitorRecommendationEdits = 'SET_MONTIOR_RECOMMENDATION_EDITS',
  SetDualLanguageYear = 'SET_DUAL_LANGUAGE_YEAR',
  SetDualLanguageLang = 'SET_DUAL_LANGUAGE_LANGUAGE',
  SetPriorMeetingPlacement = 'SET_PRIOR_MEETING_PLACEMEHT',
  SetDefaultMeetingPlacement = 'SET_DEFAULT_MEETING_PLACEMENT',
  SetMeetingStatus = 'SET_MEETING_STATUS',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<RecommendationsState> = {

  [RecommendationsMutations.Clear](state) {
    Object.assign(state, clearState())
  },

  [RecommendationsMutations.SetRoster](state, roster: MeetingRoster) {
    state.roster = roster
  },

  [RecommendationsMutations.SetPriorMeetingPlacement](state, priorMeetingPlacement: PriorMeetingPlacement) {
    state.priorMeetingPlacement = {} as MeetingPlacement
    if (!isEmpty(priorMeetingPlacement)) {
      state.priorMeetingPlacement = priorMeetingPlacement
    }
  },

  [RecommendationsMutations.SetDefaultMeetingPlacement](state) {
    const isReviewLpac: boolean = state.roster.meetingType.code === MeetingTypeCode.ReviewLpac
    const meetingPlacement: MeetingPlacement = state.roster.meetingPlacement
    if (!isEmpty(state.priorMeetingPlacement)) {
      if (isReviewLpac && !meetingPlacement) {
        state.recommendationEdits.lepStatus = state.priorMeetingPlacement.lepStatus
        state.recommendationEdits.programType = state.priorMeetingPlacement.programType
        if (state.priorMeetingPlacement.dualLanguage) {
          state.dualLanguage = state.priorMeetingPlacement.dualLanguage
        }
      }
    }
    if (state.meetingStatus === MeetingStatus.F && state.roster.currentPlacement.dualLanguage) {
      state.dualLanguage = state.roster.currentPlacement.dualLanguage
    }
  },

  [RecommendationsMutations.SetRecommendationEdits](state, edits: RecommendationEdits) {
    state.recommendationEdits = edits
  },

  [RecommendationsMutations.SetMonitorRecommendationEdits](state, edits: RecommendationEdits) {
    state.monitorRecommendiationEdits = edits
  },

  [RecommendationsMutations.SetDualLanguageYear](state, year: ProgramType) {
    state.dualLanguage.year = year
  },

  [RecommendationsMutations.SetDualLanguageLang](state, language: DistrictLanguage) {
    state.dualLanguage.language = language
  },

  [RecommendationsMutations.SetMeetingStatus](state, status: MeetingStatus) {
    state.meetingStatus = status
  },

  // Only use this mutation for setting the state for test cases
  [RecommendationsMutations.SetTestState] (state, testData: RecommendationsState) {
    Object.assign(state, cloneDeep(testData))
  }

}
