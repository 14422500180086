module.exports = {
  primary: '#EB2227',
  primaryDark: '#9E194D',
  primaryHighlight: '#EFEFEF',

  secondary: '#747778',
  secondaryDark: '#E5840D',
  secondaryHighlight: '#EFEFEF',

  tertiary: '#412B86',
  tertiaryDark: '#1c123a',
  tertiaryHighlight: '#D8D0F0',

  success: '#2F8C3E',
  successDark: '#6FAA23',
  successHighlight: '#F2FBF3',
  successDarker: '#2F8C3E',

  warning: '#EB2227',
  warningDark: '#B92212',
  warningHighlight: '#FFE7E5',
  warningAccent: '#E44235',

  selected: '#FFFFEB',
  aside: '#A5DCFF',
  shadow: '#F2F1F1',
  shadowDark: '#DEDDDD',
  purple: '#9B58B6',
  orange: '#F39C12',

  black: '#333645',
  slate: '#A3A7B2',
  gray: '#D3DADA',
  silver: '#EBEBEB',
  white: '#FFF',
  yellow: '#ffff00',
  red: '#ff0000',
  green: '#04CC49'
}
