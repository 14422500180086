import { RootState } from './state'
import { GetterTree } from 'vuex'
import { Terms } from './types'
import { TermCode, ProficiencyCategoryType, SchoolTypeCode } from '@/enums'
import {
  Term,
  ELSchool,
  ELDistrict,
  Language,
  ProgramType,
  ParentPermissionDecision,
  DashboardType,
  LimitedEnglishProficiencyStatus,
  AAPTTestType,
  OlptTestType,
  TestScoreLevel,
  TestDesignation,
  TestProficiencyLevel,
  AssessmentFormatOptionType,
  ProficiencyCategory,
  ProficiencyLevel,
  ProficiencyTestType, EnrollmentType, TransferStatement, TransferOption, FeatureToggle, AccommodationCommittee
} from 'models'
import sortBy from 'lodash/sortBy'
import flatMap from 'lodash/flatMap'
import cloneDeep from 'lodash/cloneDeep'
import flatten from 'lodash/flatten'
import {first} from 'lodash';

export enum RootGetters {
  Terms = 'TERMS',
  Languages = 'LANGUAGES',
  ProgramTypes = 'PROGRAM_TYPES',
  ParentalDecisions = 'PARENTAL_DECISIONS',
  DashboardType = 'DASHBOARD_TYPE',
  LEPStatuses = 'LEP_STATUSES',
  OlptTestTypes = 'OLPT_TEST_TYPES',
  OlptTestDesignations = 'OLPT_TEST_DESIGNATIONS',
  OlptTestScoreLevels = 'OLPT_TEST_SCORE_LEVELS',
  OlptTestProficiencyLevels = 'OLPT_TEST_PROFICIENCY_LEVELS',
  SelectedSchool = 'SELECTED_SCHOOL',
  SelectedSchoolId = 'SELECTED_SCHOOL_ID',
  SelectedSchoolName = 'SELECTED_SCHOOL_NAME',
  SelectedDistrict = 'SELECTED_DISTRICT',
  SelectedDistrictName = 'SELECTED_DISTRICT_NAME',
  SelectedDistrictId = 'SELECTED_DISTRICT_ID',
  SelectedDistrictSalesForceId = 'SELECTED_DISTRICT_SALES_FORCE_ID',
  AAPTTestTypes = 'AAPT_TEST_TYPS',
  AssessmentFormatTypes = 'ASSESSMENT_FORMAT_TYPES',
  ProficiencyCategories = 'PROFICIENCY_CATEGORIES',
  ProficiencyCategoriesMap = 'PROFICIENCY_CATEGORIES_MAP',
  ProficiencyLevels = 'PROFICIENCY_LEVELS',
  ProficiencyTestTypes = 'PROFICIENCY_TEST_TYPES',
  CovidActive = 'COVID_ACTIVE',
  ShowCLS = 'SHOW_CLS',
  CurrentEnv = 'CURRENT_ENV',
  EnrollmentTypes = 'ENROLLMENT_TYPES',
  TransferStatements = 'TRANSFER_STATEMENTS',
  AccommodationCommittees = 'ACCOMMODATION_COMMITTEES',
  RequiredTransferOptions = 'REQUIRED_TRANSFER_OPTIONS',
  DualLanguageActive = 'DUAL_LANGUAGE_ACTIVE',
  FeatureToggle = 'FEATURE_TOGGLE',
  DistrictSchools = 'DISTRICT_SCHOOLS',
  ShowStudentSearchToggle = 'SHOW_STUDENT_SEARCH_TOGGLE',
  MultiMeetingsActive = 'MULTI_MEETINGS_ACTIVE',
  MeetingTypeLimit = 'MEETING_TYPE_LIMIT',
  UsePeims = 'USE_PEIMS',
  SupportPhoneWithoutExtension = 'SUPPORT_PHONE_WITHOUT_EXTENSION',
  ZendeskEnabled = 'ZENDESK_ENABLED',
  FreshchatEnabled = 'FRESHCHAT_ENABLED'
}

export const getters: GetterTree<RootState, RootState> = {

  [RootGetters.Terms](state): Terms {
    return sortBy<Term>(state.terms, [term => term.criteria.dflt]).reduce((all, term) => {
      return Object.assign(all, {
        [term.code as TermCode]: term.name,
        [term.codePlural as TermCode]: term.namePlural
      })
    }, {} as Terms)
  },

  [RootGetters.Languages](state): Language[] {
    return state.dimensions.languages
  },

  [RootGetters.ProgramTypes](state): ProgramType[] {
    return state.dimensions.programTypes
  },

  [RootGetters.ParentalDecisions](state): ParentPermissionDecision[] {
    if (state.dimensions.parentPermissionDecisions) {
      let ppdLets = state.dimensions.parentPermissionDecisions.filter(ppd => !/^-{0,1}\d+$/.test(ppd.eSchoolCode))
      let ppdNums = state.dimensions.parentPermissionDecisions.filter(ppd => /^-{0,1}\d+$/.test(ppd.eSchoolCode))
      ppdLets = ppdLets.sort((a, b) => {
        const aesc = a.eSchoolCode.toLowerCase()
        const besc = b.eSchoolCode.toLowerCase()
        return aesc < besc ? -1 : aesc > besc ? 1 : 0
      })
      ppdNums = ppdNums.sort()
      return ppdLets.concat(ppdNums)
    }

    return []
  },

  [RootGetters.DashboardType](state): DashboardType[] {
    return state.dimensions.dashboardType
  },

  [RootGetters.LEPStatuses](state): LimitedEnglishProficiencyStatus[] {
    return state.dimensions.lepStatuses
  },

  [RootGetters.SelectedSchool](state): ELSchool {
    if (state.districtSchools && state.selected) {
      return flatMap(state.districtSchools, district => district.schools)
        .find(school => school.id === state.selected.schoolId)
    }
  },

  [RootGetters.SelectedSchoolName](state, getters): string {
    const school: ELSchool = getters[RootGetters.SelectedSchool]

    if (school) {
      return school.name
    }
  },

  [RootGetters.SelectedSchoolId](state, getters): number {
    const school: ELSchool = getters[RootGetters.SelectedSchool]

    return school?.id
  },

  [RootGetters.SelectedDistrict](state): ELDistrict {
    if (state.districtSchools && state.selected) {
      return state.districtSchools.find(district => district.schools.some(school => school.id === state.selected.schoolId))
    }
  },

  [RootGetters.SelectedDistrictName](state, getters): string {
    const district: ELDistrict = getters[RootGetters.SelectedDistrict]

    if (district) {
      return district.name
    }
  },

  [RootGetters.SelectedDistrictId](state, getters): number {
    const district: ELDistrict = getters[RootGetters.SelectedDistrict]

    return district?.id
  },

  [RootGetters.SelectedDistrictSalesForceId](state, getters): string {
    const district: ELDistrict = getters[RootGetters.SelectedDistrict]

    return district?.salesForceId
  },

  [RootGetters.DistrictSchools](state, getters): ELSchool[] {
    const district: ELDistrict = getters[RootGetters.SelectedDistrict]

    if (district) {
      const districtSchools = state.districtSchools.find(dstSch => dstSch.id === district.id)
      if (districtSchools) {
        return districtSchools.schools.filter(school => school.schoolTypeCode === SchoolTypeCode.D)
      }
    }

  },

  [RootGetters.AAPTTestTypes](state): AAPTTestType[] {
    return state.dimensions.aaptTestTypes
  },

  [RootGetters.OlptTestTypes](state): OlptTestType[] {
    return state.dimensions.olptTestTypes
  },

  [RootGetters.OlptTestScoreLevels](state): TestScoreLevel[] {
    return state.dimensions.olptTestScoreLevels
  },

  [RootGetters.OlptTestDesignations](state): TestDesignation[] {
    return state.dimensions.olptTestDesignations
  },

  [RootGetters.OlptTestProficiencyLevels](state): TestProficiencyLevel[] {
    return state.dimensions.olptTestProficiencyLevels
  },
  [RootGetters.AssessmentFormatTypes](state): AssessmentFormatOptionType[] {
    return state.dimensions.assessmentFormatOptionTypes
  },
  [RootGetters.ProficiencyCategories](state): ProficiencyCategory[] {
    return state.dimensions.proficiencyCategories
  },
  [RootGetters.ProficiencyCategoriesMap](state): Map<ProficiencyCategoryType, ProficiencyCategory> {
    return new Map(state.dimensions.proficiencyCategories.map(cat => [cat.proficiencyCategoryType as ProficiencyCategoryType, cat]))
  },
  [RootGetters.ProficiencyLevels](state): ProficiencyLevel[] {
    return state.dimensions.proficiencyLevels
  },
  [RootGetters.ProficiencyTestTypes](state): ProficiencyTestType[] {
    return state.dimensions.proficiencyTestTypes
  },
  [RootGetters.CovidActive](state): boolean {
    return state.dimensions.covidActive
  },
  [RootGetters.MeetingTypeLimit](state): string {
    return state.dimensions.meetingTypeLimit
  },
  [RootGetters.ShowStudentSearchToggle](state): boolean {
    return state.dimensions.showStudentSearchToggle
  },
  [RootGetters.ShowCLS](state): boolean {
    return state.dimensions.showCLS
  },
  [RootGetters.UsePeims](state): boolean {
    return state.dimensions.usePeims
  },
  [RootGetters.DualLanguageActive](state): boolean {
    return state.dimensions.dualLanguageActive
  },
  [RootGetters.CurrentEnv](state): string {
    return state.dimensions.currentEnv
  },
  [RootGetters.EnrollmentTypes](state): EnrollmentType[] {
    return state.dimensions.enrollmentTypes
  },
  [RootGetters.FeatureToggle](state): FeatureToggle[] {
    return state.features
  },
  [RootGetters.TransferStatements](state): TransferStatement[] {
    const transferStatementCopy = cloneDeep(state.dimensions.transferStatements)
    const sortedTransferStatements = transferStatementCopy.sort((statement1, statement2) => {
      if (statement1.order < statement2.order) {
        return -1
      }
      if (statement1.order > statement2.order) {
        return 1
      }
      return 0
    })
    sortedTransferStatements.forEach(statement => {
      statement.transferOptions = statement.transferOptions.sort((opt1, opt2) => {
        if (opt1.order < opt2.order) {
          return -1
        }
        if (opt1.order > opt2.order) {
          return 1
        }
        return 0
      })
    })
    return sortedTransferStatements
  },
  [RootGetters.AccommodationCommittees](state): AccommodationCommittee[] {
    return state.dimensions.accommodationCommittees
  },
  [RootGetters.RequiredTransferOptions](state): TransferOption[] {
    return flatten(state.dimensions.transferStatements.filter(statement => statement.required).map(statement => statement.transferOptions))
  },
  [RootGetters.MultiMeetingsActive](state): boolean {
    const feature: FeatureToggle = state.features.find(feature => feature.name === 'Multiple Meetings')
    if (feature) {
      return feature.enabled
    }
    return true
  },
  [RootGetters.SupportPhoneWithoutExtension](state): string {
    return first(state.support.phone.split('x'))
  },
  [RootGetters.ZendeskEnabled](state): boolean {
    return state.zendeskStatus
  },
  [RootGetters.FreshchatEnabled](state): boolean {
    return state.freshchatStatus
  }
}
