import cloneDeep from 'lodash/cloneDeep'
import {
  Meeting,
  MeetingType,
  Pagination,
  StudentSchoolGrade,
  StudentPaging,
  PagedSelectedItems,
  Student,
  MeetingPlacement,
  MeetingsPaging,
  AssessmentStudentScore,
  StudentGradeAccommodation,
  StudentMarkingPeriodGrades,
  AssessmentAttribute,
  StudentDocument
} from 'models'
import { MeetingTypeCode, ZipFileStatus } from '@/enums'
import { PagingUtil } from '@806/utils'

export interface ViewStudentsState {
  loaded: boolean
  meetings: Meeting[]
  code: MeetingTypeCode
  showInactive: boolean
  isDPU: boolean
  types: MeetingType[]
  students: StudentSchoolGrade[]
  allStudentIds: number[]
  pagination: Pagination
  paging: StudentPaging
  meetingsPaging: MeetingsPaging
  selectedStudents: PagedSelectedItems
  studentSchoolGrade: StudentSchoolGrade
  meetingPlacement: MeetingPlacement
  student: Student
  studentId: number
  params: StudentPaging
  staarScores: AssessmentStudentScore[]
  telpasScores: AssessmentStudentScore[]
  testTypes: AssessmentAttribute[]
  studentAccommodations: StudentGradeAccommodation[]
  studentMarkingPeriodGrades: StudentMarkingPeriodGrades[]
  teacherDocumentLocation: string,
  schoolYear: string,
  zipDocument: StudentDocument,
  zipFileStatus: ZipFileStatus,
  hasDocs: boolean
}

export const state: ViewStudentsState = {
  loaded: false,
  meetings: [],
  code: null,
  showInactive: false,
  isDPU: false,
  types: [],
  students: [],
  allStudentIds: [],
  pagination: {},
  paging: PagingUtil.initialPaging(),
  meetingsPaging: PagingUtil.initialPaging(),
  selectedStudents: PagingUtil.initialSelectedItems(),
  studentSchoolGrade: {},
  meetingPlacement: {},
  student: {},
  studentId: null,
  params: null,
  staarScores: [],
  telpasScores: [],
  testTypes: [],
  studentAccommodations: [],
  studentMarkingPeriodGrades: [],
  teacherDocumentLocation: null,
  schoolYear: null,
  zipDocument: null,
  zipFileStatus: ZipFileStatus.None,
  hasDocs: false
}

export const clearState: ViewStudentsState = cloneDeep(state)
