import cloneDeep from 'lodash/cloneDeep'
import {
  MeetingType,
  Pagination,
  StudentSchoolGrade,
  StudentPaging,
  PagedSelectedItems
} from 'models'
import { MeetingTypeCode } from '@/enums'
import { PagingUtil } from '@806/utils'

export interface CreateMeetingState {
  loaded: boolean
  code: MeetingTypeCode
  all: boolean
  types: MeetingType[]
  students: StudentSchoolGrade[]
  allStudentIds: number[]
  pagination: Pagination
  paging: StudentPaging
  selectedStudents: PagedSelectedItems
}

export const state: CreateMeetingState = {
  loaded: false,
  code: null,
  all: false,
  types: [],
  students: [],
  allStudentIds: [],
  pagination: {},
  paging: PagingUtil.initialPaging(),
  selectedStudents: PagingUtil.initialSelectedItems()
}

export const clearState: CreateMeetingState = cloneDeep(state)
