import cloneDeep from 'lodash/cloneDeep'
import { ContentLanguageControlOptions, RosterOptionEdit } from 'models'
import { InitialCLSOptions } from './types'

export interface ContentLanguageSupportState {
  supportOptions: ContentLanguageControlOptions[],
  supportOptionEdits: RosterOptionEdit[],
  carryOverOptions: InitialCLSOptions[],
  selectedCategories: number[],
  inMeeting: boolean
}

export const state: ContentLanguageSupportState = {
  supportOptions: [],
  supportOptionEdits: [],
  carryOverOptions: [],
  selectedCategories: [],
  inMeeting: false
}

export const clearState: ContentLanguageSupportState = cloneDeep(state)
