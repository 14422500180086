import {ActionTree} from 'vuex';
import {RootState} from '@/store/root';
import {LayoutState} from '@/store/modules/layout/state';


export enum LayoutActions {
  //
}

export const actions: ActionTree<LayoutState, RootState> = {
  //
}
