import { ReportRequest } from 'models'

export interface DownloadReportRequest {
  reportType: string,
  request: ReportRequest
}

export interface ReportOptionError {
  headerText: string,
  message: string
}

export type GenerateReport = (request: DownloadReportRequest) => Promise<void>

export type GenerateCLSReport = (meetingId: number) => Promise<void>

export const errorMessages: { [K: string]: ReportOptionError } = {
  'School': {
    headerText: 'Please Select a School',
    message: 'Please select a school or campus type from the School dropdown menu. If you require a specific set of campuses, please click the Filter button for more options.' },
  'Staff': {
    headerText: 'Please Select a Staff Member',
    message: 'Please select a teacher or staff member for this report. If you require multiple staff for this report, please click the Filter button for more options.' },
  'Reviewer': {
    headerText: 'Please Select a Reviewer',
    message: 'Please select a Reviewer for this report. If you require multiple Reviewers for this report, please click the Filter button for more options.' },
  'Teacher': {
    headerText: 'Please Select a Teacher',
    message: 'Please select a Teacher for this report. If you require multiple Teachers for this report, please click the Filter button for more options.' },
  'DOWNLOAD_TYPE': { headerText: 'Please Select a File Format', message: 'Please select the file format in which this report will be downloaded.' }
}

export const typeConversion: {[K: string]: string } = {
  'E' : 'Elementary School',
  'M' : 'Middle/Jr School',
  'H' : 'High School',
  'O' : 'Other'
}
