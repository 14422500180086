import { Getter, Action, AuthGetter, State} from '@/store/helpers'
import { AuthGetters } from '@/store/modules/auth'
import { RootActions, RootGetters } from '@/store/root'
import { retry } from '@806/utils'
import isEmpty from 'lodash/isEmpty'
import { LoggerMixin } from '@806/web'
import trim from 'lodash/trim'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import freshchat from './freshchat'
import loader from './loader'
import { Freshchat, FreshchatUser, Olark } from './types'
import { ELSchoolYear } from 'models'
import { tracker } from '@/util/open-replay'


/**
 * Empty template component that updates Olark with current user info.
 *
 * Olark is loaded in the the main index.html.
 */
@Component({
  render: h => h()
})
export default class SupportChat extends Mixins(LoggerMixin) {

  protected olark: Olark

  protected freshchat: Freshchat

  @Getter(RootGetters.SelectedDistrictName)
  protected districtName: string

  @Getter(RootGetters.SelectedDistrictId)
  protected districtId: number

  @Getter(RootGetters.SelectedSchoolName)
  protected schoolName: string

  @Getter(RootGetters.SelectedSchoolId)
  protected campusId: number

  @Getter(RootGetters.SelectedDistrictSalesForceId)
  protected salesForceId: string

  @Action(RootActions.GetOlarkStatus)
  getOlarkStatus

  @Action(RootActions.GetFreshchatStatus)
  getFreshchatStatus

  @State
  olarkStatus: boolean

  @State
  freshchatStatus: boolean

  @State
  protected currentSchoolYear: ELSchoolYear

  @AuthGetter(AuthGetters.UserFullName)
  protected userFullName: string

  @AuthGetter(AuthGetters.UserTitle)
  protected userTitle: string

  @AuthGetter(AuthGetters.UserEmail)
  protected userEmail: string

  @AuthGetter(AuthGetters.UserRoleName)
  protected userRoleName: string

  @AuthGetter(AuthGetters.UserID)
  protected userId: number

  protected get nickname (): string | void {
    const parts: string[] = [
      this.userFullName,
      this.userTitle,
      this.districtName,
      this.schoolName,
      this.userEmail,
      this.userRoleName,
      tracker.getSessionID()
    ]

    if (parts.every(part => !isEmpty(trim(part)))) {
      return parts.join(' | ')
    }
  }

  protected async mounted() {
    try {
      await this.getFreshchatStatus()
    } catch (error) {
      this.logger.error('Chat status failed', error)
    }

    if(this.freshchatStatus) {
      this.freshchatSetup()
    }
  }

  @Watch('nickname', { immediate: true })
  protected updateNickname () {
    if (!this.freshchat) {
      return
    }

    if (this.nickname) {
      const firstName = this?.userFullName?.split(' ').slice(0, -1).join(' ')
      const lastName = this?.userFullName?.split(' ').slice(-1).join(' ')

      const freshVars: FreshchatUser =
      {
        role: this.userRoleName,
        district: this.districtName,
        campus: this.schoolName,
        openreplayLink: `${tracker.getSessionURL()}`
      }
        this.freshchat.user.setFirstName(firstName)
        this.freshchat.user.setLastName(lastName)
        this.freshchat.user.setEmail(this.userEmail)
        this.freshchat.user.setProperties(freshVars)
    }
  }

  protected async clearUserInfo() {
    if (!this.freshchat) {
      return Promise.resolve()
    }
    return this.freshchat.user.clear()
  }


  protected async freshchatSetup() {
    if(!window.fcWidget) {
      freshchat()
    }

    try {
      this.freshchat = await retry(() => window.fcWidget, {
        interval: 250,
        max: 20
      })
    } catch (error) {
      this.logger.error('Freshchat failed', error)
      return
    }
    const exists = await this.freshchat.user.isExists()

    if (exists?.data) {
    await this.clearUserInfo()
    }
    this.updateNickname()
  }

      protected async olarkSetup() {
        try {
          if(!window.olark) {
            loader()
          }
          this.olark = await retry(() => window.olark, {
            interval: 250,
          })
          this.olark.identify(process.env.OLARK_API_KEY)

          this.updateNickname()
        } catch (error) {
          this.logger.error('Olark not loaded', error)
        }
      }

}
