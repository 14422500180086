import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { CreateMeetingState } from './state'
import { MeetingType } from 'models'

export enum CreateMeetingGetters {
  Type = 'TYPE',
  TypeName = 'TYPE_NAME',
  NoStudents = 'NO_STUDENTS'
}

export const getters: GetterTree<CreateMeetingState, RootState> = {

  [CreateMeetingGetters.Type] (state): MeetingType | void {
    if (state.types) {
      return state.types.find(type => type.code === state.code)
    }
  },

  [CreateMeetingGetters.TypeName] (state, getters): string | void {
    const type: MeetingType = getters[CreateMeetingGetters.Type]

    if (type) {
      return type.name
    }
  },

  [CreateMeetingGetters.NoStudents] (state): boolean {
    return state.loaded && state.pagination.unfilteredTotal === 0
  }

}
