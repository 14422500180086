import { AuthMutations } from '@/store/modules/auth'
import {
  isSelected,
  RootMutations,
  RootState,
  Selected
} from '@/store/root'
import { SessionVariables } from '@/util/SessionVariables'
import { Plugin } from 'vuex'
import { Logger, LoggerFactory } from '@806/utils'

const log: Logger = LoggerFactory.getLogger('selected session')

export const selectedSession: Plugin<RootState> = store => {
  const stored = sessionStorage.getItem(SessionVariables.Selected)

  if (stored) {
    try {
      const selected = JSON.parse(stored) as Selected

      if (isSelected(selected)) {
        store.commit(RootMutations.SetSelected, selected)
      }
    } catch (error) {
      log.error('Error parsing selected session variable.', error)

      sessionStorage.removeItem(SessionVariables.Selected)
    }
  }

  store.subscribe((mutation, state) => {
    switch (mutation.type) {

      case `auth/${AuthMutations.SetAuthorized}`:
        if (!mutation.payload) {
          sessionStorage.removeItem(SessionVariables.Selected)
        }
        break

      case RootMutations.SetSelected:
        sessionStorage.setItem(SessionVariables.Selected, JSON.stringify(state.selected))
        break
    }
  })
}
