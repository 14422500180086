import {GetterTree} from 'vuex';
import {CustomFilterState} from '@/store/modules/custom-filter/state';
import {RootState} from '@/store/root';
import {CustomFilterComponent} from '@/enums';
import {CustomFilterMenu, CustomFilterOption} from 'models';

export enum CustomFilterGetters {
  GetFilterMenu = 'GET_FILTER_MENU',
  GetType = 'GET_TYPE',
  GetSelected = 'GET_SELECTED'
}


export const getters: GetterTree<CustomFilterState, RootState> = {
  [CustomFilterGetters.GetFilterMenu](state): (type: CustomFilterComponent) => CustomFilterMenu {
    return type => {
      return state.filterMenus.find(menu => menu.type === type)
    }
  },

  [CustomFilterGetters.GetType](state): CustomFilterComponent {
    return state.type
  },

  [CustomFilterGetters.GetSelected](state): CustomFilterOption[] {
    return state.selected
  }
}
