import Vue from 'vue'
import { ActionTree } from 'vuex'
import {
  termService,
  dimensionService,
  dimensionalLookupService,
  authService,
  signService,
  featureService,
  schoolService,
  externalTogglesService, ssoService, zendeskService
} from '@/services'
import { RootState } from './state'
import { RootMutations } from './mutations'
import {
  NavigableChangeDistrict,
  NavigableChangeSchool,
  NavigableChangeSelected
} from './types'
import {HelloSignConfigResponse, SaveFeatureTogglesRequest, ToggleStatusResponse, ZendeskUserRequest} from 'models'
import { AuthGetters } from '@/store/modules/auth'
import first from 'lodash/first'
import isFunction from 'lodash/isFunction'
import { tracker } from '@/util/open-replay'
import {ExternalToggle} from '@/enums'

export enum RootActions {
  GetTerms = 'GET_TERMS',
  GetFeatures = 'GET_FEATURES',
  GetGrades = 'GET_GRADES',
  GetMeetingReviewAddresses = 'GET_MEETING_REVIEW_ADDRESSES',
  GetDimensions = 'GET_DIMENSIONS',
  GetDistrictSchools = 'GET_DISTRICT_SCHOOLS',
  GetSchoolRole = 'GET_SCHOOL_ROLE',
  ChangeDistrict = 'CHANGE_DISTRICT',
  ChangeSchool = 'CHANGE_SCHOOL',
  GetHelloSignConfig = 'GET_HELLOSIGN_CONFIG',
  SetFeatures = 'SET_FEATURES',
  GetSchoolYears = 'GET_SCHOOL_YEARS',
  GetDistrictLanguages = 'GET_DISTRICT_LANGUAGES',
  GetOlarkStatus = 'GET_OLARK_STATUS',
  GetFreshchatStatus = 'GET_FRESH_CHAT_STATUS',
  GetHellosignStatus = 'GET_HELLO_SIGN_STATUS',
  SetAllProducts = 'SET_ALL_PRODUCTS',
  UpdateZendeskUser = 'UPDATE_ZENDESK_USER',
  GetZendeskStatus = 'GET_ZENDESK_STATUS',
  GetZendeskJwt = 'GET_ZENDESK_JWT'
}

export const actions: ActionTree<RootState, RootState> = {

  async [RootActions.GetTerms]({ commit, rootState }) {
    const terms = await termService.getTerms(rootState)

    commit(RootMutations.SetTerms, terms)
  },

  async [RootActions.GetMeetingReviewAddresses]({ commit, rootState }) {
    const meetingReviewAddresses = await dimensionalLookupService.getMeetingReviewAddresses(rootState)
    commit(RootMutations.SetMeetingReviewAddresses, meetingReviewAddresses)
  },

  async [RootActions.GetGrades]({ commit, rootState }) {
    const grades = await dimensionalLookupService.getAllGrades(rootState)
    commit(RootMutations.SetGrades, grades)
  },

  async [RootActions.GetSchoolYears]({ commit, rootState }) {
    const schoolYears = await schoolService.getSchoolYears(rootState)
    const currentSchoolYear = schoolYears.find(year => year.currentYear === true)
    commit(RootMutations.SetCurrentSchoolYear, currentSchoolYear)
    commit(RootMutations.SetSchoolYears, schoolYears)
  },

  async [RootActions.GetDistrictLanguages]({ commit, rootState }) {
    const languages = await schoolService.getDistrictLanguages(rootState)
    commit(RootMutations.SetDistrictLanguages, languages)
  },

  async [RootActions.GetDimensions]({ commit, rootState }) {
    const dimensions = await dimensionService.getDimensions(rootState)
    commit(RootMutations.SetDimensions, dimensions)
  },

  async [RootActions.GetDistrictSchools]({ commit, rootState }) {
    const districts = await authService.getUserDistrictSchools(rootState)

    commit(RootMutations.SetDistrictSchools, districts)
  },

  async [RootActions.GetFeatures]({ commit, rootState }) {
    const features = await featureService.getFeatures(rootState)

    commit(RootMutations.SetAllFeatureToggles, features)
  },

  async [RootActions.SetFeatures]({ commit, rootState }, featureToggles: SaveFeatureTogglesRequest) {
    const features = await featureService.setFeatures(rootState, featureToggles)

    commit(RootMutations.SetAllFeatureToggles, features)
  },

  async [RootActions.GetSchoolRole]({ commit, rootState }) {
    const schoolRole = await authService.getUserSchoolRole(rootState)

    commit(RootMutations.SetSchoolRole, schoolRole)
  },

  async [RootActions.ChangeDistrict]({ dispatch, rootGetters }, { district, navigate }: NavigableChangeDistrict) {
    const userSchoolId: number = rootGetters[`auth/${AuthGetters.UserSchoolID}`]
    const userSchool = district.schools.find(school => school.id === userSchoolId)
    const districtLookupCode: string = district.salesForceId
    const { id: districtId } = district
    const { id: schoolId } = userSchool ? userSchool : first(district.schools)
    const changeSelected: NavigableChangeSelected = { selected: { districtId, schoolId, districtLookupCode }, navigate }

    await dispatch('changeSelected', changeSelected)
  },

  async [RootActions.ChangeSchool]({ dispatch, state }, { school, navigate }: NavigableChangeSchool) {
    const { districtId, districtLookupCode } = state.selected
    const { id: schoolId } = school
    const changeSelected: NavigableChangeSelected = { selected: { districtId, schoolId, districtLookupCode }, navigate }

    await dispatch('changeSelected', changeSelected)
  },

  /**
   * Internal use only. Use ChangeDistrict and ChangeSchool.
   */
  async changeSelected({ commit, state, dispatch }, { selected, navigate }: NavigableChangeSelected) {
    commit(RootMutations.SetSelectedChanging, true)
    commit(RootMutations.SetSelected, selected)

    if (state.zendeskStatus) {
      await dispatch(RootActions.UpdateZendeskUser)
    }

    // let things adjust to change
    await Vue.nextTick()

    if (isFunction(navigate)) {
      await navigate()
    }

    commit(RootMutations.SetSelectedChanging, false)
  },

  async [RootActions.GetHelloSignConfig]({ state, commit }) {
    const hsConf: HelloSignConfigResponse = await signService.getHelloSignConfig(state)
    commit(RootMutations.SetHelloSignConfig,hsConf)
  },

  async [RootActions.GetOlarkStatus]({ commit, rootState }) {
    const status: ToggleStatusResponse = await externalTogglesService.getStatus(rootState, ExternalToggle.Olark)

    commit(RootMutations.SetOlarkStatus, status)
  },

  async [RootActions.GetFreshchatStatus]({ commit, rootState }) {
    const status: ToggleStatusResponse = await externalTogglesService.getStatus(rootState, ExternalToggle.Freshdesk)

    commit(RootMutations.SetFreshchatStatus, status)
  },

  async [RootActions.GetHellosignStatus]({ commit, rootState }) {
    const status: ToggleStatusResponse = await externalTogglesService.getStatus(rootState, ExternalToggle.Hellosign)
    commit(RootMutations.SetHellosignStatus, status)
  },

  async [RootActions.SetAllProducts]({ commit, rootState }) {
    const productInfo = await ssoService.getAllProductsInfo(rootState)

    commit(RootMutations.SetProductInfo, productInfo)
  },

  async [RootActions.GetZendeskStatus]({ commit, rootState }) {
    const enabled = await externalTogglesService.getStatus(rootState, ExternalToggle.Zendesk)

    commit(RootMutations.SetZendeskStatus, enabled)
    return enabled
  },

  async [RootActions.UpdateZendeskUser]({ state}) {
    if (state.zendeskStatus) {
      const request: ZendeskUserRequest = {
        user: {
          userFields: {
            openReplayLink: tracker.getSessionURL() ?? ''
          }
        }
      }
      await zendeskService.updateUser(state, request)
    }
  },

  async [RootActions.GetZendeskJwt]({ state }) {
    if (state.zendeskStatus) {
      return await zendeskService.getZendeskJWT(state)
    }
  }

}
