import { Module } from 'vuex'
import { OverviewMeetingState, state } from './state'
import { RootState } from '@/store/root/state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export * from './types'

export { OverviewMeetingState } from './state'
export { OverviewMeetingGetters } from './getters'
export { OverviewMeetingMutations } from './mutations'
export { OverviewMeetingActions } from './actions'

export const store: Module<OverviewMeetingState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
