import { authService } from '@/services'
import { RootState } from '@/store/root'
import { User, PasswordResetRequest, TeacherLinkRequest, NewTeacherRequest } from 'models'
import { ActionTree } from 'vuex'
import { AuthMutations } from './mutations'
import { AuthState } from './state'

export enum AuthActions {
  AuthenticateUser = 'AUTHENTICATE_USER',
  GetAuthUser = 'GET_AUTH_USER',
  LogoutAuthUser = 'LOGOUT_AUTH_USER',
  InsertResetLink = 'INSERT_RESET_LINK',
  SendPasswordReset = 'SEND_PASSWORD_RESET',
  SendPasswordResetByUsername = 'SEND_PASSWORD_RESET_USER',
  GetTeacherByLink = 'GET_TEACHER_LINK',
  InsertNewTeacher = 'INSERT_NEW_TEACHER'
}

export const actions: ActionTree<AuthState, RootState> = {

  async [AuthActions.AuthenticateUser] ({ commit }, user: User) {
    const loginUser = await authService.login(user)

    commit(AuthMutations.SetAuthUser, loginUser)
  },

  async [AuthActions.GetAuthUser] ({ commit }) {
    const user = await authService.getAuthUser()

    commit(AuthMutations.SetAuthUser, user)
  },

  async [AuthActions.LogoutAuthUser] ({ commit }) {
    const redirect = await authService.logout()

    commit(AuthMutations.SetAuthorized, false)
    return redirect
  },

  async [AuthActions.InsertResetLink] ({ commit }, email) {
    const resetPass = await authService.insertResetLink(email)
    if (resetPass.success) {
      commit(AuthMutations.SetPasswordReset, true)
    }
  },

  async [AuthActions.SendPasswordReset] ({ commit }, request: PasswordResetRequest) {
    const passReset = await authService.sendPasswordReset(request)
    if (passReset.success) {
      commit(AuthMutations.SetAuthUser, passReset.user)
      commit(AuthMutations.SetRetrievedUsername, passReset.username)
      commit(AuthMutations.SetPasswordReset, true)
    }
    return passReset.username
  },

  async [AuthActions.SendPasswordResetByUsername] ({ commit }, request: PasswordResetRequest) {
    const passReset = await authService.sendPasswordResetByUsername(request)
    if (passReset.success) {
      commit(AuthMutations.SetPasswordReset, true)
    }
    return passReset.username
  },

  async [AuthActions.GetTeacherByLink] ({ commit }, request: TeacherLinkRequest) {
    const linkRequest = await authService.getTeacherByLink(request)
    commit(AuthMutations.SetValid, linkRequest)
    if (linkRequest.success) {
      commit(AuthMutations.SetTeacher, linkRequest.teacher)
    }
    return linkRequest
  },

  async [AuthActions.InsertNewTeacher] ({ commit }, request: NewTeacherRequest) {
    const response = await authService.insertNewTeacher(request)
    commit(AuthMutations.SetNewTeacherComplete, response.success)
    return response
  }
}
