import cloneDeep from 'lodash/cloneDeep'
import { PagingUtil } from '@806/utils'
import {
  StaarAccommodationsTeachers,
  StaarAccommodationStudentPaging
} from './types'
import { SelectedAssessmentFormatOption, SelectedAccommodationCommittee, StudentNote, StudentAccommodationSubject } from 'models'

export interface StaarAccommodationsState {
  selectedCommittees: SelectedAccommodationCommittee[],
  selectedAssessmentFormatOpts: SelectedAssessmentFormatOption[],
  showAll: boolean,
  staarAccommodationsStudents: StudentAccommodationSubject[],
  staarAccommodationStudentPaging: StaarAccommodationStudentPaging,
  staarAccommodationsTeachers: StaarAccommodationsTeachers,
  currentSigningRequestId: string,
  currentSigningUrl: string,
  teachersWithChanges: Set<number>
  lastSignSuccess: boolean,
  teachersExpanded: Set<number>,
  allTeachersSigned: boolean,
  studentNotesMap: Map<number, StudentNote>,
  teacherSeenNonExit: Set<number>
}

export const state: StaarAccommodationsState = {
  selectedCommittees: [],
  selectedAssessmentFormatOpts: [],
  showAll: false,
  staarAccommodationsStudents: [],
  staarAccommodationsTeachers: {
    teachers: [],
    pagination: {},
    paging: PagingUtil.initialPaging(),
    selected: PagingUtil.initialSelectedItems()
  } as StaarAccommodationsTeachers,
  staarAccommodationStudentPaging: {
    pagination: {},
    paging: PagingUtil.initialPaging()
  },
  currentSigningRequestId: null,
  currentSigningUrl: null,
  teachersWithChanges: new Set(),
  lastSignSuccess: true,
  teachersExpanded: new Set(),
  allTeachersSigned: false,
  studentNotesMap: new Map(),
  teacherSeenNonExit: new Set()
}

export const clearState: StaarAccommodationsState = cloneDeep(state)
