import Vue from 'vue'
import Vuex, { Plugin, Module, Store, StoreOptions } from 'vuex'
import createLogger from 'vuex/dist/logger'
import { RootState, store as root } from './root'
import { AuthState, store as auth } from './modules/auth'
import { MeetingState, store as meeting } from './modules/meeting'
import { CreateMeetingState, store as createMeeting } from './modules/create-meeting'
import { MeetingSummaryState, store as meetingSummary } from './modules/meeting-summary'
import { ReviewMeetingState, store as reviewMeeting } from './modules/review-meeting'
import { OverviewMeetingState, store as overviewMeeting } from './modules/overview-meeting'
import { ReviewStudentState, store as reviewStudent } from './modules/review-student'
import { ViewStudentsState, store as viewStudents } from './modules/view-students'
import { CompletedMeetingsState, store as completedMeetings } from './modules/completed-meetings'
import { CompletedMeetingState, store as completedMeeting } from './modules/completed-meeting'
import { ParentDecisionState, store as parentDecision } from './modules/parent-decision'
import { RecommendationsState, store as recommendations } from '@/store/modules/recommendations'
import { CustomFilterState, store as customFilter } from '@/store/modules/custom-filter'
import { errorLogger, selectedSession } from './plugins'
import { SetupCommitteeState, store as setupCommittee } from './modules/setup-committee'
import { StaarAccommodationsState, store as staarAccommodations } from './modules/staar-accommodations'
import { UserSetupState, store as userSetup } from './modules/user-setup'
import { EOYLasLinksState, store as eoyLasLinks } from './modules/eoy-las-links'
import { StudentDocumentsState, store as studentDocuments } from './modules/student-document'
import { ReportsState, store as reports } from './modules/reports'
import { ContactUsState, store as contactUs } from './modules/contact-us'
import { SSOState, store as sso } from './modules/sso'
import { LayoutState, store as layout } from './modules/layout'
import { ContentLanguageSupportState, store as contentLanguageSupport } from './modules/content-language-support'
import { tracker } from '@/util/open-replay'
import trackerVuex from '@openreplay/tracker-vuex'
import trackerAssist from '@openreplay/tracker-assist'
Vue.use(Vuex)

const plugins: Array<Plugin<RootState>> = [
  errorLogger,
  selectedSession
]

if (process.env.OPEN_REPLAY_ENABLED) {
  tracker.use(trackerAssist())
  const vuexPlugin = tracker.use(trackerVuex())
  const storeTracker = vuexPlugin('EL Store')
  tracker.start({
    forceNew: false,
    metadata: {
      product: 'EL'
    }
  })
  plugins.push(storeTracker)
}

if (process.env.ENV === 'development') {
  plugins.push(createLogger<RootState>({}))
}

function getStore<S>(imported: Promise<{ store: S }>): Promise<S> {
  return imported.then(({ store }) => store)
}

function getModule<S>(imported: Promise<{ store: Module<S, RootState> }>): Promise<Module<S, RootState>> {
  return getStore<Module<S, RootState>>(imported)
}

export const makeHot = (store: Store<RootState>): Store<RootState> => {
  if (process.env.ENV === 'development' && module.hot) {
    module.hot.accept([
      './root',
      './modules/auth',
      './modules/meeting',
      './modules/create-meeting',
      './modules/meeting-summary',
      './modules/review-meeting',
      './modules/overview-meeting',
      './modules/review-student',
      './modules/view-students',
      './modules/completed-meetings',
      './modules/completed-meeting',
      './modules/parent-decision',
      './modules/setup-committee',
      './modules/staar-accommodations',
      './modules/user-setup',
      './modules/eoy-las-links',
      './modules/student-document',
      './modules/reports',
      './modules/contact-us',
      './modules/recommendations',
      './modules/custom-filter',
      './modules/sso',
      './modules/layout',
      './modules/content-language-support'
    ], async () => {
      const newRoot = await getStore<StoreOptions<RootState>>(import('./root'))
      const newAuth = await getModule<AuthState>(import('./modules/auth'))
      const newMeeting = await getModule<MeetingState>(import('./modules/meeting'))
      const newCreateMeeting = await getModule<CreateMeetingState>(import('./modules/create-meeting'))
      const newMeetingSummary = await getModule<MeetingSummaryState>(import('./modules/meeting-summary'))
      const newReviewMeeting = await getModule<ReviewMeetingState>(import('./modules/review-meeting'))
      const newOverviewMeeting = await getModule<OverviewMeetingState>(import('./modules/overview-meeting'))
      const newReviewStudent = await getModule<ReviewStudentState>(import('./modules/review-student'))
      const newViewStudents = await getModule<ViewStudentsState>(import('./modules/view-students'))
      const newCompletedMeetings = await getModule<CompletedMeetingsState>(import('./modules/completed-meetings'))
      const newCompletedMeeting = await getModule<CompletedMeetingState>(import('./modules/completed-meeting'))
      const newParentDecision = await getModule<ParentDecisionState>(import('./modules/parent-decision'))
      const newRecommendations = await getModule<RecommendationsState>(import('./modules/recommendations'))
      const newCustomFilter = await getModule<CustomFilterState>(import('./modules/custom-filter'))
      const newSetupCommittee = await getModule<SetupCommitteeState>(import('./modules/setup-committee'))
      const newStaarAccommodations = await getModule<StaarAccommodationsState>(import('./modules/staar-accommodations'))
      const newUserSetup = await getModule<UserSetupState>(import('./modules/user-setup'))
      const newEOYLasLinks = await getModule<EOYLasLinksState>(import('./modules/eoy-las-links'))
      const newStudentDocuments = await getModule<StudentDocumentsState>(import('./modules/student-document'))
      const newReports = await getModule<ReportsState>(import('./modules/reports'))
      const newContactUs = await getModule<ContactUsState>(import('./modules/contact-us'))
      const newSSO = await getModule<SSOState>(import('./modules/sso'))
      const newLayout = await getModule<LayoutState>(import('./modules/layout'))
      const newContentLanguageSupport = await getModule<ContentLanguageSupportState>(import('./modules/content-language-support'))

      store.hotUpdate({
        getters: newRoot.getters,
        mutations: newRoot.mutations,
        actions: newRoot.actions,
        modules: {
          auth: newAuth,
          meeting: newMeeting,
          createMeeting: newCreateMeeting,
          meetingSummary: newMeetingSummary,
          reviewMeeting: newReviewMeeting,
          overviewMeeting: newOverviewMeeting,
          reviewStudent: newReviewStudent,
          viewStudent: newViewStudents,
          completedMeetings: newCompletedMeetings,
          completedMeeting: newCompletedMeeting,
          parentDecision: newParentDecision,
          setupCommittee: newSetupCommittee,
          staarAccommodations: newStaarAccommodations,
          userSetup: newUserSetup,
          eoyLasLinks: newEOYLasLinks,
          studentDocuments: newStudentDocuments,
          reports: newReports,
          contactUs: newContactUs,
          recommendations: newRecommendations,
          customFilter: newCustomFilter,
          sso: newSSO,
          layout: newLayout,
          contentLanguageSupport: newContentLanguageSupport
        }
      })
    })
  }

  return store
}

export const createStore = () => makeHot(new Vuex.Store<RootState>({
  ...root,
  modules: {
    auth,
    meeting,
    createMeeting,
    meetingSummary,
    reviewMeeting,
    overviewMeeting,
    reviewStudent,
    viewStudents,
    completedMeetings,
    completedMeeting,
    parentDecision,
    recommendations,
    setupCommittee,
    staarAccommodations,
    userSetup,
    eoyLasLinks,
    studentDocuments,
    reports,
    contactUs,
    customFilter,
    sso,
    layout,
    contentLanguageSupport
  },
  plugins,
  strict: process.env.ENV === 'development'
}))
