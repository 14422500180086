import {ActionTree} from 'vuex';
import {SSOState} from '@/store/modules/sso/state';
import {RootState} from '@/store/root';
import {ssoService} from '@/services';
import {SSOMutations} from '@/store/modules/sso/mutations';
import { browser } from '@/util/browser'
import {SSOGetters} from '@/store/modules/sso/getters';

export enum SSOActions {
  GetAccess = 'GET_ACCESS',
  GetProductInfo = 'GET_PRODUCT_INFO',
  GetDistrictInfo = 'GET_DISTRICT_INFO',
  Login = 'LOGIN',
}

export const actions: ActionTree<SSOState, RootState> = {

  async [SSOActions.GetAccess]({ commit, state, rootState }) {
    const access = await ssoService.getAccess(rootState, state.product)
    commit(SSOMutations.SetAccess, access)
  },

  async [SSOActions.GetDistrictInfo]({ commit, state, rootState }) {
    const districtInfo = await ssoService.getDistrictInfo(rootState, state.product)

    commit(SSOMutations.SetDistrictInfo, districtInfo)
  },

  async [SSOActions.GetProductInfo]({ commit, state, rootState }) {
    const productInfo = await ssoService.getProductInfo(rootState, state.product)

    commit(SSOMutations.SetProductInfo, productInfo)
  },

  async [SSOActions.Login]({ state, getters, rootState }) {
    await ssoService.login(
      rootState,
      state.product,
      state.access.userId,
      browser,
      getters[SSOGetters.Logout],
    )
  },
}
