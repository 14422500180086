import BaseService, {ServiceConfig} from '@/services/base';
import {RootState} from '@/store/root';
import {
  SupportOptionsResponse,
  SupportOptionsRequest,
  RosterStudentOptionsRequest,
  ContentLanguageControlOptions, RosterStudentOptionsResponse
} from 'models';

export default class ContentLanguageSupportService extends BaseService {

  private readonly baseUri: string = '/content-language-support'

  constructor(config: ServiceConfig) {
    super(config)
  }

  async getStudentInOpenMeeting(state: RootState, studentId: number): Promise<boolean> {
    const { data } = await this.get<boolean>(`${this.baseUri}/student/support-options/meeting-status/${studentId}`, state)
    return data
  }

  async getSupportOptions(state: RootState, request: SupportOptionsRequest): Promise<SupportOptionsResponse> {
    const { data } = await this.get<SupportOptionsResponse>(`${this.baseUri}/student/support-options/${request.studentId}`, state)
    return data
  }

  async getRosterSupportOptions(state: RootState, request: SupportOptionsRequest): Promise<SupportOptionsResponse> {
    const { data } = await this.get<SupportOptionsResponse>(`${this.baseUri}/student/support-options/roster/${request.rosterId}`, state)
    return data
  }

  async saveStudentSupportOption(state: RootState, request: RosterStudentOptionsRequest): Promise<ContentLanguageControlOptions[]> {
    const { data } = await this.post<RosterStudentOptionsResponse>(`${this.baseUri}/student/support-options`, { state, request })

    return data.options
  }

}
