import {GetterTree} from 'vuex';
import {SSOState} from '@/store/modules/sso/state';
import {RootState} from '@/store/root';
import {ProductLogoType, ProductPageType} from '@/enums';
import {SchoolUsers} from 'models';
import {sortBy} from 'lodash';


export enum SSOGetters {
  Name = 'NAME',
  Color = 'COLOR',
  Home = 'HOME',
  Logout = 'LOGOUT',
  Logo = 'LOGO',
  SchoolUser = 'SCHOOL_USERS',
  App = 'APP',
}

export const getters: GetterTree<SSOState, RootState> = {
  [SSOGetters.Name](state): string | void {
    if (state.productInfo) {
      return state.productInfo.name
    }
  },

  [SSOGetters.Color](state): string | void {
    if (state.productInfo) {
      return state.productInfo.color
    }
  },

  [SSOGetters.Home](state): string | void {
    if (state.productInfo) {
      return state.productInfo.urls[ProductPageType.Main.toLowerCase()]
    }
  },

  [SSOGetters.Logout](state): string | void {
    if (state.productInfo) {
      return state.productInfo.urls[ProductPageType.Logout.toLowerCase()]
    }
  },

  [SSOGetters.Logo](state): string | void {
    if (state.productInfo) {
      return state.productInfo.logos[ProductLogoType.Title.toLowerCase()]
    }
  },

  [SSOGetters.SchoolUser](state): SchoolUsers[] {
    return state.districtInfo ? sortBy(state.districtInfo.schoolUsers, 'name') : []
  },

  [SSOGetters.App](state): string | void {
    if (state.access) {

      return `${state.access.appUrl}`
    }
  },
}
