import cloneDeep from 'lodash/cloneDeep'
import { ELUser, MeetingStaarTeacher } from 'models'

export interface AuthState {
  user: ELUser,
  authorized: boolean,
  passwordReset: boolean,
  retrievedUsername: string,
  teacher: MeetingStaarTeacher,
  validLink: boolean,
  newTeacherComplete: boolean,
  super: boolean
}

export const state: AuthState = {
  authorized: false,
  passwordReset: false,
  retrievedUsername: '',
  user: null,
  teacher: null,
  validLink: true,
  newTeacherComplete: false,
  super: false
}

export const clearState: AuthState = cloneDeep(state)
