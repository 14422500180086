import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { RecommendationsState } from './state'
import {
  MeetingPlacement,
  StudentSchoolGrade,
  Student,
  EnrollmentType, MeetingTypeCode, DualLanguageSelection, MeetingRoster, StudentPlacement
} from 'models'
import {buildStudentPlacementRecommendations} from '@/store/modules/recommendations/builders'
import {MeetingStatus} from '@/enums'

export enum RecommendationsGetters {
  MeetingRosterId = 'MEETING_ROSTER_ID',
  DualLanguage = 'DUAL_LANGUAGE',
  Recommendations = 'RECOMMENDATIONS',
  StudentSchoolGrade = 'STUDENT_SCHOOL_GRADE',
  StudentSchoolGradeId = 'STUDENT_SCHOOL_GRADE_ID',
  Student = 'STUDENT',
  SelectedRecommendations = 'SELECTED_RECOMMENDATIONS',
  EnrollmentType = 'ENROLLMENT_TYPE',
  MeetingTypeCode = 'MEETING_TYPE_CODE',
  PriorMeetingPlacement = 'PRIOR_MEETING_PLACEMENT',
  Roster = 'ROSTER',
  StudentId = 'STUDENT_ID',
  StudentPlacementRecommendations = 'STUDENT_PLACEMENT_RECOMMENDATIONS',
  StudentPlacementDualLanguage = 'STUDENT_PLACEMENT_DUAL_LANGUAGE'
}

export const getters: GetterTree<RecommendationsState, RootState> = {

  [RecommendationsGetters.MeetingRosterId](state): number {
    if (state.roster) {
      return state.roster.id
    }
  },

  [RecommendationsGetters.Recommendations](state): MeetingPlacement {
    if (state.roster) {
      if (state.meetingStatus === MeetingStatus.F) {
        return state.roster.currentPlacement
      }
      return state.roster.meetingPlacement
    }
  },

  [RecommendationsGetters.StudentPlacementRecommendations](state): StudentPlacement {
    return buildStudentPlacementRecommendations(state)
  },

  [RecommendationsGetters.StudentPlacementDualLanguage](state): DualLanguageSelection {
    return state.dualLanguage
  },

  [RecommendationsGetters.Roster](state): MeetingRoster {
    return state.roster
  },

  [RecommendationsGetters.SelectedRecommendations](state): RecommendationsState {
    return state
  },

  [RecommendationsGetters.DualLanguage](state): DualLanguageSelection {
    if (state.roster) {
      if (state.meetingStatus === MeetingStatus.F && state.roster.currentPlacement.dualLanguage) {
        return state.roster.currentPlacement.dualLanguage
      }
      if (state.roster?.meetingPlacement?.dualLanguage) {
        return state.roster.meetingPlacement.dualLanguage
      }
    }

    return {
      language: null,
      year: null
    }
  },

  [RecommendationsGetters.StudentSchoolGrade](state): StudentSchoolGrade {
    if (state.roster) {
      return state.roster.studentSchoolGrade
    }
  },

  [RecommendationsGetters.MeetingTypeCode](state): MeetingTypeCode {
    if (state.roster) {
      return state.roster?.meetingType?.code
    }
  },

  [RecommendationsGetters.StudentSchoolGradeId](state, getters): number {
    const studentSchoolGrade: StudentSchoolGrade = getters[RecommendationsGetters.StudentSchoolGrade]

    if (studentSchoolGrade) {
      return studentSchoolGrade.id
    }
  },

  [RecommendationsGetters.Student](state, getters): Student {
    const studentSchoolGrade: StudentSchoolGrade = getters[RecommendationsGetters.StudentSchoolGrade]

    if (studentSchoolGrade) {
      return studentSchoolGrade.student
    }
  },

  [RecommendationsGetters.StudentId](state, getters): number {
    const student: Student = getters[RecommendationsGetters.Student]

    if (student) {
      return student.id
    }
  },

  [RecommendationsGetters.EnrollmentType](state): EnrollmentType {
    if (state.roster?.enrollmentType) {
      return state.roster.enrollmentType
    }
  },

  [RecommendationsGetters.PriorMeetingPlacement](state){
    return state.priorMeetingPlacement
  }

}
