import api from './api'
import AuthService from './auth'
import ContactService from './contact'
import MeetingService from './meeting'
import FeatureService from './features'
import GradeService from './grade'
import DecisionsService from './decisions'
import StudentService from './student'
import TermService from './term'
import SignService from '@/services/sign'
import DimensionalLookupService from '@/services/dimensional'
import DimensionService from './dimension'
import StandardizedTestService from './standardizedTest'
import SchoolService from './schools'
import DocumentsService from './documents'
import ReportsService from './reports'
import ExternalTogglesService from './externalToggles'
import CustomFilterService from '@/services/customFilter';
import {SessionVariables} from '@/util/SessionVariables';
import SSOService from '@/services/sso';
import ContentLanguageSupportService from '@/services/contentLanguageSupport';
import ZendeskService from '@/services/zendesk';

export const csrf = {
  get token() {
    return sessionStorage.getItem(SessionVariables.Csrf)
  },
  set token(token: string) {
    sessionStorage.setItem(SessionVariables.Csrf, token)
  },
  clear() {
    sessionStorage.removeItem(SessionVariables.Csrf)
  }
}

export const authService = new AuthService({ http: api, csrf })
export const meetingService = new MeetingService({ http: api, csrf })
export const featureService = new FeatureService({ http: api, csrf })
export const gradeService = new GradeService({ http: api, csrf })
export const decisionsService = new DecisionsService({ http: api, csrf })
export const studentService = new StudentService({ http: api, csrf })
export const termService = new TermService({ http: api, csrf })
export const signService = new SignService({ http: api, csrf })
export const dimensionalLookupService = new DimensionalLookupService({ http: api, csrf })
export const dimensionService = new DimensionService({ http: api, csrf })
export const standardizedTestService = new StandardizedTestService({ http: api, csrf })
export const schoolService = new SchoolService({ http: api, csrf })
export const documentsService = new DocumentsService({ http: api, csrf })
export const reportsService = new ReportsService({ http: api, csrf })
export const contactService = new ContactService({ http: api, csrf })
export const externalTogglesService = new ExternalTogglesService({ http: api, csrf })
export const customFilterService = new CustomFilterService({ http: api, csrf })
export const ssoService = new SSOService({ http: api, csrf })
export const contentLanguageSupportService = new ContentLanguageSupportService({ http: api, csrf })
export const zendeskService = new ZendeskService({ http: api, csrf })
