import { ErrorCode } from './ErrorCode'

export type ErrorMessages = {
  [code in ErrorCode]: string;
}

/**
 * User friendly error messages for common errors.
 */
export const errorMessages: ErrorMessages = {
  [ErrorCode.NetworkError]: 'We are unable to connect to our servers at this time.',
  [ErrorCode.ServiceError]: 'An internal error has occurred. Our technical staff is investigating.',
  [ErrorCode.RuntimeError]: 'An internal error has occurred. Our technical staff is investigating.',
  [ErrorCode.NotAuthError]: 'Please login to access this page.',
  [ErrorCode.NotFoundError]: 'Could not find the requested resource.',
  [ErrorCode.BadCredentials]: 'Invalid Password',
  [ErrorCode.NoUserFound]: 'Invalid Username'
}
