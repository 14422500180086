import {customFilterService, meetingService, studentService} from '@/services'
import { RootState } from '@/store/root'
import { CreateMeetingState } from './state'
import { CreateMeetingMutations } from './mutations'
import { ActionTree } from 'vuex'
import first from 'lodash/first'
import defaults from 'lodash/defaults'

export enum CreateMeetingActions {
  GetTypes = 'GET_TYPES',
  GetStudents = 'GET_STUDENTS',
  GetFilteredStudents = 'GET_FILTERED_STUDENTS'
}

export const actions: ActionTree<CreateMeetingState, RootState> = {

  async [CreateMeetingActions.GetTypes] ({ commit, state, rootState }) {
    const types = await meetingService.getTypes(rootState)

    if (!state.code) {
      commit(CreateMeetingMutations.SetCode, first(types).code)
    }

    commit(CreateMeetingMutations.SetTypes, types)
  },

  async [CreateMeetingActions.GetStudents] ({ commit, state, rootState }, hideCurrentMeeting: boolean) {
    commit(CreateMeetingMutations.SetLoaded, false)

    try {
      const code = state.all ? null : state.code

      const { students, allStudentIds, pagination } = await studentService.getStudents(rootState, state.paging, code, hideCurrentMeeting)

      if (state.paging.page !== pagination.currentPage) {
        commit(CreateMeetingMutations.SetPaging, defaults({
          page: pagination.currentPage
        }, state.paging))
      }

      commit(CreateMeetingMutations.SetStudents, { students, allStudentIds, pagination })
    } finally {
      commit(CreateMeetingMutations.SetLoaded, true)
    }
  },

  async [CreateMeetingActions.GetFilteredStudents] ({ commit, state, rootState }, { hideCurrentMeeting, filters }) {

    try {
      const code = state.all ? null : state.code

      const { students, allStudentIds, pagination } = await customFilterService.getFilteredStudents(rootState, state.paging, code, hideCurrentMeeting, filters)

      if (state.paging.page !== pagination.currentPage) {
        commit(CreateMeetingMutations.SetPaging, defaults({
          page: pagination.currentPage
        }, state.paging))
      }

      commit(CreateMeetingMutations.SetStudents, { students, allStudentIds, pagination })
    } finally {
      commit(CreateMeetingMutations.SetLoaded, true)
    }
  }

}
