import { Module } from 'vuex'
import { RootState } from '@/store/root/state'
import { CompletedMeetingsState, state } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export * from './types'

export { CompletedMeetingsState } from './state'
export { CompletedMeetingsGetters } from './getters'
export { CompletedMeetingsMutations } from './mutations'
export { CompletedMeetingsActions } from './actions'

export const store: Module<CompletedMeetingsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
