import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { ParentDecisionState } from './state'
import { MeetingPlacement, MeetingRoster } from 'models'
import isNil from 'lodash/isNil'

export enum ParentDecisionGetters {
  RosterIds = 'ROSTER_IDS',
  RosterMeetingPlacement = 'ROSTER_MEETING_PLACEMENT',
  RosterDecisionDate = 'ROSTER_DECISION_DATE',
  RosterDecisionFormLocation = 'ROSTER_DECISION_FORM_LOCATION',
  RosterDecisionId = 'ROSTER_DECISION_ID',
  RosterHasDecisionForm = 'ROSTER_HAS_DECISION_FORM',
  RosterStudentNumber = 'ROSTER_STUDENT_NUMBER',
  Roster = 'ROSTER',
  MeetingDate = 'MEETING_DATE'
}

export const getters: GetterTree<ParentDecisionState, RootState> = {

  [ParentDecisionGetters.RosterIds](state): number[] {
    return state.meeting ? state.meeting.roster.map(roster => roster.id) : []
  },

  [ParentDecisionGetters.RosterMeetingPlacement](state): MeetingPlacement {
    if (state.roster) {
      return state.roster.meetingPlacement
    }
  },

  [ParentDecisionGetters.RosterDecisionDate](state, getters): string {
    const placement: MeetingPlacement = getters[ParentDecisionGetters.RosterMeetingPlacement]

    if (placement) {
      return placement.parentDecisionTime
    }
  },

  [ParentDecisionGetters.RosterDecisionFormLocation](state, getters): string {
    const placement: MeetingPlacement = getters[ParentDecisionGetters.RosterMeetingPlacement]

    if (placement) {
      return placement.parentDecisionFormLocation
    }
  },

  [ParentDecisionGetters.RosterDecisionId](state, getters): number {
    const placement: MeetingPlacement = getters[ParentDecisionGetters.RosterMeetingPlacement]

    if (placement && placement.parentDecision) {
      return placement.parentDecision.id
    }
  },

  [ParentDecisionGetters.RosterHasDecisionForm](state, getters): boolean {
    return !isNil(getters[ParentDecisionGetters.RosterDecisionFormLocation])
  },

  [ParentDecisionGetters.RosterStudentNumber](state): string {
    if (state.roster) {
      return state.roster.studentSchoolGrade.student.studentNumber
    }
  },

  [ParentDecisionGetters.Roster](state): MeetingRoster {
    if (state.roster) {
      return state.roster
    }
  },

  [ParentDecisionGetters.MeetingDate](state): string {
    if (state.meeting) {
      return state.meeting.closeDate
    }
  }

}
