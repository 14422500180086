import {GetterTree} from 'vuex';
import {RootState} from '@/store/root';
import {LayoutState} from '@/store/modules/layout/state';


export enum LayoutGetters {
  //
}

export const getters: GetterTree<LayoutState, RootState> = {
  //
}
