import {ELSchool, ELDistrict, ProductInfoResponse} from 'models'
import {ProductCode, TermCode} from '@/enums'
import isObjectLike from 'lodash/isObjectLike'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'

export interface Selected {
  districtId: number
  schoolId: number
  districtLookupCode: string
}

export type ProductInfo = {
  [product in ProductCode]?: ProductInfoResponse
}

export const isSelected = (selected: Selected): selected is Selected => {
  return isObjectLike(selected) && isNumber(selected.schoolId)
}

export const enum NotificationType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning'
}

export interface Notification {
  message: string
  title?: string
}

export interface TypedNotification extends Notification {
  type: NotificationType
}

export interface KeyedNotification extends TypedNotification {
  id: string
}

export const isNotification = (notification: Notification): notification is Notification => {
  return isObjectLike(notification) && isString(notification.message)
}

export const isKeyedNotification = (notification: Notification): notification is KeyedNotification => {
  return isNotification(notification) && isString((notification as KeyedNotification).id)
}

export type Terms = {
  [code in TermCode]: string
}

export interface Navigable {
  navigate?: () => Promise<unknown>
}

export interface NavigableChangeDistrict extends Navigable {
  district: ELDistrict
}

export interface NavigableChangeSchool extends Navigable {
  school: ELSchool
}

export interface NavigableChangeSelected extends Navigable {
  selected: Selected
}

export interface SupportInfo {
  email: string
  phone: string
}

export type AddSuccessNotification = (notification: Notification) => void
export type AddInfoNotification = (notification: Notification) => void
export type AddWarningNotification = (notification: Notification) => void
export type AddNotification = (notification: TypedNotification) => void
export type RemoveNotification = (notification: KeyedNotification) => void
export type SetSelected = (selected: Selected) => void
export type ChangeDistrict = (changeDistrict: NavigableChangeDistrict) => Promise<void>
export type ChangeSchool = (changeSchool: NavigableChangeSchool) => Promise<void>
