import { MeetingTypeCode } from '@/enums'
import { Pages, paths } from '@/pages'
import cloneDeep from 'lodash/cloneDeep'
import { MeetingStatus, MeetingStatuses } from './types'
import { MeetingType, MeetingTypeStatus } from 'models'
import MeetingStudents from '@/util/MeetingStudents'
import { MeetingTypeStudents } from '../review-meeting'
import { PagingUtil, PerPageSize } from '@806/utils'

export interface MeetingSummaryState {
  types: MeetingType[],
  statuses: MeetingStatuses,
  meetingTypeStatuses: MeetingTypeStatus[],
  currentSigningUrl: string,
  meetingStudents: MeetingStudents,
  isSigningMeetingMinutes: boolean,
  isSigningCommittee: boolean
}

const statuses = Object.assign({}, Object.values(MeetingTypeCode)
  .reduce<MeetingStatuses>((meetingStatuses: MeetingStatuses, code: MeetingTypeCode) => {
    meetingStatuses[code] = {
      numberOfStudents: 0,
      meetingTypeName: '',
      buttonText: '',
      linkTo: { path: paths[Pages.ReviewMeeting] },
      code: null
    } as MeetingStatus

    return meetingStatuses
  }, {} as MeetingStatuses)
)

const meetingStudents = Object.assign({}, Object.values(MeetingTypeCode)
  .reduce<MeetingStudents>((meetingStudents: MeetingStudents, code: MeetingTypeCode) => {
    meetingStudents[code] = {
      students: [],
      pagination: {},
      paging: PagingUtil.initialPaging(PerPageSize.Medium),
      selected: PagingUtil.initialSelectedItems()
    } as MeetingTypeStudents
    return meetingStudents
  }, {} as MeetingStudents)
)

export const state: MeetingSummaryState = {
  types: [],
  meetingStudents,
  statuses,
  meetingTypeStatuses: [],
  currentSigningUrl: null,
  isSigningMeetingMinutes: false,
  isSigningCommittee: false
}

export const clearState: MeetingSummaryState = cloneDeep(state)
