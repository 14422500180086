import {
  Meeting,
  MeetingRequest,
  MeetingResponse,
  MeetingsRequest,
  MeetingsResponse,
  PagedMeetingsResponse,
  MeetingType,
  MeetingTypeResponse,
  MeetingSelectedStudentsRequest,
  MeetingRole,
  MeetingMember,
  MeetingsPaging,
  MeetingRolesResponse,
  MeetingMembersResponse,
  MeetingMemberAddRequest,
  MeetingMemberRequest,
  MeetingMemberUpdateRequest,
  SaveMeetingMinutesRequest,
  PrintReclassificationRequest,
  PrintMeetingMinutesRequest,
  PrintReclassifcationFormResponse,
  RemoveStudentRequest,
  PrintTeacherFormRequest,
  ConfirmCommitteeRequest,
  ConfirmCommitteeResponse,
  ResetMeetingSignaturesRequest,
  ResetMeetingSignaturesResponse,
  PrintReclassificationStaarRequest,
  GetMeetingMinutesInfoRequest,
  GetMeetingMinutesInfoResponse,
  PrintReclassificationTelpasRequest,
  MeetingMemberScreenshotRequest,
  MeetingMemberScreenshotResponse,
  ReclassificationFileRequest,
  PrintMeetingRosterRequest,
  PagedMeetingAccommodationsResponse,
  MeetingTypeStatusCode,
  MeetingTypeStatusCodeRequest,
  MeetingTypeStatusResponse,
  GetParentPermissionsRequest,
  DeleteMeetingRequest,
  CreateMeetingRequest,
  DashboardMeetingsResponse,
  GetRosterRequest,
  GetActiveRosterResponse,
  StudentSchoolGrade,
  GetMeetingFormSignaturesReponse, UpdateMeetingRequest
} from 'models'
import { RootState } from '@/store/root'
import BaseService, {ServiceConfig} from './base'
import { ErrorHolder, ErrorCode } from '@/util/errors'
import { PagingUtil } from '@806/utils'
import { MeetingTypeCode } from '@/enums'
import {AxiosRequestConfig} from 'axios';

export default class MeetingService extends BaseService {

  private readonly baseUri: string = '/meetings'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getTypes(state: RootState): Promise<MeetingType[]> {
    const { data: { types } } = await this.get<MeetingTypeResponse>(`${this.baseUri}/types`, state )

    return types
  }

  async getPagedMeetings(
    state: RootState,
    paging: MeetingsPaging,
    roleId: string
  ): Promise<PagedMeetingsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: MeetingsRequest = { paging, roleId }

      const { data } = await this.post<PagedMeetingsResponse>(`${this.baseUri}/completed`, { state, request })

      return data
    })
  }

  async getPagedMeetingsByStudentId(
    state: RootState,
    paging: MeetingsPaging,
    studentId: number
  ): Promise<PagedMeetingAccommodationsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: MeetingsRequest = { paging, studentId }

      const { data } = await this.post<PagedMeetingAccommodationsResponse>(`${this.baseUri}/completed/student`, { state, request })

      return data
    })
  }

  async getPagedMeetingsByStaffId(
    state: RootState,
    paging: MeetingsPaging,
    staffId: number
  ): Promise<PagedMeetingsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: MeetingsRequest = { paging, staffId }

      const { data } = await this.post<PagedMeetingsResponse>(`${this.baseUri}/teacher/${request.staffId}`, { state, request })

      return data
    })
  }

  async getCompletedMeetings(state: RootState) {
    const { data: { meetings } } = await this.post<MeetingsResponse>(`${this.baseUri}/getCompletedMeetings`, { state })

    return meetings
  }

  async getCompletedMeeting(state: RootState, meetingId: number) {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: meetingId
      }
    }
    const { data: { meeting } } = await this.get<MeetingResponse>(`${this.baseUri}/completed/${meetingId}`, state, config)

    return meeting
  }

  async getCompletedMeetingLite(state: RootState, meetingId: number) {
    const request: MeetingRequest = { meetingId }
    const { data: { meeting } } = await this.post<MeetingResponse>(`${this.baseUri}/getCompletedMeetingLite`, { state, request })

    return meeting
  }

  async getMeetingByMeetingId(state: RootState, request: MeetingRequest): Promise<Meeting> {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: request.meetingId
      }
    }
    const { data: { meeting } } = await this.get<MeetingResponse>(`${this.baseUri}/meeting/${request.meetingId}`, state, config)

    return meeting
  }

  async getDashboardMeetings(state: RootState, paging: MeetingsPaging): Promise<DashboardMeetingsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: MeetingsRequest = { paging }

      const { data } = await this.post<DashboardMeetingsResponse>(`${this.baseUri}/dashboard`, { state, request })

      return data
    })
  }

  async getActiveRostersByMeetingType(state: RootState, request: GetRosterRequest): Promise<StudentSchoolGrade[]> {
    const config: AxiosRequestConfig = {
      params: {
        meetingTypeCode: request.meetingTypeCode
      }
    }
    const { data: { stuSchGrdIds } } = await this.get<GetActiveRosterResponse>(`${this.baseUri}/${request.meetingTypeCode}/students`, state, config)

    return stuSchGrdIds
  }

  async getCurrentMeeting(state: RootState): Promise<Meeting> {
    try {
      const { data: { meeting } } = await this.post<MeetingResponse>(`${this.baseUri}/getCurrentMeeting`, { state })

      return meeting
    } catch (error) {
      if (ErrorHolder.isCode(error, ErrorCode.NotFoundError)) {
        return // return empty if not found
      }

      throw error
    }
  }

  async getMeetingMemberScreenshotS3Loc(state: RootState, request: MeetingMemberScreenshotRequest) {
    const meetingId = isNaN(request.meetingId) ? null : request.meetingId
    const config: AxiosRequestConfig = {
      params: {
        meetingId: meetingId
      }
    }
    const { data: { location } } = await this.get<MeetingMemberScreenshotResponse>(`${this.baseUri}/meeting/${meetingId}/form/meeting-screenshot`, state, config)
    return location
  }

  async getMergedParentLettersUploaded(state: RootState, request: MeetingMemberScreenshotRequest) {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: request.meetingId
      }
    }
    const { data: { location } } = await this.get<MeetingMemberScreenshotResponse>(`${this.baseUri}/completed/${request.meetingId}/form/merged-parent-letters`, state, config)
    return location
  }

  async createMeeting(state: RootState, request: CreateMeetingRequest) {
    const { data: { meeting } } = await this.post<MeetingResponse>(`${this.baseUri}/meeting/create`, { state, request })
    return meeting
  }

  async updateMeeting(state: RootState, request: UpdateMeetingRequest) {
    const { data: { meeting } } = await this.put<MeetingResponse>(`${this.baseUri}/meeting/${request.meetingId}/update`, { state, request })
    return meeting
  }

  async deleteMeeting(state: RootState): Promise<void> {
    await this.post(`${this.baseUri}/deleteMeeting`, { state })
  }

  async deleteMeetingByMeetingId(state: RootState, request: DeleteMeetingRequest): Promise<void> {
    await this.delete(`${this.baseUri}/meeting/${request.meetingId}`, state )
  }

  async startMeeting(state: RootState, request: MeetingRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.put<MeetingResponse>(`${this.baseUri}/meeting/${request.meetingId}/start`, { state })

    return meeting
  }

  async addStudents(state: RootState, request: MeetingSelectedStudentsRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.post<MeetingResponse>(`${this.baseUri}/meeting/${request.meetingId}/students/add`, { state, request })

    return meeting
  }

  async removeStudents(state: RootState, request: MeetingSelectedStudentsRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.post<MeetingResponse>(`${this.baseUri}/removeStudents`, { state, request })

    return meeting
  }

  async removeStudent(state: RootState, request: RemoveStudentRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.delete<MeetingResponse>(`${this.baseUri}/meeting/${request.meetingId}/roster/${request.rosterId}`, state )

    return meeting
  }

  async getRoles(state: RootState): Promise<MeetingRole[]> {
    const { data: { roles } } = await this.get<MeetingRolesResponse>(`${this.baseUri}/committee/roles`, state )

    return roles
  }

  async getAllMembers(state: RootState): Promise<MeetingMember[]> {
    const { data: { members } } = await this.post<MeetingMembersResponse>(`${this.baseUri}/getAllMembers`, { state })

    return members
  }

  async getDefaultMembers(state: RootState): Promise<MeetingMember[]> {
    const { data: { members } } = await this.get<MeetingMembersResponse>(`${this.baseUri}/committee/default`, state )

    return members
  }

  async getAllUsers(state: RootState): Promise<MeetingMember[]> {
    const { data: { members } } = await this.get<MeetingMembersResponse>(`${this.baseUri}/committee/users`,  state )

    return members
  }

  async addDefaultMember(state: RootState, request: MeetingMemberAddRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.put<MeetingResponse>(`${this.baseUri}/committee/default/add`, { state, request })

    return meeting
  }

  async addMeetingMember(state: RootState, request: MeetingMemberAddRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.put<MeetingResponse>(`${this.baseUri}/meeting/${request.meetingId}/committee/add`, { state, request })

    return meeting
  }

  async removeMember(state: RootState, member: MeetingMember, meetingId: number, isDefault: boolean): Promise<Meeting> {
    const request: MeetingMemberRequest = { member, meetingId, isDefault }
    const { data: { meeting } } = await this.put<MeetingResponse>(`${this.baseUri}/committee/remove`, { state, request })

    return meeting
  }

  async updateMember(state: RootState, request: MeetingMemberUpdateRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.post<MeetingResponse>(`${this.baseUri}/updateMember`, { state, request })

    return meeting
  }

  async saveMeetingMinutes(state: RootState, request: SaveMeetingMinutesRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.put<MeetingResponse>(`${this.baseUri}/meeting/${request.meetingId}/form/meeting-minutes/save`, { state, request })

    return meeting
  }

  async printReclassificationForm(state: RootState, request: PrintReclassificationRequest): Promise<void> {
    const { data } = await this.post<PrintReclassifcationFormResponse>(`${this.baseUri}/completed/${request.meetingId}/form/download/peims`, { state, request }, { responseType: 'blob' })
    this.downloadFile(data, 'peims-form.pdf', 'application/pdf')
  }

  async printPotentialExitReclassifcationForm(state: RootState, request: PrintReclassificationRequest, schoolId: number): Promise<void> {
    const { data } = await this.post<PrintReclassifcationFormResponse>(`${this.baseUri}/school/${schoolId}/form/download/potential-exits-peims`, { state, request }, { responseType: 'blob' })
    this.downloadFile(data, 'peims-form.pdf', 'application/pdf')
  }

  async printMeetingMinutesForm(state: RootState, request: PrintMeetingMinutesRequest): Promise<void> {
    const { data } = await this.post<PrintReclassifcationFormResponse>(`${this.baseUri}/completed/${request.meetingId}/form/download/meeting-minutes`, { state, request }, { responseType: 'blob' })
    this.downloadFile(data, 'meeting-minutes-form.pdf', 'application/pdf')
  }

  async printMeetingRosterForm(state: RootState, request: PrintMeetingRosterRequest): Promise<void> {
    const { data } = await this.post<PrintReclassifcationFormResponse>(`${this.baseUri}/completed/${request.meetingId}/form/download/meeting-roster`, { state, request }, { responseType: 'blob' })
    this.downloadFile(data, 'meeting-roster-form.pdf', 'application/pdf')
  }

  async printReclassificationStaarForm(state: RootState, request: PrintReclassificationStaarRequest): Promise<void> {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: request.meetingId
      },
      responseType: 'blob'
    }
    const { data } = await this.get<PrintReclassifcationFormResponse>(`${this.baseUri}/completed/${request.meetingId}/form/download/staar-accommodations`, state, config)
    this.downloadFile(data, 'STAAR-participation-decisions.csv', 'application/csv')
  }

  async printReclassificationTelpasForm(state: RootState, request: PrintReclassificationTelpasRequest): Promise<void> {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: request.meetingId
      },
      responseType: 'blob'
    }
    const { data } = await this.get<PrintReclassifcationFormResponse>(`${this.baseUri}/completed/${request.meetingId}/form/download/telpas-decisions`, state, config)
    this.downloadFile(data, 'telpas-participation-decisions.pdf', 'application/pdf')
  }

  async downloadMemberScreenshotUpload(state: RootState, request: MeetingMemberScreenshotRequest): Promise<void> {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: request.meetingId
      },
      responseType: 'blob'
    }
    const { data, headers } = await this.get<MeetingMemberScreenshotResponse>(`${this.baseUri}/completed/${request.meetingId}/form/download/meeting-screenshot`, state, config)
    const fileExtension = headers['content-type'].split('/').filter(type => type !== 'image').join()
    this.downloadFile(data, 'meeting-attendee-screenshot.' + fileExtension, headers['content-type'])
  }

  async downloadTeacherDocument(state: RootState, teacherDocLocation: string): Promise<void> {
    const request: ReclassificationFileRequest = { fileLoc: teacherDocLocation }
    const { data, headers } = await this.post('students/getTeacherDocument', { state, request }, { responseType: 'blob' })
    const fileExtension = headers['content-type'].split('/').filter(type => type !== 'image').join()
    this.downloadFile(data, 'teacher-document.' + fileExtension, headers['content-type'])
  }

  async printTeacherForm(state: RootState, request: PrintTeacherFormRequest): Promise<void> {
    const { data } = await this.post<PrintReclassifcationFormResponse>(`${this.baseUri}/meeting/${request.meetingId}/form/download/staar-teacher-form`, { state, request }, { responseType: 'blob' })
    this.downloadFile(data, 'teacher-form.pdf', 'application/pdf')
  }

  async downloadParentLettersCombined(state: RootState, request: GetParentPermissionsRequest): Promise<void> {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: request.meetingId
      },
      responseType: 'blob'
    }
    const { data } = await this.get(`${this.baseUri}/completed/${request.meetingId}/form/download/merged-parent-letters`, state, config)
    this.downloadFile(data, `Parent_Letters_${request.meetingId}_Combined.pdf`, 'application/pdf')
  }

  async confirmCommittee(state: RootState, request: ConfirmCommitteeRequest): Promise<ConfirmCommitteeResponse> {
    const resp = await this.put<ConfirmCommitteeResponse>(`${this.baseUri}/meeting/${request.meetingId}/committee/confirm`, { state })
    return resp
  }

  async resetMeetingSignaturesById(state: RootState, request: ResetMeetingSignaturesRequest): Promise<Meeting> {
    const { data: { currentMeeting } } = await this.delete<ResetMeetingSignaturesResponse>(`${this.baseUri}/meeting/${request.meetingId}/signatures`, state )
    return currentMeeting
  }

  async resetMeetingSignatures(state: RootState): Promise<Meeting> {
    const { data: { currentMeeting } } = await this.post<ResetMeetingSignaturesResponse>(`${this.baseUri}/resetMeetingSignatures`, { state })
    return currentMeeting
  }

  async getMeetingMinutesInfo(state: RootState, request: GetMeetingMinutesInfoRequest): Promise<GetMeetingMinutesInfoResponse> {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: request.meetingId
      }
    }
    const resp = await this.get<GetMeetingMinutesInfoResponse>(`${this.baseUri}/meeting/${request.meetingId}/form/meeting-minutes`, state, config)
    return resp.data
  }

  async skipMeetingAttendeeUpload(state: RootState, meetingId: number) {
    await this.put<MeetingMemberScreenshotResponse>(`${this.baseUri}/meeting/${meetingId}/form/meeting-minutes/skip-attendee-upload`, { state })
  }

  async resetMeeting(state: RootState, request: MeetingRequest): Promise<Meeting> {
    const { data: { meeting } } = await this.put<MeetingResponse>(`${this.baseUri}/meeting/${request.meetingId}/unstart`, { state })
    return meeting
  }

  async setMeetingTypeStatusCode(state: RootState, meetingId: number, meetingTypeCode: MeetingTypeCode, meetingTypeStatusCode: MeetingTypeStatusCode): Promise<MeetingTypeStatusResponse> {
    const request: MeetingTypeStatusCodeRequest = { meetingId, meetingTypeCode, meetingTypeStatusCode }
    const { data } = await this.put<MeetingTypeStatusResponse>(`${this.baseUri}/meeting/${request.meetingId}/status/update`, { state, request })

    return data
  }

  async getMeetingByTeacherLink(state: RootState, teacherLink: string) {
    const { data: { meetingId } } = await this.get<MeetingResponse>(`${this.baseUri}/meeting/teacher/${teacherLink}`, state )

    return meetingId
  }

  async clearPendingStudentsList(state: RootState, schoolId: number){
    await this.delete(`${this.baseUri}/school/${schoolId}/students/potential-exits`, state )
  }

  async getMeetingFormSignatures(state: RootState, meetingId: number) {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: meetingId
      },
    }
    const { data } = await this.get<GetMeetingFormSignaturesReponse>(`${this.baseUri}/meeting/${meetingId}/form/status`, state, config)
    return data.meetingFormSignatures
  }
  async printCurrentStaarAccommodations(state: RootState, meetingId: number) {
    const config: AxiosRequestConfig = {
      params: {
        meetingId: meetingId
      },
    }
    const { data } = await this.get(`${this.baseUri}/meeting/${meetingId}/form/download/staar-accommodations`, state, config)
    const contentType = '.csv'
    const fileName = 'Current_STAAR_Accommodations' + contentType
    this.downloadFile(data, fileName, contentType)
  }

}
