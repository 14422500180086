declare global {
  interface Window {
    zESettings: ZendeskSettings,
    zE: ZendeskEvent
  }
}
export interface ZendeskSettings {
  webWidget: {
    authenticate: {
      chat: {
        jwtFn: (callback: (jwtToken: string) => void) => void
      }
    }
  }
}

export interface ZendeskIdentify {
  name?: string,
  email?: string,
  organization?: string
}

export interface ZendeskPrefill {
  name?: { value: string },
  email?: { value: string },
}

export enum ZendeskChatStatus {
  Away = 'away',
  Offline = 'offline',
  Online = 'online'
}

export type ZendeskEvent = (event: string, callback: string, fn?: (() => void) | ((status: ZendeskChatStatus) => void) | ZendeskIdentify | ZendeskPrefill) => void
