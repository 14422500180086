import { MutationTree } from 'vuex'
import { isEmpty } from 'lodash'
import { clearState, ContentLanguageSupportState } from './state'
import { ContentLanguageControlOptions, RosterOptionEdit } from 'models'
import cloneDeep from 'lodash/cloneDeep'
import {
  InitialCLSOptions
} from './types'

export enum ContentLanguageSupportMutations {
  Clear = 'CLEAR',
  SetSupportOptions = 'SET_SUPPORT_OPTIONS',
  SetInitialOptions = 'SET_INITIAL_OPTIONS',
  SetEditOptions = 'SET_EDIT_OPTIONS',
  RemoveRosterEditOptions = 'REMOVE_ROSTER_EDIT_OPTIONS',
  RemoveStudentEditOptions = 'REMOVE_STUDENT_EDIT_OPTIONS',
  ClearEditOptions = 'CLEAR_EDIT_OPTIONS',
  SetSelectedCategories = 'SET_SELECTED_CATEGORIES',
  SetMeetingStatus = 'SET_MEETING_STATUS',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<ContentLanguageSupportState> = {

  [ContentLanguageSupportMutations.Clear](state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [ContentLanguageSupportMutations.SetSupportOptions](state, options: ContentLanguageControlOptions[]) {
    state.supportOptions = options
  },

  [ContentLanguageSupportMutations.SetInitialOptions](state, options: InitialCLSOptions) {
    state.carryOverOptions = options.rosterId ? state.carryOverOptions.filter(student => student.rosterId !== options.rosterId) : state.carryOverOptions.filter(student => student.studentId !== options.studentId)
    state.carryOverOptions.push(options)
  },

  [ContentLanguageSupportMutations.SetEditOptions](state, newEdits: RosterOptionEdit) {
    state.supportOptionEdits = newEdits.meetingRosterId ? state.supportOptionEdits.filter(edit => edit.meetingRosterId !== newEdits.meetingRosterId) : state.supportOptionEdits.filter(edit => edit.studentId !== newEdits.studentId)
    state.supportOptionEdits.push(newEdits)
  },

  [ContentLanguageSupportMutations.SetSelectedCategories](state, {ids, category}) {
    const categories = state.selectedCategories.filter(id => id !== category)
    if (!isEmpty(ids)) {
      categories.push(category)
    }
    state.selectedCategories = categories
  },

  [ContentLanguageSupportMutations.SetMeetingStatus](state, inMeeting: boolean) {
    state.inMeeting = inMeeting
  },

  [ContentLanguageSupportMutations.RemoveRosterEditOptions](state, meetingRosterId: number) {
    state.supportOptionEdits = state.supportOptionEdits.filter(edits => edits.meetingRosterId !== meetingRosterId)
  },

  [ContentLanguageSupportMutations.RemoveStudentEditOptions](state, studentId: number) {
    state.supportOptionEdits = state.supportOptionEdits.filter(edits => edits.studentId !== studentId)
  },

  [ContentLanguageSupportMutations.ClearEditOptions](state) {
    state.supportOptionEdits = []
  },

  // Only use this mutation for setting the state for test cases
  [ContentLanguageSupportMutations.SetTestState] (state, testData: ContentLanguageSupportState) {
    Object.assign(state, cloneDeep(testData))
  }

}
