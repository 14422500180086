import { contactService } from '@/services'
import { RootState } from '@/store/root'
import {ContactUsRequest} from 'models'
import { ActionTree } from 'vuex'
import { ContactUsState } from './state'

export enum ContactUsActions {
    SendEmail = 'SEND_EMAIL',
}

export const actions: ActionTree<ContactUsState, RootState> = {

    async [ContactUsActions.SendEmail]({ rootState }, request: ContactUsRequest) {
        await contactService.sendEmail(rootState, request)
      },

}
