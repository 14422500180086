import { GetterTree } from 'vuex'
import { RootState } from '@/store/root'
import { ReviewStudentState } from './state'
import {
  MeetingPlacement,
  StudentSchoolGrade,
  Student,
  OralLanguageProficiencyTest, EnrollmentType, MeetingTypeCode, DualLanguageSelection
} from 'models'

export enum ReviewStudentGetters {
  MeetingRosterId = 'MEETING_ROSTER_ID',
  DualLanguage = 'DUAL_LANGUAGE',
  Recommendations = 'RECOMMENDATIONS',
  StudentSchoolGrade = 'STUDENT_SCHOOL_GRADE',
  StudentSchoolGradeId = 'STUDENT_SCHOOL_GRADE_ID',
  Student = 'STUDENT',
  Test = 'TEST',
  EnrollmentType = 'ENROLLMENT_TYPE',
  MeetingTypeCode = 'MEETING_TYPE_CODE',
  PriorMeetingPlacement = 'PRIOR_MEETING_PLACEMENT'
}

export const getters: GetterTree<ReviewStudentState, RootState> = {

  [ReviewStudentGetters.MeetingRosterId](state): number {
    if (state.roster) {
      return state.roster.id
    }
  },

  [ReviewStudentGetters.Recommendations](state): MeetingPlacement {
    if (state.roster) {
      return state.roster.meetingPlacement
    }
  },

  [ReviewStudentGetters.DualLanguage](state): DualLanguageSelection {
    if (state.roster && state.roster.meetingPlacement.dualLanguage) {
      return state.roster.meetingPlacement.dualLanguage
    }
    return {
      language: null,
      year: null
    }
  },

  [ReviewStudentGetters.StudentSchoolGrade](state): StudentSchoolGrade {
    if (state.roster) {
      return state.roster.studentSchoolGrade
    }
  },

  [ReviewStudentGetters.MeetingTypeCode](state): MeetingTypeCode {
    if (state.roster) {
      return state.roster?.meetingType?.code
    }
  },

  [ReviewStudentGetters.StudentSchoolGradeId](state, getters): number {
    const studentSchoolGrade: StudentSchoolGrade = getters[ReviewStudentGetters.StudentSchoolGrade]

    if (studentSchoolGrade) {
      return studentSchoolGrade.id
    }
  },

  [ReviewStudentGetters.Student](state, getters): Student {
    const studentSchoolGrade: StudentSchoolGrade = getters[ReviewStudentGetters.StudentSchoolGrade]

    if (studentSchoolGrade) {
      return studentSchoolGrade.student
    }
  },

  [ReviewStudentGetters.Test](state, getters): OralLanguageProficiencyTest {
    const meetingRosterId: number = getters[ReviewStudentGetters.MeetingRosterId]
    const studentSchoolGrade: StudentSchoolGrade = getters[ReviewStudentGetters.StudentSchoolGrade]
    if (studentSchoolGrade && Array.isArray(studentSchoolGrade.olpts)) {
      return studentSchoolGrade.olpts.find(olpt => olpt.meetingRosterId === meetingRosterId)
    }
  },
  [ReviewStudentGetters.EnrollmentType](state): EnrollmentType {
    if (state.roster?.enrollmentType) {
      return state.roster.enrollmentType
    }
  },
  [ReviewStudentGetters.PriorMeetingPlacement](state){
    return state.priorMeetingPlacement
  }

}
