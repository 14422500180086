import BaseService, {ServiceConfig} from '@/services/base';
import {ProductCode} from '@/enums';
import {
  AppAccessResponse,
  BrowserInfo,
  DistrictInfo,
  DistrictInfoResponse,
  ProductInfoResponse,
  SSOLoginRequest
} from 'models';
import {RootState} from '@/store/root';

export default class SSOService extends BaseService {

  private readonly baseUri: string = '/sso'

  constructor(config: ServiceConfig) {
    super(config)
  }

  async getAllProductsInfo(state: RootState): Promise<{ [product in ProductCode]?: ProductInfoResponse }> {
    const { data } = await this.get<{ [product in ProductCode]?: ProductInfoResponse }>(`${this.baseUri}/all-products`, state)

    return data
  }

  async getAccess(state: RootState, productCode: ProductCode): Promise<AppAccessResponse> {
    const { data } = await this.get<AppAccessResponse>(`${this.baseUri}/access/${productCode}`, state)

    return data
  }

  async getProductInfo(state: RootState, productCode: ProductCode): Promise<ProductInfoResponse> {
    const { data } = await this.get<ProductInfoResponse>(`${this.baseUri}/product/${productCode}`, state)

    return data
  }

  async login(
    state: RootState,
    app: ProductCode,
    userId: number,
    browser: BrowserInfo,
    logoutUrl: string,
  ): Promise<void> {
    const request: SSOLoginRequest = { app, userId, browser, logoutUrl }

    await this.post(`${this.baseUri}/login`, { state, request })
  }

  async getDistrictInfo(state: RootState, productCode: ProductCode): Promise<DistrictInfo> {
    const { data } = await this.get<DistrictInfoResponse>(`${this.baseUri}/district-info/${productCode}`, state)

    return data.dstInfo
  }
}
