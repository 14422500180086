import Vue from 'vue'
import Component from 'vue-class-component'
import { createRouter } from './router'
import {
  TestElement,
  TestElementOptions,
  CommonComponents,
  CommonComponentsOptions
} from '@806/web'
import { plural } from '@806/utils'
import App from './App.vue'
import { createLoginGuard } from './util/login-guard'
import { createStore } from './store'
import { termFilter } from './util/term-filter'
import { TestElement as TestElements } from './util/TestElement'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faCheck, faTimesCircle, faArrowDown, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { colors, breakpoints } from '@/style'

import '@806/web/dist/web.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Vue.config.productionTip = false

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

const store = createStore()
const router = createRouter()

router.beforeEach(createLoginGuard(store))

Vue.use<CommonComponentsOptions>(CommonComponents, {
  theme: {
    colors,
    breakpoints,
    stackOrder: {
      modal: 100
    }
  }
})

Vue.filter('plural', plural)
Vue.use(termFilter, { store })
Vue.use<TestElementOptions>(TestElement, {
  elements: TestElements
})
library.add(faCoffee)
library.add(faCheck)
library.add(faTimesCircle)
library.add(faArrowDown)
library.add(faArrowRight)
library.add(faArrowLeft)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// tslint:disable-next-line:no-unused-expression
new Vue({
  el: '#app-main',
  router,
  store,
  render: h => h(App)
})
