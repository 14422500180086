import BaseService, {ServiceConfig} from './base'
import {
  MeetingRoster,
  StudentPaging,
  StudentSchoolGradesRequest,
  StudentSchoolGradesResponse,
  RosterStudentsRequest,
  RosterStudentsResponse,
  SaveStudentReviewRequest,
  OralLanguageProficiencyTest,
  MeetingPlacement,
  MeetingRosterRequest,
  MeetingRosterResponse,
  StudentMeetingFileRequest,
  ParentDecisionRequest,
  ReclassificationFileRequest,
  MeetingFilesCombinedRequest,
  SaveStudentTestsResponse,
  StudentInformationRequest,
  StudentInformationResponse,
  ProficiencyTestResults,
  SaveStudentProfTestRequest,
  SetProficiencyTestTypeRequest,
  UpdateMeetingNotesRequest,
  GetStaarStudentNotesRequest,
  GetStaarStudentNotesResponse,
  StudentNote,
  GetStudentNotesRequest,
  GetStudentNotesResponse,
  TelpasAssessmentDecisionRequest,
  TelpasAssessmentDecisionResponse,
  RosterStudentOptionsRequest,
  RosterOptionEdit,
  SaveRosterTransferOptionsRequest,
  SetRosterEnrollmentTypeRequest,
  SaveAllIdentificationInfoRequest,
  DualLanguageSelection,
  RosterStudentOptionsResponse,
  ContentLanguageControlOptions,
  RosterReviewStudentsResponse,
  GetDashboardStudentsResponse,
  GetDashboardStudentsRequest,
  ParentLetterRegenRequest,
  ParentLetterRegenResponse,
  PriorMeetingPlacementResponse,
  ReportStudentsRequest,
  StudentSchoolGrade,
  SaveDnqRequest, ProcessStudentPlacementRequest, ProcessStudentPlacementResponse, GetRosterMeetingFormTypesResponse, GetRecentStudentPlacementResponse
} from 'models'
import { RootState } from '@/store/root'
import {IndicatorType, LimitedEnglishProficiencyCode, MeetingStatus, MeetingTypeCode, ProficiencyTestTypeCode} from '@/enums'
import { PagingUtil } from '@806/utils'
import {AxiosRequestConfig} from 'axios'

export default class StudentService extends BaseService {

  private readonly baseUri: string = '/students'

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getStudents(
    state: RootState,
    paging: StudentPaging,
    meetingType?: MeetingTypeCode,
    hideCurrentMeeting?: boolean
  ): Promise<StudentSchoolGradesResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: StudentSchoolGradesRequest = { paging, meetingType, hideCurrentMeeting }
      const { data } = await this.post<StudentSchoolGradesResponse>(`${this.baseUri}/getStudents`, { state, request })

      return data
    })
  }

  async getAnyTypeStudents(
    state: RootState,
    paging: StudentPaging,
    meetingType?: MeetingTypeCode,
    showInactive?: boolean,
    isDPU?: boolean,
    hideCurrentMeeting?: boolean
  ): Promise<StudentSchoolGradesResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: StudentSchoolGradesRequest = { paging, meetingType, hideCurrentMeeting, isDPU, showInactive }

      const { data } = await this.post<StudentSchoolGradesResponse>(`${this.baseUri}/getAnyTypeStudents`, { state, request })

      return data
    })
  }

  async getStudentById(
    state: RootState,
    studentId: number,
    hideCurrentMeeting?: boolean,
    showInactive?: boolean
  ): Promise<StudentInformationResponse> {
    const request: StudentInformationRequest = { studentId, hideCurrentMeeting, showInactive }
    const { data } = await this.post<StudentInformationResponse>('/students/getStudentById', { state, request })
    return data
  }

  async getStudentsByTeacherIds(state: RootState, teacherIds: number[], schoolIds: number[]): Promise<StudentSchoolGrade[]> {
    const request: ReportStudentsRequest = { teacherIds, schoolIds }
    const { data } = await this.post<StudentSchoolGrade[]>(`${this.baseUri}/getStudentsByTeacherIds`, { state, request })

    return data
  }

  async getPagedStudentsByTeacherIds(
    state: RootState,
    teacherIds: number[],
    schoolIds: number[],
    paging: StudentPaging,
    isAdvancedUser: boolean,
  ): Promise<StudentSchoolGradesResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: ReportStudentsRequest = { teacherIds, schoolIds, paging, isAdvancedUser }

      const { data } = await this.post<StudentSchoolGradesResponse>(`${this.baseUri}/getPagedStudentsByTeacherIds`, { state, request })

      return data
    })
  }

  async getRosterStudents(
    state: RootState,
    paging: StudentPaging,
    meetingId: number,
    meetingType?: MeetingTypeCode,
    decisionStudents?: Array<number>,
    includeStaarData?: boolean,
    includeTelpasData?: boolean
  ): Promise<RosterStudentsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: RosterStudentsRequest = { meetingId, meetingType, paging, includeStaarData, includeTelpasData, decisionStudents }

      const { data } = await this.post<RosterStudentsResponse>(`${this.baseUri}/getRosterStudents`, { state, request })

      return data
    })
  }

  async getClosedRosterStudents(
    state: RootState,
    paging: StudentPaging,
    meetingId: number,
    meetingType?: MeetingTypeCode,
    decisionStudents?: Array<number>,
    includeStaarData?: boolean,
    includeTelpasData?: boolean
  ): Promise<RosterStudentsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: RosterStudentsRequest = { meetingId, meetingType, paging, includeStaarData, includeTelpasData, decisionStudents }

      const { data } = await this.post<RosterStudentsResponse>(`${this.baseUri}/meeting/roster`, { state, request })

      return data
    })
  }

  async getOpenIdRosterStudents(
    state: RootState,
    paging: StudentPaging,
    meetingId: number,
    meetingType?: MeetingTypeCode,
    decisionStudents?: Array<number>,
    includeStaarData?: boolean,
    includeTelpasData?: boolean
  ): Promise<RosterStudentsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: RosterStudentsRequest = { meetingId, meetingType, paging, includeStaarData, includeTelpasData, decisionStudents, meetingOpen: true, inPrepare: true }
      const { data } = await this.post<RosterStudentsResponse>(`${this.baseUri}/meeting/roster`, { state, request })

      return data
    })
  }

  async getOpenRosterStudents(
    state: RootState,
    paging: StudentPaging,
    meetingId: number,
    meetingType?: MeetingTypeCode,
    decisionStudents?: Array<number>,
    includeStaarData?: boolean,
    includeTelpasData?: boolean,
    inPrepare?: boolean
  ): Promise<RosterStudentsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: RosterStudentsRequest = { meetingId, meetingType, paging, includeStaarData, includeTelpasData, decisionStudents, meetingOpen: true, inPrepare }
      const { data } = await this.post<RosterStudentsResponse>(`${this.baseUri}/meeting/roster`, { state, request })

      return data
    })
  }

  async getRosterReviewStudents(
    state: RootState,
    paging: StudentPaging,
    meetingId: number,
    meetingType?: MeetingTypeCode
  ): Promise<RosterReviewStudentsResponse> {
    const request: RosterStudentsRequest = { meetingId, meetingType, paging }

    const { data } = await this.post<RosterReviewStudentsResponse>(`${this.baseUri}/getRosterReviewStudents`, { state, request })

    return data
  }

  async getAllRostersCompleted(
    state: RootState,
    meetingId: number
  ): Promise<boolean> {

    const { data } = await this.get<boolean>(`${this.baseUri}/all-completed/` + meetingId, state)

    return data
  }

  async getRoster(state: RootState, rosterId: number, meetingStatus: MeetingStatus): Promise<MeetingRoster> {
    const request: MeetingRosterRequest = { rosterId, meetingStatus }
    const { data: { roster } } = await this.post<MeetingRosterResponse>('/students/getRoster', { state, request })

    return roster
  }


  async saveProficiencyTest(state: RootState, meetingRosterId: number, test: OralLanguageProficiencyTest, meetingTypeCode: MeetingTypeCode): Promise<MeetingRoster> {
    const request: SaveStudentReviewRequest = { meetingRosterId, test, meetingTypeCode }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveStudentReviewTest`, { state, request })

    return roster
  }

  async saveStudentProficiencyTest(state: RootState, meetingRosterId: number, proficiencyTestResults: ProficiencyTestResults[]): Promise<MeetingRoster> {
    const request: SaveStudentReviewRequest = { meetingRosterId, proficiencyTestResults }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveStudentReviewProficiencyTest`, { state, request })

    return roster
  }

  async saveProficiencyTestResults(state: RootState, meetingRosterId: number, studentSchoolGradeId: number, proficiencyTestResults: ProficiencyTestResults[], isMeetingClosed?: boolean): Promise<MeetingRoster> {
    const request: SaveStudentProfTestRequest = { meetingRosterId, studentSchoolGradeId, proficiencyTestResults, isMeetingClosed }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveRosterStudentProfTestRslts`, { state, request })

    return roster
  }

  async saveProficiencyTestType(state: RootState, meetingRosterId: number, proficiencyTestTypeCode: ProficiencyTestTypeCode): Promise<MeetingRoster> {
    const request: SetProficiencyTestTypeRequest = { meetingRosterId, proficiencyTestTypeCode }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/setProficiencyTestTypeForRoster`, { state, request })

    return roster
  }

  async saveRosterTransferOptions(state: RootState, request: SaveRosterTransferOptionsRequest): Promise<MeetingRoster> {
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveRosterTransferOptions`, { state, request })

    return roster
  }

  async saveProficiencyTests(
    state: RootState,
    meetingId: number,
    tests: OralLanguageProficiencyTest[],
    proficiencyTestResults: ProficiencyTestResults[],
    paging: StudentPaging,
    meetingType?: MeetingTypeCode,
    saveRosterTransferOptionsRequests?: SaveRosterTransferOptionsRequest[]
  ): Promise<SaveStudentTestsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: SaveAllIdentificationInfoRequest = { meetingId, tests, proficiencyTestResults, paging, meetingType, saveRosterTransferOptionsRequests }

      const { data } = await this.post<SaveStudentTestsResponse>(`${this.baseUri}/saveAllIdentificationInfo`, { state, request })

      return data
    })
  }

  async saveAllCLSOptions(state: RootState, rosterOptions: RosterOptionEdit[]): Promise<void> {
    const request: RosterStudentOptionsRequest = { rosterOptions }
    await this.post<void>(`${this.baseUri}/saveAllStudentSupportOptions`, { state, request })
  }

  async saveStudentSupportOption(state: RootState, request: RosterStudentOptionsRequest): Promise<ContentLanguageControlOptions[]> {
    const { data } = await this.post<RosterStudentOptionsResponse>(`${this.baseUri}/saveStudentSupportOptions`, { state, request })

    return data.options
  }

  async saveRecommendations(state: RootState, meetingRosterId: number, recommendations: MeetingPlacement): Promise<MeetingRoster> {
    const request: SaveStudentReviewRequest = { meetingRosterId, recommendations }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveStudentReviewRecommendations`, { state, request })

    return roster
  }

  async setRosterEnrollmentType(state: RootState, request: SetRosterEnrollmentTypeRequest) {
    await this.post<MeetingRosterResponse>(`${this.baseUri}/setRosterEnrollmentType`, { state, request })
  }

  async deleteRosterEnrollmentType(state: RootState, request: SetRosterEnrollmentTypeRequest) {
    await this.post<MeetingRosterResponse>(`${this.baseUri}/deleteRosterEnrollmentType`, { state, request })
  }

  async saveDnqStatus(state: RootState, request: SaveDnqRequest): Promise<MeetingRoster> {
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveDnq`, { state, request })
    return roster
  }

  async saveRosterSummary(
    state: RootState,
    meetingRosterId: number,
    summary: string
  ): Promise<MeetingRoster> {
    const request: SaveStudentReviewRequest = { meetingRosterId, summary }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveRosterSummary`, { state , request })

    return roster
  }

  async processStudentPlacements(state: RootState, request: ProcessStudentPlacementRequest): Promise<ProcessStudentPlacementResponse> {
    const { data } = await this.post<ProcessStudentPlacementResponse>(`${this.baseUri}/processStudentPlacements`, { state, request })
    return data;
  }

  async saveStudentReview(
    state: RootState,
    meetingRosterId: number,
    summary: string,
    test: OralLanguageProficiencyTest,
    proficiencyTestResults: ProficiencyTestResults[],
    recommendations: MeetingPlacement,
    proficiencyTestTypeCode: ProficiencyTestTypeCode,
    inProgress: boolean,
    dualLanguage: DualLanguageSelection,
    meetingTypeCode: MeetingTypeCode,
    meetingId: number
  ): Promise<MeetingRoster> {
    const request: SaveStudentReviewRequest = { meetingRosterId, summary, test, recommendations, proficiencyTestResults, proficiencyTestTypeCode, inProgress, dualLanguage, meetingTypeCode, meetingId }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveStudentReview`, { state , request })

    return roster
  }

  async saveStudentAnnualLepReview(
    state: RootState,
    meetingRosterId: number,
    summary: string,
    test: OralLanguageProficiencyTest,
    proficiencyTestResults: ProficiencyTestResults[],
    recommendations: MeetingPlacement,
    proficiencyTestTypeCode: ProficiencyTestTypeCode,
    inProgress: boolean,
    readiness: IndicatorType,
    dualLanguage: DualLanguageSelection,
    meetingId: number,
    meetingTypeCode: MeetingTypeCode
  ): Promise<MeetingRoster> {
    const request: SaveStudentReviewRequest = { meetingRosterId, summary, test, recommendations, proficiencyTestResults, proficiencyTestTypeCode, inProgress, readiness, dualLanguage, meetingId, meetingTypeCode }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/student/annual/lepreview`, { state , request })

    return roster
  }

  async saveStudentPotentialExit(
    state: RootState,
    meetingRosterId: number,
    summary: string,
    test: OralLanguageProficiencyTest,
    proficiencyTestResults: ProficiencyTestResults[],
    recommendations: MeetingPlacement,
    proficiencyTestTypeCode: ProficiencyTestTypeCode,
    inProgress: boolean,
    readiness: IndicatorType,
    dualLanguage: DualLanguageSelection,
    meetingId: number
  ): Promise<MeetingRoster> {
    const request: SaveStudentReviewRequest = { meetingRosterId, summary, test, recommendations, proficiencyTestResults, proficiencyTestTypeCode, inProgress, readiness, dualLanguage, meetingId }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/student/annual/potential/exit`, { state , request })

    return roster
  }

  async saveStudentAnnualMonitorReview(
    state: RootState,
    meetingRosterId: number,
    summary: string,
    test: OralLanguageProficiencyTest,
    proficiencyTestResults: ProficiencyTestResults[],
    recommendations: MeetingPlacement,
    proficiencyTestTypeCode: ProficiencyTestTypeCode,
    inProgress: boolean,
    dualLanguage: DualLanguageSelection,
    meetingId: number
  ): Promise<MeetingRoster> {
    const request: SaveStudentReviewRequest = { meetingRosterId, summary, test, recommendations, proficiencyTestResults, proficiencyTestTypeCode, inProgress, dualLanguage, meetingId }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/student/annual/monitor`, { state , request })

    return roster
  }

  async getTelpasAssessmentDecisions(state: RootState, request: TelpasAssessmentDecisionRequest): Promise<TelpasAssessmentDecisionResponse> {
    const { data } = await this.post<TelpasAssessmentDecisionResponse>(`${this.baseUri}/getTelpasAssessmentDecisions`, { state, request })

    return data
  }

  async getStudentMeetingFile(state: RootState, request: StudentMeetingFileRequest): Promise<void> {
    const { data } = await this.post(`${this.baseUri}/getStudentMeetingFile`, { state, request }, { responseType: 'blob' })
    this.downloadFile(data, `${request.studentNumber}.pdf`, 'application/pdf')
  }

  async getStudentTeacherMeetingFile(state: RootState, request: StudentMeetingFileRequest): Promise<void> {
    const { data } = await this.post(`${this.baseUri}/getStudentTeacherMeetingFile`, { state, request }, { responseType: 'blob' })
    this.downloadFile(data, `${request.studentNumber}.pdf`, 'application/pdf')
  }

  async downloadMeetingFilesCombined(state: RootState, request: MeetingFilesCombinedRequest): Promise<void> {
    const { data } = await this.post(`${this.baseUri}/getMeetingFilesCombined`, { state, request }, { responseType: 'blob' })
    this.downloadFile(data, `LPAC_${request.meetingDate}_Combined.pdf`, 'application/pdf')
  }

  async saveParentDecision(state: RootState, rosterId: number, date: string, decisionId: number, meetingId: number) {
    const request: ParentDecisionRequest = { rosterId, date, decisionId, meetingId }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveParentDecision`, { state, request })

    return roster
  }

  async saveParentDecisionUpdates(state: RootState, request: ParentDecisionRequest) {
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/saveParentDecision`, { state, request })

    return roster
  }

  async saveStudentNote(state: RootState, request: UpdateMeetingNotesRequest) {
    await this.post(`${this.baseUri}/saveMeetingNote`, { state, request })
  }

  async getStaarStudentNotes(state: RootState, request: GetStaarStudentNotesRequest): Promise<StudentNote[]> {
    const { data } = await this.post<GetStaarStudentNotesResponse>(`${this.baseUri}/getStaarStudentNotes`, { state, request })
    return data.studentNoteLst
  }

  async getStudentNotesByMeetingType(state: RootState, request: GetStudentNotesRequest): Promise<StudentNote[]> {
    const { data } = await this.post<GetStudentNotesResponse>(`${this.baseUri}/getStudentNotesByMeetingType`, { state, request })
    return data.studentNoteLst
  }

  async removeParentDecisionForm(state: RootState, rosterId: number, meetingId: number): Promise<MeetingRoster> {
    const request: MeetingRosterRequest = { rosterId, meetingId }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/removeParentDecisionForm`, { state, request })

    return roster
  }

  async removeTeacherDocument(state: RootState, rosterId: number): Promise<MeetingRoster> {
    const request: MeetingRosterRequest = { rosterId }
    const { data: { roster } } = await this.post<MeetingRosterResponse>(`${this.baseUri}/removeTeacherDocument`, { state, request })

    return roster
  }

  async downloadParentDecisionForm(state: RootState, rosterId: number, filename: string): Promise<void> {
    const request: MeetingRosterRequest = { rosterId }
    const { data } = await this.post(`${this.baseUri}/getParentDecisionForm`, { state, request }, { responseType: 'blob' })

    this.downloadFile(data, filename, 'application/pdf')
  }

  async downloadReclassificationForm(state: RootState, reclassificationFormLoc: string): Promise<void> {
    const request: ReclassificationFileRequest = { fileLoc: reclassificationFormLoc }
    const { data } = await this.post(`${this.baseUri}/getReclassificationFile`, { state, request }, { responseType: 'blob' })
    const type = reclassificationFormLoc.split('.').pop() === 'pdf' ? 'application/pdf' : 'application/csv'
    const fileName = type === 'application/pdf' ? 'PEIMS_Form.pdf' : 'Participation_Decisions.csv'
    this.downloadFile(data, fileName, type)
  }

  async getPotentialExitStudents(
    state: RootState,
    paging: StudentPaging,
    lepCode: LimitedEnglishProficiencyCode,
    meetingTypeCode: MeetingTypeCode
  ): Promise<GetDashboardStudentsResponse> {
    return PagingUtil.getPagedInBounds(paging, async paging => {
      const request: GetDashboardStudentsRequest = { paging, lepCode, meetingTypeCode }
      const { data } = await this.post<GetDashboardStudentsResponse>(`${this.baseUri}/potential-exit-students`, { state, request })
      return data
    })
  }

  async regenerateParentLetters(state: RootState, request: ParentLetterRegenRequest): Promise<ParentLetterRegenResponse> {
    const { data } = await this.post<ParentLetterRegenResponse>(`${this.baseUri}/regenerateParentLetters`, { state, request })
    return data
  }

  async getPriorMeetingPlacement(
    state: RootState,
    stuSchGrdId: string
  ): Promise<PriorMeetingPlacementResponse> {
    const config: AxiosRequestConfig = {
      params: {
        stuSchGrdId: stuSchGrdId
      }
    }
    const { data } = await this.get<PriorMeetingPlacementResponse>(`${this.baseUri}/getPriorMeetingPlacement/` + stuSchGrdId, state, config)
    return data
  }

  async getRosterMeetingFormTypesById(state: RootState, rosterId: number): Promise<GetRosterMeetingFormTypesResponse> {
    const config: AxiosRequestConfig = {
      params: {
        rosterId: rosterId
      }
    }
    const { data } = await this.get<GetRosterMeetingFormTypesResponse>(`${this.baseUri}/roster/form/types/` + rosterId, state, config)
    return data
  }


  async getRecentStudentPlacement(
    state: RootState,
    studentId: number
  ): Promise<GetRecentStudentPlacementResponse> {
    const config: AxiosRequestConfig = {
      params: {
        studentId: studentId
      }
    }
    const { data } = await this.get<GetRecentStudentPlacementResponse>(`${this.baseUri}/student/placement/` + studentId, state, config)
    return data
  }

}
