import BaseService, {ServiceConfig} from './base'
import { RootState } from '@/store/root'
import { RestUrls } from './RestUrls'
import { StudentMarkingPeriodGrades, SupportOptionsResponse } from 'models'
import {AxiosRequestConfig} from 'axios';

export default class GradeService extends BaseService {

  constructor (config: ServiceConfig) {
    super(config)
  }

  async getStudentMarkingPeriodGrades(state: RootState, studentId: number): Promise<StudentMarkingPeriodGrades> {
    const config: AxiosRequestConfig = {
      params: {
        studentId: studentId
      },
    }
    const { data } = await this.get<StudentMarkingPeriodGrades>(`${RestUrls.studentMarkingPeriodGrades}/${studentId}`, state, config)

    return data
  }

  async getSupportOptions(state: RootState, rosterId?: number): Promise<SupportOptionsResponse> {
    const rosterVal = rosterId ? rosterId : 0
    const { data } = await this.get<SupportOptionsResponse>(`${RestUrls.getSupportOptions}/${rosterVal}`, state)
    return data
  }

}
