import { documentsService, meetingService, studentService } from '@/services'
import { RootState } from '@/store/root'
import { CompletedMeetingState } from './state'
import { CompletedMeetingMutations } from './mutations'
import { ActionTree } from 'vuex'
import defaults from 'lodash/defaults'
import {
  StudentMeetingFileRequest,
  MeetingRoster,
  MeetingFilesCombinedRequest,
  MeetingMemberScreenshotRequest,
  GetParentPermissionsRequest,
  ParentLetterRegenRequest,
  StudentDocumentRequest,
  StudentDocFileRequest} from 'models'

export enum CompletedMeetingActions {
  GetMeeting = 'GET_MEETING',
  GetMeetingLite = 'GET_MEETING_LITE',
  GetStudents = 'GET_STUDENTS',
  GetStudentDocument = 'GET_STUDENT_DOCUMENT',
  GetStudentTeacherDocument = 'GET_STUDENT_TEACHER_DOCUMENT',
  GetParentDecisionForm = 'GET_PARENT_DECISION_FORM',
  GetReclassificationForm = 'GET_RECLASSIFICATION_FORM',
  GetMeetingFilesCombined = 'GET_MEETING_FILES_COMBINED',
  GetMeetingMemberScreenshotUploaded = 'GET_MEETING_MEMBER_SCREENSHOT_UPLOADED',
  GetParentLettersUploaded = 'GET_PARENT_LETTERS_UPLOADED',
  GetParentLettersCombined = 'GET_PARENT_LETTERS_COMBINED',
  GetRegeneratingParentLetters = 'GET_REGENERATING_PARENT_LETTERS',
  RegenerateParentLetters = 'REGENERATE_PARENT_LETTERS',
  GetStudentDocumentByType = 'GET_STUDENT_DOCUMENT_BY_TYPE',
  GetMeetingStudentDocument = 'GET_MEETING_STUDENT_DOCUMENT',
  GetMeetingFormSignatures = 'GET_MEETING_FORM_SIGNATURES',
  GetRosterMeetingFormTypes ='GET_ROSTER_MEETING_FORM_TYPES'
}

export const actions: ActionTree<CompletedMeetingState, RootState> = {

  async [CompletedMeetingActions.GetMeeting] ({ commit, rootState }, meetingId: number) {
    const meeting = await meetingService.getCompletedMeeting(rootState, meetingId)

    commit(CompletedMeetingMutations.SetMeeting, meeting)
    if (meeting.generatingParentLetters === true) {
      commit(CompletedMeetingMutations.SetRegeneratingParentLetters, meeting.generatingParentLetters)
    }
  },

  async [CompletedMeetingActions.GetMeetingLite] ({ commit, rootState }, meetingId: number) {
    const meeting = await meetingService.getCompletedMeetingLite(rootState, meetingId)

    commit(CompletedMeetingMutations.SetMeeting, meeting)
    if (meeting.generatingParentLetters === true) {
      commit(CompletedMeetingMutations.SetRegeneratingParentLetters, meeting.generatingParentLetters)
    }
  },

  async [CompletedMeetingActions.GetStudents]({ commit, state, rootState }) {
    const { students, pagination } = await studentService.getClosedRosterStudents(rootState, state.paging, state.meeting.id)

    if (state.paging.page !== pagination.currentPage) {
      commit(CompletedMeetingMutations.SetPaging, defaults({
        page: pagination.currentPage
      }, state.paging))
    }

    commit(CompletedMeetingMutations.SetStudents, { students, pagination })
  },

  async [CompletedMeetingActions.GetStudentDocumentByType]({ commit, rootState }, request: StudentDocumentRequest) {
    const { documents } = await documentsService.getDocumentByType(rootState, request)
    commit(CompletedMeetingMutations.SetDocuments, documents)
  },

  async [CompletedMeetingActions.GetMeetingStudentDocument]({ rootState }, request: StudentDocFileRequest) {
    await documentsService.downloadStudentDocument(rootState, request)
  },

  async [CompletedMeetingActions.GetStudentDocument]({ rootState }, getStudentDocument: StudentMeetingFileRequest) {
    await studentService.getStudentMeetingFile(rootState, getStudentDocument)
  },

  async [CompletedMeetingActions.GetStudentTeacherDocument]({ rootState }, getStudentDocument: StudentMeetingFileRequest) {
    await studentService.getStudentTeacherMeetingFile(rootState, getStudentDocument)
  },

  async [CompletedMeetingActions.GetMeetingMemberScreenshotUploaded]({ commit, rootState }, request: MeetingMemberScreenshotRequest) {
    const location = await meetingService.getMeetingMemberScreenshotS3Loc(rootState, request)
    const locationExists = location !== undefined && location !== 'skipped' ? true : false

    commit(CompletedMeetingMutations.SetMeetingMemberScreenshotUploaded, locationExists)
  },

  async [CompletedMeetingActions.GetParentLettersUploaded]({ commit, rootState }, request: MeetingMemberScreenshotRequest) {
    const location = await meetingService.getMergedParentLettersUploaded(rootState, request)
    const locationExists = location !== undefined && location !== 'skipped' ? true : false

    commit(CompletedMeetingMutations.SetParentLettersUploaded, locationExists)
  },

  async [CompletedMeetingActions.GetParentDecisionForm]({ rootState }, roster: MeetingRoster) {
    const studentNumber = roster.studentSchoolGrade.student.studentNumber
    const filename = `${studentNumber}-${roster.meetingPlacement.formFilename}`

    await studentService.downloadParentDecisionForm(rootState, roster.id, filename)
  },

  async [CompletedMeetingActions.GetReclassificationForm]({ rootState }, reclassificationFormLoc: string) {
    await studentService.downloadReclassificationForm(rootState, reclassificationFormLoc)
  },

  async [CompletedMeetingActions.GetMeetingFilesCombined]({ rootState }, request: MeetingFilesCombinedRequest) {
    await studentService.downloadMeetingFilesCombined(rootState, request)
  },

  async [CompletedMeetingActions.GetParentLettersCombined]({ rootState }, request: GetParentPermissionsRequest) {
    await meetingService.downloadParentLettersCombined(rootState, request)
  },

  async [CompletedMeetingActions.RegenerateParentLetters]({ commit, rootState }, request: ParentLetterRegenRequest) {
    const resp = await studentService.regenerateParentLetters(rootState, request)

    commit(CompletedMeetingMutations.SetRegeneratingParentLetters, resp.success)
  },

  async [CompletedMeetingActions.GetRegeneratingParentLetters]({ commit, rootState }, meetingId: number) {
    const isRegeneratingParentLetters = await documentsService.getRegeneratingParentLetters(rootState, meetingId)

    commit(CompletedMeetingMutations.SetRegeneratingParentLetters, isRegeneratingParentLetters)
  },

  async [CompletedMeetingActions.GetMeetingFormSignatures]({ commit, rootState }, meetingId: number) {
    const meetingFormSignatures = await meetingService.getMeetingFormSignatures(rootState,meetingId)
    commit(CompletedMeetingMutations.SetMeetingFormSignatures, meetingFormSignatures)
  },

  async [CompletedMeetingActions.GetRosterMeetingFormTypes]({ commit, rootState }, roster: MeetingRoster) {
    const data = await studentService.getRosterMeetingFormTypesById(rootState,roster.id)

    commit(CompletedMeetingMutations.SetRosterMeetingFormTypes, {roster, meetingFormTypes: data.meetingFormTypes})
  },
}
