import { documentsService } from '@/services'
import { RootState } from '@/store/root'
import { StudentDocumentsState } from './state'
import { StudentDocumentsMutations } from './mutations'
import { ActionTree } from 'vuex'
import defaults from 'lodash/defaults'
import { RemoveStudentDocRequest, StudentDocFileRequest } from 'models'

export enum StudentDocumentsActions {
  GetPagedDocuments = 'GET_PAGED_DOCUMENTS',
  GetDocumentTypes = 'GET_DOCUMENT_TYPES',
  GetStudentDocument = 'GET_STUDENT_DOCUMENT',
  GetAllStudentDocuments = 'GET_ALL_STUDENT_DOCUMENTS',
  GetZipDocument = 'GET_ZIP_DOCUMENT',
  GetZipFileStatus = 'GET_ZIP_FILE_STATUS',
  RemoveStudentDocument = 'REMOVE_STUDENT_DOCUMENT'
}

export const actions: ActionTree<StudentDocumentsState, RootState> = {

  async [StudentDocumentsActions.GetPagedDocuments] ({ commit, state, rootState }) {
    commit(StudentDocumentsMutations.SetLoaded, false)

    try {

      const { documents, pagination } = await documentsService.getPagedDocuments(rootState, state.paging, state.studentId, state.meetingRosterId)

      if (state.paging.page !== pagination.currentPage) {
        commit(StudentDocumentsMutations.SetPaging, defaults({
          page: pagination.currentPage
        }, state.paging))
      }

      commit(StudentDocumentsMutations.SetDocuments, { documents, pagination })
    } finally {
      commit(StudentDocumentsMutations.SetLoaded, true)
    }
  },

  async [StudentDocumentsActions.GetDocumentTypes] ({ commit, rootState }) {
    const types = await documentsService.getDocumentTypes(rootState)

    commit(StudentDocumentsMutations.SetDocumentTypes, types)
  },

  async [StudentDocumentsActions.GetStudentDocument]({ rootState }, request: StudentDocFileRequest) {
    await documentsService.downloadStudentDocument(rootState, request)
  },

  async [StudentDocumentsActions.GetAllStudentDocuments]({ rootState }, studentId: number) {
    await documentsService.downloadAllStudentDocuments(rootState, studentId)
  },

  async [StudentDocumentsActions.GetZipDocument]({ commit, rootState }, studentId: number) {
    const zipDoc = await documentsService.getZipDocument(rootState, studentId)

    commit(StudentDocumentsMutations.SetZipDocument, zipDoc)
  },

  async [StudentDocumentsActions.GetZipFileStatus]({ commit, rootState }, studentId: number) {
    const zipFileStatusResponse = await documentsService.getZipFileStatus(rootState, studentId)
  
    commit(StudentDocumentsMutations.SetZipFileStatus, zipFileStatusResponse.zipFileStatus)
    commit(StudentDocumentsMutations.SetHasDocs, zipFileStatusResponse.hasDocs)
  },

  async [StudentDocumentsActions.RemoveStudentDocument]({ rootState }, request: RemoveStudentDocRequest) {
    await documentsService.removeStudentDocument(rootState, request)
  }

}
