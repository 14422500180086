import Vue from 'vue'
import { MutationTree } from 'vuex'
import { uniqBy, isNil, isEmpty } from 'lodash'
import { ReviewMeetingState, clearState } from './state'
import {
  MeetingType,
  MeetingRole,
  MeetingMember,
  Assessment,
  SelectedStaarJustification,
  Student,
  ProficiencyTestResults,
  TelpasAssessmentDecisionResponse, ContentLanguageControlOptions, RosterOptionEdit, StudentNote, MeetingRoster
} from 'models'
import { EnrollmentTypeCode, MeetingTypeCode } from '@/enums'
import cloneDeep from 'lodash/cloneDeep'
import {
  OlptSaveEdits,
  MeetingTypeStudents,
  StudentIdAndMeetingType,
  AssessmentId,
  SubjectId,
  SelectSubjectParams,
  StudentId,
  SelectedSubject,
  SelectedTeacher,
  SelectStaarSelection,
  SelectSubjectBehavior,
  InitialCLSOptions
} from './types'
import { isArray } from 'util'

export enum ReviewMeetingMutations {
  Clear = 'CLEAR',
  SetTypes = 'SET_TYPES',
  SetTelpasAssessmentDecisions = 'SET_TELPAS_ASSESSMENT_DECISIONS',
  RemoveTelpasAssessmentDecisionStudent = 'REMOVE_TELPAS_ASSESSMENT_DECISION_STUDENT',
  SetIdentificationAssessmentDecisions = 'SET_IDENTIFICATION_ASSESSMENT_DECISIONS',
  ClearMeetingType = 'CLEAR_MEETING_TYPE',
  SetMeetingTypeStudents = 'SET_MEETING_TYPE_STUDENTS',
  SetMeetingTypePaging = 'SET_MEETING_TYPE_PAGING',
  SetMeetingTypeSelected = 'SET_MEETING_TYPE_SELECTED',
  ClearMeetingTypeSelected = 'CLEAR_MEETING_TYPE_SELECTED',
  SetRoles = 'SET_ROLES',
  SetAllMembers = 'SET_ALL_MEMBERS',
  SetTestEditsForStudent = 'SET_TEST_EDITS_FOR_STUDENT',
  SetProficiencyEditsForStudent = 'SET_PROFICIENCY_EDITS_FOR_STUDENT',
  AddOrRemoveTransferOpt = 'ADD_REMOVE_TRANSFER_OPT',
  SetTransferOptEdits = 'SET_TRANSFER_OPT_EDITS',
  SetMeetingStudents = 'SET_MEETING_STUDENTS',
  SetHideDecisions = 'SET_HIDE_DECISIONS',
  ClearEditsMap = 'CLEAR_EDITS_MAP',
  RemoveEditEntryFromMap = 'REMOVE_EDIT_ENTRY_FROM_MAP',
  RemoveTestResultsEntryFromMap = 'REMOVE_TEST_RESULTS_ENTRY_FROM_MAP',
  ResetMeetingStudentInRoster = 'RESET_MEETING_STUDENT_IN_ROSTER',
  ResetRosterEnrollmentType = 'RESET_ROSTER_ENROLLMENT_TYPE',
  SetRemoveStudentIdAndMeetingType = 'SET_REMOVE_STUDENT_ID_AND_MEETING_TYPE',
  SetSupportOptions = 'SET_SUPPORT_OPTIONS',
  SetInitialOptions = 'SET_INITIAL_OPTIONS',
  SetEditOptions = 'SET_EDIT_OPTIONS',
  RemoveEditOptions = 'REMOVE_EDIT_OPTIONS',
  ClearEditOptions = 'CLEAR_EDIT_OPTIONS',
  RemoveRosterTransferEdits = 'REMOVE_ROSTER_TRANSFER_EDITS',
  ClearRosterTransferEdits = 'CLEAR_ROSTER_TRANSFER_EDITS',
  RemoveIdentificationDecisionStudent = 'REMOVE_IDENTIFICATION_DECISION_STUDENT',
  SetSaving = 'SET_SAVING',
  SetLoading = 'SET_LOADING',
  SetDnq = 'SET_DNQ',

  // Decisions
  SetAssessments = 'SET_ASSESSMENTS',
  ConfirmSubjectMove = 'CONFIRM_SUBJECT_MOVE',
  SelectAllSubjects = 'SELECT_ALL_SUBJECTS',
  SelectSubjectOrConfirm = 'SELECT_SUBJECT_OR_CONFIRM',
  CloseConfirmSubjectMove = 'CLOSE_CONFIRM_SUBJECT_MOVE',
  SelectJustification = 'SELECT_JUSTIFICATION',
  SetTeachersDatum = 'SET_TEACHERS_DATUM',
  SelectTeacher = 'SELECT_TEACHER',
  SetStaarSelection = 'SET_STAAR_SELECTION',
  RemoveStaarJustificationStudent = 'REMOVE_STAAR_JUSTIFICATION_STUDENT',
  SetStaarJustificationUnsavedStatus = 'SET_STAAR_JUSTIFICATION_UNSAVED_STATUS',
  RemoveFromUnsavedJustificationsSet = 'REMOVE_FROM_UNSAVED_JUSITIFCATIONS_SET',
  SetSelectedCategories = 'SET_SELECTED_CATEGORIES',
  SetStudentNotes = 'SET_STUDENT_NOTES',
  UpdateStudentNote = 'UPDATE_STUDENT_STAAR_NOTE',
  UpdateSummaryNotes = 'UPDATE_SUMMARY_NOTES',
  UpdateStudentSummaryNotes = 'UPDATE_STUDENT_SUMMARY_NOTES',
  SetTransferEnrollmentDecisionStudent = 'SET_TRANSFER_ENROLLMENT_DECISION_STUDENT',
  UpdateDnqStatus = 'UPDATE_DNQ_STATUS',

  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<ReviewMeetingState> = {

  [ReviewMeetingMutations.Clear](state) {
    Object.assign(state, cloneDeep(clearState))
  },

  [ReviewMeetingMutations.SetLoading](state, loading: boolean) {
    state.loading = loading
  },

  [ReviewMeetingMutations.SetTypes](state, types: MeetingType[]) {
    state.types = types
  },

  [ReviewMeetingMutations.SetDnq](state, dnq: boolean) {
    state.isDnq = dnq
  },

  [ReviewMeetingMutations.SetStudentNotes](state, studentNoteLst: StudentNote[]) {
    const studentNoteMap: Map<number, StudentNote> = new Map(studentNoteLst.map(studentNote => [studentNote.studentSchoolGrdId, studentNote]))
    state.studentNotesMap = studentNoteMap
  },

  [ReviewMeetingMutations.UpdateStudentNote](state, studentNote: StudentNote) {
    state.studentNotesMap.set(studentNote.studentSchoolGrdId, studentNote)
  },

  [ReviewMeetingMutations.UpdateSummaryNotes](state, summaryNotes: string) {
    state.summaryNotes = summaryNotes
  },

  [ReviewMeetingMutations.SetTelpasAssessmentDecisions](state, telpasAssessmentDecisionStudents: TelpasAssessmentDecisionResponse) {
    const setStudents = new Set<number>()
    const students = telpasAssessmentDecisionStudents.stuSchGrdIds
    students.forEach(element => {
      setStudents.add(element)
    })
    state.decisionStudents = setStudents
  },

  [ReviewMeetingMutations.RemoveTelpasAssessmentDecisionStudent](state, studentSchoolGradeId: number) {
    const filteredSet = new Set<number>()
    state.decisionStudents.forEach((value) => {
      if (value !== studentSchoolGradeId) {
        filteredSet.add(value)
      }
    })
    state.decisionStudents = filteredSet
  },

  [ReviewMeetingMutations.RemoveIdentificationDecisionStudent](state, studentSchoolGradeId: number) {
    const filteredSet = new Set<number>()
    state.decisionStudents.forEach((value) => {
      if (value !== studentSchoolGradeId) {
        filteredSet.add(value)
      }
    })
    state.decisionStudents = filteredSet
  },

  [ReviewMeetingMutations.ClearMeetingType](state, meetingType: MeetingTypeCode) {
    const clearMeetingType = cloneDeep(clearState.meetingStudents[meetingType])

    Vue.set(state.meetingStudents, meetingType, clearMeetingType)
  },

  [ReviewMeetingMutations.SetMeetingTypeStudents](state, { meetingType, students, pagination }) {
    Vue.set(state.meetingStudents[meetingType], 'students', students)
    Vue.set(state.meetingStudents[meetingType], 'pagination', pagination)

    if (meetingType === MeetingTypeCode.Identification) {
      state.decisionStudents = modifyStudentDecisionSet(state, students)
    }
  },

  [ReviewMeetingMutations.SetHideDecisions](state) {
    const hideDecisions = state.hideDecisions
    state.hideDecisions = !hideDecisions
  },

  [ReviewMeetingMutations.SetTransferEnrollmentDecisionStudent](state, studentSchoolGradeId: number) {
    if (!state.decisionStudents.has(studentSchoolGradeId)) {
      state.decisionStudents.add(studentSchoolGradeId)
    }
  },

  [ReviewMeetingMutations.SetIdentificationAssessmentDecisions](state, { proficiencyTestResults, grade }) {
    state.decisionStudents = modifyStudentDecisionSet(state, proficiencyTestResults, grade)
  },

  [ReviewMeetingMutations.SetMeetingStudents](state, { meetingType, students }) {
    Vue.set(state.meetingStudents[meetingType], 'students', students)
  },

  [ReviewMeetingMutations.SetMeetingTypePaging](state, { meetingType, paging }) {
    Vue.set(state.meetingStudents[meetingType], 'paging', paging)
  },

  [ReviewMeetingMutations.SetMeetingTypeSelected](state, { meetingType, selected }) {
    Vue.set(state.meetingStudents[meetingType], 'selected', selected)
  },

  [ReviewMeetingMutations.ClearMeetingTypeSelected](state, meetingType: MeetingTypeCode) {
    const clearSelected = cloneDeep(clearState.meetingStudents[meetingType].selected)

    Vue.set(state.meetingStudents[meetingType], 'selected', clearSelected)
  },

  [ReviewMeetingMutations.SetRoles](state, roles: MeetingRole[]) {
    state.roles = roles
  },

  [ReviewMeetingMutations.SetAllMembers](state, members: MeetingMember[]) {
    state.allMembers = members
  },

  [ReviewMeetingMutations.SetTestEditsForStudent](state, { id, olptSaveEdits }) {
    state.saveEditsMap.set(id, olptSaveEdits)
  },

  [ReviewMeetingMutations.SetProficiencyEditsForStudent](state, { id, proficiencyTestResults }) {
    if (state.saveProficiencyEditsMap.has(id)) {
      const currentEntryIndex = state.saveProficiencyEditsMap.get(id).findIndex(currentProfEntry => currentProfEntry.languageId === proficiencyTestResults.languageId)
      if (currentEntryIndex > -1) {
        const updatedProfArr = state.saveProficiencyEditsMap.get(id)
        updatedProfArr[currentEntryIndex] = proficiencyTestResults
        state.saveProficiencyEditsMap.set(id, updatedProfArr)
      } else {
        state.saveProficiencyEditsMap.get(id).push(proficiencyTestResults)
      }
    } else {
      const newProfArr: ProficiencyTestResults[] = [proficiencyTestResults]
      state.saveProficiencyEditsMap.set(id, newProfArr)
    }
  },
  [ReviewMeetingMutations.ClearEditsMap](state) {
    state.saveEditsMap = new Map<number, OlptSaveEdits>()
    state.saveTransferEditsMap = new Map<number, Set<number>>()
    state.saveProficiencyEditsMap = new Map<number, ProficiencyTestResults[]>()
  },
  [ReviewMeetingMutations.RemoveEditEntryFromMap](state, id: number) {
    state.saveEditsMap.delete(id)
  },
  [ReviewMeetingMutations.RemoveTestResultsEntryFromMap](state, id: number) {
    state.saveProficiencyEditsMap.delete(id)
  },
  [ReviewMeetingMutations.UpdateStudentSummaryNotes](state, roster: MeetingRoster) {
    const meetingType = MeetingTypeCode.Identification
    const currentMeetingStudents: MeetingTypeStudents = state.meetingStudents[meetingType]
    const rstIndex = currentMeetingStudents.students.findIndex(rst => rst.id === roster.id)
    if (!isNil(rstIndex)) {
      state.meetingStudents[meetingType].students[rstIndex].summary = roster.summary
    }
  },
  [ReviewMeetingMutations.UpdateDnqStatus](state, roster: MeetingRoster) {
    const meetingType = MeetingTypeCode.Identification
    const currentMeetingStudents: MeetingTypeStudents = state.meetingStudents[meetingType]
    const rstIndex = currentMeetingStudents.students.findIndex(rst => rst.id === roster.id)
    if (!isNil(rstIndex)) {
      state.meetingStudents[meetingType].students[rstIndex].isDnq = roster.isDnq
    }
  },
  [ReviewMeetingMutations.ResetMeetingStudentInRoster](state, { roster, meetingType }) {
    const currentMeetingStudents: MeetingTypeStudents = state.meetingStudents[meetingType]
    const rstIndex = currentMeetingStudents.students.findIndex(rst => rst.id === roster.id)
    if (!isNil(rstIndex)) {
      currentMeetingStudents.students[rstIndex] = roster
      const updatedStudents = cloneDeep(currentMeetingStudents.students)
      Vue.set(state.meetingStudents[meetingType], 'students', updatedStudents)
    }
  },

  [ReviewMeetingMutations.ResetRosterEnrollmentType](state, { meetingRosterId, enrollmentType, meetingType }) {
    const currentMeetingStudents: MeetingTypeStudents = state.meetingStudents[meetingType]
    const rstIndex = currentMeetingStudents.students.findIndex(rst => rst.id === meetingRosterId)
    if (!isNil(rstIndex)) {
      const roster = currentMeetingStudents.students[rstIndex]
      roster.enrollmentType = enrollmentType
      if (roster?.enrollmentType?.enrollmentTypeCode === EnrollmentTypeCode.Review && (!roster?.meetingPlacement?.programType?.lepStatusId || !roster?.meetingPlacement?.programType?.programTypeCD)) {
        roster.meetingPlacement = roster.carryOverMeetingPlacement
      }
      currentMeetingStudents.students[rstIndex] = roster
      if (roster?.enrollmentType?.enrollmentTypeCode === EnrollmentTypeCode.InitialEnrollment) {
        roster.transferOptionIds = []
      }
      const updatedStudents = cloneDeep(currentMeetingStudents.students)
      Vue.set(state.meetingStudents[meetingType], 'students', updatedStudents)
    }
  },

  [ReviewMeetingMutations.SetRemoveStudentIdAndMeetingType](state, studentIdAndMeetingType: StudentIdAndMeetingType) {
    state.studentIdAndMeetingType = studentIdAndMeetingType
  },

  [ReviewMeetingMutations.SetSupportOptions](state, options: ContentLanguageControlOptions[]) {
    state.supportOptions = options
  },

  [ReviewMeetingMutations.SetInitialOptions](state, options: InitialCLSOptions) {
    state.carryOverOptions = state.carryOverOptions.filter(roster => roster.rosterId !== options.rosterId)
    state.carryOverOptions.push(options)
  },

  [ReviewMeetingMutations.SetEditOptions](state, newEdits: RosterOptionEdit) {
    state.supportOptionEdits = state.supportOptionEdits.filter(edit => edit.meetingRosterId !== newEdits.meetingRosterId)
    state.supportOptionEdits.push(newEdits)
  },

  [ReviewMeetingMutations.SetSelectedCategories](state, { ids, category }) {
    const categories = state.selectedCategories.filter(id => id !== category)
    if (!isEmpty(ids)) {
      categories.push(category)
    }
    state.selectedCategories = categories
  },

  [ReviewMeetingMutations.RemoveEditOptions](state, meetingRosterId: number) {
    state.supportOptionEdits = state.supportOptionEdits.filter(edits => edits.meetingRosterId !== meetingRosterId)
  },

  [ReviewMeetingMutations.RemoveRosterTransferEdits](state, meetingRosterId: number) {
    state.saveTransferEditsMap.delete(meetingRosterId)
  },

  [ReviewMeetingMutations.ClearRosterTransferEdits](state) {
    state.saveTransferEditsMap.clear()
  },

  [ReviewMeetingMutations.SetSaving] (state, { saving, saveAll }) {
    state.saving = saving
    state.saveAll = saveAll
  },

  [ReviewMeetingMutations.ClearEditOptions](state) {
    state.supportOptionEdits = []
  },
  [ReviewMeetingMutations.AddOrRemoveTransferOpt](state, { meetingRosterId, optionId }) {
    if (state.saveTransferEditsMap.has(meetingRosterId)) {
      const currentOptIds = state.saveTransferEditsMap.get(meetingRosterId)
      if (currentOptIds.has(optionId)) {
        currentOptIds.delete(optionId)
        state.saveTransferEditsMap.set(meetingRosterId, currentOptIds)
      } else {
        currentOptIds.add(optionId)
        state.saveTransferEditsMap.set(meetingRosterId, currentOptIds)
      }
    } else {
      const optionIds = new Set<number>()
      optionIds.add(optionId)
      state.saveTransferEditsMap.set(meetingRosterId, optionIds)
    }
  },
  [ReviewMeetingMutations.SetTransferOptEdits](state, { meetingRosterId }) {
    if (!state.saveTransferEditsMap.has(meetingRosterId)) {
      state.saveTransferEditsMap.set(meetingRosterId, new Set<number>())
    }
  },

  // Decisions
  [ReviewMeetingMutations.SetAssessments](state, { studentId, asmts }) {
    const currStudentAsmts = state.staarJustifications.studentAssessments.get(studentId) || []
    const newAsmts = uniqBy(
      [...asmts, ...currStudentAsmts], 'id'
    ).filter(a => a !== undefined)

    const {
      studentAssessments,
      selectedSubjects,
      selectedStaarJustifications,
      teachersDatum,
      selectedTeachers
    } = state.staarJustifications

    Vue.set(state, 'staarJustifications', {
      studentAssessments: studentAssessments.set(studentId, newAsmts),
      selectedSubjects: selectedSubjects || [],
      selectedStaarJustifications: selectedStaarJustifications || new Map<StudentId, SelectedStaarJustification[]>(),
      confirmSubjectMoveNeeded: false,
      confirmSubjectMoveText: '',
      teachersDatum: teachersDatum || [],
      selectedTeachers: selectedTeachers
    })
  },

  [ReviewMeetingMutations.SetStaarSelection](
    state,
    params: SelectStaarSelection
  ) {
    // set selected justifications
    const {
      studentId,
      selectedStaarJustifications,
      selectedStaarTeachers
    } = params
    const currentStudentJustificationSelection = (state.staarJustifications.selectedStaarJustifications || []).filter(
      (sj: SelectedStaarJustification): sj is SelectedStaarJustification => {
        return sj.studentId === studentId
      }
    )
    const otherStudentsSelection = (state.staarJustifications.selectedStaarJustifications || []).filter(
      (sj: SelectedStaarJustification): sj is SelectedStaarJustification => {
        return sj.studentId !== studentId
      }
    )
    const filteredJustificationSelection = uniqBy(
      [...currentStudentJustificationSelection, ...selectedStaarJustifications],
      'assessmentJustificationId'
    )
    const newJustificationSelection = Object.assign(
      [],
      [...filteredJustificationSelection, ...otherStudentsSelection]
    )

    // set selected subjects
    const currentStudentSubjectSelection = state.staarJustifications.selectedSubjects.filter(
      (ss: SelectedSubject): ss is SelectedSubject => {
        return ss.studentId === studentId
      }
    )
    const otherStudentsCurrentSubjectSelection = state.staarJustifications.selectedSubjects.filter(
      (ss: SelectedSubject): ss is SelectedSubject => {
        return ss.studentId !== studentId
      }
    )
    const selectedSubjects = selectedStaarTeachers.map(st => {
      return {
        studentId: studentId,
        assessmentId: st.assessmentId,
        assessmentSubjectId: st.assessmentSubjectId,
        subjectId: st.subjectId
      } as SelectedSubject
    })
    const filteredSubjectSelection = uniqBy(
      [...currentStudentSubjectSelection, ...selectedSubjects],
      'assessmentSubjectId'
    )
    const newSubjectSelection = Object.assign(
      [], [...otherStudentsCurrentSubjectSelection, ...filteredSubjectSelection]
    )

    // set selected teachers
    const currentTeacherSelection = state.staarJustifications.selectedTeachers.get(studentId) || []
    const fetchedTeacherSelection = selectedStaarTeachers.map(st => {
      return {
        studentId: studentId,
        assessmentId: st.assessmentId,
        subjectId: st.subjectId,
        teacherId: st.teacherId
      } as SelectedTeacher
    })
    const filteredTeacherSelection = uniqBy(
      [...currentTeacherSelection, ...fetchedTeacherSelection],
      v => v.subjectId + ',' + v.assessmentId
    )
    const newTeacherSelection = state.staarJustifications.selectedTeachers.set(
      studentId,
      filteredTeacherSelection
    )

    Vue.set(state.staarJustifications, 'selectedStaarJustifications', newJustificationSelection)
    Vue.set(state.staarJustifications, 'selectedSubjects', newSubjectSelection)
    Vue.set(state.staarJustifications, 'selectedTeachers', newTeacherSelection)
  },

  [ReviewMeetingMutations.ConfirmSubjectMove](state) {
    selectSubject(
      state,
      state.staarJustifications.confirmSubjectMove.params,
      state.staarJustifications.behavior || SelectSubjectBehavior.TOGGLE
    )
  },

  [ReviewMeetingMutations.SelectSubjectOrConfirm](state, params) {
    const { selectedSubjects } = state.staarJustifications
    const { subjectId, studentId, assessmentId } = params
    const currentSelectedIdx = selectedSubjects.findIndex(
      (sa: SelectedSubject): sa is SelectedSubject => {
        return sa.subjectId === subjectId &&
          sa.assessmentId !== assessmentId &&
          sa.studentId === studentId
      }
    )

    if (currentSelectedIdx >= 0) {
      const saId = selectedSubjects[currentSelectedIdx].assessmentId
      const sa = state.staarJustifications.studentAssessments
        .get(studentId)
        .find<Assessment>((a: Assessment): a is Assessment => a.id === saId)
      const suffix = (sa !== undefined && `from ${sa.name}`) || ''
      Vue.set(state, 'staarJustifications', Object.assign(
        state.staarJustifications,
        {
          confirmSubjectMoveNeeded: true,
          confirmSubjectMoveText: `Are you sure you want to move this subject ${suffix}?`,
          confirmSubjectMove: { params: [params] }
        }
      ))
    } else {
      selectSubject(state, [params], SelectSubjectBehavior.TOGGLE)
    }
  },

  [ReviewMeetingMutations.SelectAllSubjects](state, params) {
    const { studentId, assessmentId, checked } = params
    const behavior = checked ? SelectSubjectBehavior.TOGGLE : SelectSubjectBehavior.CHECK_ONLY

    const studentAssements =
      state.staarJustifications.studentAssessments.get(studentId)

    if (studentAssements !== undefined) {
      const asmt = studentAssements.find<Assessment>((a: Assessment): a is Assessment => {
        return a.id === assessmentId
      })

      if (asmt !== undefined) {
        const { selectedSubjects } = state.staarJustifications
        const selectedForAnotherAssessment = selectedSubjects.findIndex(
          (sa: SelectedSubject): sa is SelectedSubject => {
            return sa.assessmentId !== assessmentId &&
              sa.studentId === studentId
          }
        ) >= 0

        const confirmSubjectMoveParams = asmt.subjects.map<SelectSubjectParams>(s => {
          return {
            studentId: studentId,
            assessmentId: assessmentId,
            subjectId: s.id,
            assessmentSubjectId: s.assessmentSubjectId
          }
        })

        if (selectedForAnotherAssessment) {
          Vue.set(state, 'staarJustifications', Object.assign(
            state.staarJustifications,
            {
              confirmSubjectMoveNeeded: true,
              confirmSubjectMoveText: 'Subjects are checked for another assessment. Are you sure?',
              confirmSubjectMove: { params: confirmSubjectMoveParams },
              behavior
            }
          ))
        } else {
          selectSubject(state, confirmSubjectMoveParams, behavior)
        }
      }
    }
  },

  [ReviewMeetingMutations.CloseConfirmSubjectMove](state) {
    Vue.set(state, 'staarJustifications', Object.assign(
      state.staarJustifications,
      {
        confirmSubjectMoveNeeded: false,
        confirmSubjectMoveText: '',
        confirmSubjectMove: undefined
      }
    ))
  },

  [ReviewMeetingMutations.SelectJustification](state, params: SelectedStaarJustification) {
    const { selectedStaarJustifications } = state.staarJustifications
    const { assessmentJustificationId, studentId, assessmentId } = params
    const newSelection = Object.assign([], selectedStaarJustifications)

    const currentSelectedIdx = selectedStaarJustifications.findIndex(
      (sj: SelectedStaarJustification): sj is SelectedStaarJustification =>
        sj.assessmentJustificationId === assessmentJustificationId &&
        sj.assessmentId === assessmentId &&
        sj.studentId === studentId
    )

    // justification not selected
    if (currentSelectedIdx === -1) {
      newSelection.push(params)
    } else {
      newSelection.splice(currentSelectedIdx, 1)
    }

    Vue.set(state.staarJustifications, 'selectedStaarJustifications', newSelection)
    Vue.set(state.staarJustifications, 'unsavedData', true)
    if (state.unsavedJustificationsIds) {
      state.unsavedJustificationsIds.add(params.studentId)
    }
  },

  [ReviewMeetingMutations.SetTeachersDatum](state, { studentId, staff }) {
    const { teachersDatum } = state.staarJustifications
    const newDatums = staff.map(s => {
      return {
        teacherId: s.id,
        name: s.name,
        studentId: studentId
      }
    })
    const uniqueDatums = uniqBy(teachersDatum.concat(newDatums), v => [v.teacherId, v.studentId].join())

    Vue.set(state.staarJustifications, 'teachersDatum', uniqueDatums)
  },

  [ReviewMeetingMutations.SelectTeacher](state, selectedTeacher: SelectedTeacher) {
    const { selectedTeachers } = state.staarJustifications

    const filteredSelection = (
      selectedTeachers.get(selectedTeacher.studentId) || []
    ).filter((st: SelectedTeacher): st is SelectedTeacher => {
      return !(st.assessmentId === selectedTeacher.assessmentId &&
        st.subjectId === selectedTeacher.subjectId &&
        st.studentId === selectedTeacher.studentId)
    })

    filteredSelection.push(selectedTeacher)

    const newValue = selectedTeachers.set(
      selectedTeacher.studentId,
      filteredSelection
    )

    Vue.set(
      state.staarJustifications,
      'selectedTeachers',
      newValue
    )
    Vue.set(state.staarJustifications, 'unsavedData', true)
    if (state.unsavedJustificationsIds) {
      state.unsavedJustificationsIds.add(selectedTeacher.studentId)
    }
  },

  [ReviewMeetingMutations.RemoveStaarJustificationStudent](state, studentId) {
    const {
      selectedSubjects,
      selectedStaarJustifications,
      selectedTeachers
    } = state.staarJustifications
    const filteredStudents = state.meetingStudents.STAAR_DECISIONS.students.filter((s: Student): s is Student => {
      return s.id !== studentId
    })
    const filteredSelectedSubjects = selectedSubjects.filter((ss: SelectedSubject): ss is SelectedSubject => {
      return ss.studentId !== studentId
    })

    const filteredSelectedJustifications = selectedStaarJustifications.filter((sj: SelectedStaarJustification): sj is SelectedStaarJustification => {
      return sj.studentId !== studentId
    })

    selectedTeachers.delete(studentId)

    Vue.set(state.staarJustifications, 'selectedSubjects', filteredSelectedSubjects)
    Vue.set(state.staarJustifications, 'selectedStaarJustifications', filteredSelectedJustifications)
    Vue.set(state.staarJustifications, 'selectedTeachers', selectedTeachers)
    state.unsavedJustificationsIds.delete(studentId)
    Vue.set(state.meetingStudents.STAAR_DECISIONS, 'students', filteredStudents)
  },

  [ReviewMeetingMutations.SetStaarJustificationUnsavedStatus](state) {
    state.unsavedJustificationsIds.clear()
  },

  [ReviewMeetingMutations.RemoveFromUnsavedJustificationsSet](state, studentId: number) {
    state.unsavedJustificationsIds.delete(studentId)
  },

  // Only use this mutation for setting the state for test cases
  [ReviewMeetingMutations.SetTestState] (state, testData: ReviewMeetingState) {
    Object.assign(state, cloneDeep(testData))
  }
}

const requiredFieldCount = (grade): number => {
  if (grade > 1 && grade < 14) {
    return 4
  } else if (grade === 1) {
    return 2
  } else {
    return 1
  }
}

const modifyStudentDecisionSet = (state, students, gradeLevel = null): Set<number> => {
  const setStudents = state.decisionStudents
  if (students) {
    students.forEach(element => {
      const results = element.studentSchoolGrade ? element.studentSchoolGrade.proficiencyTestResults : element
      const studentSchoolGradeId = element.studentSchoolGrade ? element.studentSchoolGrade.id : element.studentSchoolGradeId
      const grade = element.studentSchoolGrade ? element.studentSchoolGrade.grade.id : gradeLevel
      let fieldsCompleted = 0

      setStudents.delete(studentSchoolGradeId)
      if (!setStudents.has(studentSchoolGradeId) && !isEmpty(element.transferOptionIds)) {
        setStudents.add(studentSchoolGradeId)
        return
      }

      if (!isEmpty(results) && isArray(results)) {
        results.forEach(element => {
          /* eslint-disable @typescript-eslint/no-explicit-any */
          if (!isEmpty((element as any).proficiencyDate) && (element as any).proficiencyCategoryLevelResults.length === requiredFieldCount(grade)) {
            fieldsCompleted += 1
          }
        })
        if (fieldsCompleted === results.length) {
          setStudents.add(studentSchoolGradeId)
        }
      }

      if (!isEmpty(results.proficiencyDate) && results.proficiencyCategoryLevelResults.length === requiredFieldCount(grade)) {
        setStudents.add(studentSchoolGradeId)
      }
    })
  }

  return setStudents
}

const optionalyRemoveStaff = (state: ReviewMeetingState): void => {
  const { selectedSubjects, selectedTeachers } = state.staarJustifications
  const updatedSelectedTeachers: Map<number, SelectedTeacher[]> = new Map()
  selectedSubjects.forEach(selSub => {
    const selTeacher = selectedTeachers.get(selSub.studentId)
    if (selTeacher) {
      const currentSelTeacherEntry: SelectedTeacher = selTeacher.find(entry => entry.studentId === selSub.studentId && entry.assessmentId === selSub.assessmentId && entry.subjectId === selSub.subjectId)
      if (!currentSelTeacherEntry) {
        const filteredSelTeachers: SelectedTeacher[] = selTeacher.filter(entry => entry.subjectId !== selSub.subjectId)
        updatedSelectedTeachers.set(selSub.studentId, filteredSelTeachers)
      } else {
        updatedSelectedTeachers.set(selSub.studentId, selTeacher)
      }
    }
  })
  state.staarJustifications.selectedTeachers = updatedSelectedTeachers

}

const optionalyUncheckJustifications = (state: ReviewMeetingState): void => {
  const { selectedSubjects, selectedStaarJustifications } = state.staarJustifications
  const isSelectedSubject =
    (studentId: StudentId, assessmentId: AssessmentId) =>
    (sa: SelectedSubject): sa is SelectedSubject => {
      return sa.assessmentId === assessmentId &&
        sa.studentId === studentId
    }

  const isSelectedJustification =
    (sj: SelectedStaarJustification): sj is SelectedStaarJustification => {
      return selectedSubjects
      .find(isSelectedSubject(sj.studentId, sj.assessmentId)) !== undefined
    }

  Vue.set(
    state.staarJustifications,
    'selectedStaarJustifications',
    selectedStaarJustifications.filter(isSelectedJustification)
  )
}

const selectSubject = (
  state: ReviewMeetingState,
  params: SelectSubjectParams[],
  behavior: SelectSubjectBehavior
): ReviewMeetingState => {
  const newSelection = params.reduce<SelectedSubject[]>(
    (acc: SelectedSubject[], selection: SelectedSubject) => {
      const newAcc = Object.assign([], acc)
      const currentSelectedIdx = newAcc.findIndex(
        sameSubjectIdAndStudentId(selection.subjectId, selection.studentId)
      )
      // subject is not select for any assessment
      if (currentSelectedIdx === -1) {
        newAcc.push(selection)
        return newAcc
      } else if (currentSelectedIdx >= 0 &&
        newAcc[currentSelectedIdx].assessmentId !== selection.assessmentId) {
        // selecting the subject for a different assessment
        newAcc[currentSelectedIdx] = selection
        return newAcc
      } else {
        if (behavior === SelectSubjectBehavior.TOGGLE) {
          // removing a selected subject
          newAcc.splice(currentSelectedIdx, 1)
        }
        return newAcc
      }
    }, state.staarJustifications.selectedSubjects)
  // if (isEmpty(state.staarJustifications.unsavedDataSet)) {
  //   state.staarJustifications.unsavedDataSet = new Set()
  //   params.forEach(param => state.staarJustifications.unsavedDataSet.add(param.studentId))
  // } else {
  //   params.forEach(param => state.staarJustifications.unsavedDataSet.add(param.studentId))
  // }
  params.forEach(param => state.unsavedJustificationsIds.add(param.studentId))
  Vue.set(state, 'staarJustifications', Object.assign(
    state.staarJustifications,
    {
      unsavedData: true,
      selectedSubjects: newSelection,
      confirmSubjectMoveNeeded: false,
      confirmSubjectMoveText: '',
      confirmSubjectMove: undefined
    }
  ))

  optionalyUncheckJustifications(state)
  optionalyRemoveStaff(state)

  return state
}

// Auxiliary functions and types for the selectSubject function
const sameSubjectIdAndStudentId =
  (subjectId: SubjectId, studentId: StudentId) =>
    (sa: SelectedSubject): sa is SelectedSubject =>
      sa.subjectId === subjectId &&
      sa.studentId === studentId
