
export enum Pages {
  Login = 'login',
  Dashboard = 'dashboard',
  CreateMeeting = 'createMeeting',
  ReviewMeeting = 'reviewMeeting',
  MeetingSummary = 'meetingSummary',
  OverviewMeeting = 'overviewMeeting',
  ReviewStudents = 'reviewStudents',
  ReviewStudent = 'reviewStudent',
  CompletedMeetings = 'completedMeetings',
  CompletedMeeting = 'completedMeeting',
  ParentDecisions = 'parentDecisions',
  ParentDecision = 'parentDecision',
  Students = 'students',
  SearchStudents = 'searchStudents',
  ViewStudent = 'viewStudent',
  Reports = 'reports',
  HistoryLog = 'history',
  Committee = 'committee',
  PasswordReset = 'passwordReset',
  StaarAccommodations = 'staarAccommodations',
  UserSetup = 'userSetup',
  EOYLasLinks = 'eoyLasLinks',
  NewTeacher = 'newTeacher',
  Accommodations = 'accommodations',
  ContactUs = 'contactUs'
}

export type PagePaths = {
  [page in Pages]: string
}

export const paths: PagePaths = {
  [Pages.Login]: '/login',
  [Pages.Dashboard]: '/',
  [Pages.CreateMeeting]: '/create',
  [Pages.MeetingSummary]: '/summary',
  [Pages.ReviewMeeting]: '/review/:meetingTypeCode',
  [Pages.OverviewMeeting]: '/overview/:meetingTypeCode',
  [Pages.StaarAccommodations]: '/review/STAAR_DECISIONS/accommodations',
  [Pages.ReviewStudents]: '/reviewStudents',
  [Pages.ReviewStudent]: ':rosterId',
  [Pages.CompletedMeetings]: '/completed',
  [Pages.CompletedMeeting]: '/completed/:meetingId',
  [Pages.ParentDecisions]: '/completed/:meetingId/decisions',
  [Pages.ParentDecision]: ':rosterId/:meetingTypeCode',
  [Pages.Students]: '/students',
  [Pages.SearchStudents]: '/searchStudents',
  [Pages.ViewStudent]: '/students/:studentId',
  [Pages.Reports]: '/reports',
  [Pages.HistoryLog]: '/history',
  [Pages.Committee]: '/committee',
  [Pages.PasswordReset]: '/passwordReset',
  [Pages.UserSetup]: '/userSetup',
  [Pages.EOYLasLinks]: '/eoyLasLinks/:meetingId',
  [Pages.NewTeacher]: '/newTeacher',
  [Pages.Accommodations]: '/accommodations',
  [Pages.ContactUs]: 'contactUs',
}
