import cloneDeep from 'lodash/cloneDeep'
import {
  MeetingType,
  MeetingRole,
  MeetingMember,
  Assessment,
  Subject,
  SelectedStaarJustification,
  ProficiencyTestResults, ContentLanguageControlOptions, RosterOptionEdit, StudentNote
} from 'models'
import {
  StudentIdAndMeetingType,
  MeetingStudents,
  OlptSaveEdits,
  MeetingTypeStudents,
  StaarJustificationsState,
  StudentId,
  AssessmentId,
  SelectedSubject,
  TeacherDatum,
  SelectedTeacher,
  SelectSubjectBehavior,
  InitialCLSOptions
} from './types'
import { MeetingTypeCode } from '@/enums'
import { PagingUtil, PerPageSize } from '@806/utils'

export interface ReviewMeetingState {
  types: MeetingType[],
  staarJustifications: StaarJustificationsState
  meetingStudents: MeetingStudents,
  roles: MeetingRole[],
  allMembers: MeetingMember[]
  saveEditsMap: Map<number, OlptSaveEdits>,
  saveProficiencyEditsMap: Map<number, ProficiencyTestResults[]>,
  saveTransferEditsMap: Map<number, Set<number>>,
  studentIdAndMeetingType: StudentIdAndMeetingType,
  currentSigningUrl: string,
  unsavedJustificationsIds: Set<number>,
  hideDecisions: boolean,
  decisionStudents: Set<number>,
  supportOptions: ContentLanguageControlOptions[],
  supportOptionEdits: RosterOptionEdit[],
  carryOverOptions: InitialCLSOptions[],
  saving: boolean,
  saveAll: boolean,
  selectedCategories: number[],
  studentNotesMap: Map<number, StudentNote>,
  loading: boolean,
  summaryNotes: string,
  isDnq: boolean
}

// initalize meeting students for every meeting type
const meetingStudents = Object.assign({}, Object.values(MeetingTypeCode)
  .reduce<MeetingStudents>((meetingStudents: MeetingStudents, code: MeetingTypeCode) => {
    meetingStudents[code] = {
      students: [],
      assessments: [],
      pagination: {},
      paging: PagingUtil.initialPaging(PerPageSize.Medium),
      selected: PagingUtil.initialSelectedItems()
    } as MeetingTypeStudents
    return meetingStudents
  }, {} as MeetingStudents)
)

export const state: ReviewMeetingState = {
  types: [],
  staarJustifications: {
    behavior: SelectSubjectBehavior.TOGGLE,
    confirmSubjectMoveNeeded: false,
    confirmSubjectMoveText: '',
    studentAssessments: new Map<StudentId, Assessment[]>(),
    selectedSubjects: [] as SelectedSubject[],
    selectedStaarJustifications: [] as SelectedStaarJustification[],
    availableSubjects:  new Map<AssessmentId, Subject[]>(),
    teachersDatum: [] as TeacherDatum[],
    selectedTeachers: new Map<StudentId, SelectedTeacher[]>()
  } as StaarJustificationsState,
  meetingStudents,
  roles: [],
  allMembers: [],
  saveEditsMap: new Map<number, OlptSaveEdits>(),
  saveProficiencyEditsMap: new Map<number, ProficiencyTestResults[]>(),
  saveTransferEditsMap: new Map<number, Set<number>>(),
  studentIdAndMeetingType: null,
  currentSigningUrl: null,
  unsavedJustificationsIds: new Set(),
  hideDecisions: false,
  decisionStudents: new Set(),
  supportOptions: [],
  supportOptionEdits: [],
  carryOverOptions: [],
  saving: false,
  saveAll: false,
  selectedCategories: [],
  studentNotesMap: new Map(),
  loading: false,
  summaryNotes: null,
  isDnq: false
}

export const clearState: ReviewMeetingState = cloneDeep(state)
