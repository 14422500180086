import { ActionTree } from 'vuex'
import { RootState } from '@/store/root'
import { meetingService, signService } from '@/services'
import { MeetingSummaryState } from './state'
import { MeetingSummaryMutations } from './mutations'
import { GetMemeberSigningUrlRequest, Meeting, SignNowRequest } from 'models'

export enum MeetingSummaryActions {
  GetTypes = 'GET_TYPES',
  UpdateMeetingStatuses = 'UPDATE_MEETING_STATUSES',
  UpdateMeetingTypeStatuses = 'UPDATE_MEETING_TYPE_STATUSES',
  GetMemberSigningUrl = 'GET_MEMBER_SIGNING_URL',
  SignNow = 'SIGN_NOW'
}

export const actions: ActionTree<MeetingSummaryState, RootState> = {
  async [MeetingSummaryActions.GetTypes]({ commit, rootState }) {
    const types = await meetingService.getTypes(rootState)

    commit(MeetingSummaryMutations.SetTypes, types)
  },

  async [MeetingSummaryActions.UpdateMeetingStatuses]({ commit }, currentMeeting: Meeting) {
    commit(MeetingSummaryMutations.UpdateMeetingStatuses, currentMeeting)
  },
  async [MeetingSummaryActions.UpdateMeetingTypeStatuses]({ commit }, currentMeeting: Meeting) {
    commit(MeetingSummaryMutations.UpdateMeetingTypeStatuses, currentMeeting)
  },

  async [MeetingSummaryActions.GetMemberSigningUrl]({ commit, rootState }, request: GetMemeberSigningUrlRequest) {
    const resp = await signService.getMemberSigningUrl(rootState, request)
    commit(MeetingSummaryMutations.SetCurrentSigningUrl, resp.signUrl)
  },

  async [MeetingSummaryActions.SignNow]({ commit, rootState }, request: SignNowRequest) {
    const signUrl: string = await signService.signNow(rootState, request)
    commit(MeetingSummaryMutations.SetCurrentSigningUrl, signUrl)
  }
}
