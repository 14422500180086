import Vue from 'vue'
import { MutationTree } from 'vuex'
import { StaarAccommodationsState, clearState } from './state'
import cloneDeep from 'lodash/cloneDeep'
import { SelectedAssessmentFormatOption, SelectedAccommodationCommittee, SigningStatusResponse, MeetingStaarTeacher, StudentNote, StudentPaging, Pagination, StudentAccommodationSubject } from 'models'
import { AccommodationsSigningStatus } from '@/enums'

export enum StaarAccommodationMutations {
  Clear = 'CLEAR',
  SelectCommittee = 'SELECT_COMMITTEE',
  SetShowAll = 'SET_SHOW_ALL',
  SetStudentsAccommodations = 'SET_STUDENTS_ACCOMMODATIONS',
  SetStudentSubjectAccommodations = 'SET_STUDENT_SUBJECT_ACCOMMODATIONS',
  SetStudentAccommodationPaging = 'SET_STUDENT_ACCOMMODATION_PAGING',
  SetStudentAccommodationPagination = 'SET_STUDENT_ACCOMMODATION_PAGINATION',
  SetTeachers = 'SET_TEACHERS',
  SetTeachersPaging = 'SET_TEACHERS_PAGING',
  RemoveSelectedCommittee = 'REMOVE_SELECTED_COMMITTEE',
  SelectAssessmentFormatOpt = 'SELECT_ASSESSMENT_FORMAT_OPT',
  RemoveSelectedAssessmentFormatOpt = 'REMOVE_SELECTED_ASSESSEMENT_FORMAT_OPT',
  SetCurrentSigningReqId = 'SET_CURRENT_SIGNING_REQ_ID',
  SetCurrentSigningUrl = 'SET_CURRENT_SIGNING_URL',
  SetLastSign = 'SET_LAST_SIGN',
  SaveSigningStatus = 'SAVE_SIGNING_STATUS',
  ClearSelectedAccomForSubject = 'CLEAR_SELECTED_ACCOM_FOR_SUBJECT',
  ResetSigningStatusForTeachers = 'RESET_SINGING_STATUS_FOR_TEACHERS',
  RemoveTeacherIdFromMapAndSet = 'REMOVE_TEACHER_ID_FROM_MAP_AND_SET',
  AddTeacherToSet = 'ADD_TEACHER_TO_SET',
  AddTeacherToExpanded = 'ADD_TEACHER_TO_EXPANDED',
  RemoveTeacherFromExpanded = 'REMOVE_TEACHER_FROM_EXPANDED',
  SetStudentStaarNotes = 'SET_STUDENT_STAAR_NOTES',
  UpdateStudentStaarNote = 'UPDATE_STUDENT_STAAR_NOTE',
  AddTeacherToNonExitSeenSet = 'ADD_TEACHER_TO_NON_EXIT_SEEN_SET',
  RemoveTeacherToNonExitSeenSet = 'REMOVE_TEACHER_TO_NON_EXIT_SEEN_SET',
  ResetSelectedCommittees = 'RESET_SELECTED_COMMITTEES',
  ResetSelectedAssessmentFormatOpt = 'RESET_SELECTED_ASSESSMENT_FORMAT_OPT',
  ResetNonExitSelections = 'RESET_NON_EXIT_SELECTIONS',
  SetAllTeachersSigned = 'SET_ALL_TEACHERS_SIGNED',
  // Test Mutations
  SetTestState = 'SET_TEST_STATE'
}

export const mutations: MutationTree<StaarAccommodationsState> = {
  [StaarAccommodationMutations.Clear](state) {
    Object.assign(state, cloneDeep(clearState))
  },
  [StaarAccommodationMutations.SelectCommittee](state, selectedCommittee: SelectedAccommodationCommittee) {
    const currentSelection = state.selectedCommittees
    const filteredSelection = currentSelection.filter((sc: SelectedAccommodationCommittee): sc is SelectedAccommodationCommittee => {
      return !(sc.accommodationId === selectedCommittee.accommodationId &&
        sc.studentSchoolGradeId === selectedCommittee.studentSchoolGradeId &&
        sc.subjectId === selectedCommittee.subjectId &&
        sc.teacherId === selectedCommittee.teacherId
      )
    })

    filteredSelection.push(selectedCommittee)

    Vue.set(state, 'selectedCommittees', filteredSelection)
  },

  [StaarAccommodationMutations.SetShowAll](state, { showAll }) {
    Vue.set(state, 'showAll', showAll)
  },

  [StaarAccommodationMutations.SetShowAll](state, { showAll }) {
    Vue.set(state, 'showAll', showAll)
  },

  [StaarAccommodationMutations.SetStudentsAccommodations](state, { studentsMap }) {
    Vue.set(state, 'staarAccommodationsStudents', studentsMap)
  },

  [StaarAccommodationMutations.SetStudentSubjectAccommodations](state, studentAccommodations: StudentAccommodationSubject[]) {
    state.staarAccommodationsStudents = studentAccommodations
  },

  [StaarAccommodationMutations.SetTeachers](state, { teachers, pagination }) {
    Vue.set(state.staarAccommodationsTeachers, 'teachers', teachers)
    Vue.set(state.staarAccommodationsTeachers, 'pagination', pagination)
  },

  [StaarAccommodationMutations.SetTeachersPaging](state, { paging }) {
    Vue.set(state.staarAccommodationsTeachers, 'paging', paging)
  },

  [StaarAccommodationMutations.SetStudentAccommodationPaging](state, paging: StudentPaging) {
    state.staarAccommodationStudentPaging.paging = paging
  },

  [StaarAccommodationMutations.SetStudentAccommodationPagination](state, pagination: Pagination) {
    state.staarAccommodationStudentPaging.pagination = pagination
  },

  [StaarAccommodationMutations.RemoveSelectedCommittee](state, selectedCommittee: SelectedAccommodationCommittee) {
    const currentSelection = state.selectedCommittees
    const filteredSelection = currentSelection.filter((sc: SelectedAccommodationCommittee): sc is SelectedAccommodationCommittee => {
      return !(sc.accommodationId === selectedCommittee.accommodationId &&
        sc.studentSchoolGradeId === selectedCommittee.studentSchoolGradeId &&
        sc.subjectId === selectedCommittee.subjectId &&
        sc.teacherId === selectedCommittee.teacherId
      )
    })

    Vue.set(state, 'selectedCommittees', filteredSelection)
  },

  [StaarAccommodationMutations.ResetNonExitSelections](state) {
    const exitAccommodations: SelectedAccommodationCommittee[] = state.selectedCommittees.filter(accom => !accom.nonExit)
    state.selectedCommittees = exitAccommodations
  },

  [StaarAccommodationMutations.ResetSelectedCommittees](state) {
    state.selectedCommittees = []
  },

  [StaarAccommodationMutations.ResetSelectedAssessmentFormatOpt](state) {
    state.selectedAssessmentFormatOpts = []
  },

  [StaarAccommodationMutations.SelectAssessmentFormatOpt](state, selectedAssessmentFormatOpt: SelectedAssessmentFormatOption) {
    const currentSelections = state.selectedAssessmentFormatOpts
    let entryFound = false
    currentSelections.forEach(safo => {
      if (safo.assessmentId === selectedAssessmentFormatOpt.assessmentId &&
        safo.studentSchoolGradeId === selectedAssessmentFormatOpt.studentSchoolGradeId &&
        safo.subjectId === selectedAssessmentFormatOpt.subjectId &&
        safo.teacherId === selectedAssessmentFormatOpt.teacherId) {
        safo.assessmentFormatOptionId = selectedAssessmentFormatOpt.assessmentFormatOptionId
        entryFound = true
      }
    })
    if (!entryFound) {
      currentSelections.push(selectedAssessmentFormatOpt)
    }

    Vue.set(state, 'selectedAssessmentFormatOpts', currentSelections)
  },
  [StaarAccommodationMutations.RemoveSelectedAssessmentFormatOpt](state, selectedAssessmentFormatOpt: SelectedAssessmentFormatOption) {
    const currentSelections = state.selectedAssessmentFormatOpts
    const filteredSelections = currentSelections.filter((sao: SelectedAssessmentFormatOption): sao is SelectedAssessmentFormatOption => {
      return !(
        sao.assessmentId === selectedAssessmentFormatOpt.assessmentId &&
        sao.studentSchoolGradeId === selectedAssessmentFormatOpt.studentSchoolGradeId &&
        sao.subjectId === selectedAssessmentFormatOpt.subjectId &&
        sao.teacherId === selectedAssessmentFormatOpt.teacherId)
    })
    Vue.set(state, 'selectedAssessmentFormatOpts', filteredSelections)
  },
  [StaarAccommodationMutations.SetCurrentSigningReqId](state, signingReqId: string) {
    state.currentSigningRequestId = signingReqId
  },
  [StaarAccommodationMutations.SetCurrentSigningUrl](state, signingUrl: string) {
    state.currentSigningUrl = signingUrl
  },
  [StaarAccommodationMutations.SetLastSign](state, lastSign: boolean) {
    state.lastSignSuccess = lastSign
  },
  [StaarAccommodationMutations.SaveSigningStatus](state, signingStatusResponse: SigningStatusResponse) {
    state.lastSignSuccess = signingStatusResponse.success
    if (signingStatusResponse.success) {
      if (signingStatusResponse.allSigned) {
        state.allTeachersSigned = true
      }
      const teachers: MeetingStaarTeacher[] = state.staarAccommodationsTeachers.teachers
      teachers.forEach(teacher => {
        if (teacher.teacherId === signingStatusResponse.teacherId) {
          teacher.hasSigned = true
          teacher.signedStatus = 'Signed'
          teacher.accommodationsStatus = AccommodationsSigningStatus.Complete
        }
      })
      state.staarAccommodationsTeachers.teachers = teachers
      Vue.set(state, 'staarAccommodationsTeachers', state.staarAccommodationsTeachers)
    }
  },
  [StaarAccommodationMutations.ClearSelectedAccomForSubject](state, selectedAssessmentFormatOpt: SelectedAssessmentFormatOption) {
    const filteredSelectedCommittees: SelectedAccommodationCommittee[] = state.selectedCommittees.filter(selComm => {
      return !(selComm.studentSchoolGradeId === selectedAssessmentFormatOpt.studentSchoolGradeId &&
      selComm.subjectId === selectedAssessmentFormatOpt.subjectId &&
      selComm.teacherId === selectedAssessmentFormatOpt.teacherId)
    })
    Vue.set(state, 'selectedCommittees',filteredSelectedCommittees)
  },
  [StaarAccommodationMutations.ResetSigningStatusForTeachers](state, teacherIds: number[]) {
    const teachers: MeetingStaarTeacher[] = state.staarAccommodationsTeachers.teachers
    teachers.forEach(teacher => {
      if (teacherIds.indexOf(teacher.teacherId) > -1) {
        teacher.hasSigned = false
        teacher.signedStatus = 'Not Signed'
      }
    })
    state.staarAccommodationsTeachers.teachers = teachers
    state.allTeachersSigned = false
    Vue.set(state, 'staarAccommodationsTeachers', state.staarAccommodationsTeachers)
  },
  [StaarAccommodationMutations.SetAllTeachersSigned](state, allTeachersSigned: boolean) {
    state.allTeachersSigned = allTeachersSigned
  },
  [StaarAccommodationMutations.RemoveTeacherIdFromMapAndSet](state, teacherId: number) {
    state.teachersWithChanges.delete(teacherId)
  },
  [StaarAccommodationMutations.AddTeacherToSet](state, teacherId: number) {
    state.teachersWithChanges.add(teacherId)
  },
  [StaarAccommodationMutations.AddTeacherToExpanded](state, teacherId: number) {
    state.teachersExpanded.add(teacherId)
  },
  [StaarAccommodationMutations.RemoveTeacherFromExpanded](state, teacherId: number) {
    state.teachersExpanded.delete(teacherId)
  },

  [StaarAccommodationMutations.SetStudentStaarNotes](state, studentNoteLst: StudentNote[]) {
    const studentNoteMap: Map<number, StudentNote> = new Map(studentNoteLst.map(studentNote => [studentNote.studentSchoolGrdId, studentNote]))
    state.studentNotesMap = studentNoteMap
  },
  [StaarAccommodationMutations.UpdateStudentStaarNote](state, studentNote: StudentNote) {
    state.studentNotesMap.set(studentNote.studentSchoolGrdId, studentNote)
  },

  [StaarAccommodationMutations.AddTeacherToNonExitSeenSet](state, teacherId: number) {
    state.teacherSeenNonExit.add(teacherId)
  },

  [StaarAccommodationMutations.RemoveTeacherToNonExitSeenSet](state, teacherId: number) {
    state.teacherSeenNonExit.delete(teacherId)
  },

  // Only use this mutation for setting the state for test cases
  [StaarAccommodationMutations.SetTestState] (state, testData: StaarAccommodationsState) {
    Object.assign(state, cloneDeep(testData))
  }

}
